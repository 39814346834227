import { pcScreenStyle } from '@/styles/common'
import React, { HTMLProps } from 'react'

interface ContentWrapperProps extends HTMLProps<HTMLDivElement> {
  children: React.ReactNode
}

export const ContentWrapper: React.FC<ContentWrapperProps> = ({ children, ...props }) => {
  return (
    <div css={pcScreenStyle} {...props}>
      {children}
    </div>
  )
}
