import React from 'react'
import { TailSpin } from 'react-loader-spinner'
import { useAppSelector } from '@/app/hooks'

interface LoadingProps {
  loading: boolean
  children: React.ReactNode
  size?: number
}

export const Loading: React.FC<LoadingProps> = ({ loading, children, size }) => {
  const design = useAppSelector(state => state.design)
  if (loading) {
    return (
      <TailSpin color={design?.icon.color} wrapperStyle={{ justifyContent: 'center', alignItems: 'center', height: '100%' }} width={size || 60} height={size || 60} />
    )
  }

  return <>{children}</>
}
