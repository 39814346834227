import { useAppSelector } from '@/app/hooks'
import { RequestAssetType } from '@/features/asset/assets-slice'
import { usePickByLanguage } from '@/hooks/use-pick-by-language'
import { categoryMenuStyle } from '@/styles/category-menu'
import { pcScreenStyle, textOverflowStyle } from '@/styles/common'
import { css } from '@emotion/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

interface RequestCategoryMenuProps {
  asset: RequestAssetType
}

export const paramsCategoryKey = 'requestCategory'
export const RequestCategoryMenu: React.FC<RequestCategoryMenuProps> = ({ asset }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const pickByLanguage = usePickByLanguage()

  const { t } = useTranslation()

  const design = useAppSelector(state => state.design)

  const paramsCategoryId = searchParams.get(paramsCategoryKey)

  const requestCategories = [{ id: -1, name: t('all') }, ...asset.categories]

  return (
    <div css={[menuStyle, pcScreenStyle]} className="custom-design-block">
      <ul css={[categoryMenuStyle, { boxShadow: 'none' }]}>
        {requestCategories.map(category => {
          const selected = (!paramsCategoryId && category.id <= 0) || Number(paramsCategoryId) === category.id

          return (
            <li
              key={category.id}
              onClick={() => setSearchParams({ [paramsCategoryKey]: category.id.toString(), requestTag: '-1' })}
              css={[
                textOverflowStyle,
                { maxWidth: 100, selected },
                { ...(selected ? { color: design.theme.brandColor, borderBottom: `2px solid ${design.theme.brandColor}` } : {}) },
              ]}
            >
              {typeof category.name === 'string' ? category.name : pickByLanguage(category.name)}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

const menuStyle = css({
  overflowX: 'auto',
  position: 'relative',
  boxShadow: '0px 3px 3px 0px #00000014',
  zIndex: 100,
  ul: {
    width: 'max-content',
    gap: '1.5rem',
    justifyContent: 'unset',
    flexWrap: 'nowrap',
  },
})
