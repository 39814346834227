import { css } from '@emotion/react'
import React from 'react'

export const ZhTwContent: React.FC = () => {
  return (
    <div css={layoutStyle}>
      <div css={administratorStyle}>
        <h2 css={pageTitleStyle}>aipass 服務條款｜設施使用者</h2>

        <p css={updateStyle}>最後更新時間：2022年 4月15日</p>
      </div>

      <h3 css={semiTitleStyle}>第1章 一般規定</h3>

      <h3 css={semiTitleStyle}>第1条（應用）</h3>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          {`本aipass使用條款（以下簡稱「條款」）是基於aipass Inc.（以下簡稱「本公司」）所提供的名為aipass的服務（以下簡稱「服務」）而適用。本公司與使用者之間。`}
        </li>
        <li css={listItemStyle}>
          除本條款外，本公司還可以製定使用規則等有關本服務的各種規定（以下簡稱「個別規定」）。
          這些單獨的規定應構成這些條款的一部分，無論其名稱為何。本條款的規定與前條單項規定相衝突的，以單項規定為準，單項規定另有規定的除外。
        </li>
      </ol>

      <h3 css={semiTitleStyle}>第2條（定義）</h3>
      <p css={leadTextStyle}>在這些條款中，下列各項中列出的術語的含義應按各項中的規定。</p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          成員
          <br />
          {`同意本條款、註冊註冊資料並與本公司就本服務的使用簽訂合約（以下簡稱「使用協議」）的個人。`}
        </li>
        <li css={listItemStyle}>
          使用者
          <br />
          同意本條款後與本公司簽訂使用協議，在未註冊註冊資訊的情況下訪問並使用本服務的人。
        </li>
        <li css={listItemStyle}>
          用者
          <br />
          指會員和用戶。
        </li>
        <li css={listItemStyle}>
          這個網站
          <br />
          指由本公司經營的提供此項服務的網站。 本服務透過本網站或下一期定義的本軟體提供給使用者。
        </li>

        <li css={listItemStyle}>
          這個軟體
          <br />
          是指我公司為了提供本服務而創建、發布的應用程式和軟體，包括更新版本、修改版本、替代版本和副本。
        </li>
        <li css={listItemStyle}>
          註冊資訊
          <br />
          是指為了成為本服務的會員而需要註冊的本公司規定的會員資訊。
        </li>
        <li css={listItemStyle}>
          會員資訊
          <br />
          指公司為本服務管理的伺服器上儲存的各類資訊、通訊記錄及其他會員資訊，包括註冊資訊以及本公司提供的或會員透過本服務所獲得的資訊。
        </li>
      </ol>

      <h3 css={semiTitleStyle}>第3條（條款與條件的變更）</h3>
      <p css={leadTextStyle}>本公司可隨時更改本條款的內容，無需事先徵得用戶同意。</p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          若本公司變更本條款的內容並依aipass使用條款的規定通知使用者變更內容，本公司應在通知中指定的日期後通知使用者（但是，如果通知中未指定日期）若您於發佈日使用本服務，則視為您已同意修改後的條款，並適用修改後的條款。
          若會員未在通知規定的期限內辦理取消手續，則視為會員同意修改後的條款。
        </li>
      </ol>

      <h3 css={semiTitleStyle}>第4條（通知）</h3>
      <p css={leadTextStyle}>
        當本公司通知使用者有關本服務時，本公司可能會使用以下方法，例如在本網站上發布或向作為註冊資訊註冊的電子郵件地址/地址發送電子郵件或文件。以我們認為適當的方式。
      </p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          透過前款規定的方法發出的通知，在前者的情況下，在通知內容在本網站上發佈時生效，在後者的情況下，在公司發送電子郵件或文件時生效。做。
        </li>
        <li css={listItemStyle}>有關本服務的查詢以及使用者向本公司發出的其他通訊或通知應按照本公司確定的方式進行。</li>
      </ol>

      <h3 css={semiTitleStyle}>第2章 契約的成立</h3>
      <h3 css={semiTitleStyle}>第5條（本使用協議的訂立（接納））</h3>
      <p css={leadTextStyle}>
        當希望使用本服務的人依照本公司指定的方法提出申請並經本公司受理時，本使用協議即生效。
        當希望使用本服務的人透過本公司規定的方式提出申請時，即視為申請人已同意本條款。
      </p>
      <ol css={listStyle}>
        <li css={listItemStyle}>會員自本公司受理前款規定的申請之日起即可使用本服務。</li>
        <li css={listItemStyle}>
          未成年人如欲使用本服務，須取得法定代理人的同意。 未成年人成為會員，即視為其法定代表人已同意使用本服務及本條款的內容。
        </li>
      </ol>

      <h3 css={semiTitleStyle}>第第6（使用費及支付方式）</h3>
      <p css={leadTextStyle}>
        使用本服務所需的通訊費用（包括下載、使用本軟體所產生的通訊費用）及通訊設備等，由使用者自行承擔費用及責任。
        本公司不保證本網站及本軟體能夠在使用者使用的通訊設備上正常運作。
      </p>

      <h3 css={semiTitleStyle}>第3章 使用服務注意事項</h3>
      <h3 css={semiTitleStyle}>第7條（會員ID等）</h3>
      <p css={leadTextStyle}>
        會員應自行負責嚴格管理申請本服務時指定的會員ID（電子郵件地址）及會員密碼（以下簡稱「會員ID等」），並對所採取的一切行為負責。
      </p>
      <ol css={listStyle}>
        <li css={listItemStyle}>會員不得允許除自己以外的任何人使用本服務。</li>
        <li css={listItemStyle}>如果會員發現其會員密碼或會員ID被盜或被第三方使用，會員應立即通知公司並遵循公司的指示。</li>
      </ol>

      <h3 css={semiTitleStyle}>第8條（註冊資訊變更）</h3>
      <p css={leadTextStyle}>
        註冊資訊如有變更，會員應及時依照公司規定的方式通知會員。 對於因未通知註冊資訊變更而對會員造成的任何損失，本公司不承擔任何責任。
      </p>

      <h3 css={semiTitleStyle}>第9條（禁止行為）</h3>
      <p css={leadTextStyle}>使用者不得從事與本服務有關的下列各項所規定的行為。</p>
      <ol css={listStyle}>
        <li css={listItemStyle}>向本公司作出虛假申報的行為</li>
        <li css={listItemStyle}>將本公司依本使用協議提供的資訊和服務（包括本網站和本軟體）用於本服務以外的目的的行為。</li>
        <li css={listItemStyle}>
          侵害或可能侵害本公司或第三方的財產（包括智慧財產權）、隱私權、榮譽、信託、肖像權或形象權或其他權益的行為。
        </li>
        <li css={listItemStyle}>侵害或可能侵犯前項以外的公司或第三人權益的行為。</li>
        <li css={listItemStyle}>違反或可能違反法律或公共秩序和道德的行為。</li>
        <li css={listItemStyle}>冒用或取得第三方會員ID等的行為，或使用本服務的會員冒充第三方的其他行為。</li>
        <li css={listItemStyle}>使用或傳播電腦病毒等有害程式的行為或可能這樣做的行為。</li>
        <li css={listItemStyle}>除前項規定外，本公司認為有可能幹擾公司業務執行、服務實施或公司通訊設施的行為，例如未經授權的存取行為。</li>
        <li css={listItemStyle}>其他本公司認為不適當的行為。</li>
      </ol>

      <h3 css={semiTitleStyle}>第10條（入住設施並同意住宿設施的條款）</h3>
      <p css={leadTextStyle}>當使用者使用本服務入住住宿設施時，即視為使用者已申請該住宿設施並同意該住宿設施的條款與條件。</p>

      <h3 css={semiTitleStyle}>第4章 取消/取消/撤銷</h3>
      <h3 css={semiTitleStyle}>第11條（會員取消本服務）</h3>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          會員欲解除本服務協議時，應依公司規定的方式辦理解除手續，解除手續完成後，服務使用合約即告解除。
          此時，會員應自行負責確認公司所發出的取消通知。 若您希望終止使用各項單項服務，您應依照各項單項服務規定的終止程序進行操作。
        </li>
        <li css={listItemStyle}>會員依第1款解除本使用協議時，本公司可刪除其會員資料。</li>
      </ol>

      <h3 css={semiTitleStyle}>第12條（本公司解除合約）</h3>
      <p css={leadTextStyle}>
        若用戶有下列情形之一的，本公司將在不通知用戶的情況下，取消部分或全部本使用協議，並要求用戶退出會員資格，您可以自行處置或停止提供本服務。
      </p>
      <ol css={listStyle}>
        <li css={listItemStyle}>如果您有任何違反本條款任何規定的行為。</li>
        <li css={listItemStyle}>若向本公司提供的全部或部分註冊資訊有虛假、錯誤或遺漏的情況。</li>
        <li css={listItemStyle}>
          反社會勢力等（組織犯罪集團、組織犯罪集團成員、右翼團體、反社會勢力、組織犯罪集團準成員、敲詐勒索者、社會運動假面舞會、特殊情報暴力組織）
          <ol css={innerListStyle}>
            <li>它意味著與此同等的任何其他人。 與下面相同。 ），透過資助或其他方式配合反社會勢力等的維護、運作或管理。</li>
            <li>或與反社會勢力等有任何形式的往來或參與，或有反社會勢力等實質參與管理。</li>
            <li>如果公司合理地確定公司是法人等</li>
          </ol>
        </li>
        <li css={listItemStyle}>如果存在費用等付款義務的違約。</li>
        <li css={listItemStyle}>如果超過90天我公司沒有回复</li>
        <li css={listItemStyle}>如果您過去曾被取消此服務。</li>
        <li css={listItemStyle}>本公司合理判斷用戶不適合作為用戶的其他情況。</li>
      </ol>
      <p css={leadTextStyle}>
        即使您依據前款規定退出會員資格或停止提供本服務，本公司也不會退還已收取的使用費，並且對於給用戶或第三方造成的任何損害或不利，我們不承擔任何責任。
      </p>

      <h3 css={semiTitleStyle}>第13條（剷除組織犯罪集團等反社會勢力）</h3>
      <p css={leadTextStyle}>
        在使用本服務時，使用者可能會將自己標識為有組織犯罪集團的成員、組織犯罪集團的成員、組織犯罪集團的附屬公司、企業敲詐勒索者、社會活動家、政治活動家、特殊情報人員暴力團體或其他反社會勢力（以下簡稱「反組織團夥等」）。我們特此聲明，我們不屬於或屬於「社會勢力」範疇，我們不參與其中與有組織的犯罪集團或其他反社會勢力合作，而我們確認將來不會屬於、落入或捲入其中。
      </p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          若本公司認定使用者屬於、落入或參與有組織犯罪集團或其他反社會勢力，本公司將採取暫停使用本服務或終止合約的措施，恕不另行通知。
        </li>
        <li css={listItemStyle}>對於因使用者違反本條規定而導致停止使用或取消使用而造成的任何損害，本公司不承擔任何義務或責任。</li>
      </ol>

      <h3 css={semiTitleStyle}>第5章 服務的暫停、變更與終止</h3>
      <h3 css={semiTitleStyle}>第14條（暫停服務）</h3>
      <p css={leadTextStyle}>發生下列情況之一的，本公司可在不事先通知用戶的情況下，暫停部分或全部服務。</p>
      <ol css={listStyle}>
        <li css={listItemStyle}>提供本服務所需的系統、設備等發生故障或需要維護、保養、施工時。</li>
        <li css={listItemStyle}>由於電信業者停止提供電信服務等本公司以外的第三方的行為而導致本服務的提供變得困難時。</li>
        <li css={listItemStyle}>若因發生緊急狀況（天災、戰爭、恐怖主義、暴動、暴動、政府行為、勞動爭議等）而導致或可能難以提供本服務</li>
        <li css={listItemStyle}>如果由於可同步服務周圍的情況導致可同步服務變得不可用。</li>
        <li css={listItemStyle}>因法律法規、行政命令等原因導致本服務難以提供時</li>
        <li css={listItemStyle}>
          因其他非本公司原因，本公司認為必要或不可避免的。
          <br />
          對於因依據前款規定暫停服務而對用戶或任何第三方造成的任何損害或不利，本公司不承擔任何責任。
        </li>
      </ol>

      <h3 css={semiTitleStyle}>第15條（服務變更）</h3>
      <p css={leadTextStyle}>
        我們可能會自行決定新增或變更本服務的某些內容。 本公司不保證因根據本條對服務進行添加或變更而維持變更前服務的所有功能和性能。
        <br />
        對於因前款增加或變更服務而對用戶造成的任何損害或不利，本公司不承擔任何責任。
      </p>

      <h3 css={semiTitleStyle}>第16條（服務的取消和終止）</h3>
      <p css={leadTextStyle}>
        本公司可在事先通知會員後，自行決定暫停或終止提供部分或全部服務。
        但是，如果取消或終止的內容並不嚴重，我們可能會在不另行通知的情況下這樣做。
        <br />
        對於因前款規定暫停或終止服務而對用戶造成的任何損害，本公司不承擔責任。
      </p>

      <h3 css={semiTitleStyle}>第17條（契約地位的轉移等）</h3>
      <p css={leadTextStyle}>
        未經本公司事先同意，使用者不得將本使用者協議項下的地位或基於本使用者協議的權利或義務轉讓、轉移、設定抵押或以其他方式處分給第三方。
        <br />
        若公司透過業務轉讓、公司分立或其他方式將與本服務相關的業務轉讓給第三方（以下簡稱「業務轉讓等」），公司將基於以下合約地位、權利和義務根據本《用戶協議》，有關用戶的資訊可能會被轉讓和轉讓給業務轉讓等的受讓人，並且用戶應事先同意該轉讓和轉讓。
      </p>

      <h3 css={semiTitleStyle}>第6章 一般規定</h3>
      <h3 css={semiTitleStyle}>第18條（保固）</h3>
      <p css={leadTextStyle}>
        我們將盡一切合理努力確保服務在建議的環境中運作。 但本公司不保證用戶資訊的準確性、有效性、有用性、完整性等。
        使用者應根據自己的判斷和責任，在必要時進行更改、更正等後使用使用者資訊。
      </p>

      <h3 css={semiTitleStyle}>第19條（智慧財產權）</h3>
      <p css={leadTextStyle}>
        與本服務相關的所有版權、精神權、專利權、實用新型權、設計權、商標權、宣傳權等智慧財產權均屬於本公司及作為合法權利持有人的第三方。並不表示允許超出使用本服務所需的範圍使用智慧財產權。
      </p>

      <h3 css={semiTitleStyle}>第20條（損害賠償和豁免）</h3>
      <p css={leadTextStyle}>
        若因本服務而對使用者造成任何損害，本公司被認定有過失，則本公司應就造成損害的直接原因的服務向使用者賠償最高 1,000
        日圓的損失。我們對任何其他損害不承擔任何責任。
      </p>
      <ol css={listStyle}>
        <li css={listItemStyle}>若使用者因違反本條款或因使用本服務而造成公司損失的，使用者必須賠償對本公司造成的損失。</li>
        <li css={listItemStyle}>
          若用戶與第三方就本服務發生爭議，用戶應自行承擔責任及費用解決，且不得對本公司造成任何不便，亦不得對本公司造成任何損害。公司結果。（包括律師費）。
        </li>
      </ol>

      <h3 css={semiTitleStyle}>第21條（委託）</h3>
      <p css={leadTextStyle}>本公司可能將與本服務相關的部分或全部工作外包給第三方。</p>

      <h3 css={semiTitleStyle}>第22條（資訊管理）</h3>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          未經會員事先同意，本公司不會向任何第三方透露會員資料。 但是，以下情況除外。
          <ol css={innerListStyle}>
            <li>當我們收到法律或公共機構的請求並認識到需要遵守該請求時。</li>
            <li>為保護人的生命、身體或財產而有必要且難以獲得會員同意時。</li>
            <li>當使用本服務的註冊用戶的資料存入支付系統公司、信貸公司、銀行等，以便向會員收取使用費時。</li>
            <li>向受託人、代理人等揭露提供本服務或改善或改善功能所需的資訊時。</li>
            <li>在本公司與子公司共同使用的情況下提供協同服務。</li>
          </ol>
        </li>
        <li css={listItemStyle}>
          儘管有前款規定，公司仍會匯總和分析用戶信息的屬性，並創建經過處理的信息，以便無法識別或識別會員（以下簡稱“統計數據”），並使用該信息來改進服務以及公司的業績。它可以用於其他服務。
          我們也可能向第三方揭露統計資料。
        </li>
        <li css={listItemStyle}>公司將針對會員資訊遺失、毀損、竄改、外洩等風險採取最大限度合理的安全措施。</li>
        <li css={listItemStyle}>為了提高電話應答質量，公司可能會對與用戶的電話交談進行錄音，並在業務運營中使用錄音內容。</li>
      </ol>

      <h3 css={semiTitleStyle}>第23條（個人資料）</h3>
      <p css={leadTextStyle}>
        儘管有前條的規定，本公司對個人資訊的處理將依照本公司的隱私權政策的規定進行。
        <br />
        即使本使用協議終止後，本公司也可以在隱私權政策中規定的使用目的範圍內使用會員的個人資訊。
      </p>

      <h3 css={semiTitleStyle}>第24條（禁止轉讓權利義務）</h3>
      <p css={leadTextStyle}>
        未經本公司事先書面同意，會員不得將本使用者協議項下的權利義務轉讓給第三方（包括因合併、公司分立等原因而全面繼承）或為安全目的而提供。
      </p>

      <h3 css={semiTitleStyle}>第25條（適用法律、管轄法院）</h3>
      <p css={leadTextStyle}>
        這些條款和本使用者協議應由日本法律解釋和管轄。
        <br />
        如果用戶與公司之間就本用戶協議發生爭議，東京地方法院擁有一審專屬管轄權。
      </p>

      <p css={[revisionStyle, { marginTop: '32px' }]}>2019年09月27日 頒布</p>
      <p css={revisionStyle}>2019年11月15日 修訂</p>
      <p css={revisionStyle}>2020年01月08日 修訂</p>
      <p css={revisionStyle}>2020年10月29日 修訂</p>
      <p css={revisionStyle}>2021年07月28日 修訂</p>
      <p css={revisionStyle}>2022年04月15日 修訂</p>
    </div>
  )
}

const layoutStyle = css({
  marginTop: '5rem',
  paddingRight: '2.5rem',
  paddingLeft: '2.5rem',
  lineHeight: '1.625',
})

const pageTitleStyle = css({
  fontWeight: '600',
  textAlign: 'center',
  fontSize: '20px',
})

const administratorStyle = css({
  textAlign: 'center',
})

const updateStyle = css({
  marginTop: '24px',
  marginBottom: '24px',
  fontSize: '14px',
  color: '#475569',
})

const semiTitleStyle = css({
  fontWeight: '600',
  marginTop: '32px',
  marginBottom: '8px',
})

const leadTextStyle = css({
  fontSize: '12px',
  marginTop: '1.5rem',
  marginBottom: '1.5rem',
  lineHeight: '1.625',
})

const listStyle = css({
  paddingLeft: '1rem',
  paddingRight: '1rem',
  marginBottom: '1rem',
  listStyleType: 'decimal',
})

const listItemStyle = css({
  fontSize: '12px',
  lineHeight: '1.625',
  listStyleType: 'decimal',
})

const innerListStyle = css({
  marginRight: '32px',
  marginLeft: '32px',
  listStyleType: 'lower-latin',
})

const revisionStyle = css({
  fontWeight: '600',
  fontSize: '14px',
  marginBottom: '4px',
  '&:first-of-type': {
    marginTop: 0,
  },
})
