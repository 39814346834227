import { http } from '@/apis/aipass'
import { env } from '@/libs/env'
import { inputKatakana } from '@/utils/zod-validate-map'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { z } from 'zod'

export interface MembershipInfo {
  id: string
  lastName: string
  firstName: string
  familyNameKana: string
  givenNameKana: string
  telephone: string
  postalCode: string | null
  address: string
}

export const memberSchema = z.object({
  lastName: z.string().min(1),
  firstName: z.string().min(1),
  postalCode: z.string().max(7).nullish(),
  telephone: z.string().min(1),
  address: z.string().min(1),
})
export const kanaSchema = z.object({
  familyNameKana: inputKatakana(),
  givenNameKana: inputKatakana(),
})

export const checkMembershipValid = (userData, lang) => {
  if (!userData) return false

  if (lang === 'ja') {
    return !!(userData['name'] && userData['nameKana'] && userData['address'] && userData['telephone'])
  }

  return !!(userData['name'] && userData['address'] && userData['telephone'])
}

export const putMembershipInfo = createAsyncThunk('user/putMembershipInfo', async (userData: MembershipInfo, { rejectWithValue }) => {
  try {
    const user = {
      name: `${userData.lastName} ${userData.firstName}`,
      nameKana: userData.familyNameKana && userData.givenNameKana ? `${userData.familyNameKana} ${userData.givenNameKana}` : null,
      telephone: userData.telephone,
      address: userData.address,
      postalCode: userData.postalCode,
    }

    const response = await http({
      method: 'put',
      url: `${env('BASE_API_V3')}/me/membership-info`,
      data: { user },
    })

    return { ...user, id: response.data.userId }
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const putMembershipInfoSlice = createSlice({
  name: 'membership',
  initialState: {},
  reducers: {},
})

export const membershipReducer = putMembershipInfoSlice.reducer
