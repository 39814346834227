import React, { useEffect } from 'react'
import { NavigateHeader } from '@/components/atoms/navigate-header'
import { Layout } from '@/components/template/layout'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Input } from '@/components/atoms/input'
import { Button } from '@/components/atoms/button'
import { css } from '@emotion/react'
import { horizontalSpacing, pcScreenStyle } from '@/styles/common'
import { useAppDispatch } from '@/app/hooks'
import { setCheckoutReservations } from '@/features/checkout/checkout-reservation-slice'
import { getStayReservations, schema, StayReservationParams } from '@/features/reservation/stay-reservation-slice'
import { useQuerySearchParams } from '@/hooks/use-query-search-params'
import { useNavigate } from 'react-router-dom'
import { MessageType, toastAction } from '@/features/notification/toast-slice'
import { clearCheckoutResultHistories } from '@/features/checkout/checkout-data-slice'
import { CUSTOM_BORDER, CUSTOM_CARD } from '@/components/template/custom-design-style'
import { RadioButton } from '@/components/atoms/radio-button'
import { SearchType } from '@/features/checkIn/search-reservations-slice'
import { multipleSelectBoxStyle } from '@/styles/form'
import { zodResolver } from '@hookform/resolvers/zod'
import { errorHandler } from '@/libs/errors'

export const CheckoutSearch: React.FC = () => {
  const { t } = useTranslation()
  const { hotelId } = useQuerySearchParams<{ hotelId: string }>()

  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const {
    handleSubmit,
    watch,
    register,
    resetField,
    formState: { isValid },
  } = useForm<StayReservationParams & { searchType: SearchType }>({
    mode: 'all',
    resolver: zodResolver(schema),
  })

  const watchSearchType = watch('searchType')

  const onSubmit: SubmitHandler<StayReservationParams> = async submitData => {
    dispatch(setCheckoutReservations(null))

    const params = {
      hotelId,
      telephone: submitData.telephone,
      reservationId: submitData.reservationId,
      givenName: submitData.givenName,
      familyName: submitData.familyName,
    }

    try {
      const stayReservations = await dispatch(getStayReservations(params)).unwrap()

      if (!stayReservations?.length) {
        return dispatch(
          toastAction.setMessage(MessageType.Error, t('Registration information not found. Please change the criteria and try again.')),
        )
      }

      if (stayReservations.some(reservation => reservation.frontSupported)) {
        dispatch(setCheckoutReservations(stayReservations))
        return navigate({ pathname: '/checkout/thanks', search: `hotelId=${hotelId}` })
      }

      return navigate({
        pathname: '/checkout/select-reservation',
        search: `hotelId=${hotelId}&telephone=${submitData.telephone ?? ''}&reservationId=${submitData.reservationId ?? ''}&givenName=${
          submitData.givenName ?? ''
        }`,
      })
    } catch (error) {
      errorHandler({ error })
    }
  }

  useEffect(() => {
    dispatch(clearCheckoutResultHistories())
  }, [])

  useEffect(() => {
    resetField('familyName')
    resetField('givenName')
    resetField('telephone')
    resetField('reservationId')
  }, [watchSearchType])

  return (
    <Layout>
      <NavigateHeader title={t('Check-out')} style={{ marginBottom: '1rem' }} />
      <p css={leadTextStyle}>{t('Please enter your reservation information')}</p>
      <form onSubmit={handleSubmit(onSubmit)} css={pcScreenStyle}>
        <div className={CUSTOM_CARD}>
          <div css={listStyle} className={CUSTOM_BORDER}>
            <RadioButton
              {...register('searchType')}
              radioSize="middle"
              checked={watchSearchType === SearchType.Phone}
              customLabelCss={radioButtonStyle}
              value={SearchType.Phone}
              label={t('Phone number of your reservation')}
            />
            {watchSearchType === SearchType.Phone && (
              <Input radiusStyle {...register('telephone')} placeholder="08012345678" type="tel" itemCss={inputStyle} />
            )}
          </div>
          <div css={listStyle} className={CUSTOM_BORDER}>
            <RadioButton
              {...register('searchType')}
              radioSize="middle"
              checked={watchSearchType === SearchType.ReservationId}
              customLabelCss={radioButtonStyle}
              value={SearchType.ReservationId}
              label={t('Reservation ID')}
            />
            {watchSearchType === SearchType.ReservationId && (
              <Input radiusStyle {...register('reservationId')} placeholder="aipass001" itemCss={inputStyle} />
            )}
          </div>
          <div css={listStyle} className={CUSTOM_BORDER}>
            <RadioButton
              {...register('searchType')}
              radioSize="middle"
              checked={watchSearchType === SearchType.GuestName}
              customLabelCss={radioButtonStyle}
              value={SearchType.GuestName}
              label={t('reservation name or guest name')}
            />
            {watchSearchType === SearchType.GuestName && (
              <div css={[multipleSelectBoxStyle, inputStyle]}>
                <Input required {...register('familyName')} placeholder={t('Last name')} />
                <Input required {...register('givenName')} placeholder={t('First name')} />
              </div>
            )}
          </div>
        </div>

        <Button disabled={!isValid} buttonCss={[horizontalSpacing, buttonStyle]} text={t('Next')} type="submit" />
      </form>
    </Layout>
  )
}

const radioButtonStyle = css({
  fontWeight: 'bold',
  marginBottom: 0,
})

const buttonStyle = css({
  marginTop: '2rem',
})

const listStyle = css({
  padding: '1rem 1.5rem',
  borderTop: 'none',
  '&:last-of-type': {
    border: 'none',
  },
})

const inputStyle = css({
  marginTop: '1rem',
})

const leadTextStyle = css({
  textAlign: 'center',
  fontSize: '14px',
  paddingBottom: 16,
})
