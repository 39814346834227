import React from 'react'
import { css } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '@/app/hooks'

interface TitleWithCategoryProps {
  facilityName: string
  subCategory: string
  isFavorite: boolean
  ellipsis?: boolean
}

export const TitleWithSubCategory: React.FC<TitleWithCategoryProps> = ({ facilityName, subCategory, isFavorite, ellipsis }) => {
  const { t } = useTranslation()

  const design = useAppSelector(state => state.design)

  return (
    <>
      <h3 css={titleBlockStyle}>
        <span css={[titleStyle, ellipsis ? ellipsisTextStyle : null]}>{facilityName}</span>
        {isFavorite && (
          <span css={[popularStyle, { color: design.theme.brandColor, borderBlockColor: design.theme.brandColor }]}>{t('popular')}</span>
        )}
      </h3>
      <p css={typeNameStyle}>{subCategory}</p>
    </>
  )
}

const titleBlockStyle = css({
  display: 'flex',
  alignItems: 'flex-start',
  gap: '0.25rem',
  marginBottom: '0.5rem',
  lineHeight: '1.31',
  fontSize: '16px',
  fontWeight: 'bold',
})

const typeNameStyle = css({
  fontSize: '12px',
})

const titleStyle = css({
  width: 100,
  flexGrow: 1,
  marginTop: -4,
})

const popularStyle = css({
  fontSize: '10px',
  border: '1px solid',
  borderRadius: '2px',
  paddingInline: '0.25rem',
  fontWeight: 'normal',
  minWidth: 'fit-content',
})

const ellipsisTextStyle = css({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
})
