import dayjs from 'dayjs'

export const dateOptions = Array.from(Array(15)).map((_, index) => {
  return {
    value: dayjs().add(index, 'days').format('YYYY/MM/DD'),
    label: dayjs().add(index, 'days').format('YYYY/MM/DD'),
  }
})

export const guestsOptions = Array.from(Array(20)).map((_, index) => {
  return {
    value: `${index + 1}`,
    label: `${index + 1}人`,
  }
})
