import { explanatoryNoteStyle, labelStyle, multipleSelectBoxStyle } from '@/styles/form'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { RequiredMark } from '../../atoms/required-mark'
import { RadioButton } from '../../atoms/radio-button'
import { spacerTopStyle } from '@/styles/common'
import { Input } from '../../atoms/input'
import { css } from '@emotion/react'
import { SelectBox } from '../../atoms/select-box'
import { isEmployeeByOccupation, isOtherByOccupation, occupationOptions } from '@/utils/form/occupation'
import { CheckInEditFormType } from '@/app/store'
import { Controller, UseFormReturn } from 'react-hook-form'
import { ErrorMessage } from '@/components/atoms/error-message'
import { RHFSearchAddress } from '@/components/molecules/rhf-search-address'

interface RegisterAccountEditFormProps {
  useFormReturn: UseFormReturn<CheckInEditFormType>
}

export const RegisterAccountEditForm: React.FC<RegisterAccountEditFormProps> = ({ useFormReturn }) => {
  const { t } = useTranslation()

  const {
    control,
    setValue,
    register,
    watch,
    formState: { errors },
  } = useFormReturn

  const watchNationality = watch('nationality')
  const watchOccupation = watch('occupation')

  const renderBirthDateError = () => {
    const errorMessage = errors.birthYear?.message || errors.birthMonth?.message || errors.birthDay?.message || errors.birthDate?.message
    if (errorMessage) {
      return <ErrorMessage text={errorMessage} />
    }
  }

  const setAddressValue = (addressText: string) => {
    setValue('address', addressText)
  }

  return (
    <>
      <label css={labelStyle}>
        {t('Nationality')}
        <RequiredMark />
      </label>
      <div css={[multipleRadioButtonStyle, multipleSelectBoxStyle]}>
        <Controller
          control={control}
          name="nationality"
          render={({ field }) => (
            <RadioButton label={t('Japanese')} checked={field.value === 'JPN'} name={field.name} value="JPN" onChange={field.onChange} />
          )}
        />

        <Controller
          control={control}
          name="nationality"
          render={({ field }) => (
            <RadioButton
              label={t('non-Japanese')}
              checked={field.value === 'NJP'}
              name={field.name}
              value="NJP"
              onChange={field.onChange}
            />
          )}
        />
      </div>

      <>
        <label css={[spacerTopStyle, labelStyle]}>
          {t('Your name')}
          <RequiredMark />
        </label>
        <div css={[multipleSelectBoxStyle]}>
          <Input {...register('lastName')} required placeholder={t('Last name')} error={errors.lastName?.message} />
          <Input {...register('firstName')} required placeholder={t('First name')} error={errors.firstName?.message} />
        </div>
      </>

      {watchNationality === 'JPN' && (
        <>
          <label css={[spacerTopStyle, labelStyle]}>
            {t('Furigana')}
            <RequiredMark />
          </label>
          <div css={[multipleSelectBoxStyle]}>
            <Input {...register('familyNameKana')} required placeholder={t('Surname')} error={errors.familyNameKana?.message} />
            <Input {...register('givenNameKana')} required placeholder={t('Surname')} error={errors.givenNameKana?.message} />
          </div>
        </>
      )}

      <div css={spacerTopStyle}>
        <label css={labelStyle}>
          {t('Date of birth')}
          <RequiredMark />
        </label>

        <div css={[multipleSelectBoxStyle, birthDateWidth]}>
          <Input {...register('birthYear', { deps: ['birthDay'] })} type="number" required placeholder="1985" />
          <Input {...register('birthMonth', { deps: ['birthDay'] })} type="number" required placeholder="01" />
          <Input {...register('birthDay')} type="number" required placeholder="01" />
        </div>
        {renderBirthDateError()}
      </div>

      <div css={spacerTopStyle}>
        <label css={labelStyle}>
          {t('Gender')}
          <RequiredMark />
        </label>

        <Controller
          control={control}
          name="gender"
          render={({ field }) => (
            <div css={[multipleRadioButtonStyle, multipleSelectBoxStyle]}>
              <RadioButton label={t('Male')} checked={field.value === 'M'} name={field.name} value="M" onChange={field.onChange} />
              <RadioButton label={t('Female')} checked={field.value === 'F'} name={field.name} value="F" onChange={field.onChange} />
            </div>
          )}
        />
      </div>

      <div css={spacerTopStyle}>
        <Input
          {...register('telephone')}
          type="number"
          required
          label={t('Phone number')}
          placeholder="0123456789"
          error={errors.telephone?.message}
        />
      </div>

      {watchNationality === 'JPN' && <RHFSearchAddress control={control} name="postalCode" setAddressValue={setAddressValue} />}

      <div css={spacerTopStyle}>
        <Input
          {...register('address')}
          required
          label={t('Address')}
          placeholder={t('Street, City, State, Country')}
          error={errors.address?.message}
        />
      </div>

      <div css={spacerTopStyle}>
        <SelectBox {...register('occupation')} label={t('Occupation')} id="occupation" options={occupationOptions()} />
      </div>

      {isEmployeeByOccupation(watchOccupation) && (
        <div css={spacerTopStyle}>
          <Input {...register('company')} label={t('Company')} placeholder="aipass inc." />
          <p css={explanatoryNoteStyle}>{t('If you would like a receipt with your corporate name, please fill it out')}</p>
        </div>
      )}

      {isOtherByOccupation(watchOccupation) && (
        <div css={spacerTopStyle}>
          <Input {...register('otherOccupation')} label={t('Company')} />
          <p css={explanatoryNoteStyle}>{t('If you would like a receipt with your corporate name, please fill it out')}</p>
        </div>
      )}
    </>
  )
}

const birthDateWidth = css({
  div: {
    width: '30%',
    '&:first-of-type': {
      width: '40%',
    },
  },
})

const multipleRadioButtonStyle = css({
  marginTop: '1rem',
})
