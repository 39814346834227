import React from 'react'
import { css } from '@emotion/react'
import { CarouselItemWrapper } from '@/components/atoms/carousel-item-wrapper'
import { v4 as uuid } from 'uuid'

interface Props {
  images: string[]
  isPc: boolean
}

export const DetailImageDisplay: React.FC<Props> = ({ images, isPc }) => {
  const NoImage = ({ height }) => <div css={[noImageStyle, { height: height }]}>No Image</div>

  const SingleImage = () => (
    <div>
      <img
        src={images[0]}
        style={{
          width: '100%',
          height: 'auto',
          maxHeight: '355px',
          objectFit: 'cover',
        }}
      />
    </div>
  )

  const ImageCarousel = () => (
    <CarouselItemWrapper style={{ width: '100%', gap: '0' }} isShowSnapDisplay>
      {images.map(imagePath => (
        <div key={uuid()} className="embla__slide">
          <img src={imagePath} style={{ width: '100vw' }} />
        </div>
      ))}
    </CarouselItemWrapper>
  )

  if (!images.length) {
    return <NoImage height={isPc ? 355 : 200} />
  }

  return isPc ? <SingleImage /> : <ImageCarousel />
}

const noImageStyle = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#f2f2f2',
  color: '#bfbfbf',
})
