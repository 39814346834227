export const paymentMethodDetail = {
  checkout: {
    code: 0,
    name: 'チェックアウト支払い',
    nameEn: 'checkoutPayment',
  },
  creditCard: {
    code: 1,
    name: 'アイパス決済',
    nameEn: 'aiPassPayment',
  },
  noPaymentMethod: {
    code: 2,
    name: '-',
    nameEn: '-',
  },
}
