import { CSSObject, SerializedStyles, css } from '@emotion/react'
import React, { ComponentPropsWithRef, forwardRef, useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import dayjs, { Dayjs } from 'dayjs'
import ja from 'date-fns/locale/ja'

import { BorderButton, Button } from './button'
import { useTranslation } from 'react-i18next'
import { spacerTopStyle } from '@/styles/common'
import { ErrorMessage } from './error-message'
import { labelStyle } from '@/styles/form'
import { RequiredMark } from './required-mark'
import { CUSTOM_BLOCK, CUSTOM_FORM } from '../template/custom-design-style'

interface DateCalendarProps {
  maxDate?: Dayjs | Date
  customInput?: React.ReactNode
  onSettlement: (date: Date) => void
  onSetValue?: (date: string) => void
  onClose: () => void
  date?: string
}

export const DateCalendar: React.FC<DateCalendarProps> = ({ maxDate, customInput, date, onSettlement, onClose, onSetValue }) => {
  const { t } = useTranslation()
  registerLocale('ja', ja)

  const [selectDate, setSelectDate] = useState<any>(date ? dayjs(date).toDate() : undefined)

  const Container = ({ children }) => {
    return (
      <>
        <div css={containerStyle} className={CUSTOM_BLOCK}>
          {children}
          <div style={{ paddingLeft: '2rem', paddingRight: '2rem', paddingBottom: '1.5rem' }}>
            <Button
              disabled={!selectDate}
              buttonCss={[spacerTopStyle, buttonWidthStyle]}
              text={t('Settlement')}
              onClick={() => {
                onSettlement(selectDate)
                if (onSetValue) {
                  onSetValue(dayjs(selectDate).format('YYYY/MM/DD'))
                }
              }}
            />
            <BorderButton buttonCss={[spacerTopStyle, buttonWidthStyle]} text={t('Cancel')} onClick={() => onClose()} />
          </div>
        </div>
        <div css={overlayStyle}></div>
      </>
    )
  }

  return (
    <>
      <DatePicker
        locale="ja"
        minDate={dayjs().toDate()}
        maxDate={maxDate || undefined}
        selected={selectDate}
        onChange={date => setSelectDate(date)}
        calendarContainer={Container}
        customInput={customInput}
        inline
      />
    </>
  )
}

interface datePickerButtonProps {
  maxDate?: Dayjs | Date
  name: string
  value?: string
  buttonCss?: SerializedStyles | CSSObject
  errorMessage?: string
  label?: string
  required?: boolean
  onSetValue?: (date: string) => void
}
type DatePickerButtonType = ComponentPropsWithRef<'button'> & datePickerButtonProps

export const DatePickerButton: React.FC<DatePickerButtonType> = forwardRef(
  ({ maxDate, name, value, buttonCss, errorMessage, label, required, onSetValue }, ref) => {
    const [isOpen, setIsOpen] = useState(false)
    const [selectDate, setSelectDate] = useState<any>(value)

    const { t } = useTranslation()

    const onSettlement = (date: Date) => {
      setSelectDate(date)
      setIsOpen(false)
    }

    return (
      <>
        {isOpen && (
          <DateCalendar
            maxDate={maxDate}
            date={selectDate}
            onSettlement={onSettlement}
            onClose={() => setIsOpen(false)}
            onSetValue={onSetValue}
          />
        )}
        {label && (
          <label css={labelStyle}>
            {label} {required && <RequiredMark />}
          </label>
        )}
        <div css={ButtonWrapStyle} className="select-wrapper">
          <button
            ref={ref}
            type="button"
            name={name}
            css={[datePickerButtonStyle, buttonCss]}
            onClick={() => setIsOpen(true)}
            id="checkInDate"
            className={CUSTOM_FORM}
          >
            {selectDate ? dayjs(selectDate).format('YYYY/MM/DD') : t('Please select')}
          </button>
        </div>
        {errorMessage && <ErrorMessage text={errorMessage} />}
      </>
    )
  },
)
DatePickerButton.displayName = 'DatePickerButton'

const containerStyle = css({
  width: '100%',
  position: 'fixed',
  top: 'auto',
  bottom: 0,
  left: 0,
  borderTopLeftRadius: '12px',
  borderTopRightRadius: '12px',
  zIndex: 10000,
  '.react-datepicker__navigation': {
    top: '1.5rem',
  },
  '.react-datepicker__navigation--next': {
    right: '8%',
  },
  '.react-datepicker__navigation--previous': {
    left: '8%',
  },
  '.react-datepicker__day-names': {
    paddingTop: '1rem',
  },
  '.react-datepicker__header': {
    background: 'none',
    border: 'none',
    paddingTop: '2rem',
    paddingBottom: '1rem',
  },
  '.react-datepicker__month-container': {
    float: 'none',
  },
})

const ButtonWrapStyle = css({
  width: '100%',
  position: 'relative',
})

const datePickerButtonStyle = css({
  width: '100%',
  height: '44px',
  appearance: 'none',
  textAlign: 'left',
  paddingLeft: '20px',
  '::placeholder': {
    color: '#CCCCCC',
  },
})

const overlayStyle = css({
  width: '100%',
  height: '100vh',
  position: 'fixed',
  left: 0,
  top: 0,
  background: '#000',
  opacity: 0.5,
  zIndex: 1,
})

const buttonWidthStyle = css({
  maxWidth: 350,
  marginInline: 'auto'
})
