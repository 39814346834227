import { css } from '@emotion/react'
import React from 'react'

export const EnContent: React.FC = () => {
  return (
    <div css={layoutStyle}>
      <div css={administratorStyle}>
        <h2 css={pageTitleStyle}>[Second Edition] Information Security Policy</h2>

        <p css={updateStyle}>Last update: April 1, 2024</p>
      </div>

      <p css={leadTextStyle}>
        ＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿ <br />
        ISMS-01_ <br />
        Information Security Policy Statement <br />
        Revised History：3rd edition <br />
        Approved by: Mayumi Yamada <br />
        Contents: Changed of top management name <br />
        Approval Date: April 1, 2024 <br />
        ＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿
      </p>

      <h3 css={semiTitleStyle}>1 Objective</h3>
      <p css={leadTextStyle}>
        {`(hereinafter referred to as "the Company") uses a large number of information assets in the
      planning, development, operation, and sales of web applications and in the management of
      employees (hereinafter referred to as "the business"). Therefore, we recognize that it is not
      only an essential requirement to promote our corporate activities with the trust of society,
      but also a serious social responsibility. Therefore, in consideration of the importance of
      information security, we have established this Information Security Policy (hereinafter
      referred to as "the Policy"), and will establish, implement, maintain, and improve an
      information security management system to specifically implement the Policy.`}
      </p>

      <h3 css={semiTitleStyle}>2 Definition of information security</h3>
      <p css={leadTextStyle}>Information security is defined as the maintenance of confidentiality, integrity, and availability.</p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          Confidentiality <br />
          Confidentiality means that information assets are protected from unauthorized access and are not leaked to unauthorized persons.
          (A characteristic that prevents the use or disclosure of information to unauthorized individuals, entities, or processes.)
        </li>
        <li css={listItemStyle}>
          Integrity <br />
          Integrity means that information assets are protected from falsification and errors and are maintained accurately and completely.
          (Characteristics of accuracy and completeness)
        </li>
        <li css={listItemStyle}>
          Availability <br />
          Availability means that information assets are protected from loss, damage, and system shutdown, and are available when needed.
          (Characteristics of access and use when requested by authorized entities)
        </li>
      </ol>

      <h3 css={semiTitleStyle}>3 Scope of Application</h3>
      <p css={leadTextStyle}>
        This policy applies to all information assets managed by the Company. The scope of information assets is not limited to electronic
        devices and electronic data, but includes all forms of media, including paper.
      </p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          Organization <br />
          Quicken Corporation (all employees)
        </li>
        <li css={listItemStyle}>
          Facilities <br />
          Head Office (Address: Shinoda Building 3F, 23-3 Sakuragaokacho, Shibuya-ku, Tokyo)
        </li>
        <li css={listItemStyle}>
          Business <br />
          Planning, development, operation and sales of web applications
        </li>
        <li css={listItemStyle}>
          Assets <br />
          Documents, data, information systems, and networks related to the above business and various services
        </li>
      </ol>

      <h3 css={semiTitleStyle}>4 Implementation</h3>
      <p css={leadTextStyle}>
        {' '}
        The following items shall be implemented in accordance with this policy and our information security management system.
      </p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          Information security objectives <br />
          We will formulate information security objectives that are consistent with this policy and that take into account applicable
          information security requirements as well as the results of risk assessment and risk response, make them known to all employees,
          and review them periodically as necessary in response to changes in our environment, even if there are no changes.
        </li>
        <li css={listItemStyle}>
          Handling of information assets <br />
          <ol css={listDiscStyle}>
            <li>Access privileges shall be granted only to those who need them for business purposes.</li>
            <li>
              Information shall be managed in accordance with legal and regulatory requirements, contractual requirements, and the
              provisions of our information security management system.
            </li>
            <li>
              Information assets shall be appropriately classified and managed according to their importance in terms of value,
              confidentiality, integrity, and availability.
            </li>
            <li>Information assets shall be continuously monitored to ensure that they are being managed appropriately.</li>
          </ol>
        </li>
        <li css={listItemStyle}>
          Risk assessment <br />
          <ol css={listDiscStyle}>
            <li>
              We will conduct risk assessments and implement appropriate risk responses and control measures for information assets that are
              judged to be the most important based on the characteristics of our business.
            </li>
            <li>Analyze the causes of accidents related to information security and take measures to prevent recurrence.</li>
          </ol>
        </li>
        <li css={listItemStyle}>
          Business continuity management <br />
          We will minimize business interruptions caused by disasters or malfunctions and ensure business continuity capabilities.
        </li>
        <li css={listItemStyle}>
          Education <br />
          We will provide all employees with information security education and training.
        </li>
        <li css={listItemStyle}>
          Compliance with regulations and procedures <br />
          We will comply with the rules and procedures of the information security management system.
        </li>
        <li css={listItemStyle}>
          Compliance with legal and regulatory requirements and contractual requirements <br />
          We will comply with legal and regulatory requirements and contractual requirements related to information security.
        </li>
        <li css={listItemStyle}>
          Continuous improvement <br />
          We will work to continuously improve our information security management system.
        </li>
        <li css={listItemStyle}>
          Policies for information security <br />
          We will establish the following information security policies related to the Information Security Policy.
        </li>
      </ol>
      <p css={leadTextStyle}>
        Policies related to mobile devices <br />
        Access control <br />
        Policy on the use of cryptographic control measures <br />
        Clear desk and clear screen policy <br />
        Policy for information transfer <br />
        Policy for Security Sensitive Development <br />
        Policy on Information Security for Supplier Relationships <br />
      </p>

      <h3 css={semiTitleStyle}>5 Responsibilities, Obligations, and Penalties</h3>
      <p css={leadTextStyle}>
        The responsibility for the information security management system, including this policy, rests with top management, and employees
        in the applicable scope are obligated to comply with the established rules and procedures. Employees who neglect their duties and
        commit violations will be punished in accordance with the employment regulations. Employees of subcontractors will be dealt with in
        accordance with individually stipulated contracts.
      </p>

      <h3 css={semiTitleStyle}>6 Periodic Review</h3>
      <p css={leadTextStyle}>
        The information security management system shall be reviewed periodically and as necessary to maintain and manage the system.
      </p>

      <p css={[leadTextStyle, { marginTop: '2rem' }]}>
        Enacted: April 1, 2021 <br />
        Date of last Revised: April 1, 2024 <br />
        Top Management Mayumi Yamada <br />
      </p>
    </div>
  )
}

const layoutStyle = css({
  marginTop: '5rem',
  paddingRight: '2.5rem',
  paddingLeft: '2.5rem',
  lineHeight: '1.625',
})

const pageTitleStyle = css({
  fontWeight: '600',
  textAlign: 'center',
  fontSize: '20px',
})

const administratorStyle = css({
  textAlign: 'center',
})

const updateStyle = css({
  marginTop: '24px',
  marginBottom: '24px',
  fontSize: '14px',
  color: '#475569',
})

const semiTitleStyle = css({
  fontWeight: '600',
  marginTop: '32px',
  marginBottom: '8px',
})

const leadTextStyle = css({
  fontSize: '12px',
  marginTop: '1.5rem',
  marginBottom: '1.5rem',
  lineHeight: '1.625',
})

const listDiscStyle = css({
  listStyle: 'disc',
  marginRight: '1rem',
  marginLeft: '1rem',
  li: {
    listStyle: 'disc',
  },
})

const listStyle = css({
  paddingLeft: '1rem',
  paddingRight: '1rem',
  marginBottom: '1rem',
  listStyleType: 'decimal',
})

const listItemStyle = css({
  fontSize: '12px',
  lineHeight: '1.625',
  listStyleType: 'decimal',
})
