import React, { PropsWithChildren } from 'react'
import * as Sentry from '@sentry/browser'

import { onlyForLocal, onlyForProd } from '@/utils/check-environment'
import { Error404 } from '@/components/pages/404'

interface State {
  eventId?: string
  hasError: boolean
}

export class ErrorBoundary extends React.Component<PropsWithChildren, State> {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidMount() {
    const initSentry = (): void => {
      if (onlyForLocal) {
        return
      }

      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: process.env.REACT_APP_ENV,
        release: 'aipass_guest@' + process.env.REACT_APP_APP_TAG || 'unknown',
        tracesSampleRate: 0.1,
        integrations: [Sentry.replayIntegration()],
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 1,
      })
    }
    initSentry()
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // Added sending errors to Sentry
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo)
      Sentry.captureException(error)
    })
    this.setState(state => ({ ...state, hasError: true }))
  }
  render() {
    if (this.state.hasError && onlyForProd) {
      return <Error404 />
    }
    return this.props.children
  }
}
