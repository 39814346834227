import { css } from '@emotion/react'
import React from 'react'

export const EnContent: React.FC = () => {
  return (
    <div css={layoutStyle}>
      <div css={administratorStyle}>
        <h2 css={pageTitleStyle}>aipass Terms of Service</h2>

        <p css={updateStyle}>Last update: April 15, 2022</p>
      </div>

      <h3 css={semiTitleStyle}>Chapter 1 General Provisions</h3>

      <h3 css={semiTitleStyle}>Article 1 (Application)</h3>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          {`These aipass Terms of Use (the "Terms"). aipass Corporation (the "Company") A service called aipass (hereinafter referred to as
          the "Service"), which is provided by This applies between the Company and the user with respect to the use of the Company's
          website.`}
        </li>
        <li css={listItemStyle}>
          {`In addition to these terms and conditions, the Company may stipulate various rules (hereinafter referred to as "individual
          provisions") for the use of the Service. These individual provisions, regardless of their names, shall constitute a part of this
          Agreement.`}
        </li>
        <li css={listItemStyle}>
          In the event that the provisions of this Agreement conflict with the individual provisions of the preceding article, the
          individual provisions shall take precedence, unless otherwise specified in the individual provisions.
        </li>
      </ol>

      <h3 css={semiTitleStyle}>Article 2 (Definitions)</h3>
      <p css={leadTextStyle}>
        In these terms and conditions, the meanings of the terms listed in the following items shall be as set forth in those items.
      </p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          Member
          <br />
          {`An individual who has entered into a contract with the Company for the use of the Service after agreeing to these terms and
          conditions (hereinafter referred to as the "User Agreement”) and registering his or her registration information.`}
        </li>
        <li css={listItemStyle}>
          Guest
          <br />A person who accesses and uses the Service by entering into the Service Agreement with the Company without agreeing to these
          Terms and without registering any registration information.
        </li>
        <li css={listItemStyle}>
          User
          <br />
          Members and Guests.
        </li>
        <li css={listItemStyle}>
          This site
          <br />
          The application software that the Company creates and publishes to provide the Service, including updated versions, modified
          versions, substitutes and reproductions.
        </li>
        <li css={listItemStyle}>
          This software
          <br />
          The application software that the Company creates and publishes to provide the Service, including updated versions, modified
          versions, substitutes and reproductions.
        </li>
        <li css={listItemStyle}>
          Registration information
          <br />
          Information set by the Company about a member that is required to be registered in order to become a member of the Service.
        </li>
        <li css={listItemStyle}>
          Membership information
          <br />
          All information of the members of the various information and communication records and other information stored on the server
          managed by the Company for this service. Registration information, including information provided by the Company through the
          Service or acquired by the Member.
        </li>
      </ol>

      <h3 css={semiTitleStyle}>Article 3 (Amendment of Terms of Use)</h3>
      <p css={leadTextStyle}>
        The Company may change the contents of these terms and conditions at any time without obtaining the prior consent of the user.
      </p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          If the Company changes the contents of these Terms of Use and notifies the User of the changes in accordance with the provisions
          of these Terms of Use, if the User uses the Service after the date specified in the notification(however, if the date is not
          specified in the notification, the date shall be the date on which the Company issues the notification), the User is deemed to
          have agreed to the changed Terms of Use and the changed Terms of Use will be applied. If a member does not take the cancellation
          procedure by the date specified in the notice, the member will be deemed to have agreed to the revised terms and conditions.
        </li>
      </ol>

      <h3 css={semiTitleStyle}>Article 4 (Notice)</h3>
      <p css={leadTextStyle}>
        When notifying users in relation to the Service, the Company will use any method that the Company deems appropriate, such as posting
        on this site or sending e-mails or documents to the e-mail address or address registered as registration information.
      </p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          In the case of the former, any notice made in the manner set forth in the preceding paragraph shall take effect at the time the
          contents of the notice are posted on this sitei, and in the case of the latter, at the time the Company sends out an e-mail or
          document.
        </li>
        <li css={listItemStyle}>
          Inquiries about the Service and other communications or notifications from the User to the Company shall be made in the manner
          prescribed by the Company.
        </li>
      </ol>

      <h3 css={semiTitleStyle}>Chapter 2 Conclusion of a Contract</h3>
      <h3 css={semiTitleStyle}>Article 5 (Formation of the User Agreement (admission))</h3>
      <p css={leadTextStyle}>
        This Agreement shall come into effect when a person wishing to use the Service applies for it in the manner specified by the Company
        and the Company accepts it. When a person wishing to use the Service applies for the Service in the manner specified by the Company,
        the person concerned shall be deemed to have agreed to these Terms.
      </p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          {`Members can use the Service from the date of the Company's acceptance of the application in the preceding paragraph.`}
        </li>
        <li css={listItemStyle}>
          If a minor wishes to use the Service, he or she must obtain the consent of his or her legal representative. If a minor becomes a
          member, it is assumed that he or she has the consent of his or her legal representative regarding the use of the Service and the
          contents of this Agreement.
        </li>
      </ol>

      <h3 css={semiTitleStyle}>Article 6 (Usage Fee and Payment Method)</h3>
      <p css={leadTextStyle}>
        Communication costs required to use the Service (including communication costs incurred to download and use the Software). The user
        shall be responsible for the preparation of all necessary equipment, including but not limited to the following The Company does not
        guarantee that this Site and this Software will operate properly on the communication equipment used by the User.
      </p>

      <h3 css={semiTitleStyle}>Chapter 3 Precautions for Use of the Service</h3>
      <h3 css={semiTitleStyle}>Article 7 (Member ID, etc.)</h3>
      <p css={leadTextStyle}>
        {`Members shall use the member ID (email address) and password (hereinafter referred to as "member ID, etc.") specified at the time of
        application for the Service. Members are responsible for any and all actions taken using these materials.`}
      </p>
      <ol css={listStyle}>
        <li css={listItemStyle}> Members may not allow anyone other than themselves to use the Service.</li>
        <li css={listItemStyle}>
          {`If a member's password or member ID is found to have been stolen or used by a third party, the member shall immediately notify the
          Company and follow the Company's instructions.`}
        </li>
      </ol>

      <h3 css={semiTitleStyle}>Article 8 (Change of registration information)</h3>
      <p css={leadTextStyle}>
        If there is any change in the registered information, the member shall promptly notify the Company through the method specified by
        the Company. The Company is not responsible for any damage caused to members due to the failure to notify them of changes to their
        registration information.
      </p>

      <h3 css={semiTitleStyle}>Article 9 (Prohibited acts)</h3>
      <p css={leadTextStyle}> The user shall not engage in any of the following acts in relation to the Service.</p>
      <ol css={listStyle}>
        <li css={listItemStyle}>The act of making a false declaration to the Company</li>
        <li css={listItemStyle}>
          Use the information and services, including this Site and thisSoftware, provided by the Company in accordance with this User
          Agreement for any purpose other than the use of the Service.
        </li>
        <li css={listItemStyle}>
          Property (including intellectual property rights) of the Company or a third party Any act that infringes or is likely to infringe
          on the privacy, honor, credit, portrait or publicity rights, or any other rights or interests
        </li>
        <li css={listItemStyle}>
          Any act that infringes on the rights or interests of the Company or a third party other than the above, or any act that is likely
          to do such infringement.
        </li>
        <li css={listItemStyle}>Actions that violate laws and regulations or are or may be offensive to public order and morals.</li>
        <li css={listItemStyle}>
          {`Unauthorized use or acquisition of a third party's member ID, etc., or any other act in which a member pretends to be a third
          party and uses the Service`}
        </li>
        <li css={listItemStyle}>
          Use or transmission of harmful programs, such as computer viruses, or actions that may lead to such actions.
        </li>
        <li css={listItemStyle}>
          {`In addition to what is provided for in the preceding item, acts that the Company deems to interfere or be likely to interfere with
          the execution of the Company's business, the implementation of the Service, or the Company's communication facilities, etc., such
          as acts of unauthorized access, etc.`}
        </li>
        <li css={listItemStyle}>Any other actions that the Company deems inappropriate.</li>
      </ol>

      <h3 css={semiTitleStyle}>
        Article 10 (Checking in to a Facility and Agreeing to the Terms and Conditions of the Accommodation Facility)
      </h3>
      <p css={leadTextStyle}>
        When a user checks in to a facility using this service, the user is deemed to have applied for the facility and agreed to the terms
        and conditions of the facility.
      </p>

      <h3 css={semiTitleStyle}>Chapter 4 Termination, Cancellation and Withdrawal of Membership</h3>
      <h3 css={semiTitleStyle}>Article 11 (Cancellation of the Service by the Member)</h3>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          When a member cancels this service contract, it is assumed that the cancellation procedure is done by the method prescribed by the
          Company, and upon completion of the cancellation procedure, the service contract shall be cancelled. In this case, the member
          shall, at their own risk, confirm the notice of termination from the Company. When terminating the use of said service for each
          individual service, the termination procedure prescribed for each individual service shall be followed.
        </li>
        <li css={listItemStyle}>
          {`If a member cancels this service agreement in accordance with paragraph 1, the Company may delete the member's information.`}
        </li>
      </ol>

      <h3 css={semiTitleStyle}>Article 12 (Termination of Contract by the Company)</h3>
      <p css={leadTextStyle}>
        {`If a User falls under any of the following items, the Company may terminate this User Agreement in whole or in part without giving
        any notice to the User, cancel the User's membership, or suspend the provision of the Service without any notice to the User.`}
      </p>
      <ol css={listStyle}>
        <li css={listItemStyle}>If User violate any of the provisions of these Terms of Use</li>
        <li css={listItemStyle}>Falsehoods, errors or omissions in all or part of the registration information provided to the Company</li>
        <li css={listItemStyle}>
          Antisocial forces (meaning organized crime groups, gang members, right-wing organizations, antisocial forces, quasi-members of
          organized crime groups, general assembly members, social movement advocates, special intelligence violence groups, and other
          similar persons) (The same shall apply hereinafter). If the Company reasonably judges that the company is a corporation, etc. that
          is a member of an antisocial force, etc. (i.e., a corporation, etc.), that is engaged in any kind of exchange or involvement with
          an antisocial force, etc., such as cooperation or involvement in the maintenance, operation or management of an antisocial force,
          etc. through the provision of funds or otherwise, or that an antisocial force, etc. is substantially involved in the management of
          the company.
        </li>
        <li css={listItemStyle}>In the event of default on payment obligations, such as fees</li>
        <li css={listItemStyle}>If User have not responded to any contact from us for more than 90 days</li>
        <li css={listItemStyle}>If it is found that User have been suspended from the Service in the past</li>
        <li css={listItemStyle}>In the event that the Company reasonably determines that a user is inappropriate for any other purpose</li>
      </ol>
      <p css={leadTextStyle}>
        In the event of withdrawal from the membership or suspension of the provision of the Service in accordance with the preceding
        paragraph, the Company will not refund the usage fees already received and will not be liable for any damage or disadvantage
        incurred by the User or any third party.
      </p>

      <h3 css={semiTitleStyle}>Article 13 (Exclusion of Anti-Social Forces such as Organized Crime Groups)</h3>
      <p css={leadTextStyle}>
        When using this service, users shall declare that they do not belong to or fall under any organized crime groups,gangster, gangster
        member, gang-related company, general meeting house, social movement gangster, political movement gangster, special intelligent
        {`violence group, or other antisocial forces (hereinafter referred to as "gangster and other antisocial forces") and that they are not`}
        involved with anti-social forces such as gangster and other antisocial forces, and shall promise not to belong to, fall under, or be
        involved in the future.
      </p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          If the Company judges that a user belongs to, falls under, or is involved with an gangster and other antisocial forces such as an
          organized crime syndicate, the Company may take.
        </li>
        <li css={listItemStyle}>
          The Company shall have no obligation or responsibility for any damage caused by the suspension of use or termination of the
          service due to a violation by the User under this Article.
        </li>
      </ol>

      <h3 css={semiTitleStyle}>Chapter 5 Suspension, Modification and Termination of Service</h3>
      <h3 css={semiTitleStyle}>Article 14 (Suspension of Service)</h3>
      <p css={leadTextStyle}>
        In the event of any of the following events, the Company may suspend the Service in whole or in part without prior notice to the
        User.
      </p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          In the event of a failure of the system, equipment, etc. necessary for the provision of the Service, or the need for maintenance,
          servicing, or construction, etc.
        </li>
        <li css={listItemStyle}>
          When it becomes difficult to provide the Service due to the act of a third party other than the Company, such as a
          telecommunication carrier discontinuing the provision of telecommunication services
        </li>
        <li css={listItemStyle}>
          If the provision of the Service becomes difficult or may become difficult due to the occurrence of an emergency (natural disaster,
          war, terrorism, riot, mayhem, government action, labor dispute, etc.)
        </li>
        <li css={listItemStyle}>
          When the synchronization service is no longer available due to the circumstances of the synchronization service
        </li>
        <li css={listItemStyle}>
          When it becomes difficult to provide the Service due to laws and regulations, administrative orders, etc.
        </li>
        <li css={listItemStyle}>If the Company deems it necessary to do so due to any other reason not attributable to the Company.</li>
      </ol>

      <h3 css={semiTitleStyle}>Article 15 (Change of Service)</h3>
      <p css={leadTextStyle}>
        The Company may add or change some of the contents of the Service at its discretion. The Company does not warrant that all functions
        and performance of the Service prior to the change will be maintained as a result of any additions or changes to the Service under
        this Article. The Company shall not be liable for any damage or disadvantage incurred by the User as a result of adding or changing
        the Service in accordance with the preceding paragraph.
      </p>

      <h3 css={semiTitleStyle}>Article 16 (Suspension and Termination of Service)</h3>
      <p css={leadTextStyle}>
        {`The Company may suspend or terminate the provision of the Service in whole or in part at the Company's discretion with prior notice
        to the Member. However, if the content of the suspension or termination is not material, they may be carried out without notice. The
        Company shall not be liable for any damages incurred by the user as a result of the discontinuation or termination of the Service in
        accordance with the preceding paragraph.`}
      </p>

      <h3 css={semiTitleStyle}>Article 17 (Transfer, etc. of Contractual Status)</h3>
      <p css={leadTextStyle}>
        {`The User may not assign, transfer, mortgage or otherwise dispose of the status of the User or the rights or obligations under this
        User Agreement to a third party without the prior consent of the Company. In the event that the Company transfers the business
        related to the Service to a third party by means of a business transfer, company split or other method (hereinafter referred to as
        "Business Transfer, etc."), the Company may assign and transfer the status of the Business Transfer, etc., the rights and
        obligations under the Terms of Use Agreement, as well as information about the User, to the transferee of the Business Transfer,
        etc., and the User shall have agreed in advance to such assignment and transfer.`}
      </p>

      <h3 css={semiTitleStyle}>Chapter 6 General Provisions</h3>
      <h3 css={semiTitleStyle}>Article 18 (Warranty)</h3>
      <p css={leadTextStyle}>
        The Company will make every reasonable effort to ensure that the Service functions in the recommended environment. However, the
        Company does not guarantee the accuracy, legitimacy, usefulness, or completeness of the User Information. Users shall use user
        information after making changes or corrections to it as necessary at their own discretion and responsibility.
      </p>

      <h3 css={semiTitleStyle}>Article 19 (Intellectual Property Rights)</h3>
      <p css={leadTextStyle}>
        All intellectual property rights, such as copyrights, moral rights, patent rights, utility model rights, design rights, trademark
        rights, and publicity rights related to the Service, belong to the Company and third parties as legitimate rights holders, and the
        conclusion of this User Agreement does not imply the licensing of intellectual property rights beyond the scope necessary for the
        use of the Service.
      </p>

      <h3 css={semiTitleStyle}>Article 20 (Indemnification and Immunity)</h3>
      <p css={leadTextStyle}>
        In the event that the company is found to be negligent in relation to any damage caused to a user in relation to the service, the
        company shall compensate up to 1,000 yen for the service that was the direct cause of the damage, and shall not be liable for any
        other damage.
      </p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          In the event that the user causes damage to the Company by violating these Terms and Conditions or by using the Service, the user
          must compensate the Company for any damage caused to the Company.
        </li>
        <li css={listItemStyle}>
          In the event that a dispute arises between a user and a third party regarding the Service, the user shall settle the dispute at
          their own risk and expense. The user will not cause any inconvenience to the company. The user shall compensate for any
          damage(including legal fees) suffered by the company due to a dispute.
        </li>
      </ol>

      <h3 css={semiTitleStyle}>Article 21 (Consignment)</h3>
      <p css={leadTextStyle}>The Company may outsource all or part of the work related to the Service to a third party.</p>

      <h3 css={semiTitleStyle}>Article 22 (Information Management)</h3>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          The Company will not disclose member information to third parties without the prior consent of the member. However, this does not
          apply in the following cases
          <ol css={innerListStyle}>
            <li>
              When the Company receive a request from a law or public institution and the Company recognize the need to respond to the
              request.
            </li>
            <li>
              When it is necessary to protect the life, body or property of a person and it is difficult to obtain the consent of the
              member.
            </li>
            <li>
              When the information of registered users of the Service is deposited with payment system companies, credit card companies,
              banks, etc. for the purpose of billing members for their use of the Service
            </li>
            <li>
              When disclosing information to a fiduciary or agent, etc. that is necessary to provide the Service or to improve or improve
              its functionality
            </li>
            <li>When the Company and its subsidiaries share the use of the same in order to provide coordinated services</li>
          </ol>
        </li>
        <li css={listItemStyle}>
          {`Notwithstanding the preceding paragraph, the Company will perform attribute aggregation and analysis of user information and
          process it so that members cannot be identified and identified (hereinafter referred to as "statistical data"). (the "Service")
          and may use it for the Service and our other services. In addition, we may disclose statistical data to third parties.`}
        </li>
        <li css={listItemStyle}>
          The Company will take the maximum reasonable security measures against the risk of loss, destruction, falsify, leakage, etc. of
          member information.
        </li>
        <li css={listItemStyle}>
          The Company may record telephone conversations with users and use the recorded content in the course of business in order to
          improve the quality of telephone responses, etc.
        </li>
      </ol>

      <h3 css={semiTitleStyle}>Article 23 (Personal Information)</h3>
      <p css={leadTextStyle}>
        {`Notwithstanding the provisions of the preceding article, the Company's handling of personal information shall be as set forth in the
        Privacy Policy. The Company shall be able to use the member's personal information within the scope of the purpose of use stated in
        the Privacy Policy, even after the termination of this User Agreement.`}
      </p>

      <h3 css={semiTitleStyle}>Article 24 (Prohibition of assignment of rights and obligations)</h3>
      <p css={leadTextStyle}>
        Without obtaining the prior written consent of the Company, the Member shall not transfer the rights and obligations under this
        Agreement to a third party (including comprehensive succession by merger, corporate separation, etc.). In addition, the Member shall
        not use it as collateral.
      </p>

      <h3 css={semiTitleStyle}>Article 25 (Governing Law, Court of Jurisdiction)</h3>
      <p css={leadTextStyle}>
        These Terms and Conditions and this User Agreement shall be governed by and construed in accordance with the laws of Japan, and
        shall be governed by and construed in accordance with the laws of Japan. The Tokyo District Court shall be the exclusive
        jurisdiction court of first instance in the event of any dispute between the User and the Company regarding this User Agreement.
      </p>

      <p css={[revisionStyle, { marginTop: '32px' }]}>Enacted on September 27, 2019</p>
      <p css={revisionStyle}>Revised on November 15, 2019</p>
      <p css={revisionStyle}>Revised on January 8, 2020</p>
      <p css={revisionStyle}>Revised on October 29, 2020</p>
      <p css={revisionStyle}>Revised on July 28, 2021</p>
      <p css={revisionStyle}>Revised on August 06, 2021</p>
      <p css={revisionStyle}>Revised on April 15, 2022</p>
    </div>
  )
}

const layoutStyle = css({
  marginTop: '5rem',
  paddingRight: '2.5rem',
  paddingLeft: '2.5rem',
  lineHeight: '1.625',
})

const pageTitleStyle = css({
  fontWeight: '600',
  textAlign: 'center',
  fontSize: '20px',
})

const administratorStyle = css({
  textAlign: 'center',
})

const updateStyle = css({
  marginTop: '24px',
  marginBottom: '24px',
  fontSize: '14px',
  color: '#475569',
})

const semiTitleStyle = css({
  fontWeight: '600',
  marginTop: '32px',
  marginBottom: '8px',
})

const leadTextStyle = css({
  fontSize: '12px',
  marginTop: '1.5rem',
  marginBottom: '1.5rem',
  lineHeight: '1.625',
})

const listStyle = css({
  paddingLeft: '1rem',
  paddingRight: '1rem',
  marginBottom: '1rem',
  listStyleType: 'decimal',
})

const listItemStyle = css({
  fontSize: '12px',
  lineHeight: '1.625',
  listStyleType: 'decimal',
})

const innerListStyle = css({
  marginRight: '32px',
  marginLeft: '32px',
  listStyleType: 'lower-latin',
})

const revisionStyle = css({
  fontWeight: '600',
  fontSize: '14px',
  marginBottom: '4px',
  '&:first-of-type': {
    marginTop: 0,
  },
})
