import { css } from '@emotion/react'

// for form component space
export const spacerTopStyle = css({
  marginTop: '1.5rem',
})

export const textOverflowStyle = css({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

export const horizontalSpacing = css({
  paddingLeft: '2rem',
  paddingRight: '2rem',
})

export const blockSpaceTop = css({
  marginTop: '1rem',
})

export const lineClampStyle = (clamp: number) =>
  css({
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: `${clamp}`,
    overflow: 'hidden',
  })

// max width for pc screen
export const pcScreenStyle = css({
  maxWidth: '600px',
  marginInline: 'auto',
})
