import { http } from '@/apis/aipass'
import { env } from '@/libs/env'

export const makeSales = async (data: { hotelId: string; sales: any }) => {
  const response = await http({
    method: 'put',
    url: `${env('BASE_API_V3')}/sales-manager/sales`,
    data,
  })

  return response.data
}
