import sha256 from 'crypto-js/sha256'

console.log({
  language: navigator.language,
  languages: navigator.languages.join(','),
  userAgent: navigator.userAgent,
  height: window.screen.height,
  width: window.screen.width,
  pixelDepth: window.screen.pixelDepth,
  colorDepth: window.screen.colorDepth,
  devicePixelRatio: window.devicePixelRatio,
})
const str = JSON.stringify([
  navigator.language,
  navigator.languages.join(','),
  navigator.userAgent,
  window.screen.height,
  window.screen.width,
  window.screen.pixelDepth,
  window.screen.colorDepth,
  window.devicePixelRatio,
])
export const fingerprint: string = sha256(str).toString()
console.log(fingerprint)
