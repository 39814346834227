import { useAppDispatch, useAppSelector } from '@/app/hooks'
import React from 'react'
import { Button } from '@/components/atoms/button'
import { NavigateHeader } from '@/components/atoms/navigate-header'
import { CardForm } from '@/components/organisms/card/card-form'
import { Loading } from '@/components/organisms/loading'
import { Layout } from '@/components/template/layout'
import { AccountInfo } from '@/features/user/account-slice'
import { CardDetail, addCreditCard, cardSchema } from '@/features/user/user-card-slice'
import { blockSpaceTop, pcScreenStyle } from '@/styles/common'
import { formButtonStyle } from '@/styles/form'
import { zodResolver } from '@hookform/resolvers/zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { MessageType, toastAction } from '@/features/notification/toast-slice'
import { errorHandler } from '@/libs/errors'
import { formatCardExpire } from '@/utils/creditCard/card-expire'

export const RegisterCard: React.FC = () => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const userData = useAppSelector<AccountInfo>(state => state.accountInfo.user)
  const loading = useAppSelector(state => state.creditCard.addLoading)

  const useFormReturn = useForm<CardDetail>({
    mode: 'all',
    resolver: zodResolver(cardSchema),
  })
  const {
    handleSubmit,
    formState: { isDirty, isValid },
  } = useFormReturn

  const onSubmit: SubmitHandler<CardDetail> = async submitData => {
    try {
      const processResult = await dispatch(
        addCreditCard({
          cuicinUserId: userData.id,
          cardDetail: {
            cardNumber: submitData.cardNumber.replace(/ /g, ''),
            cardExpire: formatCardExpire({ cardExpireMonth: submitData.cardExpireMonth, cardExpireYear: submitData.cardExpireYear }),
            securityCode: submitData.securityCode,
          },
        }),
      ).unwrap()

      if (processResult?.status === 'success') {
        return navigate('/mypage/credit-card')
      } else {
        dispatch(
          toastAction.setMessage(MessageType.Error, t(processResult.errorMessage || 'Cannot register credit card. Please check it again.')),
        )
      }
    } catch (error) {
      errorHandler({ error })
    }
  }

  return (
    <Layout>
      <NavigateHeader title={t('Credit Card Registration')} style={{ marginBottom: '1rem' }} />
      <form onSubmit={handleSubmit(onSubmit)} css={pcScreenStyle}>
        <Loading loading={loading}>
          <CardForm useFormReturn={useFormReturn} />
          <div css={blockSpaceTop}>
            <Button disabled={!isDirty || !isValid} buttonCss={formButtonStyle} type="submit" text={t('Save')} />
          </div>
        </Loading>
      </form>
    </Layout>
  )
}
