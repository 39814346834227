import { css } from '@emotion/react'

export const categoryMenuStyle = css({
  position: 'relative',
  display: 'flex',
  boxShadow: '0px 2px 3px 0px #00000014',
  paddingInline: '2rem',
  li: {
    paddingBlock: '1rem',
    fontSize: '14px',
    letterSpacing: 0,
  },
})
