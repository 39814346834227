import React, { useEffect } from 'react'
import { css } from '@emotion/react'
import { SelectBox } from '../atoms/select-box'
import { CheckInData } from '@/features/checkIn/check-in-slice'
import { getHoursTimeRange } from '@/utils/time'

import { spacerTopStyle } from '@/styles/common'
import { useTranslation } from 'react-i18next'
import { CheckBox } from '../atoms/check-box'
import { Input } from '../atoms/input'
import { CloseButton } from '../atoms/close-button'
import { useFieldArray, Controller, UseFormReturn } from 'react-hook-form'
import { guestsOptions } from '@/utils/form/options'
import { RequiredMark } from '../atoms/required-mark'
import { labelStyle } from '@/styles/form'
import { HotelGuide } from '@/features/hotel/hotel-guide-slice'
import { Button } from '../atoms/button'
import { Card } from '../atoms/card'
import { DatePickerButton } from '../atoms/date-calendar'
import { CUSTOM_LINK } from '../template/custom-design-style'
import { PlusIcon } from '../atoms/icon'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { fetchCheckInSetting } from '@/features/hotel/check-in-setting-slice'

interface CheckInStartProps {
  hotelGuideData: HotelGuide
  useFormReturn: UseFormReturn<CheckInData>
}

export const CheckInStart: React.FC<CheckInStartProps> = ({ hotelGuideData, useFormReturn }) => {
  const { t } = useTranslation()
  const { checkInSetting } = useAppSelector(state => state.checkInSetting)
  const dispatch = useAppDispatch()

  const {
    watch,
    setValue,
    register,
    getValues,
    control,
    formState: { errors, isValid },
  } = useFormReturn

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'companions',
  })

  const watchGuests = watch('guests')
  const watchCompanions = watch('companions')
  const watchKnowContracts = watch('knowContacts')

  useEffect(() => {
    dispatch(fetchCheckInSetting({ hotelId: hotelGuideData.hotelId }))
  }, [])

  return (
    <>
      <Card cardCss={cardStyle}>
        <Input
          {...register('telephone')}
          required
          type="tel"
          label={t('Phone number of your reservation')}
          placeholder="08012345678"
          name="telephone"
          error={errors.telephone?.message}
        />

        {!hotelGuideData.hasReservationPlugins && (
          <div css={spacerTopStyle}>
            <DatePickerButton
              maxDate={dayjs().add(14, 'day').toDate()}
              required
              {...register('checkInDate')}
              onSetValue={date => setValue('checkInDate', date)}
              value={getValues('checkInDate')}
              errorMessage={errors.checkInDate?.message}
              label={t('Arrival Date')}
            />
          </div>
        )}

        <SelectBox
          {...register('checkInTime')}
          required
          label={t('Check-in Time')}
          id="checkInTime"
          name="checkInTime"
          options={getHoursTimeRange({
            beginTime: hotelGuideData.facilityBasicInfo.checkinBeginTime,
            endTime: hotelGuideData.facilityBasicInfo.checkinEndTime,
          }).map(value => ({
            label: value,
            value,
          }))}
          itemCss={spacerTopStyle}
          error={errors.checkInTime?.message}
        />

        <Controller
          name="guests"
          control={control}
          render={({ field }) => (
            <SelectBox
              required
              {...register('guests')}
              arrowDefaultSelect={false}
              label={t('Number of guests')}
              id="guests"
              name="guests"
              options={guestsOptions}
              itemCss={spacerTopStyle}
              onChange={e => {
                const value = Number(e.target.value)

                const renderFields = Array.from(Array(value - 1)).map(() => ({
                  lastName: '',
                  firstName: '',
                }))

                remove()
                append(renderFields)
                field.onChange(value)
              }}
            />
          )}
        />

        {watchGuests > 1 && (
          <div css={spacerTopStyle}>
            {!checkInSetting?.requiredAccompanyNameInput && (
              <CheckBox
                {...register('knowContacts')}
                customLabelStyle={css({ fontSize: '12px' })}
                label={t('I know everyone’s contacts')}
                checked={Boolean(watchKnowContracts)}
              />
            )}

            {!watchKnowContracts && (
              <>
                <div css={spacerTopStyle}>
                  <label css={labelStyle}>
                    {t('Companions')}
                    <RequiredMark />
                  </label>
                  {fields.map((field, index) => (
                    <div css={[multipleSelectBoxStyle, { alignItems: 'flex-start' }]} key={field.id}>
                      <Input
                        {...register(`companions.${index}.lastName`)}
                        itemCss={css({ flex: 1 })}
                        name={`companions.${index}.lastName`}
                        placeholder={t('Last name')}
                        error={errors?.companions?.[index]?.lastName?.message}
                      />
                      <Input
                        {...register(`companions.${index}.firstName`)}
                        itemCss={css({ flex: 1 })}
                        name={`companions.${index}.firstName`}
                        placeholder={t('First name')}
                        error={errors?.companions?.[index]?.firstName?.message}
                      />
                      <CloseButton
                        onClick={() => {
                          remove(index)
                          setValue('guests', watchCompanions.length)
                        }}
                        customStyle={css({ marginTop: '12px' })}
                      />
                    </div>
                  ))}
                </div>
                <p
                  css={addTextStyle}
                  onClick={() => {
                    append({ firstName: '', lastName: '' })
                    setValue('guests', watchCompanions.length + 2)
                  }}
                  className={CUSTOM_LINK}
                >
                  <PlusIcon />
                  {t('Add entry field')}
                </p>
              </>
            )}
          </div>
        )}
      </Card>

      <Button disabled={!isValid} text={t('Check-in starts')} type="submit" />
    </>
  )
}

const multipleSelectBoxStyle = css({
  display: 'flex',
  gap: '16px',
  marginTop: '1.5rem',
  alignItems: 'center',
  '&:first-of-type': {
    marginTop: 0,
  },
})

const addTextStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '0.5rem',
  fontSize: '12px',
  marginTop: '1rem',
})

const cardStyle = css({
  marginBottom: '2rem',
})
