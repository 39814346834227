import { http } from "@/apis/aipass"
import { env } from "@/libs/env"

export const postCheckoutInfo = async (data: { hotelId: string, orderId: string, checkinIds: string[] }) => {
  const response = await http({
    method: 'post',
    url: `${env('BASE_API_V3')}/payment/checkout-info`,
    data,
  })

  return response.data
}
