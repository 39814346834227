import { combineReducers } from "@reduxjs/toolkit";
import { searchCheckInReducer } from "./search-reservations-slice";
import { checkInDataReducer } from "./check-in-slice";
import { checkInPaymentsReducer } from "./check-in-payment-slice";

export const checkInReducer = combineReducers({
  searchCheckIn: searchCheckInReducer,
  checkInData: checkInDataReducer,
  checkInPayments: checkInPaymentsReducer
})
