import { useAppSelector } from '@/app/hooks'
import { Button } from '@/components/atoms/button'
import { ContentWrapper } from '@/components/template/content-wrapper'
import { RequestItem } from '@/features/request/request-cart-slice'
import { displayYen } from '@/libs/text'
import { css } from '@emotion/react'
import { isEmpty, sum } from 'lodash'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

interface RequestListFooterProps {
  assetId: string
}

export const RequestListFooter: React.FC<RequestListFooterProps> = ({ assetId }) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const requestCart = useAppSelector(state => state.request.requestCart.cart)
  const design = useAppSelector(state => state.design)

  const requestCartPriceAmount = useMemo(() => {
    const requestCarts: RequestItem[] = requestCart[assetId]
    const requestCartPrices = requestCarts?.map(requestCart => requestCart.detail.price * requestCart.count)
    const requestCartCounts = requestCarts?.map(requestCart => requestCart.count)

    return { totalAmount: sum(requestCartPrices), totalCount: sum(requestCartCounts) }
  }, [requestCart])

  return isEmpty(requestCart[assetId]) ? (
    <></>
  ) : (
    <div className="custom-design-block" css={footerStyle}>
      <ContentWrapper>
        <div className="count-price">
          <span className="count" style={{ backgroundColor: design.theme.brandColor }}>
            {requestCartPriceAmount.totalCount}
          </span>
          {displayYen(requestCartPriceAmount.totalAmount.toString())}
        </div>
        <Button className="confirm" onClick={() => navigate(`/request/${assetId}/confirmation`)} text={t('Confirm')} />
      </ContentWrapper>
    </div>
  )
}

const footerStyle = css({
  position: 'fixed',
  padding: '1rem 1.5rem',
  width: '100%',
  left: 0,
  bottom: 0,
  boxShadow: '0px 0px 6px #00000029',
  '> div': {
    display: 'flex',
  },
  '.count': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 2,
    fontSize: '14px',
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    color: '#fff',
  },
  '.count-price': {
    display: 'flex',
    alignItems: 'center',
    fontSize: 20,
    fontWeight: 'bold',
    gap: '1rem',
  },
  '.confirm': {
    width: '84px',
    marginLeft: 'auto',
  },
})
