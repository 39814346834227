import React, { createContext } from 'react'

// components
import { useAppSelector } from '@/app/hooks'
import { AccountInfo } from '@/features/user/account-slice'

export const AccountContext = createContext({})

export const AccountProvider = ({ children }) => {
  const accountInfo = useAppSelector<AccountInfo>(state => state.accountInfo.user)

  return (
    <AccountContext.Provider
      value={{
        accountInfo,
      }}
    >
      {children}
    </AccountContext.Provider>
  )
}
