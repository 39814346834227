import { http } from '@/apis/aipass'
import { env } from '@/libs/env'
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { z } from 'zod'

export const SearchType = {
  Phone: 'phone',
  ReservationId: 'reservationId',
  GuestName: 'guestName',
} as const
export type SearchType = (typeof SearchType)[keyof typeof SearchType]

export const schema = z.discriminatedUnion('searchType', [
  z.object({
    searchType: z.literal(SearchType.ReservationId),
    reservationId: z.string().min(1),
  }),
  z.object({
    searchType: z.literal(SearchType.Phone),
    telephone: z.string().min(1),
  }),
  z.object({
    searchType: z.literal(SearchType.GuestName),
    familyName: z.string().min(1),
    givenName: z.string().min(1),
  }),
])

export interface StayReservationParams {
  hotelId: string
  telephone?: string
  reservationId?: string
  givenName?: string
  familyName?: string
}

export const getStayReservations = createAsyncThunk('stay-reservation/list', async (params: StayReservationParams, { rejectWithValue }) => {
  try {
    const reservationResponse = await http({
      method: 'get',
      url: `${env('BASE_API_V3')}/stay-reservation`,
      params,
    })
    return reservationResponse.data.stayReservations
  } catch (error) {
    return rejectWithValue(error)
  }
})

export interface StayReservation {
  checkinId: string
  checkinDate: string
  checkoutDate: string
  frontSupported: boolean
  guestName: string
  reservationUserName: string
  nights: number
  plan: string
  planEn: string
  reservationId: string
  roomNumbers: string[]
  assignRooms: Array<{
    typeId: string
    name: string
    roomNumber: string
  }>
}

const stayReservationsSlice = createSlice({
  name: 'stayReservations',
  initialState: {
    stayReservations: [] as StayReservation[],
  },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getStayReservations.fulfilled, (state, action: PayloadAction<StayReservation[]>) => {
      state.stayReservations = action.payload
    })
  },
})

export const stayReservationsReducer = stayReservationsSlice.reducer
