import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { http } from '@/apis/aipass'
import { env } from '@/libs/env'

export const fetchPaymentSetting = createAsyncThunk('hotel/fetchPaymentSetting', async (hotelId: string, { rejectWithValue }) => {
  try {
    const response = await http({
      method: 'get',
      url: `${env('BASE_API_V3')}/payment-setting-by-hotel`,
      params: { hotelId: hotelId },
    })
    return response.data
  } catch (error) {
    return rejectWithValue(error)
  }
})

interface PaymentTime {
  id: string
  is_enabled: number
  payment_method: { id: string; is_enabled: number; value: string }[]
  value: string
}

interface PaymentSetting {
  settings: {
    payment_time: PaymentTime[]
  }
}
export const paymentSettingSlice = createSlice({
  name: 'paymentSetting',
  initialState: {
    settings: {
      payment_time: [] as PaymentTime[],
    },
    loading: false
  },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchPaymentSetting.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchPaymentSetting.fulfilled, (state, action: PayloadAction<PaymentSetting>) => {
      state.settings = action.payload.settings
      state.loading = false
    })
    builder.addCase(fetchPaymentSetting.rejected, (state) => {
      state.loading = false
    })
  },
})

export const paymentSettingReducer = paymentSettingSlice.reducer
