import { css } from '@emotion/react'
import React from 'react'

export const ZhContent: React.FC = () => {
  return (
    <div css={layoutStyle}>
      <div css={administratorStyle}>
        <h2 css={pageTitleStyle}>[第 2 版] 信息安全政策</h2>

        <p css={updateStyle}>最后更新日期：2024年4月1日</p>
      </div>

      <p css={leadTextStyle}>
        ＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿ <br />
        ISMS-01_ <br />
        信息安全政策声明 <br />
        修订历史》第3版。 <br />
        审批人：山田真由美 <br />
        目录：高层管理人员姓名变更
        <br />
        批准日期： 2024年4月1日 <br />
        ＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿
      </p>

      <h3 css={semiTitleStyle}>1 目标</h3>
      <p css={leadTextStyle}>
        {`(本公司（以下简称 "本公司"）在网络应用程序的规划、开发、运营和销售业务以及员工管 理（以下简称 "本业务"）中使用了大量信息资产，因此本公司认识到正确实现信息安全和保护信 息资产是一项基本要求。 公司认识到，努力保护信息资产是基于社会信任推进企业活动的基本要求，也是一项严肃的社会责任。 因此，鉴于信息安全的重要性，本公司制定了本信息安全方针（以下简称 "本方针"），并将建立、实施、维护和改进信息安全管理系统，以具体落实本方针。`}
      </p>

      <h3 css={semiTitleStyle}>2 信息安全的定义</h3>
      <p css={leadTextStyle}>信息安全的定义是维护机密性、完整性和可用性。</p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          机密性 <br />
          这意味着信息资产受到保护，防止未经授权的访问等，并且不会泄露给未经授权的人员。
          (不允许未经授权的个人、实体或程序使用或披露信息的特征）。
        </li>
        <li css={listItemStyle}>
          完整性 <br />
          这意味着信息资产不会被篡改和出错，并得到准确和完整的维护。 (准确性和完整性的特点）
        </li>
        <li css={listItemStyle}>
          可用性 <br />
          这意味着信息资产受到保护，不会丢失、损坏或系统中断，并在需要时可用。 (在授权实体提出要求时可以访问和使用的特征）。
        </li>
      </ol>

      <h3 css={semiTitleStyle}>3 范围</h3>
      <p css={leadTextStyle}>本政策适用于我们控制下的所有信息资产。 信息资产的范围不仅限于电子设备和电子数据，还包括纸张等所有形式。</p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          组织 <br />
          aipass公司（全体员工）
        </li>
        <li css={listItemStyle}>
          设备 <br />
          总部（地址：Shinoda Building 3F, 23-3 Sakuragaokacho, Shibuya-ku, Tokyo）
        </li>
        <li css={listItemStyle}>
          生意 <br />
          网络应用程序的规划、开发、运行和销售
        </li>
        <li css={listItemStyle}>
          资产 <br />
          与上述业务和各种服务有关的文件、数据、信息系统、网络等
        </li>
      </ol>

      <h3 css={semiTitleStyle}>4 执法议程</h3>
      <p css={leadTextStyle}>根据本政策和我们的信息安全管理系统，我们将</p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          信息安全目标 <br />
          我们制定并向所有员工传达与本政策相一致的信息安全目标，同时考虑到适用的信息安全要求以及风险评估和风险应对的结果，并根据我们的环境变化不时进行审查，即使没有变化也会定期进行审查。
        </li>
        <li css={listItemStyle}>
          信息资产的处理 <br />
          <ol css={listDiscStyle}>
            <li>只有工作需要者才能获得访问权限。</li>
            <li>根据法律法规要求、合同要求和公司信息安全管理系统的规定进行管理。</li>
            <li>根据信息资产在价值、保密性、完整性和可用性方面的重要性，对信息资产进行适当分类和管理。</li>
            <li>对信息资产进行持续监测，以确保信息资产得到妥善管理。</li>
          </ol>
        </li>
        <li css={listItemStyle}>
          关于风险评估 <br />
          <ol css={listDiscStyle}>
            <li>对因业务性质而被视为最重要的信息资产进行风险评估，实施适当的风险应对措施和控制措施。</li>
            <li>分析与信息安全有关的事件的原因，并采取措施防止再次发生。</li>
          </ol>
        </li>
        <li css={listItemStyle}>
          业务连续性管理 <br />
          最大限度地减少因灾难和故障造成的业务中断，确保业务连续性能力。
        </li>
        <li css={listItemStyle}>
          教育 <br />
          为所有员工提供信息安全教育和培训。
        </li>
        <li css={listItemStyle}>
          遵守法规和程序 <br />
          公司遵守信息安全管理系统的规定和程序。
        </li>
        <li css={listItemStyle}>
          遵守法律法规要求和合同要求。 <br />
          我们遵守与信息安全有关的法律法规要求和合同要求。
        </li>
        <li css={listItemStyle}>
          持续改进 <br />
          不断改进信息安全管理系统。
        </li>
        <li css={listItemStyle}>
          一组信息安全政策。 <br />
          设立了以下与信息安全政策有关的信息安全政策小组
        </li>
      </ol>
      <p css={leadTextStyle}>
        移动设备政策小组 <br />
        门禁 <br />
        暗号による管理策の利用方針 <br />
        使用加密控制措施的政策 <br />
        信息转让政策 <br />
        安全敏感型发展政策。 <br />
        供应商关系信息安全政策。 <br />
      </p>

      <h3 css={semiTitleStyle}>5 责任、义务和处罚</h3>
      <p css={leadTextStyle}>
        最高管理层对包括本政策在内的信息安全管理系统负责，适用范围内的员工有义务遵守既定的规定和程序。
        未履行义务或违反规定的员工将根据雇佣条例受到处罚。 对于分包商的员工，应根据各自的合同进行处理。
      </p>

      <h3 css={semiTitleStyle}>6 定期审查</h3>
      <p css={leadTextStyle}>应根据需要定期审查、维护和管理信息安全管理系统。</p>

      <p css={[leadTextStyle, { marginTop: '2rem' }]}>
        立法：2021年4月1日 <br />
        最后修订日期：2024年4月1日 <br />
        高层管理 山田 真由美
        <br />
      </p>
    </div>
  )
}

const layoutStyle = css({
  marginTop: '5rem',
  paddingRight: '2.5rem',
  paddingLeft: '2.5rem',
  lineHeight: '1.625',
})

const pageTitleStyle = css({
  fontWeight: '600',
  textAlign: 'center',
  fontSize: '20px',
})

const administratorStyle = css({
  textAlign: 'center',
})

const updateStyle = css({
  marginTop: '24px',
  marginBottom: '24px',
  fontSize: '14px',
  color: '#475569',
})

const semiTitleStyle = css({
  fontWeight: '600',
  marginTop: '32px',
  marginBottom: '8px',
})

const leadTextStyle = css({
  fontSize: '12px',
  marginTop: '1.5rem',
  marginBottom: '1.5rem',
  lineHeight: '1.625',
})

const listDiscStyle = css({
  listStyle: 'disc',
  marginRight: '1rem',
  marginLeft: '1rem',
  li: {
    listStyle: 'disc',
  },
})

const listStyle = css({
  paddingLeft: '1rem',
  paddingRight: '1rem',
  marginBottom: '1rem',
  listStyleType: 'decimal',
})

const listItemStyle = css({
  fontSize: '12px',
  lineHeight: '1.625',
  listStyleType: 'decimal',
})
