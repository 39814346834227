import { fetchZipCode } from '@/features/user/zip-code-slice'
import { useState } from 'react'

interface ZipAddress {
  address1: string
  address2: string
  address3: string
  kana1: string
  kana2: string
  kana3: string
  prefcode: string
  zipcode: string
}

export const useZipCode = () => {
  const [address, setAddress] = useState<ZipAddress | null>(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const getAddressInfo = async (zipCode: string) => {
    try {
      setLoading(true)
      const result = await fetchZipCode(zipCode)

      const fetchedAddress = result.results?.[0]
      setAddress(fetchedAddress)

      return fetchedAddress
    } catch (error) {
      setErrorMessage('住所の取得に失敗しました。')
    } finally {
      setLoading(false)
    }
  }

  return { zipCodeResult: address, fullAddress: `${address?.address1 || ''}${address?.address2 || ''}${address?.address3 || ''}`, getAddressInfo, errorMessage, loading }
}
