interface getHoursTimeRangeProps {
  beginTime: string
  endTime: string
}

export const getHoursTimeRange = ({ beginTime, endTime }: getHoursTimeRangeProps) => {
  if (!beginTime || !endTime) return []

  const checkinBeginHour = Number(beginTime.split(':')[0])
  const checkinEndHour = Number(endTime.split(':')[0])

  if (checkinBeginHour < checkinEndHour) {
    return [...Array(checkinEndHour - checkinBeginHour + 1)].map((_, i) => `${String(+checkinBeginHour + i).padStart(2, '0')}:00`)
  } else {
    return [
      ...[...Array(24 - checkinBeginHour)].map((_, i) => `${String(+checkinBeginHour + i).padStart(2, '0')}:00`),
      ...[...Array(+checkinEndHour + (checkinEndHour !== checkinBeginHour ? 1 : 0))].map((_, i) => {
        return `${String(i).padStart(2, '0')}:00`
      }),
    ]
  }
}
