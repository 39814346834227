import React from 'react'
import { Layout } from '@/components/template/layout'
import { css } from '@emotion/react'
import { NavigateHeader } from '../atoms/navigate-header'
import { pcScreenStyle } from '@/styles/common'
import { LoginType } from '../pages/login'
import { CheckInStep } from '../organisms/checkIn/check-in-step'

interface LoginTemplateProps {
  children: React.ReactNode
  navigateTitle: string
  headText: string
  loginType?: LoginType
}

export const LoginTemplate: React.FC<LoginTemplateProps> = ({ children, navigateTitle, headText, loginType }) => {
  return (
    <Layout>
      {loginType === LoginType.checkIn && <CheckInStep current={1} />}
      {loginType !== LoginType.checkIn && <NavigateHeader title={navigateTitle} />}
      <p css={needIdTextStyle}>{headText}</p>
      <div css={pcScreenStyle}>{children}</div>
    </Layout>
  )
}

const needIdTextStyle = css({
  fontSize: 14,
  padding: '1rem 0',
  textAlign: 'center',
  lineHeight: 1.5,
})
