import React, { useMemo, useState } from 'react'
import { useAppSelector } from '@/app/hooks'
import { Layout } from '@/components/template/layout'
import { NewsAssetType } from '@/features/asset/assets-slice'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/react'
import { Divider } from '@/components/atoms/divider'
import { chunk, isEmpty } from 'lodash'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { ReactComponent as ArrowRightIcon } from '@/static/images/controls/arrow_right.svg'
import { ReactComponent as ArrowLeftIcon } from '@/static/images/controls/arrow_left.svg'
import { usePickByLanguage } from '@/hooks/use-pick-by-language'
import { CUSTOM_TEXT_COLOR_LINK } from '@/components/template/custom-design-style'
import { pcScreenStyle } from '@/styles/common'

const MAX_ITEMS = 10
const PARAMETER_KEY = 'currentPage'

export const NoticeList: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [currentPage, setCurrentPage] = useState(Number(searchParams.get(PARAMETER_KEY)) || 1)

  const { assetId } = useParams<{ assetId: string }>()

  const pickByLanguage = usePickByLanguage()

  const {
    i18n: { language },
  } = useTranslation()

  const { assets } = useAppSelector(state => state.assets)

  const newsAsset: NewsAssetType | undefined = assets.find(asset => asset.id === assetId)

  const chunkNewsDetails = useMemo(() => {
    return chunk(newsAsset?.details, MAX_ITEMS)
  }, [assets, assetId])

  const formatDate = (dateString: string) => {
    return dayjs(dateString).format('YYYY.MM.DD')
  }

  const onClickPagination = (page: number) => {
    setCurrentPage(page)
    setSearchParams({ [PARAMETER_KEY]: page.toString() })
  }

  const targetDetailIndex = currentPage - 1

  return (
    <Layout>
      <main className="custom-design-block" css={[mainLayoutStyle, pcScreenStyle]}>
        <h2>{pickByLanguage(newsAsset?.title)}</h2>

        {chunkNewsDetails[targetDetailIndex]?.length && (
          <ul>
            {chunkNewsDetails[targetDetailIndex].map(newsDetail => (
              <li className={CUSTOM_TEXT_COLOR_LINK} key={newsDetail.id} css={newsListItemStyle}>
                <Link to={`/notice/${newsAsset?.id}/${newsDetail.id}`}>
                  <time dateTime={formatDate(newsDetail.updatedAt)}>{formatDate(newsDetail.updatedAt)}</time>

                  <p>{newsDetail.title[language]}</p>

                  <Divider />
                </Link>
              </li>
            ))}
          </ul>
        )}
      </main>

      <div className="custom-design-block" css={[paginationStyle, pcScreenStyle]}>
        <ArrowLeftIcon
          css={isEmpty(chunkNewsDetails) || currentPage === 1 ? noneClickStyle : undefined}
          className="left custom-design-icon"
          onClick={() => onClickPagination(currentPage - 1)}
        />
        <span className="count">
          {isEmpty(chunkNewsDetails) ? 0 : currentPage}&nbsp;/&nbsp;{chunkNewsDetails.length}
        </span>
        <ArrowRightIcon
          css={isEmpty(chunkNewsDetails) || currentPage === chunkNewsDetails.length ? noneClickStyle : undefined}
          className="right custom-design-icon"
          onClick={() => onClickPagination(currentPage + 1)}
        />
      </div>
    </Layout>
  )
}

const mainLayoutStyle = css({
  paddingBlock: '2rem',
  paddingInline: '1rem',
  h2: {
    fontSize: '24px',
    marginBottom: '2rem',
  },
})

const newsListItemStyle = css({
  marginBottom: '1rem',
  time: {
    fontSize: '12px',
  },
  p: {
    marginTop: '0.25rem',
    paddingBottom: '0.75rem',
    fontSize: '16px',
    lineHeight: 1.5,
  },
})

const paginationStyle = css({
  textAlign: 'center',
  paddingBottom: '54px',
  '.count': {
    paddingInline: '34px',
  },
})

const noneClickStyle = css({
  pointerEvents: 'none',
  opacity: 0.5,
})
