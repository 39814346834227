import React, { useEffect } from 'react'
import { css } from '@emotion/react'
import { Button } from '../atoms/button'
import { useTranslation } from 'react-i18next'
import { ExclamationIcon } from '../atoms/icon'
import { useNavigate } from 'react-router-dom'
import { useHotelId } from '@/hooks/use-hotel-id'
import { CUSTOM_HL, CustomDesignStyle } from '../template/custom-design-style'
import { useAppDispatch } from '@/app/hooks'
import { fetchDesign } from '@/features/asset/design-slice'

export const Error404: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { getHotelId } = useHotelId()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const hotelId = getHotelId()
    if (!hotelId) {
      return
    }
    dispatch(fetchDesign({ hotelId }))
  }, [])

  return (
    <>
      <CustomDesignStyle />
      <div css={pageStyle}>
        <div className="message">
          <ExclamationIcon />
          <div className={`text-message ${CUSTOM_HL}`}>
            このページは
            <br />
            ご利用いただけません
          </div>
          <p className="text-message">
            リンクに問題があるか、
            <br />
            ページが削除された可能性があります。
          </p>
          {getHotelId() && (
            <p className="text-message">
              お手数をおかけしますが、
              <br />
              トップページからアクセスください。
            </p>
          )}
        </div>
        {getHotelId() && (
          <div className="bottom-button">
            <Button text={t('Back to main')} onClick={() => navigate({ pathname: '/' })} />
          </div>
        )}
      </div>
    </>
  )
}

const pageStyle = css({
  display: 'flex',
  flexFlow: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '100vh',
  '.message': {
    paddingTop: 96,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '.text-message': {
      textAlign: 'center',
      fontSize: 14,
      letterSpacing: 1.4,
      lineHeight: '21px',
      [`&.${CUSTOM_HL}`]: {
        fontWeight: 'bold',
        fontSize: 21,
        letterSpacing: 1.05,
        lineHeight: '32px',
        padding: '40px 0 32px 0',
      },
    },
  },
  '.bottom-button': {
    width: '100vw',
    padding: '0 22px 40px 22px',
  },
})
