import { http } from '@/apis/aipass'
import { env } from '@/libs/env'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const getGuestRoomByTelephone = createAsyncThunk(
  'mobileRequest/checkinRoomNumber',
  async (params: { hotelId: string; telephone: string }, { rejectWithValue }) => {
    try {
      const response = await http({
        method: 'get',
        url: `${env('BASE_API_V3')}/mobile-request/checkin-room-number`,
        params,
      })

      return response.data.checkinAssignRooms
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

interface CartRoom {
  checkinId: string
  email: string | null
  name: string | null
  roomNumber: string[]
}

const sessionStorageKey = 'cartRooms'
export const getSessionCartRooms = (): CartRoom[] => {
  const sessionCartRooms = sessionStorage.getItem(sessionStorageKey)
  const requestCart = sessionCartRooms ? JSON.parse(sessionCartRooms) : []

  return requestCart
}

const guestRoomByTelephoneSlice = createSlice({
  name: 'guestRoomByTelephone',
  reducers: { 
    clearCartRooms: (state) => {
      state.cartRooms = []
    }
   },
  initialState: {
    cartRooms: getSessionCartRooms(),
    loading: false,
  },
  extraReducers(builder) {
    builder.addCase(getGuestRoomByTelephone.pending, state => {
      state.loading = true
    })
    builder.addCase(getGuestRoomByTelephone.fulfilled, (state, action) => {
      state.cartRooms = action.payload
      state.loading = false

      sessionStorage.setItem(sessionStorageKey, JSON.stringify(state.cartRooms))
    })
    builder.addCase(getGuestRoomByTelephone.rejected, state => {
      state.loading = false
    })
  },
})

export const { clearCartRooms } = guestRoomByTelephoneSlice.actions
export const guestRoomByTelephoneReducer = guestRoomByTelephoneSlice.reducer
