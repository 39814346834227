import React, { useCallback, useEffect, useState } from 'react'
import { EmblaCarouselType } from 'embla-carousel'
import { css } from '@emotion/react'

type UseSelectedSnapDisplayType = {
  selectedSnap: number
  snapCount: number
}

export const useSelectedSnapDisplay = (emblaApi: EmblaCarouselType | undefined): UseSelectedSnapDisplayType => {
  const [selectedSnap, setSelectedSnap] = useState(0)
  const [snapCount, setSnapCount] = useState(0)

  const updateScrollSnapState = useCallback((emblaApi: EmblaCarouselType) => {
    setSnapCount(emblaApi.scrollSnapList().length)
    setSelectedSnap(emblaApi.selectedScrollSnap())
  }, [])

  useEffect(() => {
    if (!emblaApi) return

    updateScrollSnapState(emblaApi)
    emblaApi.on('select', updateScrollSnapState)
    emblaApi.on('reInit', updateScrollSnapState)
  }, [emblaApi, updateScrollSnapState])

  return {
    selectedSnap,
    snapCount,
  }
}

type PropType = {
  selectedSnap: number
  snapCount: number
}

export const SelectedSnapDisplay: React.FC<PropType> = props => {
  const { selectedSnap, snapCount } = props

  return (
    <div className="embla__selected-snap-display" css={style}>
      {selectedSnap + 1}/{snapCount}
    </div>
  )
}
const style = css({
  position: 'absolute',
  bottom: 26,
  right: 11,
  background: 'rgba(0,0,0,0.4)',
  borderRadius: 4,
  height: 30,
  lineHeight: '30px',
  width: 45,
  color: '#ffffff',
  letterSpacing: 1.2,
  fontSize: 12,
  textAlign: 'center',
})
