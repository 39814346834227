import React from 'react'
import { useAppSelector } from '@/app/hooks'
import 'dayjs/locale/ja'
import { NavigateHeader } from '@/components/atoms/navigate-header'
import { Layout } from '@/components/template/layout'
import { css } from '@emotion/react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@/app/store'
import { BorderButton, Button } from '@/components/atoms/button'
import { pcScreenStyle, spacerTopStyle } from '@/styles/common'
import { useNavigate } from 'react-router'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { createDateString } from '@/utils/date'
import { Loading } from '@/components/organisms/loading'
import { ReservationSelectCard } from '@/components/molecules/reservation-select-card'
import { RadioButton } from '@/components/atoms/radio-button'
import { setReservationInfo } from '@/features/space/space-reservation-slice'
import { minScreen } from '@/styles/media-query'
import { formButtonStyle } from '@/styles/form'
import { useBreakPoint } from '@/hooks/use-break-point'
import { ReservationForSpace } from '@/features/space/space-search-reservation-slice'

export const SpaceReservationSelect: React.FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const dispatch = useDispatch<AppDispatch>()

  const navigate = useNavigate()

  const { isPc } = useBreakPoint()

  const searchedReservations = useAppSelector(state => state.searchReservationSpace.searchReservations)
  const spaceFormValues = useAppSelector(state => state.spaces.formValues)
  const reservationInfo = useAppSelector(state => state.spaceReservation.reservationInfo)

  const createLabel = (reservation: ReservationForSpace) => {
    const dateString = createDateString({ date: reservation.checkinDate, language })
    return (
      <>
        {dateString}
        <span style={{ paddingLeft: '0.5rem', fontSize: '14px' }}>
          {reservation.nights} {reservation.nights === 1 ? t('night') : t('nights')}
        </span>
      </>
    )
  }

  return (
    <Layout>
      <NavigateHeader title={t('Select your reservation')} style={{ marginBottom: '1rem' }} />
      <Loading loading={false}>
        <div css={[layoutStyle, pcScreenStyle]}>
          {searchedReservations.map((reservation, index) => {
            const label = (
              <RadioButton
                label={createLabel(reservation)}
                checked={reservationInfo?.reservationId === reservation.reservationId}
                value={reservation.reservationId}
                radioSize="middle"
                name={reservation.reservationId}
                onChange={() => {
                  dispatch(setReservationInfo(reservation))
                }}
              />
            )
            return (
              <div key={index} css={index !== 0 ? spacerTopStyle : undefined}>
                <ReservationSelectCard reservation={reservation} selectLabel={label} />
              </div>
            )
          })}
          <div css={isPc ? formButtonStyle : undefined}>
            <Button
              disabled={isEmpty(reservationInfo)}
              buttonCss={spacerTopStyle}
              text={t('Next')}
              onClick={() => navigate(`/space/${spaceFormValues?.spaceId}/application-confirm`)}
            />
            <BorderButton
              buttonCss={[spacerTopStyle]}
              text={t('Search your reservation')}
              onClick={() => navigate('/space/reservation-search')}
            />
          </div>
        </div>
      </Loading>
    </Layout>
  )
}

const layoutStyle = css({
  paddingInline: '1.5rem',
  [minScreen()]: {
    paddingInline: 0,
  },
})
