import { PayloadAction, createSlice } from "@reduxjs/toolkit"

interface CurrentPayment {
  paymentType: string
}

export const currentPaymentSlice = createSlice({
  name: 'currentPayment',
  reducers: {
    setPaymentType: (state, action: PayloadAction<CurrentPayment>) => {
      state.paymentType = action.payload.paymentType
    }
  },
  initialState: {
    paymentType: ''
  }
})

export const { setPaymentType } = currentPaymentSlice.actions
export const currentPaymentReducer = currentPaymentSlice.reducer