import { http } from '@/apis/aipass'
import { env } from '@/libs/env'

interface creditCardPayForMobileInput {
  hotelId: string
  paymentAmount: string
  checkinId: string
  cardId: string | null
  cuicinUserId: string | null
  cardDetail: { cardNumber: string; cardExpire: string; securityCode: string } | null
}
export const creditCardPayForMobileRequest = async (data: creditCardPayForMobileInput) => {
  const response = await http({
    method: 'post',
    url: `${env('BASE_API_V3')}/mobile-request/payment`,
    data,
  })

  const result = response.data
  return { mobileRequestOrderId: result.mobileRequestOrderId, ...result.processResult }
}
