import React from 'react'
import { css } from '@emotion/react'
import { minScreen } from '@/styles/media-query'

interface ThumbnailLinkBlockProps {
  children: React.ReactNode
  className?: string
}

export const ThumbnailLinkBlock: React.FC<ThumbnailLinkBlockProps> = ({ children, className }) => {
  return (
    <div className={className} css={blockStyle}>
      {children}
    </div>
  )
}

const blockStyle = css({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  rowGap: '1rem',
  [minScreen()]: {
    justifyContent: 'flex-start',
    gap: '1rem',
  }
})
