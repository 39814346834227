import { useAppSelector } from '@/app/hooks'
import { CUSTOM_BLOCK } from '@/components/template/custom-design-style'
import { css, SerializedStyles } from '@emotion/react'
import React, { useEffect, useMemo, useState } from 'react'
import { v4 as uuid } from 'uuid'

type Props = {
  icon?: {
    width?: number
    height?: number
  }
  items: string[]
  current?: number
}

export const Step: React.FC<Props> = props => {
  const design = useAppSelector(state => state.design)
  const [stepItemTailWidthStyle, setStepItemTailWidthStyle] = useState<SerializedStyles>()
  const domId = `step-block-${uuid()}`

  const calcStepItemTailWidthStyle = () => {
    const iconRefs = document.getElementById(domId)?.querySelectorAll('.step-item > .icon') as NodeListOf<HTMLDivElement>
    if (!iconRefs?.length) {
      setTimeout(calcStepItemTailWidthStyle, 100)
      return
    }
    const tailSelectorStyle = {}
    for (let i = 1; i < props.items.length; i++) {
      tailSelectorStyle[`&:nth-of-type(${i}) .icon .tail`] = {
        width: iconRefs[i].offsetLeft - iconRefs[i - 1].offsetLeft,
      }
    }
    setStepItemTailWidthStyle(css({ '.step-item': tailSelectorStyle }))
  }

  const blockStyle = useMemo(
    () =>
      css({
        padding: 16,
        display: 'flex',
        justifyContent: 'space-between',
        '.step-item': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 6,
          '.content': {
            fontSize: 10,
            lineHeight: 1.5,
            fontWeight: 'bold',
            color: design?.form.borderColor,
            '&.active': {
              color: design?.theme.brandColor,
            },
          },
          '.icon': {
            width: props.icon?.width || 10,
            height: props.icon?.height || 10,
            borderRadius: '50%',
            position: 'relative',
            backgroundColor: design?.form.borderColor,
            '&.active': {
              backgroundColor: design?.theme.brandColor,
            },
            '.tail': {
              height: 3,
              position: 'absolute',
              left: props.icon?.width || 10,
              top: (props.icon?.height || 10) / 2 - 3 / 2,
              backgroundColor: design?.form.borderColor,
              '&.active': {
                backgroundColor: design?.theme.brandColor,
              },
            },
          },
        },
      }),
    [design],
  )

  useEffect(() => {
    calcStepItemTailWidthStyle()
  }, [])

  return (
    <div>
      <div id={domId} css={[blockStyle, stepItemTailWidthStyle]} className={CUSTOM_BLOCK}>
        {props.items.map((item, i) => (
          <div className="step-item" key={`step-item-${i}`}>
            <div className={`content ${i === props.current ? 'active' : ''}`}>{item}</div>
            <div className={`icon ${i <= (props.current || 0) ? 'active' : ''}`}>
              <div className={`tail ${i < (props.current || 0) ? 'active' : ''}`}></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
