import React from 'react'
import { NavigateHeader } from '@/components/atoms/navigate-header'
import { Layout } from '@/components/template/layout'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { FormLayout } from '@/components/template/form-layout'
import { ReservationGuestRoomLabel } from '@/components/atoms/reservation-guest-room-label'
import { StayReservation } from '@/features/reservation/stay-reservation-slice'
import { spacerTopStyle, textOverflowStyle } from '@/styles/common'
import { css } from '@emotion/react'
import { CheckoutRecommendScore } from '@/components/organisms/checkout/checkout-recommend-score'
import { BoxTitle } from '@/components/atoms/box-title'
import { TextArea } from '@/components/atoms/text-area'
import { Button } from '@/components/atoms/button'
import { formButtonStyle } from '@/styles/form'
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import { useQuerySearchParams } from '@/hooks/use-query-search-params'
import { ReservationReviewFormInput, sendReservationReview } from '@/features/checkout/checkout-reservation-slice'
import { useNavigate } from 'react-router-dom'
import { errorHandler } from '@/libs/errors'
import { checkout } from '@/features/checkout/checkout-data-slice'
import { CUSTOM_FORM } from '@/components/template/custom-design-style'
import { ContentWrapper } from '@/components/template/content-wrapper'

const reviewForImprovement = [
  { name: 'Staff', nameJp: '接客', value: false },
  { name: 'food', nameJp: '食事', value: false },
  { name: 'Cleanliness', nameJp: '清掃', value: false },
  { name: 'Room', nameJp: 'その他', value: false },
  { name: 'Facility', nameJp: '客室', value: false },
  { name: 'Other', nameJp: '設備', value: false },
]

export const CheckoutReview: React.FC = () => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const { hotelId } = useQuerySearchParams<{ hotelId: string }>()

  const useFormReturn = useForm<ReservationReviewFormInput>({
    mode: 'all',
    defaultValues: {
      improvement: reviewForImprovement,
    },
  })
  const { handleSubmit, control, register } = useFormReturn

  const { fields, update } = useFieldArray({
    control,
    name: 'improvement',
  })

  const checkoutReservations: StayReservation[] = useAppSelector(state => state.checkout.checkoutReservation.checkoutReservations)

  const onSubmit: SubmitHandler<ReservationReviewFormInput> = async submitData => {
    const checkedImprovementList = submitData.improvement.filter(({ value }) => value).map(({ nameJp }) => nameJp)

    try {
      if (checkoutReservations?.length) {
        for (const reservation of checkoutReservations) {
          const reviewPayload = {
            hotelId: hotelId,
            comment: submitData.comment,
            point: submitData.point,
            reservationId: reservation.reservationId,
            improvement: checkedImprovementList.join(','),
          }
          await sendReservationReview(reviewPayload)

          const checkoutPayload = {
            hotelId,
            checkinId: '',
            reservationId: reservation.reservationId,
            withReview: true,
          }
          dispatch(checkout(checkoutPayload))
        }
      }

      navigate({ pathname: '/checkout/thanks', search: `hotelId=${hotelId}` })
    } catch (error) {
      errorHandler({ error })
    }
  }

  const onSelectImprovement = (index: number) => {
    const selectItem = fields[index]
    if (selectItem) {
      update(index, { ...selectItem, value: !selectItem.value })
    }
  }

  return (
    <Layout>
      <NavigateHeader title={t('Check-out')} style={{ marginBottom: '1rem' }} />

      <ContentWrapper>
        <FormLayout>
          <ul>
            {checkoutReservations.map((reservation, index) => (
              <li key={index} css={checkoutReservationListItemStyle}>
                <p css={roomTextStyle}>
                  <ReservationGuestRoomLabel roomNumbers={reservation.roomNumbers} />
                </p>
                <p css={reservationIdStyle}>
                  <span>{t('Reservation ID')}</span>
                  <span css={textOverflowStyle}>{reservation.reservationId}</span>
                </p>
              </li>
            ))}
          </ul>
        </FormLayout>

        <form onSubmit={handleSubmit(onSubmit)}>
          <FormLayout layoutCss={blockSpace}>
            <CheckoutRecommendScore useFormReturn={useFormReturn} />
          </FormLayout>

          <FormLayout layoutCss={blockSpace}>
            <BoxTitle title={t('Please tell us about any improvements or good points')} />
            <p css={answersStyle}>{t('Multiple answers possible')}</p>

            <ul css={improvementStyle}>
              {fields.map((item, index) => (
                <li className={`${CUSTOM_FORM} ${item.value ? 'selected' : ''}`} key={index} onClick={() => onSelectImprovement(index)}>
                  {item.nameJp}
                </li>
              ))}
            </ul>

            <div css={spacerTopStyle}>
              <TextArea {...register('comment')} label={t('Add Comment')} name="comment" />
            </div>
          </FormLayout>

          <Button buttonCss={[buttonStyle, formButtonStyle]} text={t('Check-out')} type="submit" />
        </form>
      </ContentWrapper>
    </Layout>
  )
}

const blockSpace = css({
  marginTop: '1rem',
})

const roomTextStyle = css({
  fontWeight: 'bold',
})

const checkoutReservationListItemStyle = css({
  borderBottom: '1px solid #F2F2F2',
  paddingBottom: '1.5rem',
  marginBottom: '1.5rem',
  '&:last-of-type': {
    border: 'none',
    paddingBottom: 0,
    marginBottom: 0,
  },
})

const answersStyle = css({
  textAlign: 'center',
  marginTop: '0.5rem',
  marginBottom: '1.5rem',
  fontSize: '12px',
})

const reservationIdStyle = css({
  display: 'flex',
  marginTop: '0.5rem',
  fontSize: '12px',
  span: {
    '&:first-of-type': {
      flexBasis: '48px',
    },
  },
})

const improvementStyle = css({
  display: 'flex',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  gap: '1rem',
  li: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '92px',
    height: '32px',
    fontSize: '12px',
    borderRadius: '18px',
    border: '1px solid',
  },
})

const buttonStyle = css({
  marginTop: '2rem',
})
