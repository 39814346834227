import { useAppSelector } from '@/app/hooks'
import { ThumbnailLink } from '@/components/molecules/thumbnail-link'
import { CUSTOM_BLOCK } from '@/components/template/custom-design-style'
import { FormatType } from '@/features/asset/assets-slice'
import { usePickByLanguage } from '@/hooks/use-pick-by-language'
import { css } from '@emotion/react'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export const CheckInAsset: React.FC = () => {
  const { t } = useTranslation()

  const pickByLanguage = usePickByLanguage()

  const hotelGuideData = useAppSelector(state => state.hotelGuide)
  const { assets } = useAppSelector(state => state.assets)
  const design = useAppSelector(state => state.design)

  const customAssets = useMemo(() => {
    const customAssetTypes = [
      FormatType.Free,
      FormatType.Map,
      FormatType.Request,
      FormatType.Coupon,
    ] as FormatType[]
    return assets.filter(asset => customAssetTypes.includes(asset.formatType))
  }, [assets])

  return (
    <div css={blockStyle} className={CUSTOM_BLOCK}>
      <h3 css={[titleStyle, { color: design.theme.brandColor }]}>
        {t('Information from {{hotelName}}', { hotelName: hotelGuideData.facilityBasicInfo.hotelName })}
      </h3>

      <div css={contentWrapStyle}>
        {customAssets.map(customAsset => (
          <div css={contentStyle} key={customAsset.id}>
            <ThumbnailLink
              link={`/?hash=${customAsset.id}`}
              imagePath={customAsset.details[0]?.imagePath}
              title={pickByLanguage(customAsset.title)}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

const blockStyle = css({
  padding: '1.5rem 1rem',
  marginTop: '2rem',
})

const titleStyle = css({
  marginBottom: '1rem',
  fontWeight: 'bold',
  textAlign: 'left',
})

const contentWrapStyle = css({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  rowGap: '1.5rem',
})

const contentStyle = css({
  textAlign: 'left',
  '.title': {
    marginTop: '0.25rem',
    fontSize: '14px',
  },
})
