import { css } from '@emotion/react'
import React from 'react'
import { Link } from 'react-router-dom'
import { CUSTOM_TEXT_COLOR_LINK } from '../template/custom-design-style'
import { lineClampStyle } from '@/styles/common'

interface ThumbnailLinkProps {
  link: string
  imagePath: string
  title: string
}

export const ThumbnailLink: React.FC<ThumbnailLinkProps> = ({ link, imagePath, title }) => {
  return (
    <div css={contentStyle}>
      <Link to={link}>
        <div
          css={[thumbnailStyle, { height: !imagePath ? 0 : '84px' }]}
          style={{ background: `url(${imagePath}) center/cover no-repeat` }}
        />
        <p className={`title ${CUSTOM_TEXT_COLOR_LINK}`} css={lineClampStyle(2)}>
          {title}
        </p>
      </Link>
    </div>
  )
}

const contentStyle = css({
  maxWidth: 166,
  textAlign: 'left',
  a: {
    display: 'block',
  },
  '.title': {
    marginTop: '0.25rem',
    fontSize: '14px',
  },
})

const thumbnailStyle = css({
  width: '168px',
  borderRadius: 5,
})
