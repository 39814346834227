import { http } from '@/apis/aipass'
import { env } from '@/libs/env'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface ReservationReviewFormInput {
  comment: string
  point: string
  improvement: { name: string; nameJp: string; value: boolean }[]
}

interface ReservationReviewInput {
  hotelId?: string
  reservationId?: string
  checkinId?: string
  guestId?: string
  comment?: string
  point?: string
  improvement?: string
}
export const sendReservationReview = async (data: ReservationReviewInput) => {
  const response = await http({
    method: 'put',
    url: `${env('BASE_API_V3')}/reservation-review`,
    data,
  })
  return response.data
}

const getSessionCheckoutReservations = () => {
  const sessionCheckoutReservations = sessionStorage.getItem('checkoutReservations')
  const checkoutReservations = sessionCheckoutReservations ? JSON.parse(sessionCheckoutReservations) : []

  return checkoutReservations
}

const checkoutReservationSlice = createSlice({
  name: 'checkoutReservation',
  initialState: {
    checkoutReservations: getSessionCheckoutReservations(),
  },
  reducers: {
    setCheckoutReservations: (state, action: PayloadAction<any>) => {
      state.checkoutReservations = action.payload
      sessionStorage.setItem('checkoutReservations', JSON.stringify(state.checkoutReservations))
    },
  },
})

export const { setCheckoutReservations } = checkoutReservationSlice.actions
export const checkoutReservationReducer = checkoutReservationSlice.reducer
