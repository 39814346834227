import { t } from 'i18next'

export const OCCUPATION_EMPLOYEE = '会社員'
export const OCCUPATION_SELF_EMPLOYED = '自営業'
export const OCCUPATION_STUDENT = '学生'
export const OCCUPATION_NONE = 'なし'
export const OCCUPATION_OTHER = 'その他'

export const OCCUPATION_LIST = {
  [OCCUPATION_EMPLOYEE]: 'Employee',
  [OCCUPATION_SELF_EMPLOYED]: 'Self Employed',
  [OCCUPATION_STUDENT]: 'Student',
  [OCCUPATION_NONE]: 'No Occupation',
  [OCCUPATION_OTHER]: 'Other',
}

export const occupationOptions = () => {
  return Object.keys(OCCUPATION_LIST).map(key => ({
    label: t(OCCUPATION_LIST[key]),
    value: key,
  }))
}

export const isOtherByOccupation = occupation =>
  ![OCCUPATION_EMPLOYEE, OCCUPATION_SELF_EMPLOYED, OCCUPATION_STUDENT, OCCUPATION_NONE].includes(occupation)

export const isEmployeeByOccupation = occupation => occupation === OCCUPATION_EMPLOYEE || occupation === OCCUPATION_SELF_EMPLOYED

export const convertedOccupationValueToLabel = ({ occupation, otherOccupation, company }) => {
  const occupationLabel = OCCUPATION_LIST[occupation]
  if (isEmployeeByOccupation(occupation)) {
    return `${t(occupationLabel)}${company ? ` / ${company}` : ''}`
  }
  if (isOtherByOccupation(occupation)) {
    return `${t(occupationLabel)}${otherOccupation ? ` / ${otherOccupation}` : ''}`
  }
  return t(occupationLabel)
}
