import { http } from '@/apis/aipass'
import { env } from '@/libs/env'
import { inputNumberWithMax } from '@/utils/zod-validate-map'
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { t } from 'i18next'
import { z } from 'zod'

export const cardSchema = z.object({
  cardNumber: z
    .string()
    .trim()
    .min(1)
    .max(19, { message: t('Please enter up to {{maxNumber}} characters', { maxNumber: 16 }) }),
  cardExpireYear: inputNumberWithMax(2),
  cardExpireMonth: inputNumberWithMax(2),
  securityCode: inputNumberWithMax(4),
  receiptName: z.string().nullish(),
  name: z.string().min(1),
})

export interface CardDetail {
  paymentType?: string
  cardNumber: string
  cardExpire: string
  cardExpireYear: string
  cardExpireMonth: string
  securityCode: string
  cardId: string
  name: string
  receiptName?: string
  paymentAmount?: number
}

interface UserCreditCard {
  status?: string
  accountId: string
  cardId: string
  cardNumber: string
  cardBrand: string
  txnResultCode: string
  processId: string
}

export const addCreditCard = createAsyncThunk(
  'user/addCreditCard',
  async (
    creditData: { cuicinUserId: string; cardDetail: Pick<CardDetail, 'cardExpire' | 'cardNumber' | 'securityCode'> },
    { rejectWithValue },
  ) => {
    try {
      const tokenResponse = await http({
        method: 'post',
        url: 'https://api.veritrans.co.jp/4gtoken',
        data: {
          token_api_key: env('AIPASS_VERITRANS_API_KEY'),
          card_number: creditData.cardDetail.cardNumber,
          card_expire: creditData.cardDetail.cardExpire,
          security_code: creditData.cardDetail.securityCode,
        },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
        },
      })
      const response = await http({
        method: 'post',
        url: `${env('BASE_API_V3')}/payment/cuicin-user-card`,
        data: {
          cuicinUserId: creditData.cuicinUserId,
          cardToken: tokenResponse.data.token,
        },
      })

      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const deleteCreditCard = createAsyncThunk('user/deleteCreditCard', async (cardId: string, { rejectWithValue }) => {
  try {
    const response = await http({
      method: 'delete',
      url: `${env('BASE_API_V3')}/payment/cuicin-user-card`,
      params: { cardId },
    })

    return response.data
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const getCreditCard = createAsyncThunk('user/getCreditCard', async (cuicinUserId: string, { rejectWithValue }) => {
  try {
    const response = await http({
      method: 'get',
      url: `${env('BASE_API_V3')}/payment/cuicin-user-card`,
      params: { cuicinUserId: cuicinUserId },
    })

    return response.data
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const creditCardSlice = createSlice({
  name: 'userCard',
  initialState: {
    checkInPaymentData: {},
    userCreditCardData: {} as UserCreditCard,
    getLoading: true,
    deleteLoading: false,
    addLoading: false,
  },
  reducers: {
    // setCheckInPaymentData: (state, action: PayloadAction<Pick<CardDetail, 'paymentType' | 'paymentAmount' | 'receiptName'>>) => {
    //   state.checkInPaymentData.paymentType = action.payload.paymentType
    //   state.checkInPaymentData.paymentAmount = action.payload.paymentAmount
    //   state.checkInPaymentData.receiptName = action.payload.receiptName

    //   sessionStorage.setItem('checkinPaymentData', JSON.stringify(state.checkInPaymentData))
    // },
    setCardData: (state, action: PayloadAction<Partial<CardDetail>>) => {
      state.checkInPaymentData = { ...state.checkInPaymentData, ...action.payload } as CardDetail
    },
  },
  extraReducers(builder) {
    builder.addCase(addCreditCard.pending, state => {
      state.addLoading = true
    })
    builder.addCase(addCreditCard.fulfilled, (state, action) => {
      state.checkInPaymentData = action.payload
      state.userCreditCardData = action.payload
      state.addLoading = false
    })
    builder.addCase(addCreditCard.rejected, state => {
      state.addLoading = false
    })
    builder.addCase(getCreditCard.fulfilled, (state, action) => {
      state.userCreditCardData = action.payload.accountInfo
      state.getLoading = false
    })
    builder.addCase(deleteCreditCard.pending, state => {
      state.deleteLoading = true
    })
    builder.addCase(deleteCreditCard.fulfilled, state => {
      state.userCreditCardData.cardNumber = ''
      state.deleteLoading = false
    })
    builder.addCase(deleteCreditCard.rejected, state => {
      state.deleteLoading = false
    })
  },
})

export const { setCardData } = creditCardSlice.actions
export const creditCardSliceReducer = creditCardSlice.reducer
