import { css } from '@emotion/react'
import React from 'react'
import { MainMenu } from '../organisms/main-menu'
import { CUSTOM_CARD } from '../template/custom-design-style'
import { useMatches, useNavigate } from 'react-router-dom'

interface HeaderProps {
  hotelName: string
}

const headerHeight = 56
export const Header: React.FC<HeaderProps> = ({ hotelName }) => {
  const navigate = useNavigate()

  const matches = useMatches()
  const isShadow = !matches.find(match => (match.handle as { noHeaderShadow?: boolean })?.noHeaderShadow)

  return (
    <header css={headerStyle} style={{ boxShadow: isShadow ? '0px 3px 6px #00000029' : 'none' }} className={CUSTOM_CARD}>
      <h1 onClick={() => navigate('/')}>{hotelName}</h1>
      <MainMenu style={{ top: headerHeight }} />
    </header>
  )
}

const headerStyle = css({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  paddingLeft: '1rem',
  paddingRight: '1rem',
  fontSize: '18px',
  height: headerHeight,

  zIndex: 200,
  justifyContent: 'space-between',
  h1: {
    textAlign: 'left',
    fontWeight: 'bold',
  },
  borderTop: 'none',
  borderLeft: 'none',
  borderRight: 'none',
})
