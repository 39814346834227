import React from 'react'
import { useAppSelector } from '@/app/hooks'
import { RequestAssetType } from '@/features/asset/assets-slice'
import { usePickByLanguage } from '@/hooks/use-pick-by-language'
import { categoryMenuStyle } from '@/styles/category-menu'
import { css } from '@emotion/react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { lineClampStyle, pcScreenStyle } from '@/styles/common'

interface RequestTagMenuProps {
  asset: RequestAssetType
}

export const paramsTagKey = 'requestTag'
export const RequestTagMenu: React.FC<RequestTagMenuProps> = ({ asset }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const pickByLanguage = usePickByLanguage()
  const { t } = useTranslation()

  const design = useAppSelector(state => state.design)

  const paramsTagId = searchParams.get(paramsTagKey)
  const paramsCategoryId = searchParams.get('requestCategory')

  const tagsList = [{ id: -1, imagePath: asset.categories[0]?.imagePath || '', name: t('all') }, ...asset.tags].map(tag => ({
    id: tag.id,
    imagePath: tag.imagePath,
    name: tag.name,
  }))

  return (
    <>
      <div css={[menuStyle, pcScreenStyle]} className="custom-design-block">
        <ul css={[categoryMenuStyle]}>
          {tagsList.map(tag => {
            const selected = (!paramsTagId && tag.id <= 0) || Number(paramsTagId) === tag.id

            return (
              <li
                key={tag.id}
                {...(selected ? { css: { color: design.theme.brandColor } } : {})}
                onClick={() => setSearchParams({ [paramsTagKey]: tag.id.toString(), requestCategory: paramsCategoryId || '' })}
              >
                <div className="round-image" style={{ border: selected ? `1px solid ${design.theme.brandColor}` : undefined }}>
                  <img src={tag.imagePath} alt={pickByLanguage(tag.name)} />
                </div>
                <div css={{ background: selected ? design.theme.brandColor : 'none' }} className="tag-name">
                  <span css={[lineClampStyle(2), { color: selected ? design?.button[0].textColor : design.text.textColor }]}>
                    {typeof tag.name === 'string' ? tag.name : pickByLanguage(tag.name)}
                  </span>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </>
  )
}

const menuStyle = css({
  overflowX: 'auto',
  paddingBlock: '0.5rem',
  '&:-webkit-scrollbar': {
    display: 'none',
  },
  ul: {
    width: 'max-content',
    gap: '1rem',
    justifyContent: 'unset',
    flexWrap: 'nowrap',
    boxShadow: 'none',
  },
  li: {
    display: 'flex',
    padding: 0,
    maxWidth: 64,
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
  },
  '.tag-name': {
    width: '100%',
    textAlign: 'center',
    marginTop: '0.25rem',
    borderRadius: '10px',
    padding: '0.25rem',
    fontSize: 11,
  },
  '.round-image': {
    width: 50,
    height: 50,
    padding: 2,
    borderRadius: '50%',
    img: {
      width: '100%',
      height: '100%',
      borderRadius: '50%',
    },
  },
})
