import { SerializedStyles, css } from '@emotion/react'
import React from 'react'
import { CUSTOM_CARD } from '../template/custom-design-style'

interface CardProps {
  children: React.ReactNode
  cardCss?: SerializedStyles
}

export const Card: React.FC<CardProps> = ({ children, cardCss }) => {
  return (
    <div className={CUSTOM_CARD} css={[CardStyle, cardCss]}>
      {children}
    </div>
  )
}

const CardStyle = css({
  borderRadius: 8,
  padding: '2rem 1.5rem',
})
