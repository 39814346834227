import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { BorderButton, Button } from '@/components/atoms/button'
import { Divider } from '@/components/atoms/divider'
import { ModalWrapper } from '@/components/atoms/modal-wrapper'
import { CUSTOM_BLOCK } from '@/components/template/custom-design-style'
import { RequestAssetDetailType } from '@/features/asset/assets-slice'
import { changeCart } from '@/features/request/request-cart-slice'
import { usePickByLanguage } from '@/hooks/use-pick-by-language'
import { displayYen } from '@/libs/text'
import { css } from '@emotion/react'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { minScreen } from '@/styles/media-query'

interface RequestDetailModalProps {
  detail: RequestAssetDetailType
  assetId: string
  onClose: () => void
}

export const RequestDetailModal: React.FC<RequestDetailModalProps> = ({ detail, onClose, assetId }) => {
  const [count, setCount] = useState(1)

  const { t } = useTranslation()

  const pickByLanguage = usePickByLanguage()

  const design = useAppSelector(state => state.design)
  const requestCarts = useAppSelector(state => state.request.requestCart)
  const targetRequestCart = requestCarts.cart[assetId]?.find(requestCart => requestCart.detail.id === detail.id)

  const dispatch = useAppDispatch()

  const _changeCart = () => {
    dispatch(changeCart({ detail, count, assetId }))
    onClose()
  }

  useEffect(() => {
    if (targetRequestCart?.count) {
      setCount(targetRequestCart.count)
    }
  }, [])

  return (
    <ModalWrapper
      modalStyle={{ padding: 0, inset: '3.5rem 1rem', maxWidth: 600, maxHeight: '552px', overflowY: 'scroll' }}
      isOpen={!!detail}
      onClose={() => onClose()}
    >
      <div className={`${CUSTOM_BLOCK}`} css={modalContent}>
        <img src={detail.imagePath} alt={pickByLanguage(detail.name)} />

        <div className="space-inline header">
          <h3 className="modal-title">{pickByLanguage(detail.name)}</h3>
          <p className="modal-price">{displayYen(detail.price.toString())}</p>

          <div className="modal-tags">
            {detail.isFavorite && (
              <span style={{ color: design.theme.brandColor, borderBlockColor: design.theme.brandColor }}>{t('popular')}</span>
            )}
            {!isEmpty(detail.tags) &&
              detail.tags.map(tag => (
                <span key={tag.id} style={{ color: design.theme.brandColor, borderBlockColor: design.theme.brandColor }}>
                  {pickByLanguage(tag.name)}
                </span>
              ))}
          </div>
        </div>

        <Divider />

        <div className="space-inline">
          {detail.description && <p className="modal-description">{pickByLanguage(detail.description)}</p>}

          <div className="count">
            <button
              className="count-button subtract custom-design-button-secondary"
              onClick={() => setCount(count - 1)}
              disabled={count <= 0}
            >
              <span css={{ backgroundColor: design.theme.brandColor }}></span>
            </button>
            <span className="count-number">{count}</span>
            <button
              className="count-button add custom-design-button-secondary"
              onClick={() => setCount(count + 1)}
              disabled={detail.stock <= count}
            >
              <span css={{ backgroundColor: design.theme.brandColor }}></span>
              <span className="plus-vertical" css={{ backgroundColor: design.theme.brandColor }}></span>
            </button>
          </div>
        </div>

        <div className="custom-design-background button-wrap">
          <BorderButton text={t('Cancel')} onClick={() => onClose()} />
          <Button text={t('Add to cart')} onClick={() => _changeCart()} />
        </div>
      </div>
    </ModalWrapper>
  )
}

const modalContent = css({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  height: '100%',
  img: {
    width: '100%',
    [minScreen()]: {
      height: 180,
      objectFit: 'cover',
    },
  },
  '.space-inline': {
    paddingInline: '0.75rem',
  },
  '.header': {
    paddingBottom: '1.5rem',
  },
  '.modal-title': {
    marginTop: '2rem',
    marginBottom: '0.5rem',
    fontWeight: 'bold',
  },
  '.modal-price': {
    fontSize: '14px',
  },
  '.modal-tags': {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.75rem',
    marginTop: '1rem',
    span: {
      textAlign: 'center',
      fontSize: '10px',
      border: '1px solid',
      borderRadius: '4px',
      padding: '0.25rem 0.5rem',
    },
  },
  '.modal-description': {
    marginBlock: '1.5rem',
    fontSize: 14,
    lineHeight: '1.5',
    whiteSpace: 'pre-wrap',
  },
  '.count': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '2rem',
    gap: '1rem',
    paddingInline: '1rem',
  },
  '.count-number': {
    fontSize: 18,
    fontWeight: 'bold',
  },
  '.count-button': {
    position: 'relative',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    fontSize: '26px',
    appearance: 'none',
    '&:disabled': {
      opacity: 0.5,
    },
    span: {
      display: 'block',
      width: 12,
      height: 2,
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: 'auto',
      '&.plus-vertical': {
        rotate: '90deg',
      },
    },
  },
  '.button-wrap': {
    position: 'sticky',
    left: 0,
    bottom: 0,
    width: '100%',
    padding: '1rem',
    marginTop: 'auto',
    display: 'flex',
    gap: '0.75rem',
  },
})
