import React, { useMemo } from 'react'
import { Layout } from '@/components/template/layout'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '@/app/hooks'
import { isCouponAsset, isFreeAsset, isImageAsset } from '@/features/asset/assets-slice'
import { FreeAssetDetail } from '@/components/organisms/assetContents/free-asset-detail'
import { ImageAssetDetail } from '@/components/organisms/assetContents/image-asset-detail'
import { CouponDetail } from '@/components/organisms/coupon/coupon-detail'

export const AssetContent: React.FC = () => {
  const { assetId, contentId } = useParams<{ assetId: string; contentId: string }>()
  const { assets } = useAppSelector(state => state.assets)

  const showDetailAsset = useMemo(() => {
    return assets.find(asset => asset.id === assetId)
  }, [assets])

  const assetDetail = useMemo(() => {
    return showDetailAsset?.details.find(detail => detail.id === contentId)
  }, [assets])

  return (
    <Layout>
      {isFreeAsset(showDetailAsset) && <FreeAssetDetail asset={assetDetail} />}
      {isImageAsset(showDetailAsset) && <ImageAssetDetail asset={assetDetail} />}
      {isCouponAsset(showDetailAsset) && <CouponDetail coupon={assetDetail} />}
    </Layout>
  )
}
