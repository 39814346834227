import React from 'react'
import { displayYen } from '@/libs/text'
import { SpaceDetail } from '@/features/space/spaces-slice'
import { css } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import { minScreen } from '@/styles/media-query'

export const SpacePriceLabel: React.FC<{ space: SpaceDetail }> = ({ space }) => {
  const { t } = useTranslation()
  return (
    <div
      css={css({
        paddingTop: 16,
        display: 'flex',
        alignItems: 'baseline',
        div: {
          letterSpacing: 1,
          fontSize: 10,
          fontWeight: 'normal',
          [minScreen()]: {
            fontSize: 12,
          },
        },
        span: {
          letterSpacing: 1.6,
          fontSize: 16,
          fontWeight: 'bold',
        },
      })}
    >
      {!space.spacePrice?.chargePrice?.unitPrice && space.spacePrice?.chargePrice?.maxPrice ? (
        <span>{displayYen(space.spacePrice.chargePrice.maxPrice.toString())}</span>
      ) : (
        <>
          <span>
            {!space.spacePrice?.chargePrice?.unitPrice && '¥-'}
            {space.spacePrice?.chargePrice?.unitPrice && `${displayYen(space.spacePrice?.chargePrice.unitPrice.toString())}`}
            {!space.isFixAvailabilityTime && '~'}
          </span>
          {!space.isFixAvailabilityTime && <div>／{t('hour')}</div>}
          {space.spacePrice?.chargePrice?.maxPrice && (
            <div>
              ({t('maximum charge')}：{displayYen(space.spacePrice.chargePrice.maxPrice.toString())})
            </div>
          )}
        </>
      )}
    </div>
  )
}
