import { ImageAssetType } from '@/features/asset/assets-slice'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { css } from '@emotion/react'
import { SwiperContainer, register } from 'swiper/element/bundle'
import { useAppSelector } from '@/app/hooks'
import { Link } from 'react-router-dom'
import { usePickByLanguage } from '@/hooks/use-pick-by-language'
import '@/static/styles/swiper.css'

type Props = {
  asset: ImageAssetType
}

export const ImageAssetBlock: React.FC<Props> = ({ asset }) => {
  const [swiperHeight, setSwiperHeight] = useState('auto')

  const pickByLanguage = usePickByLanguage()
  const design = useAppSelector(state => state.design)
  const swiperPaginationStyle = useMemo(
    () => ({
      '--swiper-pagination-color': design?.icon.color,
      '--swiper-pagination-bullet-inactive-color': design?.form.borderColor,
      '--swiper-pagination-bullet-inactive-opacity': '1',
      '--swiper-pagination-bullet-size': '10px',
      '--swiper-pagination-bullet-horizontal-gap': '8px',
      '--swiper-pagination-bottom': '0px',
    }),
    [asset, design],
  )

  useEffect(() => {
    register()
  }, [])

  const swiperRef = useRef<SwiperContainer>(null)
  const updateHeight = () => {
    if (asset.details.length === 1) {
      return
    }

    if (swiperRef.current?.swiper) {
      const currentSlide = swiperRef.current.swiper.slides[swiperRef.current.swiper.activeIndex]
      if (currentSlide) {
        setSwiperHeight(`${currentSlide.offsetHeight + 20}px`)
      }
    }
  }

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.addEventListener('swiperslidechange', () => {
        updateHeight()
      })
      updateHeight()
    }
  }, [swiperRef.current])

  return asset.details.length === 0 ? (
    <></>
  ) : (
    <div css={blockStyle}>
      <swiper-container
        ref={swiperRef}
        pagination="true"
        pagination-clickable="true"
        style={{ ...swiperPaginationStyle, height: swiperHeight }}
      >
        {asset.details.map(detail => {
          const isDestinationUrl = detail.destinationType === 'url'
          return (
            <swiper-slide key={detail.id}>
              <Link
                style={{ display: 'block' }}
                to={isDestinationUrl ? detail.destinationUrl : `/contents/${asset.id}/${detail.id}`}
                {...(isDestinationUrl ? { target: '_blank', rel: 'noopener noreferrer' } : {})}
              >
                <img src={detail.imagePath} />

                {detail.isTitleShowHome && (
                  <p css={{ color: design.text.textColor, lineHeight: 1.4, marginTop: 10, fontWeight: 'bold' }}>
                    {pickByLanguage(detail.title)}
                  </p>
                )}
              </Link>
            </swiper-slide>
          )
        })}
      </swiper-container>
    </div>
  )
}

const blockStyle = css({
  padding: '8px 0',
  img: {
    width: '100%',
    borderRadius: 6,
  },
  'swiper-slide': {
    padding: '0  16px',
  },
})
