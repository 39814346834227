import React, { useMemo } from 'react'
import { useAppSelector } from '@/app/hooks'
import { Layout } from '@/components/template/layout'
import { CheckInOutBlock } from '../organisms/home/check-in-out-block'
import {
  FormatType,
  isButtonAsset,
  isCouponAsset,
  isFreeAsset,
  isImageAsset,
  isMapAsset,
  isNewsAsset,
  isPulldownAsset,
  isRequestAsset,
  isSpaceAsset,
} from '@/features/asset/assets-slice'
import { PulldownAssetBlock } from '../organisms/home/pulldown-asset-block'
import { FreeAssetBlock } from '../organisms/home/free-asset-block'
import { css } from '@emotion/react'
import { AroundSpotBlock } from '../organisms/aroundSpot/around-spot-block'
import { NoticeBlock } from '../organisms/notice/notice-block'
import { ButtonAssetBlock } from '../organisms/home/button-asset-block'
import { ImageAssetBlock } from '../organisms/home/image-asset-block'
import { CouponAssetBlock } from '../organisms/coupon/coupon-asset-block'
import { RequestAssetBlock } from '../organisms/request/request-asset-block'
import { SpaceAssetBlock } from '../organisms/request/space-asset-block'
import { pcScreenStyle } from '@/styles/common'

export const Home: React.FC = () => {
  const { assets } = useAppSelector(state => state.assets)

  const customAssets = useMemo(() => {
    const defaultAsset = [FormatType.CheckIn, FormatType.Checkout] as FormatType[]
    return assets.filter(asset => !defaultAsset.includes(asset.formatType))
  }, [assets])

  return (
    <Layout>
      <>
        <CheckInOutBlock />
        <div css={[css({ '> div': { marginBottom: 8 } }), pcScreenStyle]}>
          {customAssets.map(asset => {
            if (isPulldownAsset(asset)) {
              return <PulldownAssetBlock key={asset.id} asset={asset} />
            }
            if (isFreeAsset(asset)) {
              return <FreeAssetBlock key={asset.id} asset={asset} />
            }
            if (isMapAsset(asset)) {
              return <AroundSpotBlock key={asset.id} asset={asset} />
            }
            if (isNewsAsset(asset)) {
              return <NoticeBlock key={asset.id} asset={asset} />
            }
            if (isButtonAsset(asset)) {
              return <ButtonAssetBlock key={asset.id} asset={asset} />
            }
            if (isImageAsset(asset)) {
              return <ImageAssetBlock key={asset.id} asset={asset} />
            }
            if (isRequestAsset(asset)) {
              return <RequestAssetBlock key={asset.id} asset={asset} />
            }
            if (isCouponAsset(asset)) {
              return <CouponAssetBlock key={asset.id} asset={asset} />
            }
            if (isSpaceAsset(asset)) {
              return <SpaceAssetBlock key={asset.id} asset={asset} />
            }
            return undefined
          })}
        </div>
      </>
    </Layout>
  )
}
