import React, { useMemo } from 'react'
import { Layout } from '@/components/template/layout'
import { CategoryMenu } from '@/components/organisms/aroundSpot/category-menu'
import { useAppSelector } from '@/app/hooks'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/react'
import { Loading } from '@/components/organisms/loading'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { TitleWithSubCategory } from '@/components/organisms/aroundSpot/title-with-sub-category'
import { ReactComponent as AddressIcon } from '@/static/images/icon/icon_address.svg'
import { MapAssetType, MapBusinessHourType, MapDetailType } from '@/features/asset/assets-slice'
import { isEmpty } from 'lodash'
import { usePickByLanguage } from '@/hooks/use-pick-by-language'
import { CUSTOM_BLOCK, CUSTOM_TEXT_COLOR_LINK } from '@/components/template/custom-design-style'
import { businessDay, businessTime } from '@/components/organisms/aroundSpot/business-time'
import { ContentWrapper } from '@/components/template/content-wrapper'
import { lineClampStyle } from '@/styles/common'

export const AroundSpotList: React.FC = () => {
  const [searchParams] = useSearchParams()

  const pickByLanguage = usePickByLanguage()

  const { assetId } = useParams<{ assetId: string }>()

  const { t } = useTranslation()

  const { assets, loading } = useAppSelector(state => state.assets)
  const design = useAppSelector(state => state.design)

  const mapAsset = assets.find(asset => asset.id === assetId)
  const aroundSpotsByCategory = useMemo(() => {
    const aroundSpots: MapDetailType[] = mapAsset?.details || []

    const paramsCategoryId = searchParams.get('category')

    if (!paramsCategoryId || paramsCategoryId === 'all') {
      return aroundSpots
    }

    return aroundSpots.filter(aroundSpot => {
      return aroundSpot.category.id === paramsCategoryId
    })
  }, [assets, searchParams, assetId])

  const getTodayOpeningHours = (mapBusinessHours: MapBusinessHourType[]) => {
    if (isEmpty(mapBusinessHours)) {
      return '-'
    }

    const todayOpenBusinessTimes = mapBusinessHours.filter(openingHour => businessDay(openingHour.weekday))
    if (isEmpty(todayOpenBusinessTimes)) {
      return <span>{t('regular holiday')}</span>
    }

    const isBusinessTime = businessTime(todayOpenBusinessTimes)
    const businessStyle = isBusinessTime
      ? { color: '#399A39', text: 'open for business' }
      : { color: '#E36666', text: 'after business hours' }

    return (
      <>
        <span style={{ color: businessStyle.color }}>{t(businessStyle.text)}</span>
        <span className="time">{todayOpenBusinessTimes.map(({ startTime, endTime }) => `${startTime}~${endTime}`).join(', ')}</span>
      </>
    )
  }

  return (
    <Layout>
      <CategoryMenu asset={mapAsset as MapAssetType} />
      <Loading loading={loading}>
        <ContentWrapper>
          {aroundSpotsByCategory.map(aroundSpot => (
            <div key={aroundSpot.id} className={`${CUSTOM_BLOCK} ${CUSTOM_TEXT_COLOR_LINK}`} css={cardStyle}>
              <Link to={`/around-spot/${mapAsset?.id}/${aroundSpot.id}`}>
                <div css={infoBlockStyle}>
                  <div>
                    <TitleWithSubCategory
                      facilityName={pickByLanguage(aroundSpot.title)}
                      subCategory={pickByLanguage(aroundSpot.subCategory)}
                      isFavorite={aroundSpot.isFavorite}
                      ellipsis
                    />
                    <p css={openingHourStyle}>{getTodayOpeningHours(aroundSpot.businessHours)}</p>

                    {aroundSpot.route?.ja && (
                      <p css={routeStyle}>
                        <AddressIcon width="14" className="custom-design-icon" />
                        <span>{pickByLanguage(aroundSpot.route)}</span>
                      </p>
                    )}
                  </div>
                  <div
                    className="thumbnail"
                    style={{
                      background: aroundSpot.imagePath
                        ? `url(${aroundSpot.imagePath}) center/cover no-repeat`
                        : design.background.backgroundColor,
                    }}
                  />
                </div>

                {pickByLanguage(aroundSpot.comment) && (
                  <p className="custom-design-background" css={commentStyle}>
                    <p css={lineClampStyle(2)}>{pickByLanguage(aroundSpot.comment)}</p>
                  </p>
                )}
              </Link>
            </div>
          ))}
        </ContentWrapper>
      </Loading>
    </Layout>
  )
}

const cardStyle = css({
  marginTop: '0.25rem',
  padding: '20px 16px',
  '&:first-of-type': {
    paddingTop: '2rem',
    marginTop: 0,
  },
})

const openingHourStyle = css({
  marginTop: '1rem',
  fontSize: '12px',
  display: 'flex',
  alignItems: 'center',
  '.time': {
    flex: 1,
    paddingLeft: '14px',
  },
})

const infoBlockStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 8,
  '.thumbnail': {
    width: '100px',
    height: '100px',
    flex: '0 0 100px',
    borderRadius: '5px',
  },
  '> div:first-of-type': {
    flexGrow: 1,
  },
})

const routeStyle = css({
  display: 'flex',
  alignItems: 'center',
  fontSize: '12px',
  marginTop: '0.25rem',
  gap: '0.25rem',
})

const commentStyle = css({
  marginTop: '1rem',
  padding: '0.75rem',
  borderRadius: 4,
  '> p': {
    lineHeight: '1.83',
    fontSize: '12px',
    whiteSpace: 'pre-wrap',
  },
})
