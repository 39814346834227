import React from 'react'

import { Footer } from '../molecules/footer'
import { CUSTOM_BG, CustomDesignStyle } from './custom-design-style'

interface StaticLayoutProps {
  children: React.ReactNode
}

export const StaticLayout: React.FC<StaticLayoutProps> = ({ children }) => {
  return (
    <>
      <CustomDesignStyle />
      <div className={CUSTOM_BG}>
        {children}
        <Footer />
      </div>
    </>
  )
}
