import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { CardDetail, addCreditCard, cardSchema } from '@/features/user/user-card-slice'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { z } from 'zod'
import { PaymentBlock } from '../payment-block'
import { RequestItem } from '@/features/request/request-cart-slice'
import { useNavigate, useParams } from 'react-router-dom'
import { isEmpty, sum } from 'lodash'
import { makeRequestOrder } from '@/features/request/request-order-slice'
import { useQuerySearchParams } from '@/hooks/use-query-search-params'
import dayjs from 'dayjs'
import { paymentMethodDetail } from '@/utils/payment'
import { makeSales } from '@/features/request/request-sales-slice'
import { backupReceiptPDF } from '@/features/reservation/reservation-slice'
import { errorHandler } from '@/libs/errors'
import { sendmailMobileRequestInfoForAdmin, sendmailMobileRequestInfoForUser } from '@/features/request/request-send-email-slice'
import { useTranslation } from 'react-i18next'
import { formatCardExpire } from '@/utils/creditCard/card-expire'
import { creditCardPayForMobileRequest } from '@/features/request/request-payment-slice'
import { Button } from '@/components/atoms/button'
import { formButtonStyle } from '@/styles/form'

interface RequestPaymentFormProps {
  selectedRoom: string
}

export const RequestPaymentForm: React.FC<RequestPaymentFormProps> = ({ selectedRoom }) => {
  const [loading, setLoading] = useState(false)

  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const { hotelId } = useQuerySearchParams<{ hotelId: string }>()
  const { assetId } = useParams<{ assetId: string }>()

  const userCreditCard = useAppSelector(state => state.creditCard.userCreditCardData)
  const paymentSetting = useAppSelector(state => state.paymentSetting.settings)
  const { ordersInfo } = useAppSelector(state => state.request.requestOrder)
  const cartRooms = useAppSelector(state => state.request.guestRoomByTelephone.cartRooms)
  const hotelGuideData = useAppSelector(state => state.hotelGuide)
  const userData = useAppSelector(state => state.accountInfo.user)
  const requestCart = useAppSelector(state => state.request.requestCart.cart)
  const requestCarts: RequestItem[] = assetId ? requestCart[assetId] : []

  // check has payment credit option
  const mobilePaymentOption = paymentSetting?.payment_time?.find(v => v.is_enabled && v.value === 'MOBILE_REQUEST')

  const schema = z.discriminatedUnion('paymentType', [
    z.object({
      paymentType: z.literal('creditCard'),
      cardNumber: userCreditCard.cardNumber ? z.string().optional() : cardSchema.shape.cardNumber,
      cardExpireYear: userCreditCard.cardNumber ? z.string().optional() : cardSchema.shape.cardExpireYear,
      cardExpireMonth: userCreditCard.cardNumber ? z.string().optional() : cardSchema.shape.cardExpireMonth,
      securityCode: userCreditCard.cardNumber ? z.string().optional() : cardSchema.shape.securityCode,
      name: userCreditCard.cardNumber ? z.string().optional() : cardSchema.shape.name,
      receiptName: cardSchema.shape.receiptName,
    }),
    z.object({
      paymentType: z.literal('checkout'),
    }),
  ])

  const createSchema = () => {
    if (!mobilePaymentOption) {
      return undefined
    }

    return zodResolver(schema)
  }

  // For keep payment type when come back from login
  const selectedPaymentType = useAppSelector(state => state.userPayment.currentPayment.paymentType)

  const useFormReturn = useForm<CardDetail>({
    mode: 'all',
    defaultValues: {
      paymentType: selectedPaymentType,
    },
    resolver: createSchema(),
  })
  const {
    handleSubmit,
    formState: { isValid },
  } = useFormReturn

  const checkInUser = () => {
    return cartRooms.find(room => room.roomNumber.includes(selectedRoom))
  }

  const totalAmount = useMemo(() => {
    const requestCartPrices = requestCarts.map(requestCart => requestCart.detail.price * requestCart.count)
    return sum(requestCartPrices)
  }, [requestCarts])

  const runMakeOrder = async (paymentResult: any): Promise<{ orderId: string }> => {
    const order = {
      checkinId: checkInUser()?.checkinId,
      cuicinUserId: userData?.id,
      mobileRequestOrderId: paymentResult?.mobileRequestOrderId || null,
      roomNumber: selectedRoom || ordersInfo.roomNumber,
      paymentMethod: paymentMethodDetail[selectedPaymentType]?.code || paymentMethodDetail['checkout'].code,
      products: requestCarts.map(requestCart => ({
        id: requestCart.detail.id,
        numberOfItems: Number(requestCart.count),
      })),
      firstName: ordersInfo?.firstName,
      lastName: ordersInfo?.lastName,
    }

    return await makeRequestOrder({ hotelId, order })
  }

  const runMakeSales = async (paymentResult: any) => {
    const sales = Object.values(requestCarts).map(item => ({
      checkinId: checkInUser()?.checkinId,
      quantity: item.count,
      salesDate: dayjs().format('YYYY-MM-DD'),
      salesPaymentPrice: selectedPaymentType && paymentMethodDetail[selectedPaymentType].code === 1 ? item.detail.price * item.count : 0,
      salesSubjectId: item.detail.salesSubjectMasterId,
      salesSubjectPrice: item.detail.price,
      orderId: paymentResult?.orderId,
      isDiscount: 0,
      paymentMethod: selectedPaymentType,
    }))

    return await makeSales({ hotelId, sales })
  }
  const runMakeReceipt = async (props: {
    mobileRequestOrderId: string
    salesResult: any
    receiptName: string
    paymentResult: any
  }): Promise<string | undefined> => {
    const afterPaymentData = {
      checkinId: checkInUser()?.checkinId,
      reserIds: props.salesResult?.reservationIds,
      saleIds: props.salesResult?.saleIds,
      orderId: props.paymentResult?.orderId ?? null,
      mobileRequestOrderId: props.mobileRequestOrderId,
      guestName: props.receiptName || checkInUser()?.name || `${ordersInfo.lastName}${ordersInfo.firstName}` || '',
      paymentPage: 'request',
      provision: '',
      cuicinUserId: userData?.id,
    }

    try {
      await dispatch(backupReceiptPDF({ hotelId, checkinIdsInfo: [afterPaymentData] })).unwrap()
      if (props.paymentResult?.orderId) {
        return `${process.env.PUBLIC_URL}/mypage/usage-detail/${props.paymentResult.orderId}?hotelId=${hotelGuideData.hotelId}&lang=${i18n.language}`
      }
    } catch (error) {
      errorHandler({ error })
    }
  }
  const runSendMail = async (props: { salesResult: any; receiptUrl: string | undefined; paymentResult: any }) => {
    try {
      if (!isEmpty(userData)) {
        const sendmailRequestBodyForUser = {
          orderDate: dayjs(),
          roomNumber: selectedRoom || ordersInfo.roomNumber,
          totalPrice: totalAmount.toLocaleString(),
          orderPaymentMethod: paymentMethodDetail[selectedPaymentType]?.name || paymentMethodDetail['checkout'].name,
          orderPaymentMethodEn: paymentMethodDetail[selectedPaymentType]?.nameEn || paymentMethodDetail['checkout'].nameEn,
          receptionNumber: props.paymentResult ? props.paymentResult.orderId : null,
          receptionDate: dayjs(),
          paymentMethod: props.paymentResult ? props.paymentResult.cardBrand : null,
          usageDetailUrl: props.receiptUrl || '',
          hotelName: hotelGuideData.facilityBasicInfo.hotelName,
          hotelPhoneNumber: hotelGuideData.facilityBasicInfo.telephone,
          products: requestCarts.map(requestCart => ({
            product_name: requestCart.detail.name.ja,
            product_name_en: requestCart.detail.name.en,
            number_of_items: requestCart.count,
          })),
          email: userData.email,
          guestName: checkInUser()?.name || `${ordersInfo.lastName}${ordersInfo.firstName}` || '',
        }
        await sendmailMobileRequestInfoForUser(sendmailRequestBodyForUser)
      }
      const sendmailRequestBodyForAdmin = {
        hotelId: hotelGuideData.hotelId,
        hotelName: hotelGuideData.facilityBasicInfo.hotelName,
        orderDate: dayjs(),
        receptionDate: dayjs(),
        guestName: checkInUser()?.name || `${ordersInfo.lastName}${ordersInfo.firstName}` || '',
        roomNumber: selectedRoom || ordersInfo.roomNumber,
        products: requestCarts.map(requestCart => ({
          product_name: requestCart.detail.name.ja,
          product_name_en: requestCart.detail.name.en,
          number_of_items: requestCart.count,
        })),
        totalPrice: totalAmount.toLocaleString(),
        orderPaymentMethod: paymentMethodDetail[selectedPaymentType]?.name || paymentMethodDetail['checkout'].name,
        orderPaymentMethodEn: paymentMethodDetail[selectedPaymentType]?.nameEn || paymentMethodDetail['checkout'].nameEn,
        receptionNumber: props.paymentResult ? props.paymentResult.orderId : null,
        paymentMethod: props.paymentResult ? props.paymentResult.cardBrand : null,
        hotelPhoneNumber: hotelGuideData.facilityBasicInfo.telephone,
        guestAppAssetId: assetId,
      }
      await sendmailMobileRequestInfoForAdmin(sendmailRequestBodyForAdmin)
    } catch (error) {
      errorHandler({ error })
    }
  }

  const onsubmit: SubmitHandler<CardDetail> = async submitData => {
    try {
      setLoading(true)
      let paymentResult

      if (selectedPaymentType === 'creditCard') {
        const paymentInfo = {
          checkinId: checkInUser()?.checkinId,
          cuicinUserId: userData?.id,
          paymentAmount: totalAmount.toString(),
          products: requestCarts.map(requestCart => ({
            id: requestCart.detail.id,
            numberOfItems: Number(requestCart.count),
          })),
        } as any

        let cardId = userCreditCard?.cardId
        if (!cardId) {
          const processResult = await dispatch(
            addCreditCard({
              cuicinUserId: userData.id,
              cardDetail: {
                cardNumber: submitData.cardNumber.replace(/ /g, ''),
                cardExpire: formatCardExpire({ cardExpireMonth: submitData.cardExpireMonth, cardExpireYear: submitData.cardExpireYear }),
                securityCode: submitData.securityCode,
              },
            }),
          ).unwrap()
          cardId = processResult.cardId
        }
        paymentResult = await creditCardPayForMobileRequest({
          ...paymentInfo,
          hotelId,
          cardId,
        })
      }

      const orderResult = await runMakeOrder(paymentResult)
      const mobileRequestOrderId = paymentResult?.mobileRequestOrderId || orderResult.orderId
      const salesResult = await runMakeSales(paymentResult)
      const receiptUrl = await runMakeReceipt({
        mobileRequestOrderId,
        salesResult,
        receiptName: submitData.receiptName || '',
        paymentResult,
      })
      await runSendMail({ salesResult, receiptUrl, paymentResult })

      navigate(`/request/thanks?assetId=${assetId}`)
    } catch (error) {
      errorHandler({ error })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <PaymentBlock useFormReturn={useFormReturn} paymentPlace="MOBILE_REQUEST" />
      <form onSubmit={handleSubmit(onsubmit)}>
        <div css={[formButtonStyle, { marginTop: '2rem' }]}>
          <Button
            disabled={(hotelGuideData.hasReservationPlugins && !selectedRoom) || !isValid}
            text={t('Settlement')}
            type="submit"
            loading={loading}
          />
        </div>
      </form>
    </>
  )
}
