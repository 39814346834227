import React, { ComponentPropsWithRef, forwardRef } from 'react'
import { RequiredMark } from './required-mark'
import { css } from '@emotion/react'
import { labelStyle } from '@/styles/form'
import { ErrorMessage } from './error-message'
import { CUSTOM_FORM } from '../template/custom-design-style'

interface TextAreaProps {
  label: string
  name: string
  value?: string
  contents?: React.ReactNode
  placeholder?: string
  required?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void
  rows?: number
  error?: string
}

type TextAreaType = ComponentPropsWithRef<'textarea'> & TextAreaProps

export const TextArea: React.FC<TextAreaType> = forwardRef(
  ({ error, value, label, name, contents, onChange, onBlur, required, placeholder, rows = 2 }, ref) => {
    return (
      <>
        {label && (
          <label css={labelStyle}>
            {label}
            {required && <RequiredMark />}
          </label>
        )}
        {contents && <div style={{ marginBottom: '1.5rem' }}>{contents}</div>}
        <textarea
          className={CUSTOM_FORM}
          ref={ref}
          value={value}
          rows={rows}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          css={textareaStyle}
        />
        {error && <ErrorMessage text={error} />}
      </>
    )
  },
)
TextArea.displayName = 'TextArea'

const textareaStyle = css({
  width: '100%',
  appearance: 'none',
  padding: '1rem',
  outline: 'none',
  border: '1px solid #CCCCCC',
  display: 'block',
})
