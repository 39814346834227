import { css } from "@emotion/react"
import React from "react"

interface BoxTitleProps {
  title: string
}

export const BoxTitle: React.FC<BoxTitleProps> = ({ title }) => {
  return <h3 css={boxTitleStyle}>{title}</h3>
}

const boxTitleStyle = css({
  fontWeight: 'bold',
  textAlign: 'center',
  lineHeight: '1.8',
  whiteSpace: 'pre-wrap',
})

