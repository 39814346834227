import { LeftIcon, RightIcon } from '@/components/atoms/icon'
import { ModalWrapper } from '@/components/atoms/modal-wrapper'
import { QrCode } from '@/components/atoms/qr-code'
import { CUSTOM_BG, CUSTOM_ICON } from '@/components/template/custom-design-style'
import { TicketType } from '@/features/user/check-in-history-slice'
import { css } from '@emotion/react'
import React, { useEffect, useState } from 'react'

interface Props {
  isOpen: boolean
  tickets: TicketType[]
  onClose: () => void
  shouldCloseOnOverlayClick?: boolean
}

export const TicketQrModal: React.FC<Props> = ({ isOpen, onClose, shouldCloseOnOverlayClick, tickets }) => {
  const [resetPasswordDisabled, setResetPasswordDisabled] = useState(true)
  const [currentPage, setCurrentPage] = useState<number>(1)

  const changeQr = (nextPage: number) => {
    if (nextPage < 1) {
      setCurrentPage(1)
      return
    }
    if (tickets.length < nextPage) {
      setCurrentPage(tickets.length)
      return
    }
    setCurrentPage(nextPage)
  }

  useEffect(() => {
    if (resetPasswordDisabled) {
      const timer = setTimeout(() => {
        setResetPasswordDisabled(false)
      }, 6000)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [resetPasswordDisabled])

  return (
    <ModalWrapper
      modalStyle={{ maxHeight: '400px', maxWidth: '300px' }}
      isOpen={isOpen}
      onClose={onClose}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      <h3 css={titleStyle}>{tickets[0].ticket_name}</h3>

      <div className={CUSTOM_BG} style={{ borderRadius: 8 }}>
        <div css={ticketQrStyle}>
          <QrCode qrCode={tickets[currentPage - 1].exchange_code} />
        </div>
      </div>

      <div css={footerStyle}>
        <LeftIcon onClick={() => changeQr(currentPage - 1)} className={`${CUSTOM_ICON} ${currentPage === 1 ? 'disabled' : ''}`} />
        <span>
          {currentPage}/{tickets.length}
        </span>
        <RightIcon
          onClick={() => changeQr(currentPage + 1)}
          className={`${CUSTOM_ICON} ${currentPage === tickets.length ? 'disabled' : ''}`}
        />
      </div>
    </ModalWrapper>
  )
}

const titleStyle = css({
  paddingBottom: 16,
})

const ticketQrStyle = css({
  padding: 24,
})

const footerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  height: 40,
  alignItems: 'center',
  marginTop: 16,
  span: {
    fontWeight: 'bold',
    fontSize: 14,
    letterSpacing: 2.4,
  },
  svg: {
    cursor: 'pointer',
    '&.disabled': {
      opacity: '0.4',
      cursor: 'auto',
    },
  },
})
