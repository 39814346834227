import React from "react"
import { useTranslation } from "react-i18next"

interface ReservationGuestRoomLabelProps {
  roomNumbers: string[] 
}

export const ReservationGuestRoomLabel: React.FC<ReservationGuestRoomLabelProps> = ({ roomNumbers }) => {
  const { t } = useTranslation()

  let start = t('Guest Room')

  if (roomNumbers.length > 1) start = t('Guest Rooms')

  return start + ' ' + (roomNumbers ? roomNumbers.join('/ ') : '')
}