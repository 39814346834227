import { useAppSelector } from '@/app/hooks'
import { Divider } from '@/components/atoms/divider'
import { SelectBox } from '@/components/atoms/select-box'
import { RequestItem } from '@/features/request/request-cart-slice'
import { usePickByLanguage } from '@/hooks/use-pick-by-language'
import { displayYen } from '@/libs/text'
import { css } from '@emotion/react'
import React, { useMemo } from 'react'

interface RequestInputItemProps {
  requestCart: RequestItem
  displayDivider: boolean
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
  onItemRemove: () => void
}

export const RequestInputItem: React.FC<RequestInputItemProps> = ({ requestCart, displayDivider, onChange, onItemRemove }) => {
  const pickByLanguage = usePickByLanguage()

  const design = useAppSelector(state => state.design)

  const closeButtonStyle = useMemo(
    () =>
      css({
        width: '22px',
        height: '22px',
        marginTop: 8,
        position: 'relative',
        border: 'none',
        background: 'none',
        '&:before, &:after': {
          content: '""',
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: '2px',
          height: '12px',
          background: design?.icon.color,
        },
        '&:before': {
          transform: 'translate(-50%,-50%) rotate(45deg)',
        },
        '&:after': {
          transform: 'translate(-50%,-50%) rotate(-45deg)',
        },
      }),
    [design],
  )

  return (
    <li>
      <div css={requestInputItemStyle}>
        <div>
          <span className="name">{pickByLanguage(requestCart.detail.name)}</span>
          <span className="price">{displayYen(requestCart.detail.price.toString())}</span>
        </div>
        <div className="input">
          <SelectBox
            name=''
            defaultValue={requestCart.count.toString()}
            onChange={onChange}
            options={[...new Array(requestCart.detail.stock || 10)].map((_, index) => ({
              value: `${index + 1}`,
              label: `${index + 1}`,
            }))}
          />
          <button type="button" onClick={() => onItemRemove()} css={closeButtonStyle}></button>
        </div>
      </div>
      {displayDivider && <Divider />}
    </li>
  )
}

const requestInputItemStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '1rem 1.5rem',
  fontSize: 14,
  span: {
    display: 'block',
    fontWeight: 'bold',
  },
  '.name': {
    lineHeight: '1.5',
    marginBottom: '0.25rem',
  },
  '.input': {
    display: 'flex',
    alignItems: 'center',
    '>div': {
      width: 72,
      marginRight: '0.5rem',
    },
  },
})
