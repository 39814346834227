import { CouponAssetDetailType } from '@/features/asset/assets-slice'
import React from 'react'
import { css } from '@emotion/react'
import { usePickByLanguage } from '@/hooks/use-pick-by-language'
import { CUSTOM_BG, CUSTOM_BLOCK, CUSTOM_LINK } from '@/components/template/custom-design-style'
import { useAppSelector } from '@/app/hooks'
import { Link } from 'react-router-dom'

type Props = {
  assetId: string
  coupon: CouponAssetDetailType
}

export const CouponTicket: React.FC<Props> = ({ assetId, coupon }) => {
  const pickByLanguage = usePickByLanguage()
  const design = useAppSelector(state => state.design)

  return (
    <div css={[couponTicketStyle, css({ color: design?.icon.color, backgroundColor: design?.icon.color })]} className={CUSTOM_BG}>
      <Link className={`inside ${CUSTOM_BLOCK}`} to={`/contents/${assetId}/${coupon.id}`}>
        {coupon.imagePath ? <img src={coupon.imagePath} className="thumbnail" /> : <div className="thumbnail" />}
        <div className="summary">
          <div className={`name ${CUSTOM_LINK}`}>{pickByLanguage(coupon.title)}</div>
          <div className={`facility ${CUSTOM_LINK}`}>{pickByLanguage(coupon.facilityName)}</div>
        </div>
      </Link>
    </div>
  )
}

const couponTicketStyle = css({
  display: 'flex',
  height: 78,
  marginTop: 8,
  borderStyle: 'solid',
  borderRadius: 6,
  borderWidth: 1,
  overflow: 'hidden',
  clipPath: 'polygon(8px 50%, 0px 35%, 0 0, 100% 0, 100% 35%, calc(100% - 8px) 50%, 100% 65%, 100% 100%, 0 100%, 0 65%)',
  position: 'relative',
  '.inside': {
    display: 'flex',
    position: 'absolute',
    borderRadius: 6,
    width: '100%',
    height: '100%',
    clipPath: 'polygon(8px 50%, 0px 35%, 0 0, 100% 0, 100% 35%, calc(100% - 8px) 50%, 100% 65%, 100% 100%, 0 100%, 0 65%)',
  },
  ':first-of-type': { marginTop: 0 },
  '.thumbnail': {
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
    height: 76,
  },
  '.summary': {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    padding: '8px 16px',
    overflow: 'hidden',
    '.name': {
      lineHeight:1.4,
      fontSize: 16,
      letterSpacing: 0.8,
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
    },
    '.facility': {
      lineHeight:1.5,
      fontSize: 12,
      letterSpacing: 0.6,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
})
