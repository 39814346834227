import React, { useMemo } from 'react'
import { LoginBlock } from '@/components/organisms/login-block'
import { LoginTemplate } from '@/components/template/login-template'
import { useTranslation } from 'react-i18next'
import { useQuerySearchParams } from '@/hooks/use-query-search-params'
import { css } from '@emotion/react'
import { Link } from 'react-router-dom'

export const LoginType = {
  membership: 'membership',
  checkIn: 'check-in',
} as const
export type LoginType = (typeof LoginType)[keyof typeof LoginType]

export const Login: React.FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const { loginType } = useQuerySearchParams<{ loginType?: LoginType }>()

  const navigateTitle = useMemo(() => {
    switch (loginType) {
      case LoginType.membership:
        return t('Member Registration')
      case LoginType.checkIn:
        return ''
      default:
        return t('Sign in')
    }
  }, [loginType])

  const headText = useMemo(() => {
    switch (loginType) {
      case LoginType.membership:
        return t('Membership registration is required to make a reservation')
      case LoginType.checkIn:
        return t('First time use, log in here')
      default:
        return t('Need your aipassID to sign in')
    }
  }, [loginType])

  return (
    <>
      <LoginTemplate navigateTitle={navigateTitle} headText={headText} loginType={loginType}>
        <LoginBlock loginType={loginType} />
        {language === 'ja' && (
          <div css={agreeText}>
            <Link to="/about-terms-of-use" target="_blank">
              <span>{t('Terms of use')}</span>
            </Link>
            及び
            <Link to="/about-privacy-policy" target="_blank">
              <span>{t('Privacy policy')}</span>
            </Link>
            に
            <br />
            同意の上、ご利用ください
          </div>
        )}

        {language === 'en' && (
          <div css={agreeText}>
            I agree with
            <br />
            the
            <Link to="/about-terms-of-use" target="_blank">
              <span>{t('Terms of use')}</span>
            </Link>
            and
            <Link to="/about-privacy-policy" target="_blank">
              <span>{t('Privacy policy')}</span>
            </Link>
          </div>
        )}

        {language === 'zh' && (
          <div css={agreeText}>
            请在同意
            <Link to="/about-terms-of-use" target="_blank">
              <span>{t('Terms of use')}</span>
            </Link>
            和隐
            <Link to="/about-privacy-policy" target="_blank">
              <span>{t('Privacy policy')}</span>
            </Link>
            后使用
          </div>
        )}

        {language === 'ko' && (
          <div css={agreeText}>
            이용 약관
            <Link to="/about-terms-of-use" target="_blank">
              <span>{t('Terms of use')}</span>
            </Link>
            및
            <Link to="/about-privacy-policy" target="_blank">
              <span>{t('Privacy policy')}</span>
            </Link>
            에 동의 한 후,
            <br />
            이용하십시오
          </div>
        )}
      </LoginTemplate>
    </>
  )
}

const agreeText = css({
  fontSize: '12px',
  paddingTop: '2rem',
  textAlign: 'center',
  lineHeight: '1.8',
})
