import React, { useMemo } from 'react'
import { EmailLoginBlock } from '@/components/organisms/email-login-block'
import { EmailLoginBlock as CheckInEmailLoginBlock } from '@/components/organisms/checkIn/email-login-block'
import { LoginTemplate } from '@/components/template/login-template'
import { useTranslation } from 'react-i18next'
import { useQuerySearchParams } from '@/hooks/use-query-search-params'
import { LoginType } from '.'

export const EmailLogin: React.FC = () => {
  const { t } = useTranslation()

  const { loginType } = useQuerySearchParams<{ loginType?: LoginType }>()

  const headText = useMemo(() => {
    switch (loginType) {
      case LoginType.membership:
        return t('Membership registration is required to make a reservation')
      case LoginType.checkIn:
        return t('The first time you use aiPass, please set a password of your choice.')
      default:
        return t('Need your aipassID to sign in')
    }
  }, [loginType])

  return (
    <>
      <LoginTemplate navigateTitle={t('Password')} headText={headText} loginType={loginType}>
        {LoginType.checkIn ? <CheckInEmailLoginBlock /> : <EmailLoginBlock />}
      </LoginTemplate>
    </>
  )
}
