import { CUSTOM_BLOCK, CUSTOM_BORDER } from '@/components/template/custom-design-style'
import { displayYen } from '@/libs/text'
import { css } from '@emotion/react'
import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Sale {
  salesDate: string
  salesSubjectName: string
  salesPrice: number | string
}
interface DetailsTableBlockProps {
  sales: Sale[]
  totalPrice: number
  totalDiscount: number
}

export const DetailsTableBlock: React.FC<DetailsTableBlockProps> = ({ sales, totalPrice, totalDiscount }) => {
  const { t } = useTranslation()

  return (
    <>
      <table css={tableStyle}>
        <thead>
          <tr css={theadStyle} className={CUSTOM_BORDER}>
            <th style={{ width: '25%' }}>{t('Date')}</th>
            <th style={{ width: '30%' }}>{t('Reference')}</th>
            <th style={{ width: '50%' }}>{t('Amount used')}</th>
          </tr>
        </thead>
        <tbody css={tbodyStyle} className={CUSTOM_BLOCK}>
          {sales.map((sale, index) => {
            return (
              <tr key={index} className={CUSTOM_BORDER}>
                <td style={{ width: '20%' }}>{dayjs(sale.salesDate).format('MM/DD')}</td>
                <td style={{ width: '30%' }}>{sale.salesSubjectName}</td>
                <td style={{ width: '50%' }}>{displayYen(String(sale.salesPrice))}</td>
              </tr>
            )
          })}
        </tbody>
      </table>

      <div css={blockStyle} className={CUSTOM_BLOCK}>
        <div css={totalAmountStyle}>
          <div>{t('Amount used')}</div>
          <div>{displayYen(String(totalPrice))}</div>
        </div>

        <div css={[totalAmountStyle, { marginTop: '1rem' }]}>
          <div>{t('Credit')}</div>
          <div>
            {displayYen(String(totalDiscount))}
            <span css={[breakdownStyle]}>{`(${t('discount')}：${displayYen(String(totalDiscount))})`}</span>
          </div>
        </div>
      </div>
    </>
  )
}

const tableStyle = css({
  width: '100%',
  padding: '2rem',
})

const theadStyle = css({
  width: '100%',
  fontSize: '10px',
  th: {
    padding: '0.5rem 0',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    '&:first-of-type': {
      paddingLeft: '2rem',
    },
    '&:last-child': {
      paddingRight: '2rem',
      textAlign: 'right',
    },
  },
})

const tbodyStyle = css({
  width: '100%',
  tr: {
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
  },
  td: {
    padding: '0.5rem 0',
    fontSize: '12px',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    '&:first-of-type': {
      paddingLeft: '2rem',
    },
    '&:last-child': {
      paddingRight: '2rem',
      textAlign: 'right',
    },
  },
})

const breakdownStyle = css({
  display: 'block',
  marginTop: '0.25rem',
  fontWeight: 'normal',
  fontSize: '10px',
})

const blockStyle = css({
  padding: '1.5rem 2rem 1rem'
})

const totalAmountStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  fontWeight: 'bold',
  div: {
    '&:first-of-type': {
      fontSize: '12px',
    },
    '&:last-child': {
      textAlign: 'right',
      fontSize: '14px',
    },
  },
})
