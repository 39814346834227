import React, { useMemo } from 'react'
import { css } from '@emotion/react'
import { CUSTOM_BG, CUSTOM_BLOCK, CUSTOM_BORDER, CUSTOM_BRAND_BG, CUSTOM_BUTTON } from '@/components/template/custom-design-style'
import { SpaceDetail } from '@/features/space/spaces-slice'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '@/app/hooks'
import dayjs from 'dayjs'
import { v4 as uuid } from 'uuid'

type Props = {
  space: SpaceDetail
  watchValues: {
    quantity: number
    useDay: string
    usageFromHour: string
    usageFromMinute: string
    usageToHour: string
    usageToMinute: string
  }
}

export const ReserveTimeBar: React.FC<Props> = ({ space, watchValues }) => {
  const { t } = useTranslation()
  const design = useAppSelector(state => state.design)
  const labelLen = 6

  const timeToMinutes = (time: string) => Number(time.split(':')[0]) * 60 + Number(time.split(':')[1])

  const usageFromMinute = useMemo(() => {
    if (!watchValues.usageFromHour || !watchValues.usageFromMinute) {
      return 0
    }
    return Number(watchValues.usageFromHour) * 60 + Number(watchValues.usageFromMinute)
  }, [watchValues.usageFromHour, watchValues.usageFromMinute])

  const usageToMinute = useMemo(() => {
    if (!watchValues.usageToHour || !watchValues.usageToMinute) {
      return 0
    }
    return Number(watchValues.usageToHour === '00' ? '24' : watchValues.usageToHour) * 60 + Number(watchValues.usageToMinute)
  }, [watchValues.usageToHour, watchValues.usageToMinute])

  const selectedMinutes = useMemo(() => usageToMinute - usageFromMinute, [usageToMinute, usageFromMinute])

  const salesHourStartMinute = useMemo(() => {
    if (!space.salesHour) {
      return 0
    }

    const startMinutes = timeToMinutes(space.salesHour.start)
    if (dayjs(watchValues.useDay).isSame(dayjs(), 'day')) {
      return Math.max(dayjs().diff(dayjs().startOf('day'), 'minutes'), startMinutes)
    }
    return startMinutes
  }, [space.salesHour.start, watchValues.useDay])

  const salesHourEndMinute = useMemo(() => (space.salesHour ? timeToMinutes(space.salesHour.end) : 0), [space.salesHour.end])

  const balloonLabel = useMemo(() => {
    const hour = Math.floor(selectedMinutes / 60)
    const min = selectedMinutes % 60
    const hourText = hour ? `${hour}${t('hour')}` : ''
    const minText = min ? `${min}${t('min')}` : ''
    return `${hourText}${minText}${t('Selecting')}`
  }, [selectedMinutes])

  const noStockTimes = useMemo(() => {
    return Object.keys(space.availabilityTimes).filter(time => space.availabilityTimes[time] < watchValues.quantity)
  }, [space, watchValues.quantity])

  const style = useMemo(
    () =>
      css({
        paddingTop: 24,
        '.label-area': {
          display: 'flex',
          justifyContent: 'space-between',
          paddingBottom: 4,
          label: {
            fontSize: 8,
            letterSpacing: 0.8,
          },
        },
        '.time-bar-area': {
          position: 'relative',
          '.time-bar': {
            height: 18,
            position: 'absolute',
            top: 0,
            '&.out-sales-hour-from': {
              left: 0,
              width: `calc(100% / 96 * ${salesHourStartMinute} / 15)`,
              borderRadius: '10px 0px 0px 10px',
            },
            '&.out-sales-hour-to': {
              right: 0,
              width: `calc(100% / 96 * (${1440 - salesHourEndMinute}) / 15)`,
              borderRadius: '0px 10px 10px 0px',
            },
            '&.selected': {
              left: `calc(100% / 96 * ${usageFromMinute} / 15)`,
              width: `calc(100% / 96 * (${selectedMinutes} / 15))`,
            },
          },
          '.time-bar-bg, .time-bar-out-sales-hour': {
            height: 18,
            borderRadius: '10px',
          },
        },
        '.time-balloon': {
          fontSize: 12,
          letterSpacing: 1.2,
          height: 26,
          lineHeight: '26px',
          padding: '0 12px',
          borderRadius: 3,
          marginTop: 12,
          position: 'relative',
          width: 130,
          textAlign: 'center',
          left: `calc((100% / 96 * ${usageFromMinute} / 15) + (100% / 96 * (${selectedMinutes} / 15) / 2) - 130px / 2)`,

          '&:before': {
            content: '""',
            position: 'absolute',
            bottom: '100%',
            left: '50%',
            marginLeft: '-4px',
            border: '4px solid transparent',
            borderBottom: `4px solid ${design.theme.brandColor}`,
          },
        },
      }),
    [design, selectedMinutes, salesHourStartMinute, salesHourEndMinute, usageFromMinute],
  )

  return (
    <div css={style}>
      <div className="label-area">
        <label style={{ width: `calc(100% / ${labelLen})` }}>0{t('h')}</label>
        <label style={{ width: `calc(100% / ${labelLen})` }}>4{t('h')}</label>
        <label style={{ width: `calc(100% / ${labelLen})` }}>8{t('h')}</label>
        <label style={{ width: `calc(100% / ${labelLen})` }}>12{t('h')}</label>
        <label style={{ width: `calc(100% / ${labelLen})` }}>16{t('h')}</label>
        <div style={{ width: `calc(100% / ${labelLen})`, display: 'flex', justifyContent: 'space-between' }}>
          <label>20{t('h')}</label>
          <label>24{t('h')}</label>
        </div>
      </div>
      <div className="time-bar-area">
        <div className={`time-bar-bg ${CUSTOM_BLOCK} ${CUSTOM_BORDER}`}></div>
        {salesHourStartMinute > 0 && <div className={`time-bar out-sales-hour-from ${CUSTOM_BG}`}></div>}
        {salesHourEndMinute > 0 && <div className={`time-bar out-sales-hour-to ${CUSTOM_BG}`}></div>}
        {selectedMinutes > 0 && <div className={`time-bar selected ${CUSTOM_BRAND_BG}`}></div>}
        {noStockTimes.map(time => (
          <div
            key={uuid()}
            className={`time-bar ${CUSTOM_BG}`}
            style={{
              left: `calc(100% / 96 * ${timeToMinutes(time)} / 15)`,
              width: `calc(100% / 96)`,
            }}
          ></div>
        ))}
      </div>
      {selectedMinutes > 0 && <div className={`time-balloon ${CUSTOM_BUTTON}`}>{balloonLabel}</div>}
    </div>
  )
}
