import React, { PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react'
import { css } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import { PullDownIcon } from '../atoms/icon'
import { CUSTOM_LINK } from '../template/custom-design-style'

type Props = {
  defaultLine: number
}

export const ReadMoreText: React.FC<PropsWithChildren<Props>> = ({ defaultLine, children }) => {
  const { t } = useTranslation()
  const [hasReadMore, setHasReadMore] = useState<boolean>(false)
  const [isOpenText, setIsOpenText] = useState<boolean>(false)
  const textRef = useRef<HTMLDivElement>(null)

  const initAccordion = () => {
    if (!textRef || !textRef.current || !realNoteLineHeight) {
      return
    }
    setHasReadMore(textRef.current.scrollHeight > defaultLine * realNoteLineHeight)
  }

  const realNoteLineHeight = useMemo(() => {
    if (!textRef || !textRef.current) {
      return 0
    }
    return Number(getComputedStyle(textRef.current).getPropertyValue('line-height').replace('px', ''))
  }, [textRef.current, children])

  useEffect(() => {
    initAccordion()
  }, [realNoteLineHeight])

  return (
    <div css={blockStyle}>
      <div
        ref={textRef}
        css={css({
          height: hasReadMore && !isOpenText ? defaultLine * realNoteLineHeight : '100%',
          overflow: 'hidden',
        })}
      >
        {children}
      </div>

      {hasReadMore &&
        (!isOpenText ? (
          <div className={`read-more ${CUSTOM_LINK}`} onClick={() => setIsOpenText(true)}>
            {t('詳細を見る')}
            <PullDownIcon />
          </div>
        ) : (
          <div className={`read-more close ${CUSTOM_LINK}`} onClick={() => setIsOpenText(false)}>
            {t('閉じる')}
            <PullDownIcon />
          </div>
        ))}
    </div>
  )
}

const blockStyle = css({
  '.read-more': {
    paddingTop: 24,
    display: 'flex',
    alignItems: 'center',
    '&.close': {
      svg: { transform: 'rotate(180deg)' },
    },
  },
  svg: { paddingLeft: 3, width: 20, height: 20 },
})
