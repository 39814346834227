import { labelStyle } from '@/styles/form'
import { CSSObject, SerializedStyles, css } from '@emotion/react'
import React, { ComponentPropsWithRef, forwardRef } from 'react'
import { CUSTOM_FORM } from '../template/custom-design-style'

interface CheckBoxProps {
  label: React.ReactNode
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  checked: boolean
  name: string
  customLabelStyle?: SerializedStyles | CSSObject
}

type CheckBoxType = ComponentPropsWithRef<'input'> & CheckBoxProps

export const CheckBox: React.FC<CheckBoxType> = forwardRef(({ checked, label, name, customLabelStyle, onChange }, ref) => {
  return (
    <label css={[labelStyle, customLabelStyle]} htmlFor={name}>
      <input
        ref={ref}
        css={inputStyle}
        onChange={onChange}
        id={name}
        name={name}
        checked={checked}
        type="checkbox"
        className={CUSTOM_FORM}
      />
      {label}
    </label>
  )
})
CheckBox.displayName = 'CheckBox'

const inputStyle = css({
  appearance: 'none',
  outline: 'none',
  border: 'none',
  background: 'transparent',
  paddingLeft: '32px!important',
  position: 'relative',
  '&:before,&:after': {
    content: '""',
    display: 'block',
    position: 'absolute',
  },
  '&:before': {
    backgroundColor: '#fff',
    borderRadius: '3px',
    width: '20px',
    height: '20px',
    transform: 'translateY(-50%)',
    top: '50%',
    left: 0,
  },
  '&:checked:before': {
    border: 'none',
  },
  '&:after': {
    borderBottom: '3px solid #fff',
    borderLeft: '3px solid #fff',
    opacity: 0,
    height: '8px',
    width: '12px',
    transform: 'rotate(-45deg)',
    top: '-3px',
    bottom: '0px',
    margin: 'auto',
    left: '4px',
  },
  '&:checked:after': {
    opacity: 1,
  },
})
