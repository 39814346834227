import { combineReducers } from '@reduxjs/toolkit'
import { requestCartReducer } from './request-cart-slice'
import { guestRoomByTelephoneReducer } from './check-in-room-number-slice'
import { requestOrderReducer } from './request-order-slice'

export const requestReducer = combineReducers({
  requestCart: requestCartReducer,
  requestOrder: requestOrderReducer,
  guestRoomByTelephone: guestRoomByTelephoneReducer,
})
