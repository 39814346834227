import React from 'react'
import { useAppSelector } from '@/app/hooks'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { MapAssetType } from '@/features/asset/assets-slice'
import { usePickByLanguage } from '@/hooks/use-pick-by-language'
import { categoryMenuStyle } from '@/styles/category-menu'
import { pcScreenStyle } from '@/styles/common'

interface CategoryMenuProps {
  asset: MapAssetType
}

export const CategoryMenu: React.FC<CategoryMenuProps> = ({ asset }) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const paramsCategoryId = searchParams.get('category')

  const { t } = useTranslation()

  const design = useAppSelector(state => state.design)

  const pickByLanguage = usePickByLanguage()

  const onSelectCategory = async (categoryCode: string) => {
    setSearchParams({ category: `${categoryCode}` })
  }

  return (
    <ul className="custom-design-block" css={[categoryMenuStyle, pcScreenStyle, { justifyContent: 'space-between' }]}>
      {[{ id: 'all', title: '' }, ...(asset?.categories || [])].map(category => (
        <li
          key={category.id}
          {...(paramsCategoryId === category.id
            ? { css: { color: design.theme.brandColor, borderBottom: `2px solid ${design.theme.brandColor}` } }
            : {})}
          onClick={() => onSelectCategory(category.id)}
        >
          {pickByLanguage(category.title) || t('all')}
        </li>
      ))}
    </ul>
  )
}
