import { css } from '@emotion/react'
import React from 'react'

export const ZhTwContent: React.FC = () => {
  return (
    <div css={layoutStyle}>
      <div css={administratorStyle}>
        <h2 css={pageTitleStyle}>aipass 的隱私權政策</h2>

        <p css={updateStyle}>最後更新時間：2022年 4月15日</p>
      </div>

      <p css={leadTextStyle}>
        aipass Co.,
        Ltd.（以下簡稱「本公司」）關於本網站提供的服務「aipass」（以下簡稱「本服務」）中使用者個人資訊的處理我們制定瞭如下隱私權政策（以下簡稱「本政策」）
        。
      </p>

      <h3 css={semiTitleStyle}>第1條（個人資訊）</h3>
      <p css={leadTextStyle}>「個人資訊」是指有下列情形的個人的資訊：</p>
      <ol css={listStyle}>
        <li css={listItemStyle}>根據資訊中包含的姓名、地址、出生日期、性別、職業、電話號碼、電子郵件地址等能夠識別特定個人的資訊。</li>
        <li css={listItemStyle}>含有個人識別碼的項目（個人資訊保護法（以下簡稱「個人資訊保護法」）第2條第2項）。</li>
      </ol>

      <h3 css={semiTitleStyle}>第2條（個人資料蒐集方式）</h3>
      <p css={leadTextStyle}>
        當使用者註冊使用時，我們可能會要求提供個人信息，例如姓名、出生日期、地址、電話號碼、電子郵件地址、銀行帳號、信用卡號碼、駕照號碼等。
        此外，用戶與業務合作夥伴等（包括我們的業務合作夥伴（包括資訊提供者、廣告商、廣告分發目的地等））之間進行的交易記錄和付款訊息，包括用戶的個人訊息，可能會被存放。收集自等。
      </p>

      <h3 css={semiTitleStyle}>第三條（蒐集、使用個人資料的目的）</h3>
      <p css={leadTextStyle}>本公司收集和使用個人資訊的目的如下。</p>
      <ol css={listStyle}>
        <li css={listItemStyle}>對使用者進行個人認證並為使用者提供本服務</li>
        <li css={listItemStyle}>確認並回覆有關本服務的意見、詢問和審核提交（包括身分驗證）</li>
        <li css={listItemStyle}>與本服務的使用相關的通訊、電子郵件雜誌、DM、各種通知等的分發和發送</li>
        <li css={listItemStyle}>對違反使用條款或試圖將服務用於欺詐或不正當目的的使用者進行調查和詳細確認。</li>
        <li css={listItemStyle}>允許使用者查看、變更或刪除自己的註冊訊息，以及查看使用狀態。</li>
        <li css={listItemStyle}>根據使用者同意/申請向使用本服務的公司提供個人資訊</li>
        <li css={listItemStyle}>基於屬性資訊、終端資訊、位置資訊、行動歷史等的廣告和內容的分發、顯示以及本服務的提供</li>
        <li css={listItemStyle}>本服務的改進、新服務的發展、積分使用狀況的調查分析、行銷</li>
        <li css={listItemStyle}>進行活動、調查、監測、訪談等</li>
        <li css={listItemStyle}>向用戶收取付費服務的使用費</li>
        <li css={listItemStyle}>上述使用目的的附帶目的</li>
      </ol>

      <h3 css={semiTitleStyle}>第4條（使用目的的變更）</h3>
      <ol css={listStyle}>
        <li css={listItemStyle}>本公司只有在合理地認識到使用目的與變更前的目的相關的情況下才會變更個人資訊的使用目的。</li>
        <li css={listItemStyle}>若使用目的發生變更，變更後的目的將依照本公司規定的方式或在本網站上公告的方式通知使用者。</li>
      </ol>

      <h3 css={semiTitleStyle}>第5條（向第三方提供個人資訊）</h3>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          作為原則，未經用戶同意，本公司不會向第三方提供個人資訊。 我們會在明確訊息接收者和資訊內容並徵得用戶同意後提供資訊。
          提供的目的和目的是向使用本服務的企業提供材料請求、申請、預約、購買等服務。
        </li>
        <li css={listItemStyle}>
          所提供的個人資訊項目是指從使用者獲得的資訊（包括服務使用歷史記錄以及與瀏覽、搜尋、收藏等所有行為歷史相對應的資訊）中為實現使用目的所必需的個人資訊。做。
          提供的手段或方法應以書面形式或透過電磁方法發送或傳輸。
        </li>
        <li css={listItemStyle}>
          在下列情況下，在不違反相關法律法規的情況下，可以未經用戶同意而提供個人資訊。
          <ol css={listDiscStyle}>
            <li>如果用戶確定這會對第三方帶來不利</li>
            <li>為改善公共衛生或促進兒童健康成長特別需要，且難以獲得使用者同意時。</li>
            <li>
              當需要與國家組織、地方政府或其委託的人合作執行法律規定的事務時，我們可能會徵得用戶的同意，以免妨礙其執行事務。如果存在風險
            </li>
            <li>法院、檢察官辦公室、警察或具有類似權限的組織要求披露個人資訊時。</li>
            <li>當用戶明確要求向第三方揭露或提供時</li>
            <li>當法律允許披露或提供時</li>
            <li>因合併等事業繼承而提供個人信息，並在繼承前的使用目的範圍內處理時。</li>
          </ol>
        </li>
      </ol>

      <h3 css={semiTitleStyle}>第6條（關於提供給第三方的免責聲明）</h3>
      <p css={leadTextStyle}>在下列情況下，本公司對第三方取得個人資訊不承擔任何責任。</p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          用戶利用本服務的功能或其他方式向用戶公司等公開個人資訊時（有關用戶公司等處理個人資訊的事宜，請直接與各用戶公司聯絡）
        </li>
        <li css={listItemStyle}>如果輸入本服務的資訊意外地識別出相關人員。</li>
        <li css={listItemStyle}>如果使用者提供個人資訊並在與本服務連結的外部網站上使用</li>
        <li css={listItemStyle}>當使用者以外的人取得可辨識使用者身分的資訊（ID、密碼等）時</li>
      </ol>

      <h3 css={semiTitleStyle}>第7條（個人資訊處理外包）</h3>
      <p css={leadTextStyle}>本公司可能將部分或全部個人資料處理業務外包。 請注意，本公司負責分包商的個人資訊處理。</p>

      <h3 css={semiTitleStyle}>第8條（統計處理資料的使用）</h3>
      <p css={leadTextStyle}>
        本公司根據所提供的個人信息，使用經處理後無法識別個人身份的統計資料（指《個人資訊保護法》第2條第9項規定的資料以及《個人資訊保護法》第2條第10項規定的資料）。同法）。我們可以建立匿名處理的資訊資料庫等（下同）。
        對於無法識別個人身分的統計數據，將按照個人資訊保護委員會規則規定的標準處理個人資訊並採取安全管理措施。
        本公司在創建此類資料時，將公開匿名處理資訊中包含的有關個人的資訊項目，本公司可以根據個人資訊保護委員會的規定不受任何限制地使用該資料。
        <br />
        個人資訊項目包括性別、國籍、年齡、居住地、郵遞區號、住宿預訂、住宿歷史、同業人數、交易金額等。
      </p>

      <h3 css={semiTitleStyle}>第9條（個人資料的變更等）</h3>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          原則上，「個人資訊的使用目的」、註冊個人資訊的公開、更正、追加或刪除、停止使用、停止向第三方提供（以下簡稱「稱為「個人資訊變更」）等）。
          具體方法請聯絡以下個人資訊管理聯絡方式，但在以下情況下，我們可能不會回應個人資訊變更要求。
          <ol css={listDiscStyle}>
            <li>當存在損害本人或第三方的生命、身體、財產或其他權益的風險時。</li>
            <li>存在嚴重阻礙我們業務正常實施的風險的情況。</li>
            <li>違反其他法律、法規的情況。</li>
          </ol>
        </li>
        <li css={listItemStyle}>
          儘管有前款的規定，原則上不會公開歷史資訊、屬性資訊等個人資訊以外的資訊。
          此外，在變更個人資訊需要大量費用或變更個人資訊有困難的情況下，我們將提供保護使用者權益所必需的替代資訊。我們採取必要的措施，我們可能不會對個人資訊的變更等做出反應。
        </li>
      </ol>

      <h3 css={semiTitleStyle}>第10條（個人資料的更正、刪除）</h3>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          如果公司持有的個人資料不正確，使用者可以依照公司規定的程序要求公司更正、新增或刪除個人資料（以下簡稱「更正等」）。被認領。
        </li>
        <li css={listItemStyle}>如果本公司收到前款所述的使用者請求並確定有必要回應該請求，我們將立即更正個人資訊。</li>
        <li css={listItemStyle}>若公司依前款的規定進行更正等，或決定不進行更正等，本公司將立即通知使用者。</li>
      </ol>

      <h3 css={semiTitleStyle}>第11條（停止使用個人資料等）</h3>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          本公司可能會因超出使用目的範圍處理個人資訊或由其他人獲取個人資訊等原因，暫停或刪除個人資訊的使用（以下簡稱「個人資訊的使用」）。非法手段。如果我們被要求這樣做，我們將立即進行必要的調查。
        </li>
        <li css={listItemStyle}>如果我們根據前項的調查結果確定有必要回應請求，我們將立即停止使用該個人資訊。</li>
        <li css={listItemStyle}>當公司依前項規定暫停使用等或決定不停止使用等時，本公司將立即通知使用者。</li>
        <li css={listItemStyle}>
          儘管有前兩款規定，但在停止使用等需要大量費用的情況下，或者停止使用等有困難時，應採取保護用戶權益所需的替代措施。如果可能的話，我們將採取這種替代措施。
        </li>
      </ol>

      <h3 css={semiTitleStyle}>第12條（個人資料的準確性）</h3>
      <p css={leadTextStyle}>我們努力準確處理您提供的個人資訊。 但是，用戶有責任確保他們提供的個人資訊準確且最新。</p>

      <h3 css={semiTitleStyle}>第13條（關於身份驗證）</h3>
      <p css={leadTextStyle}>
        本公司收集個人識別資料（姓名、地址、電話號碼、出生日期、電子郵件地址、會員號碼、密碼等）以確認您的身分。
        但是，如果您以外的人獲取並使用可以識別您身份的信息，我們將不承擔任何責任。
      </p>

      <h3 css={semiTitleStyle}>第14條（關於屬性資訊、終端資訊、位置資訊、行為歷史等的取得與使用）</h3>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          在屬性資訊、終端資訊、位置資訊、行為歷史等（以下簡稱「行為歷史等」）中，我們使用cookie等來保護使用者隱私、提高便利性、發布廣告、使用統計資料.我們使用cookies來取得。
          此外，我們也利用Cookie、JavaScript等技術收集年齡、性別、職業、居住地區等無法辨識個人的屬性資訊（僅限於無法組合識別個人的資訊）、裝置資訊、使用者在本服務網站內的操作紀錄（存取的URL、內容、參考順序等）以及使用智慧型手機時基於使用者同意/申請的位置資訊等。
          然而，cookies和行為歷史記錄不包含任何個人資訊。
        </li>
        <li css={listItemStyle}>
          您裝置上的 Cookie 會保留在您的裝置上，直到您將其刪除或過期為止。 大多數瀏覽器預設會自動接受
          cookie，但使用者可以透過變更瀏覽器設定來停用 cookie。 但是，如果您停用cookie，某些網站功能可能無法使用或某些頁面可能無法正確顯示。
        </li>
        <li css={listItemStyle}>
          對於操作歷史記錄等中下列情況下的存取日誌等，當使用者登入並使用本服務時，本公司將識別個人並使用登入等先前的操作記錄。顯示廣告、內容等，並提供此服務。
          如果您想停止接收該廣告等，請聯絡個人資訊管理視窗。
        </li>
      </ol>

      <h3 css={semiTitleStyle}>第15條（隱私權政策的變更）</h3>
      <p css={leadTextStyle}>
        除法律及本政策另有規定的其他事項外，本政策的內容可能會在未通知使用者的情況下變更。
        此外，除非本公司另有規定，否則修訂後的隱私權政策自本網站公佈之日起生效。
      </p>

      <h3 css={semiTitleStyle}>第16條（詢問）</h3>
      <p css={leadTextStyle}>有關本政策的諮詢，請聯絡以下聯絡點。</p>

      <p css={infoStyle}>
        地址 <br />
        150-0031, Japan <br />
        Shinoda Building 3F, 23-3 Sakuragaokacho, Shibuya-ku, Tokyo
      </p>
      <p css={infoStyle}>
        公司名稱 <br />
        aipass株式会社
      </p>
      <p css={infoStyle}>
        電子郵件地址 <br />
        support@aipass.jp
      </p>
    </div>
  )
}

const layoutStyle = css({
  marginTop: '5rem',
  paddingRight: '2.5rem',
  paddingLeft: '2.5rem',
  lineHeight: '1.625',
})

const pageTitleStyle = css({
  fontWeight: '600',
  textAlign: 'center',
  fontSize: '20px',
})

const administratorStyle = css({
  textAlign: 'center',
})

const updateStyle = css({
  marginTop: '24px',
  marginBottom: '24px',
  fontSize: '14px',
  color: '#475569',
})

const semiTitleStyle = css({
  fontWeight: '600',
  marginTop: '32px',
  marginBottom: '8px',
})

const leadTextStyle = css({
  fontSize: '12px',
  marginTop: '1.5rem',
  marginBottom: '1.5rem',
  lineHeight: '1.625',
})

const listDiscStyle = css({
  listStyle: 'disc',
  marginRight: '1rem',
  marginLeft: '1rem',
  li: {
    listStyle: 'disc',
  },
})

const listStyle = css({
  paddingLeft: '1rem',
  paddingRight: '1rem',
  marginBottom: '1rem',
  listStyleType: 'decimal',
})

const listItemStyle = css({
  fontSize: '12px',
  lineHeight: '1.625',
  listStyleType: 'decimal',
})

const infoStyle = css({
  fontSize: '14px',
  marginTop: '1rem',
  lineHeight: '1.625',
})
