import { useAppSelector } from '@/app/hooks'
import { RequestAssetType } from '@/features/asset/assets-slice'
import { usePickByLanguage } from '@/hooks/use-pick-by-language'
import React, { useEffect, useState } from 'react'
import { CarouselItemWrapper } from '../../atoms/carousel-item-wrapper'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { CUSTOM_LINK, CUSTOM_TEXT_COLOR_LINK } from '@/components/template/custom-design-style'
import { displayYen } from '@/libs/text'
import { css } from '@emotion/react'
import { Divider } from '@/components/atoms/divider'
import { ThumbnailLink } from '@/components/molecules/thumbnail-link'
import { ThumbnailLinkBlock } from '../home/thumbnail-link-block'
import { isEmpty } from 'lodash'
import { BorderButton } from '@/components/atoms/button'
import { useTranslation } from 'react-i18next'
import { PullDownIcon } from '@/components/atoms/icon'
import { useSmoothScroll } from '@/hooks/use-smooth-scroll'

interface RequestAssetBlockProps {
  asset: RequestAssetType
}

const MAX_ITEMS = 4

export const RequestAssetBlock: React.FC<RequestAssetBlockProps> = ({ asset }) => {
  const [isShowAllCategories, setIsShowAllCategories] = useState(false)

  const { t } = useTranslation()
  const { smoothScroll } = useSmoothScroll()
  const pickByLanguage = usePickByLanguage()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const design = useAppSelector(state => state.design)

  const requests = asset.details
  const requestsByFavorite = requests.filter(request => request.isFavorite)
  const onlySalesRequestItems = requestsByFavorite.filter(item => item.isNowOnSale)
  const categories = asset.categories

  const overMaxItems = asset.categories.length > MAX_ITEMS

  const requestCategories = isShowAllCategories ? categories : categories.slice(0, MAX_ITEMS)

  useEffect(() => {
    if (searchParams.get('hash') !== asset.id) {
      return
    }
    smoothScroll(asset.id)
  }, [searchParams])

  return (
    <div id={asset.id} css={requestBlockStyle} className="custom-design-block">
      <div className="order space-inline">
        <h2 className="block-title" style={{ color: design.theme.brandColor }}>
          {pickByLanguage(asset.title)}
        </h2>

        <BorderButton onClick={() => navigate(`/request/${asset.id}`)} text={t('Order from Mobile')} />
      </div>

      {!isEmpty(onlySalesRequestItems) && (
        <>
          <CarouselItemWrapper
            options={{ align: 'start' }}
            style={{ width: '272px', gap: '1rem' }}
            customStyle={css({
              paddingBottom: '2rem',
              paddingLeft: '1rem',
            })}
          >
            {onlySalesRequestItems.map(request => (
              <div key={request.id} className="embla__slide">
                <Link
                  to={`/request/${asset.id}?detailId=${request.id}`}
                  className={`custom-design-card ${CUSTOM_TEXT_COLOR_LINK} slide-item`}
                >
                  <img src={request.imagePath} alt={pickByLanguage(request.name)} />

                  <div className="space-inline">
                    <h3 className={`title`}>{pickByLanguage(request.name)}</h3>

                    <p className="price">{displayYen(request.price.toString())}</p>
                  </div>
                </Link>
              </div>
            ))}
          </CarouselItemWrapper>

          <div className="space-inline">
            <Divider customCss={{ marginBottom: '2rem' }} />
          </div>
        </>
      )}

      <div className="space-inline">
        <ThumbnailLinkBlock className="thumbnail-block">
          {requestCategories.map(requestCategory => (
            <ThumbnailLink
              key={requestCategory.id}
              link={`/request/${asset.id}?requestCategory=${requestCategory.id}`}
              title={pickByLanguage(requestCategory.name)}
              imagePath={requestCategory.imagePath}
            />
          ))}
        </ThumbnailLinkBlock>
        {overMaxItems && !isShowAllCategories && (
          <div
            className={`show-all-link`}
            onClick={() => {
              setIsShowAllCategories(true)
            }}
          >
            <div className={CUSTOM_LINK} style={{ marginRight: 4 }}>
              {t('Show all')}
            </div>
            <PullDownIcon height="20" width="20" />
          </div>
        )}
      </div>
    </div>
  )
}

const requestBlockStyle = css({
  paddingBlock: '2rem',
  '.order': {
    marginBottom: '2rem',
  },
  '.space-inline': {
    paddingInline: '1rem',
  },
  '.block-title': {
    marginBottom: '1rem',
    fontSize: 18,
  },
  '.slide-item': {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '6px',
    img: {
      width: '100%',
      borderTopLeftRadius: '6px',
      borderTopRightRadius: '6px',
    },
    '.title': {
      marginTop: '1rem',
      marginBottom: '2rem',
      fontWeight: 'bold',
      lineHeight: '1.31',
    },
    '.price': {
      marginTop: 'auto',
      fontSize: '14px',
      paddingBottom: '1rem',
    },
  },
  '.show-all-link': {
    paddingTop: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    borderBottom: 'none',
    borderRight: 'none',
    borderLeft: 'none',
    '> img': {
      paddingLeft: 4,
      width: 20,
      height: 20,
    },
  },
})
