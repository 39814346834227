import { ButtonAssetType } from '@/features/asset/assets-slice'
import React from 'react'
import { css } from '@emotion/react'
import { usePickByLanguage } from '@/hooks/use-pick-by-language'
import { BorderButton, Button } from '@/components/atoms/button'
import { ButtonRoleType } from '@/features/asset/design-slice'

type Props = {
  asset: ButtonAssetType
}

export const ButtonAssetBlock: React.FC<Props> = ({ asset }) => {
  const pickByLanguage = usePickByLanguage()

  return (
    <div css={blockStyle}>
      {asset.details
        .filter(detail => detail.name.ja)
        .map(detail => {
          const buttonProps = {
            key: detail.id,
            text: pickByLanguage(detail.name),
            onClick: () => window.open(detail.url),
          }
          return detail.designType === ButtonRoleType.Primary ? <Button {...buttonProps} /> : <BorderButton {...buttonProps} />
        })}
    </div>
  )
}

const blockStyle = css({
  padding: '8px 16px',
  display: 'flex',
  gap: 8,
})
