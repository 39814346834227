import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import { CheckBox } from '../atoms/check-box'
import { BorderButton, Button } from '../atoms/button'
import { spacerTopStyle } from '@/styles/common'
import { css } from '@emotion/react'

interface LineConfirmModalProps {
  isOpen: boolean
  onSocialSignUp: (type: string) => void
  onClose: () => void
}

export const LineConfirmModal: React.FC<LineConfirmModalProps> = ({ isOpen, onSocialSignUp, onClose }) => {
  const [isChecked, setIsChecked] = useState(false)

  const { t } = useTranslation()

  Modal.setAppElement('#root')

  return (
    <Modal
      onClose={onClose}
      style={{
        overlay: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
        content: { border: 'none', inset: '1rem', top: 0, left: 0, margin: 'auto', maxHeight: '390px', padding: '40px 32px' },
      }}
      isOpen={isOpen}
      contentLabel={t('Notes on using LINE')}
    >
      <div>
        <h2 css={modalTitle}>{t('Notes on using LINE')}</h2>

        <ul css={noticeList}>
          <li>・{t('I Browser is not in secret/private mode')}</li>
          <li>・{t('Email address is already set up on LINE')}</li>
          <li>・{t('Permission to access your contacts on LINE')}</li>
        </ul>

        <div css={checkboxWrapper}>
          <CheckBox
            customLabelStyle={css({ fontSize: '12px', fontWeight: 'normal' })}
            name="confirm"
            checked={isChecked}
            label={t('I checked')}
            onChange={() => setIsChecked(!isChecked)}
          />
        </div>

        <div>
          <Button disabled={!isChecked} text={t('Login')} onClick={() => onSocialSignUp('line')} />
          <BorderButton onClick={() => onClose()} buttonCss={spacerTopStyle} text={t('go back')} />
        </div>
      </div>
    </Modal>
  )
}

const modalTitle = css({
  textAlign: 'center',
  fontSize: '18px',
  fontWeight: 'bold',
  marginBottom: '1.5rem',
})

const checkboxWrapper = css({ display: 'flex', justifyContent: 'center', marginTop: '1.5rem', marginBottom: '1.5rem' })

const noticeList = css({
  li: {
    fontSize: '12px',
    marginBottom: '0.5rem',
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
})
