import React, { useEffect } from 'react'
import { Layout } from '@/components/template/layout'
import { RegisterAccountTemplate } from '@/components/template/registerAccount/register-account-template'
import { isEmpty } from 'lodash'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { AccountInfo } from '@/features/user/account-slice'
import { getCheckInPayments } from '@/features/checkIn/check-in-payment-slice'
import { useQuerySearchParams } from '@/hooks/use-query-search-params'
import { CheckInStep } from '@/components/organisms/checkIn/check-in-step'

export const RegisterAccount: React.FC = () => {
  const { hotelId } = useQuerySearchParams<{ hotelId: string }>()

  const dispatch = useAppDispatch()

  const accountInfo = useAppSelector<AccountInfo>(state => state.accountInfo.user)
  const hotelGuideData = useAppSelector(state => state.hotelGuide.plugins)
  const checkInData = useAppSelector(state => state.checkIn.checkInData.fields)

  useEffect(() => {
    if (checkInData?.reservationIds) {
      dispatch(getCheckInPayments({ hotelId, reservationIds: checkInData.reservationIds }))
    }
  }, [])

  return (
    <Layout>
      <CheckInStep current={2} />
      <div style={{ paddingTop: '1rem' }}>{!isEmpty(accountInfo) && !isEmpty(hotelGuideData) && <RegisterAccountTemplate />}</div>
    </Layout>
  )
}
