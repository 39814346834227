import React, { useMemo } from 'react'
import { Layout } from '@/components/template/layout'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '@/app/hooks'
import { capitalize } from '@/libs/text'
import { Loading } from '@/components/organisms/loading'
import { css } from '@emotion/react'
import { TitleWithSubCategory } from '@/components/organisms/aroundSpot/title-with-sub-category'
import { ReactComponent as RootIcon } from '@/static/images/icon/icon_root.svg'
import { ReactComponent as PhoneIcon } from '@/static/images/icon/icon_call.svg'
import { ReactComponent as SiteIcon } from '@/static/images/icon/icon_site.svg'
import { ReactComponent as AddressIcon } from '@/static/images/icon/icon_address.svg'
import { ReactComponent as TimeIcon } from '@/static/images/icon/icon_time.svg'
import { BusinessTime } from '@/components/organisms/aroundSpot/business-time'
import { MapDetailType } from '@/features/asset/assets-slice'
import { usePickByLanguage } from '@/hooks/use-pick-by-language'
import { CUSTOM_TEXT_COLOR_LINK } from '@/components/template/custom-design-style'
import { ContentWrapper } from '@/components/template/content-wrapper'

interface CircleIconProps {
  icon: React.ReactNode
}

const CircleIcon: React.FC<CircleIconProps> = ({ icon }) => {
  return (
    <div className="custom-design-background" css={circleIconStyle}>
      {icon}
    </div>
  )
}
const circleIconStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '50px',
  height: '50px',
  borderRadius: '50%',
})

export const AroundSpotDetail: React.FC = () => {
  const { assetId, detailId } = useParams<{ assetId: string; detailId: string }>()

  const pickByLanguage = usePickByLanguage()

  const { t } = useTranslation()

  const design = useAppSelector(state => state.design)
  const { assets, loading } = useAppSelector(state => state.assets)
  const { facilityBasicInfo } = useAppSelector(state => state.hotelGuide)

  const mapAsset = assets.find(asset => asset.id === assetId)

  const aroundSpot = useMemo(() => {
    return (mapAsset?.details as MapDetailType[])?.find(detail => detail.id === detailId)
  }, [assets])

  return (
    <Layout>
      <Loading loading={loading}>
        {aroundSpot && (
          <>
            <div css={facilityPhoto}>
              {aroundSpot.imagePath && <img src={aroundSpot.imagePath} alt={pickByLanguage(aroundSpot.title)} />}
            </div>

            <ContentWrapper>
              <div className="custom-design-block" css={blockStyle}>
                <TitleWithSubCategory
                  facilityName={pickByLanguage(aroundSpot.title)}
                  subCategory={pickByLanguage(aroundSpot.subCategory)}
                  isFavorite={aroundSpot.isFavorite || false}
                />

                <div css={iconWrapStyle}>
                  <div css={iconStyle}>
                    <a
                      className={aroundSpot.lat && aroundSpot.lng ? '' : 'noItem'}
                      href={`https://www.google.com/maps/dir/?api=1&destination=${aroundSpot.lat},${aroundSpot.lng}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <CircleIcon icon={<RootIcon className="custom-design-icon" />} />
                      <span style={{ color: design.theme.brandColor }}>{capitalize(t('route'))}</span>
                    </a>
                  </div>
                  <div css={iconStyle}>
                    <a className={aroundSpot.telephone ? '' : 'noItem'} href={`tel:${aroundSpot.telephone}`}>
                      <CircleIcon icon={<PhoneIcon className="custom-design-icon" />} />
                      <span style={{ color: design.theme.brandColor }}>{capitalize(t('phone'))}</span>
                    </a>
                  </div>
                  <div css={iconStyle}>
                    <a className={aroundSpot.siteUrl ? '' : 'noItem'} href={aroundSpot.siteUrl} target="_blank" rel="noopener noreferrer">
                      <CircleIcon icon={<SiteIcon className="custom-design-icon" />} />
                      <span style={{ color: design.theme.brandColor }}>{capitalize(t('website'))}</span>
                    </a>
                  </div>
                </div>
              </div>

              <div className="custom-design-block" css={blockStyle}>
                <ul css={infoListWrapStyle}>
                  <li css={infoListStyle} className={CUSTOM_TEXT_COLOR_LINK}>
                    <AddressIcon className="custom-design-icon" />
                    <p className="info-text">{pickByLanguage(aroundSpot.address) || '-'}</p>
                  </li>
                  <li css={infoListStyle} className={CUSTOM_TEXT_COLOR_LINK}>
                    <PhoneIcon className="custom-design-icon" />
                    <p className="info-text">
                      {aroundSpot.telephone ? <a href={`tel:${aroundSpot.telephone}`}>{aroundSpot.telephone}</a> : '-'}
                    </p>
                  </li>
                  <li css={infoListStyle} className={CUSTOM_TEXT_COLOR_LINK}>
                    <SiteIcon width="20" className="custom-design-icon" />

                    <p className="info-text">
                      {aroundSpot.siteUrl ? (
                        <a href={aroundSpot.siteUrl} target="_blank" rel="noopener noreferrer">
                          {aroundSpot.siteUrl}
                        </a>
                      ) : (
                        '-'
                      )}
                    </p>
                  </li>
                  <li css={[infoListStyle, { alignItems: 'flex-start' }]} className={CUSTOM_TEXT_COLOR_LINK}>
                    <TimeIcon className="custom-design-icon" />
                    <div className="info-text">
                      <BusinessTime mapBusinessHour={aroundSpot.businessHours || []} />
                    </div>
                  </li>
                </ul>
              </div>

              <div className="custom-design-block" css={blockStyle}>
                <div css={recommendationsTitleStyle}>
                  <div css={hotelLogoStyle}>
                    {facilityBasicInfo.logoImageId ? <img src={facilityBasicInfo.logoImageId} alt={facilityBasicInfo.hotelName} /> : <></>}
                  </div>
                  <p>{t('recommendations')}</p>
                </div>
                {pickByLanguage(aroundSpot.comment) && (
                  <p className="" css={recommendationsStyle}>
                    {pickByLanguage(aroundSpot.comment)}
                  </p>
                )}
              </div>
            </ContentWrapper>
          </>
        )}
      </Loading>
    </Layout>
  )
}

const blockStyle = css({
  paddingTop: '2rem',
  paddingInline: '1rem',
  paddingBottom: '2rem',
  ':not(first-of-type)': {
    marginTop: '0.25rem',
  },
})

const facilityPhoto = css({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: '250px',
  img: {
    width: '100%',
    objectFit: 'cover',
  },
})

const iconWrapStyle = css({
  display: 'flex',
  justifyContent: 'center',
  gap: '2.5rem',
  marginTop: '2rem',
})

const iconStyle = css({
  textAlign: 'center',
  fontSize: '13px',
  span: {
    display: 'block',
    marginTop: '0.5rem',
  },
  '.noItem': {
    pointerEvents: 'none',
    opacity: 0.5,
  },
})

const infoListWrapStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',
})

const infoListStyle = css({
  display: 'flex',
  alignItems: 'center',
  '.info-text': {
    marginLeft: '1rem',
    fontSize: '14px',
    flex: 1,
    lineHeight: 1.4,
    wordBreak: 'break-all',
  },
})

const hotelLogoStyle = css({
  width: '40px',
  height: '40px',
  marginRight: '0.5rem',
  img: {
    borderRadius: '50%',
    width: '100%',
    height: '100%',
  },
})

const recommendationsTitleStyle = css({
  display: 'flex',
  alignItems: 'center',
  fontSize: '14px',
  fontWeight: 'bold',
})

const recommendationsStyle = css({
  fontSize: '14px',
  marginTop: '1rem',
  lineHeight: 1.57,
  whiteSpace: 'pre-wrap',
})
