import { http } from '@/apis/aipass'
import { signOut } from '@/apis/auth0'
import { env } from '@/libs/env'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const logout = createAsyncThunk('auth/logout', async (params: { hotelId: string; lang: string }) => {
  http({
    method: 'post',
    url: `${env('BASE_API_V3')}/auth/logout`,
  })
  signOut(params)
})
