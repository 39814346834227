import { Input } from '@/components/atoms/input'
import { RequiredMark } from '@/components/atoms/required-mark'
import { FormLayout } from '@/components/template/form-layout'
import { CardDetail } from '@/features/user/user-card-slice'
import { spacerTopStyle } from '@/styles/common'
import { labelStyle } from '@/styles/form'
import { css } from '@emotion/react'
import { chunk } from 'lodash'
import React from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface CardFormProps {
  useFormReturn: UseFormReturn<CardDetail>
}

export const CardForm: React.FC<CardFormProps> = ({ useFormReturn }) => {
  const { t } = useTranslation()

  const {
    register,
    setValue,
    control,
    formState: { errors },
  } = useFormReturn

  const insertHalfWidthSpace = (value: string) => {
    const digits = value.replace(/[^0-9]/g, '')
    const formattedInput = chunk(digits, 4)
      .map(digit => digit.join(''))
      .join(' ')

    setValue('cardNumber', formattedInput, { shouldValidate: true })
  }

  return (
    <FormLayout>
      <div>
        <Controller
          control={control}
          name="cardNumber"
          render={() => (
            <Input
              {...register('cardNumber')}
              required
              label={t('Card Number')}
              type="tel"
              placeholder="1234 1234 1234 1234"
              error={errors.cardNumber?.message}
              onChange={e => {
                insertHalfWidthSpace(e.target.value)
              }}
            />
          )}
        />

        <div style={{ marginTop: '0.5rem' }}>
          <img src={require('@/static/images/payment_logo.jpg')} style={{ height: '24px' }} alt="使用できるカード会社" />
        </div>
      </div>

      <div css={[spacerTopStyle]}>
        <label css={labelStyle}>
          {t('Expiration Date')} <RequiredMark />
        </label>
        <div css={[multipleInput]}>
          <Input
            placeholder="01"
            {...register('cardExpireMonth')}
            type="tel"
            onBlur={e => setValue('cardExpireMonth', e.target.value.padStart(2, '0'))}
            error={errors.cardExpireMonth?.message}
          />
          /
          <Input
            placeholder="24"
            {...register('cardExpireYear')}
            type="tel"
            onBlur={e => setValue('cardExpireYear', e.target.value.padStart(2, '0'))}
            error={errors.cardExpireYear?.message}
          />
        </div>
      </div>

      <div css={[spacerTopStyle]}>
        <Input required {...register('name')} label={t("Cardholder's Name")} placeholder="TARO YAMADA" error={errors.name?.message} />
      </div>

      <div css={[spacerTopStyle]}>
        <Input
          required
          {...register('securityCode')}
          type="tel"
          label={t('CVV')}
          name="securityCode"
          placeholder="000"
          error={errors.securityCode?.message}
        />
      </div>
    </FormLayout>
  )
}

const multipleInput = css({
  width: '50%',
  display: 'flex',
  gap: '0.75rem',
  alignItems: 'baseline',
})
