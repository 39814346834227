import { css } from '@emotion/react'
import React, { HTMLProps } from 'react'
import { CUSTOM_HL_AREA } from '../template/custom-design-style'
import { pcScreenStyle } from '@/styles/common'
import { useBreakPoint } from '@/hooks/use-break-point'

interface NavigateHeaderProps extends HTMLProps<HTMLDivElement> {
  title: string
}

export const NavigateHeader: React.FC<NavigateHeaderProps> = ({ title, ...props }) => {
  const { isPc } = useBreakPoint()

  return (
    <div className={CUSTOM_HL_AREA} {...props}>
      <p css={[NavigateHeaderStyle, pcScreenStyle]} className={CUSTOM_HL_AREA} style={{ paddingLeft: isPc ? 0 : '1rem' }}>
        {title}
      </p>
    </div>
  )
}

const NavigateHeaderStyle = css({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '30px',
  paddingRight: '1rem',
  fontSize: '12px',
  fontWeight: 'bold',
})
