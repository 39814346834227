import React from 'react'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { Loading } from '../loading'
import { deleteCreditCard } from '@/features/user/user-card-slice'
import { CloseButton } from '../../atoms/close-button'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FormLayout } from '@/components/template/form-layout'
import { css } from '@emotion/react'
import { horizontalSpacing, spacerTopStyle } from '@/styles/common'
import { errorHandler } from '@/libs/errors'
import { CUSTOM_BUTTON } from '@/components/template/custom-design-style'

interface CardInfoProps {
  showNoCard?: boolean
}

export const CardInfo: React.FC<CardInfoProps> = ({ showNoCard }) => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const userCreditCard = useAppSelector(state => state.creditCard.userCreditCardData)
  const loading = useAppSelector(state => state.creditCard.deleteLoading)

  const deleteCard = async () => {
    try {
      await dispatch(deleteCreditCard(userCreditCard.cardId)).unwrap()
    } catch (error) {
      errorHandler({ error })
    }
  }

  if (showNoCard && !userCreditCard.cardNumber) {
    return (
      <>
        <FormLayout layoutCss={noCardStyle}>
          <p>宿泊料金やルームオーダーの支払いができます。 利用できない施設もございます。</p>
        </FormLayout>
        <div css={[horizontalSpacing, spacerTopStyle]}>
          <Link css={addCreditCardStyle} to="/mypage/credit-card/register" className={`${CUSTOM_BUTTON}-secondary`}>
            {t('Add a card')}
          </Link>
        </div>
      </>
    )
  }

  return (
    <Loading loading={loading}>
      <FormLayout>
        <div css={cardLayoutStyle}>
          <p style={{ fontWeight: 'bold' }}>
            <span css={cardBrandStyle}>{userCreditCard.cardBrand}</span>
            <span css={cardNumberStyle}>{userCreditCard.cardNumber}</span>
          </p>

          {showNoCard && <CloseButton onClick={() => deleteCard()} />}
        </div>
      </FormLayout>
    </Loading>
  )
}

const noCardStyle = css({
  p: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '1.7',
  },
})

const addCreditCardStyle = css({
  display: 'block',
  textAlign: 'center',
  // background: '#fff',
  paddingTop: '0.75rem',
  paddingBottom: '0.75rem',
  borderStyle: 'dashed',
  borderRadius: '5px',
})

const cardLayoutStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
})

const cardBrandStyle = css({
  fontSize: '16px',
  marginRight: '18px',
})

const cardNumberStyle = css({
  fontSize: '14px',
})
