import React from 'react'
import { CSSObject, SerializedStyles, css } from '@emotion/react'
import { CUSTOM_BORDER } from '../template/custom-design-style'

interface DividerProps {
  customCss?: SerializedStyles | CSSObject
}

export const Divider: React.FC<DividerProps> = ({ customCss }) => {
  return <div css={[dividerStyle, customCss]} className={CUSTOM_BORDER}></div>
}

const dividerStyle = css({
  borderTop: 'none',
  borderRight: 'none',
  borderLeft: 'none',
})
