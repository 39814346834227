import { css } from '@emotion/react'
import React from 'react'

interface ErrorMessageProps {
  text: string
}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ text }) => {
  return <span css={errorMessageStyle}>{text}</span>
}

const errorMessageStyle = css({
  display: 'inline-block',
  marginTop: '0.25rem',
  color: '#E6483B',
  lineHeight: '1.5',
  fontSize: '14px',
})
