import React, { useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { css } from '@emotion/react'
import { Button } from '@/components/atoms/button'
import { useAppSelector } from '@/app/hooks'
import { useNavigate } from 'react-router-dom'
import { Loading } from '@/components/organisms/loading'
import { CustomDesignStyle } from '@/components/template/custom-design-style'
import { NgImage, OkImage } from '@/components/atoms/icon'

export const CheckoutThanks: React.FC = () => {
  const [frontSupported, setFrontSupported] = useState(false)

  const { t } = useTranslation()

  const navigate = useNavigate()

  const { loading, checkoutResultHistories } = useAppSelector(state => state.checkout.checkoutData)
  const checkoutReservations: any = useAppSelector(state => state.checkout.checkoutReservation.checkoutReservations)

  const existsCheckoutError = () => {
    return Object.values(checkoutResultHistories).some(isCompleted => !isCompleted)
  }

  const isFrontSupported = () => {
    return existsCheckoutError() || checkoutReservations?.some(reservation => reservation.frontSupported)
  }

  useLayoutEffect(() => {
    setFrontSupported(isFrontSupported())
  }, [checkoutResultHistories])

  return (
    <>
      <CustomDesignStyle />
      <div css={[layoutStyle]}>
        <Loading loading={loading}>
          {frontSupported ? (
            <>
              <NgImage />
              <h2 css={thanksMessageStyle}>{t('Please complete the procedure\nat the front desk.')}</h2>
              <p css={leadStyle}>{t('Please return the key and settle \nyour account at the front desk.')}</p>
            </>
          ) : (
            <>
              <OkImage />
              <h2 css={thanksMessageStyle}>{t('Checkout is complete.')}</h2>
              <p css={leadStyle}>
                {t('Thank you for your stay.')}
                <br />
                {t('Please return the key and leave.')}
              </p>
            </>
          )}

          <Button buttonCss={buttonStyle} text={t('Back to main')} onClick={() => navigate('/')} />
        </Loading>
      </div>
    </>
  )
}

const layoutStyle = css({
  display: 'flex',
  height: '100vh',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: '6rem',
  paddingRight: '1.5rem',
  paddingLeft: '1.5rem',
})

const thanksMessageStyle = css({
  fontSize: '22px',
  fontWeight: 'bold',
  marginTop: '2.5rem',
  marginBottom: '2rem',
  textAlign: 'center',
  lineHeight: '1.54',
  whiteSpace: 'pre-wrap',
})

const leadStyle = css({
  textAlign: 'center',
  fontSize: '14px',
  lineHeight: '1.4',
  whiteSpace: 'pre-wrap',
})

const buttonStyle = css({
  maxWidth: 568,
  marginTop: 'auto',
  marginBottom: '2.5rem',
})
