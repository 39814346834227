import { combineReducers } from '@reduxjs/toolkit'
import { checkEmailReducer, forgotPasswordReducer, signInBySnsReducer, signInReducer, signUpReducer } from './auth-slice'

export const authReducer = combineReducers({
  forgotPassword: forgotPasswordReducer,
  checkMail: checkEmailReducer,
  signIn: signInReducer,
  signInBySns: signInBySnsReducer,
  signUp: signUpReducer,
})
