import { http } from '@/apis/aipass'
import { env } from '@/libs/env'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const useCoupon = createAsyncThunk('coupon/useCoupon', async (couponId: string, { rejectWithValue }) => {
  try {
    const response = await http({
      method: 'post',
      url: `${env('BASE_API_V3')}/store/coupons/${couponId}`,
    })

    return response.data
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const CouponScopeUsageType = {
  All: 'all',
  Login: 'login',
  CheckIn: 'checkIn',
}
export type CouponScopeUsageType = keyof typeof CouponScopeUsageType

export const isAuthCoupon = (scopeUsage: CouponScopeUsageType) => {
  return scopeUsage === CouponScopeUsageType.Login || scopeUsage === CouponScopeUsageType.CheckIn
}
export const isNeedCheckedInCoupon = (scopeUsage: CouponScopeUsageType) => {
  return scopeUsage === CouponScopeUsageType.CheckIn
}

const couponSlice = createSlice({
  name: 'coupon',
  initialState: {
    isLoading: false,
  },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(useCoupon.pending, state => {
      state.isLoading = true
    })
    builder.addCase(useCoupon.fulfilled, state => {
      state.isLoading = false
    })
    builder.addCase(useCoupon.rejected, state => {
      state.isLoading = false
    })
  },
})
export const couponReducer = couponSlice.reducer
