import { http } from "@/apis/aipass"
import { env } from "@/libs/env"


interface SendMailCheckout {
  email: string,
  name?: string | null,
  hotelNameJa?: string | null,
  hotelNameEn?: string | null,
  hotelPhoneNumber?: string | null,
  usageDetailUrl?: string | null,
  receptionNumber?: string | null,
  receptionDate?: string | null,
  paymentMethods?: string | null,
  paymentAmount?: string | null,
  checkins: any[],
}
export const sendmailCheckoutPaymentInfo = async (data: SendMailCheckout) => {
  const response = await http({
    method: 'post',
    url: `${env('BASE_API_V3')}/frontend-sendmail/checkout-payment-info`,
    data,
  })

  return response.data
}
