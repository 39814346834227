import { css } from '@emotion/react'
import React from 'react'
import { EditIcon } from './icon'
import { CUSTOM_ICON_BUTTON } from '../template/custom-design-style'

interface TitleWithButtonProps {
  onClick?: () => void
  title: string
}

export const TitleWithButton: React.FC<TitleWithButtonProps> = ({ onClick, title }) => {
  return (
    <div css={titleWithButtonStyle} onClick={onClick}>
      <h3>{title}</h3>
      <button className={CUSTOM_ICON_BUTTON}>
        <EditIcon />
      </button>
    </div>
  )
}

const titleWithButtonStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  h3: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: 0,
    width: '30px',
    height: '30px',
    padding: 0,
    borderRadius: '50%',
    appearance: 'none',
    border: 'none',
    svg: {
      width: '28px',
      height: '28px',
    },
  },
})
