import { LanguageType } from '@/i18n'

export function capitalize(str) {
  if (typeof str !== 'string') return str
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const getValueByLanguage = <T>({ object, key, lang }: { object: object; key: string; lang: LanguageType }): T => {
  if (!object) return undefined as T

  const checkEmptyField = (o, k) => o[k] !== undefined && o[k] !== null

  if (checkEmptyField(object, `${key}${capitalize(lang)}`)) {
    return object[`${key}${capitalize(lang)}`]
  } else if (checkEmptyField(object, `${lang}${capitalize(key)}`)) {
    return object[`${lang}${capitalize(key)}`]
  } else if (lang === 'ja' && checkEmptyField(object, `${key}Jp`)) {
    return object[`${key}Jp`]
  } else if (lang === 'ja' && checkEmptyField(object, `jp${capitalize(key)}`)) {
    return object[`jp${capitalize(key)}`]
  }

  if (object?.[key]?.[lang] !== undefined) return object[key][lang]

  return object[key]
}
