import { http } from '@/apis/aipass'
import { env } from '@/libs/env'
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Reservation } from './search-reservations-slice'

interface getCheckInPaymentsParams {
  hotelId: string
  reservationIds: string[]
}
export const getCheckInPayments = createAsyncThunk(
  'checkIn/getCheckInPayments',
  async (data: getCheckInPaymentsParams, { rejectWithValue }) => {
    try {
      const response = await http({
        method: 'post',
        url: `${env('BASE_API_V3')}/search-reservation`,
        data,
      })
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

interface CheckInPaymentData {
  paymentType?: string
  receiptName?: string
  paymentAmount?: number
}

interface Payments {
  reservations: Reservation[]
  totalAmount: number
  totalDiscount: number
  totalPayment: number
  totalPrice: number
  totalTaxPrice: number
}

export const getSessionCheckInPaymentData = (): CheckInPaymentData => {
  const sessionCheckInPaymentData = sessionStorage.getItem('checkinPaymentData')
  const checkInPaymentData = sessionCheckInPaymentData ? JSON.parse(sessionCheckInPaymentData) : {}

  return checkInPaymentData
}

export const checkInPaymentsSlice = createSlice({
  name: 'getCheckInPayments',
  initialState: {
    checkInPaymentData: getSessionCheckInPaymentData(),
    payments: {
      totalAmount: 0,
      reservations: [] as Reservation[],
    } as Payments,
    loading: false,
  },
  reducers: {
    setCheckInPaymentData: (state, action: PayloadAction<CheckInPaymentData>) => {
      state.checkInPaymentData.paymentType = action.payload.paymentType
      state.checkInPaymentData.paymentAmount = action.payload.paymentAmount
      state.checkInPaymentData.receiptName = action.payload.receiptName

      sessionStorage.setItem('checkinPaymentData', JSON.stringify(state.checkInPaymentData))
    },
    deleteCheckInPaymentData: state => {
      state.checkInPaymentData = {}
      sessionStorage.removeItem('checkinPaymentData')
    },
    clearPayments: state => {
      state.payments = {
        totalAmount: 0,
        reservations: [] as Reservation[],
      } as Payments
    },
  },
  extraReducers(builder) {
    builder.addCase(getCheckInPayments.pending, state => {
      state.loading = true
    })
    builder.addCase(getCheckInPayments.fulfilled, (state, action) => {
      state.payments = action.payload
      state.loading = false
    })
    builder.addCase(getCheckInPayments.rejected, state => {
      state.loading = false
    })
  },
})

export const { setCheckInPaymentData, deleteCheckInPaymentData, clearPayments } = checkInPaymentsSlice.actions
export const checkInPaymentsReducer = checkInPaymentsSlice.reducer
