import { css } from '@emotion/react'
import React from 'react'

export const JaContent: React.FC = () => {
  return (
    <div css={layoutStyle}>
      <div css={administratorStyle}>
        <h2 css={pageTitleStyle}>【第二版】情報セキュリティ方針</h2>

        <p css={updateStyle}>最終更新日：2024年4月1日</p>
      </div>

      <p css={leadTextStyle}>
        ＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿ <br />
        ISMS-01_ <br />
        情報セキュリティ方針書 <br />
        改定 履歴 第3版 <br />
        承認者：山田真由美 <br />
        内容：トップマネジメント名の変更 <br />
        承認日： 2024年4月1日 <br />
        ＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿
      </p>

      <h3 css={semiTitleStyle}>1 目的</h3>
      <p css={leadTextStyle}>
        aipass株式会社（以下、「当社」といいます）は、WEBアプリケーションの企画、開発、運営及び販売事業、並びに従業者の管理（以下、「事業」といいます）を実施するに当たり、多くの情報資産を利用していることから、情報セキュリティを適切に実現し、情報資産の保護に努めることは、社会の信頼のもとに企業活動を推進するための必要不可欠な要件であるとともに、重大な社会的責務であると認識しております。よって、当社は情報セキュリティの重要性を鑑み、この情報セキュリティ方針（以下、「本方針」といいます）を定め、具体的に実施するための情報セキュリティマネジメントシステムを確立し、実施し、維持し、且つ改善してまいります。
      </p>

      <h3 css={semiTitleStyle}>2 情報セキュリティの定義</h3>
      <p css={leadTextStyle}>情報セキュリティとは、機密性、完全性及び可用性を維持することと定義しています。</p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          機密性 <br />
          情報資産を不正アクセスなどから保護し、参照する権限のないものに漏洩しないことを意味します。（認可されていない個人、エンティティ又はプロセスに対して、情報を使用させず、また、開示しない特性）
        </li>
        <li css={listItemStyle}>
          完全性 <br />
          情報資産を改竄や間違いから保護し、正確かつ完全に維持されることを意味します。（正確さ及び完全さの特性）
        </li>
        <li css={listItemStyle}>
          可用性 <br />
          情報資産を紛失・破損やシステムの停止などから保護し、必要なときに利用できることを意味します。（認可されたエンティティが要求したときに、アクセス及び使用が可能である特性）
        </li>
      </ol>

      <h3 css={semiTitleStyle}>3 適用範囲</h3>
      <p css={leadTextStyle}>
        本方針を当社の管理する情報資産の全てに対して適用します。情報資産の範囲は、電子的機器並びに電子データにとどまらず、紙媒体を含めた全ての形態を含みます。
      </p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          組織 <br />
          aipass株式会社（全従業者）
        </li>
        <li css={listItemStyle}>
          施設 <br />
          本社（住所：東京都渋谷区桜丘町23-3 篠田ビル3F）
        </li>
        <li css={listItemStyle}>
          業務 <br />
          WEBアプリケーションの企画、開発、運営及び販売
        </li>
        <li css={listItemStyle}>
          資産 <br />
          上記事業および各種サービスに関連する文書、データ、情報システム、ネットワーク等
        </li>
      </ol>

      <h3 css={semiTitleStyle}>4 実施事項</h3>
      <p css={leadTextStyle}>本方針並びに当社の情報セキュリティマネジメントシステムに従い、下記の事項を実施します。</p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          情報セキュリティ目的について <br />
          本方針と整合性を有し、適用される情報セキュリティ要求事項、並びにリスクアセスメント及びリスク対応の結果を考慮した情報セキュリティ目的を策定し、全従業者に周知するとともに、当社の環境の変化に応じて随時、変化がなくとも定期的な見直しを行います。
        </li>
        <li css={listItemStyle}>
          情報資産の取り扱いについて <br />
          <ol css={listDiscStyle}>
            <li>アクセス権限は、業務上必要な者のみに与えることとします。</li>
            <li>法的及び規制の要求事項並びに契約上の要求事項、当社の情報セキュリティマネジメントシステムの規定に従い管理を行います。</li>
            <li>情報資産の価値、機密性、完全性、可用性の観点から、それらの重要性に応じて適切に分類し管理を行います。</li>
            <li>情報資産が適切に管理されていることを確認するために、継続的に監視を実施します。</li>
          </ol>
        </li>
        <li css={listItemStyle}>
          リスクアセスメントについて <br />
          <ol css={listDiscStyle}>
            <li>
              リスクアセスメントを行い、事業の特性から最も重要と判断する情報資産について適切なリスク対応を実施し、管理策を導入します。
            </li>
            <li>情報セキュリティに関連する事故原因を分析し、再発防止策を講じます。</li>
          </ol>
        </li>
        <li css={listItemStyle}>
          事業継続管理について <br />
          災害や故障などによる事業の中断を最小限に抑え、事業継続能力を確保します。
        </li>
        <li css={listItemStyle}>
          教育について <br />
          全従業者に対し、情報セキュリティ教育および訓練を実施します。
        </li>
        <li css={listItemStyle}>
          規定並びに手順の順守 <br />
          情報セキュリティマネジメントシステムの規定並びに手順を順守します。
        </li>
        <li css={listItemStyle}>
          法的及び規制の要求事項並びに契約上の要求事項の順守 <br />
          情報セキュリティに関する法的及び規制の要求事項並びに契約上の要求事項を順守します。
        </li>
        <li css={listItemStyle}>
          継続的改善 <br />
          情報セキュリティマネジメントシステムの継続的な改善に取り組みます。
        </li>
        <li css={listItemStyle}>
          情報セキュリティのための方針群 <br />
          情報セキュリティ方針に関連する情報セキュリティのための方針群として以下を定めます。
        </li>
      </ol>
      <p css={leadTextStyle}>
        モバイル機器に関する方針群 <br />
        アクセス制御 <br />
        暗号による管理策の利用方針 <br />
        クリアデスク・クリアスクリーン方針 <br />
        情報の転送に関する方針 <br />
        セキュリティに配慮した開発のための方針 <br />
        供給者関係のための情報セキュリティの方針 <br />
      </p>

      <h3 css={semiTitleStyle}>5 責任と義務及び罰則</h3>
      <p css={leadTextStyle}>
        本方針を含めた情報セキュリティマネジメントシステムに対する責任はトップマネジメントが負うこととし、適用範囲の従業者は策定された規定や手順を順守する義務を負うものとします。なお義務を怠り、違反行為を行った従業者は就業規則に定めるところにより処分します。協力会社社員については個別に定めた契約などに従って、対応を行います。
      </p>

      <h3 css={semiTitleStyle}>6 定期的見直し</h3>
      <p css={leadTextStyle}>
        情報セキュリティマネジメントシステムの見直しは、定期的および必要に応じてレビューし、維持・管理するものとします。
      </p>

      <p css={[leadTextStyle, { marginTop: '2rem' }]}>
        制定：2021年4月1日 <br />
        最終改定日：2024年4月1日 <br />
        トップマネジメント 山田 真由美
        <br />
      </p>
    </div>
  )
}

const layoutStyle = css({
  marginTop: '5rem',
  paddingRight: '2.5rem',
  paddingLeft: '2.5rem',
  lineHeight: '1.625',
})

const pageTitleStyle = css({
  fontWeight: '600',
  textAlign: 'center',
  fontSize: '20px',
})

const administratorStyle = css({
  textAlign: 'center',
})

const updateStyle = css({
  marginTop: '24px',
  marginBottom: '24px',
  fontSize: '14px',
  color: '#475569',
})

const semiTitleStyle = css({
  fontWeight: '600',
  marginTop: '32px',
  marginBottom: '8px',
})

const leadTextStyle = css({
  fontSize: '12px',
  marginTop: '1.5rem',
  marginBottom: '1.5rem',
  lineHeight: '1.625',
})

const listDiscStyle = css({
  listStyle: 'disc',
  marginRight: '1rem',
  marginLeft: '1rem',
  li: {
    listStyle: 'disc',
  },
})

const listStyle = css({
  paddingLeft: '1rem',
  paddingRight: '1rem',
  marginBottom: '1rem',
  listStyleType: 'decimal',
})

const listItemStyle = css({
  fontSize: '12px',
  lineHeight: '1.625',
  listStyleType: 'decimal',
})
