import React from 'react'

import { useTranslation } from 'react-i18next'
import { JaContent } from '@/components/organisms/privacyPolicty/ja-content'
import { EnContent } from '@/components/organisms/privacyPolicty/en-content'
import { KoContent } from '@/components/organisms/privacyPolicty/ko-content'
import { ZhContent } from '@/components/organisms/privacyPolicty/zh-content'
import { ZhTwContent } from '@/components/organisms/privacyPolicty/zh-tw-content'
import { StaticLayout } from '@/components/template/static-layout'
import { Layout } from '@/components/template/layout'
import { useQuerySearchParams } from '@/hooks/use-query-search-params'

export const PrivacyPolicy: React.FC = () => {
  const {
    i18n: { language },
  } = useTranslation()

  const { hotelId } = useQuerySearchParams<{ hotelId?: string }>()

  const contents = {
    ja: <JaContent />,
    en: <EnContent />,
    ko: <KoContent />,
    zh: <ZhContent />,
    zhTw: <ZhTwContent />,
  }

  return hotelId ? <Layout>{contents[language]}</Layout> : <StaticLayout>{contents[language]}</StaticLayout>
}
