import React, { useEffect } from 'react'
import { useAppSelector } from '@/app/hooks'
import { MapAssetType, MapCategoryType, MapDetailType } from '@/features/asset/assets-slice'
import { usePickByLanguage } from '@/hooks/use-pick-by-language'
import { css } from '@emotion/react'
import { Divider } from '@/components/atoms/divider'
import { ThumbnailLink } from '@/components/molecules/thumbnail-link'
import { isEmpty } from 'lodash'
import { Link, useSearchParams } from 'react-router-dom'
import { useSmoothScroll } from '@/hooks/use-smooth-scroll'
import { CUSTOM_TEXT_COLOR_LINK } from '@/components/template/custom-design-style'
import { CarouselItemWrapper } from '../../atoms/carousel-item-wrapper'
import { ThumbnailLinkBlock } from '../home/thumbnail-link-block'
import { lineClampStyle } from '@/styles/common'

interface AroundSpotBlockProps {
  asset: MapAssetType
}

export const AroundSpotBlock: React.FC<AroundSpotBlockProps> = ({ asset }) => {
  const { smoothScroll } = useSmoothScroll()
  const pickByLanguage = usePickByLanguage()
  const [searchParams] = useSearchParams()

  const design = useAppSelector(state => state.design)

  const aroundSpots: MapDetailType[] = asset.details || []
  const spotCategories: MapCategoryType[] = asset.categories || []

  const aroundSpotsByFavorite = aroundSpots.filter(aroundSpot => aroundSpot.isFavorite)

  useEffect(() => {
    if (searchParams.get('hash') !== asset.id) {
      return
    }
    smoothScroll(asset.id)
  }, [searchParams])

  return (
    <div id={asset.id} css={aroundSpotBlockStyle} className="custom-design-block">
      <h2 className="block-title space-inline" style={{ color: design.theme.brandColor }}>
        {pickByLanguage(asset.title)}
      </h2>
      {!isEmpty(aroundSpotsByFavorite) && (
        <>
          <CarouselItemWrapper
            options={{ align: 'start' }}
            className="slide-wrap"
            style={{ width: '272px', gap: '1rem' }}
            customStyle={css({
              paddingBottom: '2rem',
              paddingLeft: '1rem',
            })}
          >
            {aroundSpotsByFavorite.map(aroundSpot => (
              <div key={aroundSpot.id} className="embla__slide">
                <Link
                  to={`/around-spot/${asset.id}/${aroundSpot.id}`}
                  className={`custom-design-card ${CUSTOM_TEXT_COLOR_LINK}`}
                  css={cardStyle}
                >
                  {aroundSpot.imagePath ? (
                    <img className="thumbnail" src={aroundSpot.imagePath} alt={pickByLanguage(aroundSpot.title)} />
                  ) : (
                    <div className="thumbnail" style={{ backgroundColor: design.background.backgroundColor }} />
                  )}

                  <div className="title-wrap">
                    <h3 className={`title`}>{pickByLanguage(aroundSpot.title)}</h3>
                    <span className={`subCategory`}>{pickByLanguage(aroundSpot.subCategory)}</span>
                  </div>

                  {pickByLanguage(aroundSpot.comment) && (
                    <p className={`comment custom-design-background`}>
                      <span css={lineClampStyle(2)} style={{ whiteSpace: 'pre-wrap' }}>{pickByLanguage(aroundSpot.comment)}</span>
                    </p>
                  )}
                </Link>
              </div>
            ))}
          </CarouselItemWrapper>

          <div className="space-inline">
            <Divider customCss={{ marginBottom: '2rem' }} />
          </div>
        </>
      )}

      <ThumbnailLinkBlock className="space-inline custom-design-block">
        {spotCategories.map(category => (
          <ThumbnailLink
            key={category.id}
            link={`/around-spot/${asset.id}/?category=${category.id}`}
            title={pickByLanguage(category.title)}
            imagePath={category.imagePath}
          />
        ))}
      </ThumbnailLinkBlock>
    </div>
  )
}

const aroundSpotBlockStyle = css({
  paddingBlock: '2rem',
  '.space-inline': {
    paddingInline: '1rem',
  },
  '.block-title': {
    marginBottom: '1rem',
    fontSize: 18,
  },
  '.popular': {
    marginTop: '1rem',
    fontSize: '18px',
  },
})

const cardStyle = css({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '6px',
  '.thumbnail': {
    width: '100%',
    height: '154px',
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
  },
  '.title-wrap': {
    paddingInline: '1rem',
    marginTop: '1rem',
    marginBottom: '0.75rem',
  },
  '.title': {
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '1.31',
  },
  '.subCategory': {
    fontSize: '12px',
  },
  '.comment': {
    marginTop: 'auto',
    padding: '1rem',
    lineHeight: '1.83',
    fontSize: '12px',
    borderBottomLeftRadius: '6px',
    borderBottomRightRadius: '6px',
  },
})
