import { http } from '@/apis/aipass'
import { env } from '@/libs/env'
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export interface SpaceSearchReservationParams {
  hotelId: string
  reservationTel?: string
  reservationId?: string
  reservationGivenName?: string
  reservationFamilyName?: string
  searchDate: string
}

export interface ReservationForSpace {
  checkinDate: string
  checkinTime: string
  checkoutDate: string
  checkoutTime: string
  guestName: string
  guestNameEn: string
  id: string
  nights: number
  paxTotal: number
  plan: string
  planEn: string
  reservationId: string
  userName: string
  userNameEn: string
  reservationRoom: {
    roomNum: number
    roomType: string
    typeName: string
  }[]
  roomNumber: string
}
interface SearchReservations {
  searchReservations: ReservationForSpace[]
}

const sessionKey = 'searchReservationsForSpace'
export const getSessionSearchReservations = (): ReservationForSpace[] => {
  const sessionSearchReservationsForSpace = sessionStorage.getItem(sessionKey)
  const searchReservationsForSpace = sessionSearchReservationsForSpace ? JSON.parse(sessionSearchReservationsForSpace) : []

  return searchReservationsForSpace
}

export const searchReservationForSpace = createAsyncThunk(
  'space/searchReservation',
  async (params: SpaceSearchReservationParams, { rejectWithValue }) => {
    try {
      const response = await http({
        method: 'get',
        url: `${env('BASE_API_V3')}/search-reservation-space`,
        params: params,
      })

      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const searchReservationSlice = createSlice({
  name: 'reservations',
  initialState: {
    searchReservations: getSessionSearchReservations(),
    reservationId: '' as string,
    loading: false,
  },
  reducers: {
    setReservationIds: (state, action: PayloadAction<string>) => {
      state.reservationId = action.payload
    },
    deleteSearchReservations: state => {
      state.searchReservations = []
      sessionStorage.removeItem(sessionKey)
    },
  },
  extraReducers(builder) {
    builder.addCase(searchReservationForSpace.fulfilled, (state, action: PayloadAction<SearchReservations>) => {
      state.searchReservations = action.payload.searchReservations
      sessionStorage.setItem(sessionKey, JSON.stringify(state.searchReservations))
      state.loading = false
    })
    builder.addCase(searchReservationForSpace.pending, state => {
      state.loading = true
    })
  },
})

export const { setReservationIds, deleteSearchReservations } = searchReservationSlice.actions
export const searchReservationSpaceReducer = searchReservationSlice.reducer
