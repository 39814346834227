import { css } from '@emotion/react'

export const labelStyle = css({
  display: 'flex',
  alignItems: 'center',
  fontSize: '14px',
  marginBottom: '0.75rem',
  fontWeight: 'bold',
  letterSpacing: '1.4px',
  span: {
    fontSize: '9px',
  },
})

export const multipleSelectBoxStyle = css({
  display: 'flex',
  gap: '16px',
  alignItems: 'baseline',
})

export const explanatoryNoteStyle = css({
  display: 'block',
  marginTop: '0.75rem',
  fontSize: '12px',
  lineHeight: '1.5'
})

export const formButtonStyle = css({
  paddingLeft: '2rem',
  paddingRight: '2rem',
})
