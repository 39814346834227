import React, { useMemo } from 'react'
import { Global, css } from '@emotion/react'
import { useAppSelector } from '@/app/hooks'

export const CUSTOM_BRAND_BG = 'custom-brand-background'
export const CUSTOM_BG = 'custom-design-background'
export const CUSTOM_BLOCK = 'custom-design-block'
export const CUSTOM_CARD = 'custom-design-card'
export const CUSTOM_MODAL = 'custom-design-modal'
export const CUSTOM_BUTTON = 'custom-design-button'
export const CUSTOM_FORM = 'custom-design-form'
export const CUSTOM_ICON = 'custom-design-icon'
export const CUSTOM_HL = 'custom-design-head-line'
export const CUSTOM_HL_AREA = 'custom-design-head-line-area'
export const CUSTOM_LINK = 'custom-design-link'
export const CUSTOM_BORDER = 'custom-design-border'
export const CUSTOM_ICON_BUTTON = 'custom-design-icon-button'
export const CUSTOM_TEXT_COLOR_LINK = 'custom-design-text-color-link'

export const CustomDesignStyle: React.FC = () => {
  const design = useAppSelector(state => state.design)

  const customDesign = useMemo(
    () =>
      css({
        '*': {
          fontFamily: design?.text.fontFamily,
          color: design?.text.textColor,
        },
        body: {
          backgroundColor: design?.background.backgroundColor,
        },
        [`.${CUSTOM_BRAND_BG}`]: {
          backgroundColor: design?.theme.brandColor,
        },
        [`.${CUSTOM_BG}, .ant-notification-notice`]: {
          backgroundColor: design?.background.backgroundColor,
        },
        [`.${CUSTOM_BLOCK}`]: {
          backgroundColor: design?.background.cardColor,
        },
        [`.${CUSTOM_CARD}`]: {
          backgroundColor: design?.background.cardColor,
          border: `1px solid ${design?.background.backgroundColor}`,
        },
        [`.${CUSTOM_MODAL}, .ReactModal__Content`]: {
          backgroundColor: `${design?.background.cardColor} !important`,
        },
        [`.${CUSTOM_BUTTON}`]: {
          backgroundColor: design?.button[0].backgroundColor,
          borderRadius: design?.button[0].borderRadius,
          border: 'none',
          color: design?.button[0].textColor,
          '&-secondary': {
            backgroundColor: design?.button[1].backgroundColor,
            borderRadius: design?.button[1].borderRadius,
            borderColor: design?.button[1].borderColor,
            color: design?.button[1].textColor,
            borderStyle: 'solid',
            borderWidth: 1,
          },
        },
        [`div.select-wrapper:has(>.${CUSTOM_FORM})`]: {
          '&:after': {
            borderTop: `6px solid ${design?.icon.color}`,
            borderLeft: '4px solid transparent',
            borderRight: '4px solid transparent',
            content: '""',
            position: 'absolute',
            right: '1.25rem',
            top: 0,
            bottom: 0,
            width: '8px',
            height: '6px',
            margin: 'auto',
          },
        },
        [`.${CUSTOM_FORM}`]: {
          '&:-webkit-autofill': {
            WebkitTextFillColor: design?.form.textColor,
            WebkitBoxShadow: `0 0 0 30px ${design?.form.backgroundColor} inset !important`,
          },
          // hack autocomplete
          backgroundColor: design?.form.backgroundColor,
          border: `1px solid ${design?.form.borderColor}`,
          borderRadius: design?.form.borderRadius,
          color: design?.form.textColor,
          '::placeholder': {
            color: '#CCCCCC',
          },
          '&[type="radio"]': {
            '&:before': {
              backgroundColor: design?.form.backgroundColor,
              border: `1px solid ${design?.form.borderColor}`,
            },
            '&:after': {
              background: design?.icon.color,
            },
          },
          '&[type="checkbox"]': {
            '&:before': {
              border: `2px solid ${design?.form.borderColor}`,
            },
            '&:checked:before': {
              background: design?.icon.color,
            },
          },
          '&.selected': {
            color: design?.form.backgroundColor,
            backgroundColor: design?.form.textColor,
          },
        },
        [`.${CUSTOM_ICON}`]: {
          '& :not([fill="none"])': {
            fill: design?.icon.color,
          },
          '& :not([stroke="none"]) [stroke]': {
            stroke: design?.icon.color,
          },
        },
        [`.${CUSTOM_ICON}-secondary`]: {
          '& :not([fill="none"])': {
            fill: design?.form.backgroundColor,
          },
          '& :not([stroke="none"]) [stroke]': {
            stroke: design?.form.backgroundColor,
          },
        },
        [`.${CUSTOM_HL}, h1, h2, .ant-collapse-header-text`]: {
          color: design?.text.headlineColor,
        },
        [`.${CUSTOM_HL_AREA}`]: {
          background: design?.background.cardColor,
          color: design?.text.headlineColor,
        },
        [`.${CUSTOM_LINK}, a, a *`]: {
          color: design?.text.linkColor,
        },
        [`.${CUSTOM_TEXT_COLOR_LINK}, .${CUSTOM_TEXT_COLOR_LINK} *`]: {
          color: design.text.textColor,
        },
        [`.${CUSTOM_BORDER}`]: {
          border: `1px solid ${design?.background.backgroundColor}`,
        },
        '.ant-notification-notice-content': {
          borderRight: `1px solid ${design?.form.borderColor}`,
        },
        [`.${CUSTOM_ICON_BUTTON}`]: {
          background: design?.background.backgroundColor,
        },
      }),
    [design],
  )
  const customDesignForDatePicker = useMemo(
    () =>
      css({
        '.react-datepicker__day--keyboard-selected': {
          backgroundColor: design?.theme.brandColor,
          color: `${design?.button[0].textColor}!important`,
        },
        '.react-datepicker__day--selected': {
          backgroundColor: design?.theme.brandColor,
          color: `${design?.button[0].textColor}!important`,
          ':hover': {
            backgroundColor: design?.background.backgroundColor,
            color: 'inherit !important',
          },
        },
        '.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header, .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name ':
          {
            color: design?.form.textColor,
          },
        '.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled':
          {
            color: design?.form.borderColor,
          },
      }),
    [design],
  )

  return <Global styles={[customDesign, customDesignForDatePicker]} />
}
