import React from 'react'
import { spacerTopStyle } from '@/styles/common'
import { labelStyle } from '@/styles/form'
import { css } from '@emotion/react'
import { Input } from '../atoms/input'
import { useTranslation } from 'react-i18next'
import { FieldValues, UseControllerProps, useController } from 'react-hook-form'
import { BorderButton } from '../atoms/button'
import { useZipCode } from '@/hooks/use-zip-code'
import { ErrorMessage } from '../atoms/error-message'

interface RHFSearchAddressProps {
  setAddressValue: (addressText: string) => void
}

type RHFSearchAddressComponentProps<T extends FieldValues> = UseControllerProps<T> & RHFSearchAddressProps

export const RHFSearchAddress = <T extends FieldValues>(props: RHFSearchAddressComponentProps<T>) => {
  const { t } = useTranslation()

  const { name, control } = props
  const { field, fieldState } = useController<T>({ name, control })

  const { getAddressInfo, loading } = useZipCode()

  const getAddress = async () => {
    if (!field.value) return

    const zipCodeResult = await getAddressInfo(field.value)

    if (zipCodeResult) {
      props.setAddressValue(`${zipCodeResult.address1}${zipCodeResult.address2}${zipCodeResult.address3}`)
    }
  }

  return (
    <div css={spacerTopStyle}>
      <label css={labelStyle}>{t('post code')}</label>
      <div css={zipCodeStyle}>
        <Input {...field} value={field.value || ''} type="number" placeholder="1234567" />
        <BorderButton disabled={!field.value} text={t('Search Address')} onClick={() => getAddress()} loading={loading} />
      </div>
      {fieldState.error?.message && <ErrorMessage text={fieldState.error?.message} />}
    </div>
  )
}

const zipCodeStyle = css({
  display: 'flex',
  alignItems: 'start',
  justifyContent: 'space-between',
  div: {
    '&:first-of-type': {
      width: '60%',
    },
    '&:last-of-type': {
      width: '35%',
      button: {
        height: '44px',
      },
    },
  },
})
