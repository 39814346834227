import { combineReducers } from '@reduxjs/toolkit'
import { getUserPaymentReducer } from './get-user-payment-slice'
import { getInvoiceDetailsReducer } from './get-user-invoice-slice'
import { currentPaymentReducer } from './current-payment-slice'

export const paymentReducer = combineReducers({
  getUserPayment: getUserPaymentReducer,
  getInvoiceDetails: getInvoiceDetailsReducer,
  currentPayment: currentPaymentReducer,
})
