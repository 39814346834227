import React from 'react'
import { RequestItem } from '@/features/request/request-cart-slice'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Button } from '@/components/atoms/button'
import { useTranslation } from 'react-i18next'
import { formButtonStyle, labelStyle, multipleSelectBoxStyle } from '@/styles/form'
import { css } from '@emotion/react'
import { Input } from '@/components/atoms/input'
import { FormLayout } from '@/components/template/form-layout'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { errorHandler } from '@/libs/errors'
import { useQuerySearchParams } from '@/hooks/use-query-search-params'
import { getGuestRoomByTelephone } from '@/features/request/check-in-room-number-slice'
import { useNavigate } from 'react-router-dom'
import { RequestCartBlock } from './request-cart-block'
import { RequiredMark } from '@/components/atoms/required-mark'
import { pcScreenStyle, spacerTopStyle } from '@/styles/common'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { ordersInfoForNoHasReservationPlugin } from '@/features/request/request-order-slice'
import { isEmpty } from 'lodash'

interface RequestConfirmationFormProps {
  requestCarts: RequestItem[]
  assetId: string
}

interface RequestConfirmationInput {
  telephone: string
  firstName?: string
  lastName?: string
  roomNumber?: string
}

const hasPluginSchema = z.object({
  telephone: z.string().min(1),
})

const noHasPluginSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  roomNumber: z.string().min(1),
})

export const RequestConfirmationForm: React.FC<RequestConfirmationFormProps> = ({ requestCarts, assetId }) => {
  const { t } = useTranslation()

  const { hotelId } = useQuerySearchParams<{ hotelId: string }>()

  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const { hasReservationPlugins } = useAppSelector(state => state.hotelGuide)

  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<RequestConfirmationInput>({
    mode: 'all',
    resolver: zodResolver(hasReservationPlugins ? hasPluginSchema : noHasPluginSchema),
  })

  const onSubmit: SubmitHandler<RequestConfirmationInput> = async submitData => {
    if (!hasReservationPlugins) {
      dispatch(
        ordersInfoForNoHasReservationPlugin({
          firstName: submitData.firstName || '',
          lastName: submitData.lastName || '',
          roomNumber: submitData.roomNumber || '',
        }),
      )

      return navigate(`/request/${assetId}/order-confirmation`)
    }

    try {
      const result = await dispatch(getGuestRoomByTelephone({ hotelId, telephone: submitData.telephone })).unwrap()

      if (isEmpty(result)) {
        return errorHandler({ error: t('There is no room information. Check it again please.') })
      }

      navigate(`/request/${assetId}/order-confirmation`)
    } catch (error) {
      errorHandler({ error })
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} css={[formStyle, pcScreenStyle]}>
      <RequestCartBlock requestCarts={requestCarts} assetId={assetId} />

      <FormLayout layoutCss={css({ marginTop: '14px' })}>
        {hasReservationPlugins ? (
          <>
            <p className="room-confirm">{t('Your room')}</p>
            <p className="pre-text">{t('Please enter the phone number of the pre-checkin or reservation.')}</p>
            <Input placeholder="12345678" {...register('telephone')} />
          </>
        ) : (
          <>
            <label css={labelStyle}>
              {t('Room Representative Name')}
              <RequiredMark />
            </label>
            <div css={multipleSelectBoxStyle}>
              <Input placeholder="山田" {...register('lastName')} error={errors.lastName?.message} />
              <Input placeholder="太郎" {...register('firstName')} error={errors.firstName?.message} />
            </div>
            <div css={spacerTopStyle}>
              <Input required label={t('room name')} {...register('roomNumber')} placeholder="301" error={errors.roomNumber?.message} />
            </div>
          </>
        )}
      </FormLayout>

      <div css={[formButtonStyle, { marginTop: '2rem' }]}>
        <Button disabled={!isValid} text={t('Next')} type="submit" />
      </div>
    </form>
  )
}

const formStyle = css({
  '.room-confirm': {
    fontWeight: 'bold',
  },
  '.pre-text': {
    fontSize: 12,
    marginTop: '0.75rem',
    marginBottom: '1rem',
  },
})
