import { useAppSelector } from '@/app/hooks'
import { CSSObject } from '@emotion/react'
import React from 'react'
import Modal from 'react-modal'

interface modalWrapperProps {
  isOpen: boolean
  children: React.ReactNode
  onClose?: () => void
  modalStyle?: CSSObject
  shouldCloseOnOverlayClick?: boolean
}

export const ModalWrapper: React.FC<modalWrapperProps> = ({ isOpen, children, onClose, modalStyle, shouldCloseOnOverlayClick = false }) => {
  Modal.setAppElement('#root')
  const design = useAppSelector(state => state.design)

  return (
    <Modal
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      onRequestClose={shouldCloseOnOverlayClick ? onClose : undefined}
      onClose={onClose}
      style={{ overlay: { zIndex: 400, backgroundColor: 'rgba(0, 0, 0, 0.5)' }, content: { border: 'none', padding: '40px', inset: '1rem', top: 0, left: 0, margin: 'auto', ...modalStyle, backgroundColor: design?.background.cardColor, } }}
      isOpen={isOpen}
    >
      {children}
    </Modal>
  )
}
