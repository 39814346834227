import { t } from 'i18next'
import { isEmpty } from 'lodash'
import { z } from 'zod'

/**
 * zodエラーメッセージ
 */
export const zodCustomErrorMap: z.ZodErrorMap = (issue, ctx) => {
  switch (issue.code) {
    case z.ZodIssueCode.invalid_type:
      if (issue.received === z.ZodParsedType.undefined || issue.received === z.ZodParsedType.null) {
        return { message: t('This field is required') }
      } else {
        return { message: t('unknown error') }
      }

    case z.ZodIssueCode.invalid_string:
      if (issue.validation === 'email') {
        return { message: t('Please enter a valid email address') }
      }

      if (issue.path[0] === 'familyNameKana' || issue.path[0] === 'givenNameKana') {
        return { message: t('Please input katakana only') }
      }

      return { message: issue.message || t('unknown error') }

    case z.ZodIssueCode.too_big:
      return { message: t('The value must be greater than or equal {{count}} characters.', { count: issue.maximum as number }) }

    case z.ZodIssueCode.too_small:
      if (issue.type === 'array') {
        return { message: `${issue.minimum}つ以上チェックしてください` }
      }

      if (issue.type === 'string') {
        return { message: t('This field is required') }
      }

      return { message: t('The value must be less than or equal {{count}} characters.', { count: issue.minimum as number }) }

    case z.ZodIssueCode.invalid_union_discriminator:
      return { message: t('This field is required') }
  }

  return { message: ctx.defaultError }
}

export const MAX_PASSWORD_LENGTH = 8

export const PASSWORD_NUMBER_REGEX = /(?=.*[0-9])/
export const PASSWORD_LOWER_CASE_REGEX = /(?=.*[a-z])/
export const PASSWORD_UPPER_CASE_REGEX = /(?=.*[A-Z])/

export const passwordRefine = z.string().superRefine((value, ctx) => {
  const result = []

  if (!PASSWORD_UPPER_CASE_REGEX.test(value)) {
    result.push(t('upper-case alphabet'))
  }

  if (!PASSWORD_LOWER_CASE_REGEX.test(value)) {
    result.push(t('lower-case alphabet'))
  }

  if (!PASSWORD_NUMBER_REGEX.test(value)) {
    result.push(t('number'))
  }

  if (isEmpty(result)) {
    if (value.length >= MAX_PASSWORD_LENGTH) {
      return
    }

    ctx.addIssue({
      code: z.ZodIssueCode.too_small,
      message: t('Please enter at least 8 characters'),
      minimum: 8,
      inclusive: true,
      type: 'string',
    })

    return
  }

  if (value.length >= MAX_PASSWORD_LENGTH) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: t('Please include {{textType}}', { textType: result.join('・') }),
    })
    return
  }

  ctx.addIssue({
    code: z.ZodIssueCode.custom,
    message: t('Please enter at least 8 characters include {{textType}}', { textType: result.join('・') }),
  })
})

export const inputKatakana = () =>
  z
    .string()
    .min(1, { message: t('This field is required') })
    .regex(/^[ァ-ンヴーｧ-ﾝﾞﾟ-]*$/, { message: t('Please input katakana only') })

export const inputNumberWithMax = (maxNumber: number) =>
  z
    .string()
    .min(1, { message: t('This field is required') })
    .max(maxNumber)
    .regex(/^[0-9]+$/, {
      message: t('Please enter only alphanumeric'),
    })
