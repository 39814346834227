import { PulldownAssetType } from '@/features/asset/assets-slice'
import React, { useEffect, useState } from 'react'
import { Collapse } from 'antd'
import { css } from '@emotion/react'
import { HtmlContent } from '@/components/atoms/html-content'
import { usePickByLanguage } from '@/hooks/use-pick-by-language'
import { ReactComponent as IconPulldown } from '@/static/images/icon/icon_pulldown.svg'
import { useSmoothScroll } from '@/hooks/use-smooth-scroll'
import { useSearchParams } from 'react-router-dom'
import { CUSTOM_BLOCK, CUSTOM_ICON } from '@/components/template/custom-design-style'

type Props = {
  asset: PulldownAssetType
}

export const PulldownAssetBlock: React.FC<Props> = ({ asset }) => {
  const pickByLanguage = usePickByLanguage()
  const { smoothScroll } = useSmoothScroll()
  const [collapseActiveKey, setCollapseActiveKey] = useState<string[] | string>([])
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (searchParams.get('hash') !== asset.id) {
      return
    }
    setCollapseActiveKey(['1'])
    smoothScroll(asset.id)
  }, [searchParams])

  return (
    <div id={asset.id} className={CUSTOM_BLOCK}>
      <Collapse
        activeKey={collapseActiveKey}
        onChange={key => setCollapseActiveKey(key)}
        css={[attachDesignStyle, expandIconTransformStyle]}
        expandIcon={() => <IconPulldown className={CUSTOM_ICON} />}
        expandIconPosition="end"
        items={[
          {
            key: '1',
            label: pickByLanguage(asset.title),
            children: <HtmlContent text={pickByLanguage(asset.details[0].description)} />,
          },
        ]}
      />
    </div>
  )
}

const attachDesignStyle = css({
  border: 'none',
  borderRadius: 'unset',
  backgroundColor: 'revert',
  '.ant-collapse-item': {
    border: 'none',
    '.ant-collapse-header': {
      padding: 16,
      display: 'flex',
      alignItems: 'center',
      '.ant-collapse-header-text': {
        fontSize: 18,
      },
    },
    '.ant-collapse-content': {
      border: 'none',
      color: 'revert',
      backgroundColor: 'revert',
    },
  },
})

const expandIconTransformStyle = css({
  '.ant-collapse-expand-icon svg': {
    transformOrigin: '50% 50%',
    transition: '0.3s',
  },
  '.ant-collapse-item-active .ant-collapse-expand-icon svg': {
    transform: 'rotate(180deg)',
  },
})
