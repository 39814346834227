import React, { createContext } from 'react'

export const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
  const setAuth = data => {
    if (data?.access_token) {
      localStorage.setItem('access_token', data.access_token)
    } else {
      localStorage.removeItem('access_token')
    }
  }

  return (
    <AuthContext.Provider
      value={{
        auth: localStorage.getItem('access_token'),
        setAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
