import { CouponAssetDetailType, CouponAssetType } from '@/features/asset/assets-slice'
import React, { useEffect, useState } from 'react'
import { css } from '@emotion/react'
import { usePickByLanguage } from '@/hooks/use-pick-by-language'
import { CUSTOM_BLOCK, CUSTOM_LINK } from '@/components/template/custom-design-style'
import { useTranslation } from 'react-i18next'
import { PullDownIcon } from '@/components/atoms/icon'
import { CouponTicket } from './coupon-ticket'
import { useSearchParams } from 'react-router-dom'
import { useSmoothScroll } from '@/hooks/use-smooth-scroll'

type Props = {
  asset: CouponAssetType
}

export const CouponAssetBlock: React.FC<Props> = ({ asset }) => {
  const pickByLanguage = usePickByLanguage()
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const { smoothScroll } = useSmoothScroll()
  const [isShowAllDetail, setIsShowAllDetail] = useState<boolean>(false)
  const defaultViewCount = 5

  const hasMoreDetails = (): boolean => asset.details.length > defaultViewCount
  const assetDetailsOnDisplay = (): CouponAssetDetailType[] => {
    return isShowAllDetail ? asset.details : asset.details.slice(0, defaultViewCount)
  }

  useEffect(() => {
    if (searchParams.get('hash') !== asset.id) {
      return
    }
    setIsShowAllDetail(true)
    smoothScroll(asset.id)
  }, [searchParams])

  return (
    <div id={asset.id} css={blockStyle} className={CUSTOM_BLOCK}>
      <h2 className="header-text">{pickByLanguage(asset.title)}</h2>
      {assetDetailsOnDisplay().map(detail => {
        return <CouponTicket key={detail.id} assetId={asset.id} coupon={detail} />
      })}
      {hasMoreDetails() && !isShowAllDetail && (
        <div className="show-all-link" onClick={() => setIsShowAllDetail(true)}>
          <div className={CUSTOM_LINK} style={{ marginRight: 4 }}>
            {t('Show all')}
          </div>
          <PullDownIcon height="20" width="20" />
        </div>
      )}
    </div>
  )
}

const blockStyle = css({
  padding: '32px 16px',
  '.header-text': {
    fontSize: 20,
    paddingBottom: 16,
  },
  '.show-all-link': {
    paddingTop: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    borderBottom: 'none',
    borderRight: 'none',
    borderLeft: 'none',
    '> img': {
      paddingLeft: 4,
      width: 20,
      height: 20,
    },
  },
})
