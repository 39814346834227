import { css } from '@emotion/react'
import React from 'react'

export const JaContent: React.FC = () => {
  return (
    <div css={layoutStyle}>
      <div css={administratorStyle}>
        <h2 css={pageTitleStyle}>aipassサービス利用規約｜施設利用者</h2>

        <p css={updateStyle}>最終更新日：2022年4月15日</p>
      </div>

      <h3 css={semiTitleStyle}>第１章 総則</h3>

      <h3 css={semiTitleStyle}>第１条（適用）</h3>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          本aipass利用規約（以下「本規約」といいます。）は、aipass株式会社（以下「当社」といいます。）が提供するaipass(アイパス)という名称のサービス（以下「本サービス」といいます。）の利用に関し、当社と利用者の間に適用されます。
        </li>
        <li css={listItemStyle}>
          当社は本サービスに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下，「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。
          本規約の規定が前条の個別規定の規定と矛盾する場合には，個別規定において特段の定めなき限り，個別規定の規定が優先されるものとします。
        </li>
      </ol>

      <h3 css={semiTitleStyle}>第２条（定義）</h3>
      <p css={leadTextStyle}>本規約において、次の各号に掲げる用語の意味は、当該各号に定めるとおりとします。</p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          会員
          <br />
          本規約に同意の上、登録情報を登録した上で、当社と本サービスの利用に関する契約（以下「本利用契約」といいます。）を締結した個人をいいます。
        </li>
        <li css={listItemStyle}>
          ユーザー
          <br />
          本規約に同意の上、登録情報を登録することなく、当社と本利用契約を締結して本サービスへのアクセスおよび使用をする者をいいます。
        </li>
        <li css={listItemStyle}>利用者<br />会員およびユーザーをいいます。</li>
        <li css={listItemStyle}>
          本サイト
          <br />
          当社が本サービスを提供するために運営するウェブサイトをいいます。本サービスは、本サイトまたは次号に定義する本ソフトウェアを介して利用者に提供されます。
        </li>
        <li css={listItemStyle}>
          本ソフトウェア
          <br />
          当社が本サービスを提供するために作成・公開するアプリケーション・ソフトウェアをいい、アップデート版、修正版、代替品および複製物を含みます。
        </li>
        <li css={listItemStyle}>
          登録情報
          <br />
          本サービスの会員になるために、登録することが求められる、当社が定める会員に関する情報をいいます。
        </li>
        <li css={listItemStyle}>
          会員情報
          <br />
          本サービスのために当社が管理するサーバーに保存された各種情報や通信記録その他の会員の一切の情報をいい、登録情報、本サービスを通じて当社が提供しまたは会員が取得した情報を含みます。
        </li>
      </ol>

      <h3 css={semiTitleStyle}>第３条 （規約の変更）</h3>
      <p css={leadTextStyle}>当社は、いつでも、利用者の事前の承諾を得ることなく、本規約の内容を変更することができます。</p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          当社が本規約の内容を変更し、その変更内容をaipass利用規約の定めに従い利用者に通知した場合、通知において指定された期日以降（ただし、通知において期日を指定しない場合には、当社が通知を発した当日を期日とします。）に、本サービスを利用した場合、変更後の本規約に同意したものとみなし、変更後の本規約が適用されます。会員が通知において指定された期日内に解約の手続きをとらなかった場合には、変更後の本規約に同意したものとみなされます。
        </li>
      </ol>

      <h3 css={semiTitleStyle}>第４条 （通知）</h3>
      <p css={leadTextStyle}>
        当社は、本サービスに関連して利用者に通知をする場合には、本サイトに掲示する方法または登録情報として登録された電子メールアドレス・住所に宛てて電子メール・文書を送信する方法など、当社が適当と判断する方法で実施します。
      </p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          前項に定める方法により行われた通知は、前者の場合には通知内容が本サイトに掲示された時点に、後者の場合は当社が電子メール・文書を発信した時点で、それぞれその効力を生じるものとします。
        </li>
        <li css={listItemStyle}>
          本サービスに関する問い合わせその他利用者から当社に対する連絡または通知は、当社の定める方法で行うものとします。
        </li>
      </ol>

      <h3 css={semiTitleStyle}>第２章 契約の成立</h3>
      <h3 css={semiTitleStyle}>第５条 （本利用契約の成立（入会））</h3>
      <p css={leadTextStyle}>
        本利用契約は、本サービスの利用を希望する者が、当社が指定する方法で申込みを行い、これを当社が受諾した時点で成立するものとします。本サービスの利用を希望する者が当社が指定する方法で申込みを行った時点で、当該申込者は本規約に同意したものとみなされます。
      </p>
      <ol css={listStyle}>
        <li css={listItemStyle}>会員は、当社が前項の申込みを受諾した日から本サービスを利用できます。</li>
        <li css={listItemStyle}>
          未成年者が本サービスの利用を希望する場合には、法定代理人の同意が必要になります。未成年者が会員となった場合には、本サービスの利用および本規約の内容について、法定代理人の同意があったものとみなします。
        </li>
      </ol>

      <h3 css={semiTitleStyle}>第6条 （利用料金および支払方法）</h3>
      <p css={leadTextStyle}>
        本サービスを利用するために必要となる通信費（本ソフトウェアのダウンロードおよび利用のために発生する通信費を含みます。）、および通信機器等は、利用者の負担と責任により準備するものとします。当社は、利用者の使用する通信機器等において、本サイトおよび本ソフトウェアが正常に動作することを保証するものではありません。
      </p>

      <h3 css={semiTitleStyle}>第3章 サービス利用上の注意事項</h3>
      <h3 css={semiTitleStyle}>第７条 （会員ID等）</h3>
      <p css={leadTextStyle}>
        会員は、本サービスの申込みの際に指定した会員ID（メールアドレス）および会員パスワード（以下「会員ID等」といいます。）を自己の責任において厳重に管理するものとし、これらを用いてなされた一切の行為についてその責任を負います。
      </p>
      <ol css={listStyle}>
        <li css={listItemStyle}>会員は、会員自身以外の者に本サービスを利用させてはいけません。</li>
        <li css={listItemStyle}>
          会員は、会員パスワード又は会員IDが盗まれたり、第三者に使用されていることが判明した場合には、直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。
        </li>
      </ol>

      <h3 css={semiTitleStyle}>第８条 （登録情報の変更）</h3>
      <p css={leadTextStyle}>
        会員は、登録情報に変更が生じた場合には、当社が指定する方法により速やかに届出を行います。当社は、登録情報の変更の届出がなされなかったことにより会員に生じた損害について一切の責任を負いません。
      </p>

      <h3 css={semiTitleStyle}>第９条 （禁止行為）</h3>
      <p css={leadTextStyle}>利用者は、本サービスに関連して次の各号に定める行為を行ってはいけません。</p>
      <ol css={listStyle}>
        <li css={listItemStyle}>当社に対して虚偽の申告をする行為</li>
        <li css={listItemStyle}>
          本利用契約に基づき当社から提供された本サイトおよび本ソフトウェアを含む情報および役務を本サービスの利用以外の目的のために使用する行為
        </li>
        <li css={listItemStyle}>
          当社もしくは第三者の財産（知的財産権を含みます。）、プライバシー、名誉、信用、肖像またはパブリシティーに係る権利、その他の権利・利益を侵害する行為または侵害するおそれのある行為
        </li>
        <li css={listItemStyle}>前号以外で当社もしくは第三者の権利・利益を侵害する行為または侵害するおそれのある行為</li>
        <li css={listItemStyle}>法令に違反し、もしくは公序良俗に反する行為またはそのおそれのある行為</li>
        <li css={listItemStyle}>第三者の会員ID等を不正に使用または取得する行為その他会員が、第三者を装って本サービスを利用する行為</li>
        <li css={listItemStyle}>コンピュータウィルスなどの有害なプログラムを使用し、もしくは送信する行為、またはそのおそれのある行為</li>
        <li css={listItemStyle}>
          前号に定めるものの他、不正アクセス行為等当社による業務の遂行、本サービスの実施もしくは当社の通信設備等に支障を及ぼし、またはそのおそれがあると当社が判断する行為
        </li>
        <li css={listItemStyle}>その他、当社が不適切と判断する行為</li>
      </ol>

      <h3 css={semiTitleStyle}>第１０条 （施設へのチェックイン及び同宿泊施設約款の同意）</h3>
      <p css={leadTextStyle}>
        本サービスにより、当該宿泊施設のチェックインを行なった場合、利用者は当該宿泊施設の申込をおこない、同施設の約款に同意したものとみなします。
      </p>

      <h3 css={semiTitleStyle}>第４章 解約・解除・退会</h3>
      <h3 css={semiTitleStyle}>第１１条 （会員による本サービスの解約）</h3>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          会員は、本利用契約を解約する場合、当社所定の方法により解約手続きを行うこととし、当該解約手続きの完了をもって、当該サービスの利用契約が解約されるものとします。この場合、会員は自己の責任において、当社からの解約に関する通知を確認するものとします。個別サービスごとに当該サービスの利用を終了する場合は、個別サービスごとに定める終了手続きを行うものとします。
        </li>
        <li css={listItemStyle}>会員が第１項により本利用契約を解約した場合、当社は会員情報を消去することができます。</li>
      </ol>

      <h3 css={semiTitleStyle}>第１２条 （当社による契約解除）</h3>
      <p css={leadTextStyle}>
        当社は、利用者が次の各号の一つに該当した場合には、利用者に対して何らの通知催告をすることなく、本利用契約の一部または全部を解除して利用者に対する退会処分を行い、または本サービスの提供を停止することができます。
      </p>
      <ol css={listStyle}>
        <li css={listItemStyle}>本規約のいずれかの条項に違反する行為を行った場合</li>
        <li css={listItemStyle}>当社に提供された登録情報の全部または一部につき虚偽、誤記または記載漏れがあった場合</li>
        <li css={listItemStyle}>
          反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、暴力団準構成 員、総会屋、社会運動等標榜ゴロ、特殊知能暴力集団
          <ol css={innerListStyle}>
            <li>その他これに準ずる者を意味します。以下同じ。）である、資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協</li>
            <li>力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っている、または反社会的勢力等が経営に実質的に関与し</li>
            <li>ている法人等であると当社が合理的に判断した場合</li>
          </ol>
        </li>
        <li css={listItemStyle}>料金などの支払債務の不履行があった場合</li>
        <li css={listItemStyle}>当社からの連絡に対し、90日以上返答がない場合</li>
        <li css={listItemStyle}>過去に本サービスについて退会処分を受けたことが判明した場合</li>
        <li css={listItemStyle}>その他、当社が利用者として不適当であると合理的に判断した場合</li>
      </ol>
      <p css={leadTextStyle}>
        前項に基づき退会処分を行い、または本サービスの提供を停止した場合でも、当社は、既に受領した利用料金の返金は行わず、また、利用者および第三者に生じた損害および不利益につき一切の責任を負いません。
      </p>

      <h3 css={semiTitleStyle}>第１３条 （暴力団等の反社会的勢力の排除）</h3>
      <p css={leadTextStyle}>
        利用者は、本サービスの利用に際して、自身が暴力団、暴力団員、暴力団関係企業、総会屋、社会運動標ぼうゴロ、政治運動標ぼうゴロ、特殊知能暴力集団、その他反社会的勢力（以下｢暴力団等反社会的勢力｣という。）に所属または該当せず、かつ、暴力団等反社会的勢力と関与していないことを表明し、将来にわたっても所属もしくは該当、または関与しないことを確約するものとします。
      </p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          当社は、利用者が暴力団等反社会的勢力に所属もしくは該当する、または関与していると判断した場合、事前に通知等を行うことなく、本サービスの使用停止の措置、解約の措置を講じることがあります。
        </li>
        <li css={listItemStyle}>
          当社は本条に基づく利用者の違反による使用停止ならびに解約によって生じた損害について一切の義務および責任を負わないものとします。
        </li>
      </ol>

      <h3 css={semiTitleStyle}>第５章 サービスの停止、変更および終了</h3>
      <h3 css={semiTitleStyle}>第１４条 （サービスの停止）</h3>
      <p css={leadTextStyle}>
        当社は、次の各号のいずれかの事由が生じた場合には、事前に利用者に通知をすることなく、本サービスの一部または全部を停止することができます。
      </p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          本サービス提供にあたり必要なシステム、設備等に障害が発生し、またはメンテナンス、保守もしくは工事等が必要となった場合
        </li>
        <li css={listItemStyle}>
          電気通信事業者が電気通信サービスの提供を中止するなど、当社以外の第三者の行為に起因して、本サービスの提供を行うことが困難になった場合
        </li>
        <li css={listItemStyle}>
          非常事態（天災、戦争、テロ、暴動、騒乱、官の処分、労働争議等）の発生により、本サービスの提供が困難になった場合、または困難になる可能性のある場合
        </li>
        <li css={listItemStyle}>同期可能サービスの事情により、同期可能サービスが利用できなくなった場合</li>
        <li css={listItemStyle}>法令規制、行政命令等により、本サービスの提供が困難になった場合</li>
        <li css={listItemStyle}>
          その他、当社の責めに帰することができない事由により、当社が必要やむを得ないと判断した場合
          当社は、前項に基づいて本サービスを停止したことにより利用者および第三者に生じた損害および不利益につき一切の責任を負いません。
        </li>
      </ol>

      <h3 css={semiTitleStyle}>第１５条 （サービスの変更）</h3>
      <p css={leadTextStyle}>
        当社は、当社の裁量により本サービスの一部の内容を追加または変更することができます。当社は、本条に基づく本サービスの追加または変更により、変更前の本サービスのすべての機能・性能が維持されることを保証するものではありません。
        当社は、前項に基づいて本サービスを追加または変更したことにより利用者に生じた損害および不利益につき一切の責任を負いません。
      </p>

      <h3 css={semiTitleStyle}>第１６条 （サービスの中止および終了）</h3>
      <p css={leadTextStyle}>
        当社は、事前に会員に通知をしたうえで、当社の裁量により本サービスの一部もしくは全部の提供を中止または終了することができます。但し、中止または終了の内容が重大でない場合には、通知をすることなくこれらを実施することができます。
        当社は、前項に基づいて本サービスを中止または終了したことにより利用者に損害が発生した場合でも、一切の責任を負いません。
      </p>

      <h3 css={semiTitleStyle}>第１７条 （契約上の地位の譲渡等）</h3>
      <p css={leadTextStyle}>
        利用者は、当社の事前の承諾なく本利用契約上の地位または本利用契約に基づく権利もしくは義務について、第三者に対し、譲渡、移転、担保設定その他の処分をすることはできません。
        当社は、本サービスにかかる事業を事業譲渡又は会社分割その他の方法（以下「事業譲渡等」といいます）を用いて第三者に譲渡する場合には、当該事業譲渡等に伴って本利用契約上の地位、本利用契約に基づく権利及び義務ならびに利用者に関する情報を当該事業譲渡等の譲受人に譲渡及び移転することができるものとし、利用者は、かかる譲渡及び移転についてあらかじめ同意したものとします。
      </p>

      <h3 css={semiTitleStyle}>第６章 一般条項</h3>
      <h3 css={semiTitleStyle}>第１８条 （保証）</h3>
      <p css={leadTextStyle}>
        当社は、本サービスが推奨環境において機能するように合理的な最大限の努力を行います。但し、当社は、利用者情報が正確性、正当性、有用性、完全性等を有することを保証するものではありません。利用者は、利用者情報について、自らの判断および責任において必要に応じ変更、修正等を行ったうえで利用するものとします。
      </p>

      <h3 css={semiTitleStyle}>第１９条 （知的財産権）</h3>
      <p css={leadTextStyle}>
        本サービスに関する著作権、著作者人格権、特許権、実用新案権、意匠権、商標権およびパブリシティ権等の一切の知的財産権は当社および正当な権利者たる第三者に帰属し、本利用契約の成立は、本サービスの利用に必要な範囲を超える知的財産権の利用許諾を意味するものではありません。
      </p>

      <h3 css={semiTitleStyle}>第２０条 （損害賠償及び免責）</h3>
      <p css={leadTextStyle}>
        当社は、本サービスに関して利用者に生じた損害について、当社に過失が認められる場合には、当該損害の直接の原因となったサービスについて、金1,000円を上限としてその損害を賠償するものとし、それ以外の損害については一切の責任を負いません。
      </p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          利用者は、本規約に違反することにより、または本サービスの利用に関して当社に損害を与えた場合、当社に対し当社に生じた損害を賠償しなければなりません。
        </li>
        <li css={listItemStyle}>
          本サービスに関して利用者と第三者との間に紛争が生じた場合、利用者は自己の責任と費用で解決するものとし、当社に何ら迷惑をかけず、またこれにより当社が被った損害（弁護士費用を含みます。）を補償します。
        </li>
      </ol>

      <h3 css={semiTitleStyle}>第２１条 （委託）</h3>
      <p css={leadTextStyle}>当社は、本サービスに関する業務の一部または全部を第三者に委託することができるものとします。</p>

      <h3 css={semiTitleStyle}>第２２条 （情報管理）</h3>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          当社は、会員情報について、会員の事前の同意を得ずに第三者に開示しません。但し、次の各号の場合はこの限りではありません。
          <ol css={innerListStyle}>
            <li>法令または公的機関からの要請を受け、要請に応じる必要を認めた場合</li>
            <li>人の生命、身体または財産の保護のために必要があり、かつ会員の同意を得ることが困難である場合</li>
            <li>
              会員に利用料金を請求する目的で、決済システム会社、クレジット会社、銀行等に本サービスを利用している登録ユーザーの情報を預託する場合
            </li>
            <li>本サービス提供または機能向上もしくは改善のために必要な受託者、または代理人等に情報を開示する場合</li>
            <li>当社および当社子会社間で連携したサービスを提供するために共同利用する場合</li>
          </ol>
        </li>
        <li css={listItemStyle}>
          前項にかかわらず、当社は、利用者情報の属性集計・分析を行い、会員が識別・特定できないように加工したもの（以下「統計資料」といいます。）を作成し、本サービスおよび当社のその他のサービスのために利用することがあります。また、統計資料を第三者に開示することがあります。
        </li>
        <li css={listItemStyle}>当社は、会員情報の紛失、破壊、改鼠、漏洩等の危険に対して、合理的な最大限の安全対策を講じます。</li>
        <li css={listItemStyle}>
          当社は、電話応対品質向上等のため、利用者との間の電話応対を録音し、録音内容を業務において使用することができるものとします。
        </li>
      </ol>

      <h3 css={semiTitleStyle}>第２３条 （個人情報）</h3>
      <p css={leadTextStyle}>
        前条の規定にかかわらず、当社の個人情報の取り扱いについてはプライバシーポリシーに定めるとおりとします。
        当社は、本利用契約の終了後も、プライバシーポリシー記載の利用目的の範囲内で会員の個人情報を利用できるものとします。
      </p>

      <h3 css={semiTitleStyle}>第２４条 （権利義務の譲渡禁止）</h3>
      <p css={leadTextStyle}>
        会員は、当社の事前の書面による承諾を得ることなく、本利用契約に基づく権利義務を第三者に譲渡（合併、会社分割等による包括承継も含みます。）しまたは担保の目的に供してはならないものとします。
      </p>

      <h3 css={semiTitleStyle}>第２５条 （準拠法、管轄裁判所）</h3>
      <p css={leadTextStyle}>
        本規約および本利用契約は、日本法によって解釈され、日本法を準拠法とします。
        本利用契約に関して利用者と当社の間に紛争が生じた場合には、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
      </p>

      <p css={[revisionStyle, { marginTop: '32px' }]}>2019年09月27日 制定</p>
      <p css={revisionStyle}>2019年11月15日 改定</p>
      <p css={revisionStyle}>2020年01月08日 改定</p>
      <p css={revisionStyle}>2020年10月29日 改定</p>
      <p css={revisionStyle}>2021年07月28日 改定</p>
      <p css={revisionStyle}>2022年04月15日 改定</p>
    </div>
  )
}

const layoutStyle = css({
  marginTop: '5rem',
  paddingRight: '2.5rem',
  paddingLeft: '2.5rem',
  lineHeight: '1.625',
})

const pageTitleStyle = css({
  fontWeight: '600',
  textAlign: 'center',
  fontSize: '20px'
})

const administratorStyle = css({
  textAlign: 'center',
})

const updateStyle = css({
  marginTop: '24px',
  marginBottom: '24px',
  fontSize: '14px',
  color: '#475569',
})

const semiTitleStyle = css({
  fontWeight: '600',
  marginTop: '32px',
  marginBottom: '8px',
})

const leadTextStyle = css({
  fontSize: '12px',
  marginTop: '1.5rem',
  marginBottom: '1.5rem',
  lineHeight: '1.625',
})

const listStyle = css({
  paddingLeft: '1rem',
  paddingRight: '1rem',
  marginBottom: '1rem',
  listStyleType: 'decimal'
})

const listItemStyle = css({
  fontSize: '12px',
  lineHeight: '1.625',
  listStyleType: 'decimal'
})

const innerListStyle = css({
  marginRight: '32px',
  marginLeft: '32px',
  listStyleType: 'lower-latin',
})


const revisionStyle = css({
  fontWeight: '600',
  fontSize: '14px',
  marginBottom: '4px',
  '&:first-of-type': {
    marginTop: 0,
  },
})
