import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavigateHeader } from '@/components/atoms/navigate-header'
import { Layout } from '@/components/template/layout'
import { isEmpty } from 'lodash'
import { useAppSelector } from '@/app/hooks'
import { AccountInfo } from '@/features/user/account-slice'
import { MembershipAccountTemplate } from '@/components/template/registerAccount/membership-account-template'

export const MembershipAccount: React.FC = () => {
  const { t } = useTranslation()

  const accountInfo = useAppSelector<AccountInfo>(state => state.accountInfo.user)
  const hotelGuideData = useAppSelector(state => state.hotelGuide.plugins)

  return (
    <Layout>
      <NavigateHeader title={t('Enter account information')} style={{ marginBottom: '1rem' }} />
      {!isEmpty(accountInfo) && !isEmpty(hotelGuideData) && <MembershipAccountTemplate />}
    </Layout>
  )
}
