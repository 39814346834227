import { FreeAssetDetailType, FreeAssetType } from '@/features/asset/assets-slice'
import React, { useEffect, useState } from 'react'
import { css } from '@emotion/react'
import { usePickByLanguage } from '@/hooks/use-pick-by-language'
import { useTranslation } from 'react-i18next'
import { PullDownIcon } from '@/components/atoms/icon'
import { useSmoothScroll } from '@/hooks/use-smooth-scroll'
import { Link, useSearchParams } from 'react-router-dom'
import { CUSTOM_BLOCK, CUSTOM_BORDER, CUSTOM_LINK, CUSTOM_TEXT_COLOR_LINK } from '@/components/template/custom-design-style'

type Props = {
  asset: FreeAssetType
}

export const FreeAssetBlock: React.FC<Props> = ({ asset }) => {
  const [isShowAllDetail, setIsShowAllDetail] = useState<boolean>(false)
  const pickByLanguage = usePickByLanguage()
  const { smoothScroll } = useSmoothScroll()
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()

  const defaultViewCount = 5

  const assetDetailsOnDisplay = (): FreeAssetDetailType[] => {
    return isShowAllDetail ? asset.details : asset.details.slice(0, defaultViewCount)
  }

  const hasMoreDetails = (): boolean => asset.details.length > defaultViewCount

  useEffect(() => {
    if (searchParams.get('hash') !== asset.id) {
      return
    }
    setIsShowAllDetail(true)
    smoothScroll(asset.id)
  }, [searchParams])

  return (
    <div id={asset.id} css={blockStyle} className={CUSTOM_BLOCK}>
      <h2 className="header-text">{pickByLanguage(asset.title)}</h2>
      <div>
        {assetDetailsOnDisplay().map(detail => {
          return (
            <Link key={detail.id} className={`detail-list ${CUSTOM_BORDER}`} to={`/contents/${asset.id}/${detail.id}`}>
              {detail.imagePath ? <img src={detail.imagePath} className="thumbnail" /> : <div className="thumbnail" />}
              <div className={`title ${CUSTOM_TEXT_COLOR_LINK}`}>{pickByLanguage(detail.title)}</div>
            </Link>
          )
        })}
        {hasMoreDetails() && !isShowAllDetail && (
          <div
            className={`show-all-link ${CUSTOM_BORDER}`}
            onClick={() => {
              setIsShowAllDetail(true)
            }}
          >
            <div className={CUSTOM_LINK} style={{ marginRight: 4 }}>
              {t('Show all')}
            </div>
            <PullDownIcon height="20" width="20" />
          </div>
        )}
      </div>
    </div>
  )
}

const blockStyle = css({
  padding: '32px 16px',
  '.header-text': {
    fontSize: 18,
    paddingBottom: 8,
  },
  '.detail-list': {
    display: 'flex',
    padding: '8px 0',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    ':last-of-type': { borderBottom: 'none' },
    '.thumbnail': {
      width: 80,
      minWidth: 80,
      height: 45,
      borderRadius: 5,
    },
    '.title': {
      paddingLeft: 16,
      fontSize: 16,
      lineHeight: '52px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },
  '.show-all-link': {
    paddingTop: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    borderBottom: 'none',
    borderRight: 'none',
    borderLeft: 'none',
    '> img': {
      paddingLeft: 4,
      width: 20,
      height: 20,
    },
  },
})
