import React from 'react'
import { Link, To } from 'react-router-dom'

import { css } from '@emotion/react'
import { blockSpaceTop } from '@/styles/common'
import { RightIcon } from '../atoms/icon'
import { CUSTOM_BLOCK, CUSTOM_TEXT_COLOR_LINK } from '../template/custom-design-style'

interface LinkWithArrowIconProps {
  linkTo: To | string
  children: React.ReactNode
}

export const LinkWithArrowIcon: React.FC<LinkWithArrowIconProps> = ({ linkTo, children }) => {
  return (
    <Link css={[linkStyle, blockSpaceTop]} to={linkTo} className={`${CUSTOM_BLOCK} ${CUSTOM_TEXT_COLOR_LINK}`}>
      {children}
      <RightIcon />
    </Link>
  )
}

const linkStyle = css({
  display: 'block',
  position: 'relative',
  padding: '1rem 2rem',
  svg: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: '34px',
    margin: 'auto',
  },
})
