import { useEffect } from 'react'
import { useHotelId } from './use-hotel-id'
import { http } from '@/apis/aipass'
import { env } from '@/libs/env'

export const useCustomScript = () => {
  const { getHotelId } = useHotelId()

  const fetchScript = async hotelId => {
    try {
      const response = await http({
        method: 'get',
        url: `${env('BASE_API_V3')}/custom-script/guest`,
        params: { hotelId },
      })
      return response.data
    } catch (e) {
      console.error(e)
      return []
    }
  }

  const insertScript = (elm: HTMLElement, html: string) => {
    elm.innerHTML = html
    Array.from(elm.querySelectorAll('script')).forEach(oldScript => {
      const newScript = document.createElement('script')
      Array.from(oldScript.attributes).forEach(attr => newScript.setAttribute(attr.name, attr.value))
      newScript.appendChild(document.createTextNode(oldScript.innerHTML))
      oldScript.parentNode?.replaceChild(newScript, oldScript)
    })
  }

  useEffect(() => {
    const hotelId = getHotelId()
    if (!hotelId) {
      return
    }
    fetchScript(hotelId).then(cs => {
      const textScript = cs.map(s => s.script).join('')
      if (document.getElementById('chat-script-block')?.children.length) {
        return
      }
      insertScript(document.getElementById('chat-script-block')!, textScript)
    })
  }, [])
}
