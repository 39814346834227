import { labelStyle } from '@/styles/form'
import { SerializedStyles, css } from '@emotion/react'
import React, { ComponentPropsWithRef, forwardRef } from 'react'
import { RequiredMark } from './required-mark'
import { useTranslation } from 'react-i18next'
import { ErrorMessage } from './error-message'
import { CUSTOM_FORM } from '../template/custom-design-style'

interface SelectBoxProps {
  id?: string
  name: string
  label?: string
  required?: boolean
  defaultValue?: string
  value?: string | number
  arrowDefaultSelect?: boolean
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void
  onBlur?: (e: React.ChangeEvent<HTMLSelectElement>) => void
  options: {
    value: string
    label: string
  }[]
  error?: string
  itemCss?: SerializedStyles
  lead?: React.ReactNode
}

type SelectBoxType = ComponentPropsWithRef<'select'> & SelectBoxProps

export const SelectBox: React.FC<SelectBoxType> = forwardRef(
  (
    {
      id,
      name,
      options,
      required,
      label,
      defaultValue,
      itemCss,
      onChange,
      onBlur,
      arrowDefaultSelect = true,
      error,
      value,
      lead,
      placeholder,
      ...props
    },
    ref,
  ) => {
    const { t } = useTranslation()

    return (
      <div css={[itemCss]}>
        {label && (
          <label css={labelStyle} htmlFor={id}>
            {label}
            {required && <RequiredMark />}
          </label>
        )}
        {lead && <div style={{ marginBottom: '1.5rem' }}>{lead}</div>}
        <div css={SelectWrapStyle} className="select-wrapper">
          <select
            css={SelectStyle}
            value={value}
            defaultValue={defaultValue}
            id={id}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            ref={ref}
            className={`${CUSTOM_FORM}`}
            {...props}
          >
            {arrowDefaultSelect && <option value="">{placeholder || t('Please select')}</option>}
            {options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        {error && <ErrorMessage text={error} />}
      </div>
    )
  },
)
SelectBox.displayName = 'SelectBox'

const SelectWrapStyle = css({
  width: '100%',
  position: 'relative',
  marginTop: '8px',
})

const SelectStyle = css({
  width: '100%',
  height: '44px',
  appearance: 'none',
  display: 'block',
  padding: '10px 20px',
})
