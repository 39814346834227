import { useTranslation } from 'react-i18next'

export const usePickByLanguage = () => {
  const { i18n } = useTranslation()

  const pickByLanguage = (value: any): string => {
    return value && typeof value === 'object' && Object.keys(value).includes(i18n.language) ? value[i18n.language] : ''
  }

  return pickByLanguage
}
