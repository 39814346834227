const breakpoints = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
} as const

type Breakpoints = keyof typeof breakpoints

const isBreakPointKeys = (value: Breakpoints | number) => {
  return value in breakpoints
}

export const minScreen = (breakpoint: Breakpoints | number = 'md') => {
  const mediaQuery = isBreakPointKeys(breakpoint) ? `@media (min-width: ${breakpoints[breakpoint]})` : `@media (min-width: ${breakpoint}px)`
  return mediaQuery
}
