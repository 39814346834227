import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { getCreditCard } from '@/features/user/user-card-slice'
import { fetchPaymentSetting } from '@/features/hotel/payment-setting-by-hotel'
import { errorHandler } from '@/libs/errors'
import { useQuerySearchParams } from '@/hooks/use-query-search-params'

interface PaymentFormTemplateProps {
  children: React.ReactNode
}

export const PaymentFormTemplate: React.FC<PaymentFormTemplateProps> = ({ children }) => {
  const { hotelId } = useQuerySearchParams<{ hotelId: string }>()
  const dispatch = useAppDispatch()

  const hasPaymentPlugins = useAppSelector(state => state.hotelGuide.hasPaymentPlugins)
  const userData = useAppSelector(state => state.accountInfo.user)

  const loadPaymentSetting = async () => {
    try {
      await dispatch(fetchPaymentSetting(hotelId)).unwrap()
    } catch (error) {
      errorHandler({ error })
    }
  }

  const loadCreditCardData = async () => {
    try {
      await dispatch(getCreditCard(userData.id)).unwrap()
    } catch (error) {
      errorHandler({ error })
    }
  }

  useEffect(() => {
    if (userData.id) {
      loadCreditCardData()
    }
  }, [userData])

  useEffect(() => {
    if (hasPaymentPlugins) {
      loadPaymentSetting()
    }
  }, [hasPaymentPlugins])

  return <>{children}</>
}
