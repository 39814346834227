import { http } from '@/apis/aipass'
import { RootState } from '@/app/store'
import { env } from '@/libs/env'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const fetchCheckInSetting = createAsyncThunk(
  'hotel/checkInSetting',
  async (arg: { hotelId: string; isForce?: boolean }, { rejectWithValue }) => {
    try {
      const response = await http({
        method: 'get',
        url: `${env('BASE_API_V3')}/self-checkin/self-checkin-setting`,
        params: { hotelId: arg.hotelId },
      })
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  {
    dispatchConditionRejection: true,
    condition: (arg, { getState }) => {
      const state = getState() as RootState
      if (arg.isForce) {
        return true
      }
      if (state.checkInSetting.loading.fetchCheckInSetting) {
        return false
      }
      return !state.checkInSetting.checkInSetting
    },
  },
)

interface CheckInSetting {
  requiredAccompanyNameInput: boolean
}

export const checkInSettingSlice = createSlice({
  name: 'checkInSetting',
  initialState: {
    checkInSetting: undefined,
    loading: { fetchCheckInSetting: false },
  } as {
    checkInSetting: CheckInSetting | undefined
    loading: { fetchCheckInSetting: boolean }
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchCheckInSetting.fulfilled, (state, action) => {
      state.checkInSetting = action.payload?.self_checkin_required_identify?.[0]
      state.loading.fetchCheckInSetting = false
    })
    builder.addCase(fetchCheckInSetting.rejected, state => {
      state.loading.fetchCheckInSetting = false
    })
    builder.addCase(fetchCheckInSetting.pending, state => {
      state.loading.fetchCheckInSetting = true
    })
  },
})
