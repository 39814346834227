import { css } from '@emotion/react'
import React from 'react'

export const KoContent: React.FC = () => {
  return (
    <div css={layoutStyle}>
      <div css={administratorStyle}>
        <h2 css={pageTitleStyle}>aipass 서비스 이용 약관｜시설 이용자</h2>

        <p css={updateStyle}>최종 업데이트: 2022년 4월 15일</p>
      </div>

      <h3 css={semiTitleStyle}>제 1 장 총칙</h3>

      <h3 css={semiTitleStyle}>적용대상 조항(적용)</h3>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          {`본 aipass 이용 약관(이하 '본 약관'이라 합니다.) 은 aipass 주식회사(이하 '당사'라 합니다.) 가 제공하는 aipass(아이패스)라는 명칭의
          서비스(이하 '본 서비스'라 합니다.) 의 이용과 관련하여 회사와 이용자 간에 적용됩니다.`}
        </li>
        <li css={listItemStyle}>
          {`당사는 본 서비스에 관하여 본 약관 외에 이용에 관한 규칙 등 각종 규정(이하 '개별 규정'이라 합니다.)을 정할 수 있습니다. 를 할 수
          있습니다. 이러한 개별 규정은 그 명칭의 여하를 불문하고 본 규약의 일부를 구성하는 것으로 합니다. 본 약관의 규정이 전조의 개별규정의
          규정과 모순되는 경우에는 개별규정에 특별한 규정이 없는 한 개별규정의 규정이 우선합니다.`}
        </li>
      </ol>

      <h3 css={semiTitleStyle}>제2조(정의)</h3>
      <p css={leadTextStyle}>본 약관에서 다음 각 호에 기재된 용어의 의미는 해당 각 호에서 정하는 바에 따릅니다.</p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          회원
          <br />
          {`본 약관에 동의하고 등록 정보를 등록한 후 당사와 본 서비스 이용에 관한 계약(이하 '본 이용계약'이라 합니다.)을 체결한 개인을
          말합니다. 를 체결한 개인을 말합니다.`}
        </li>
        <li css={listItemStyle}>
          사용자
          <br />본 약관에 동의하고 등록정보를 등록하지 않고 당사와 본 이용계약을 체결하여 본 서비스에 접속 및 이용하는 자를 말합니다.
        </li>
        <li css={listItemStyle}>
          이용자
          <br />
          회원 및 사용자를 말합니다.
        </li>
        <li css={listItemStyle}>
          본 사이트
          <br />
          당사가 본 서비스를 제공하기 위해 운영하는 웹사이트를 말합니다. 본 서비스는 본 사이트 또는 다음 호에서 정의하는 본 소프트웨어를
          통해 이용자에게 제공됩니다.
        </li>
        <li css={listItemStyle}>
          본 소프트웨어
          <br />
          당사가 본 서비스를 제공하기 위해 작성 및 공개하는 응용 소프트웨어를 말하며, 업데이트 버전, 수정 버전, 대체품 및 복제품을
          포함합니다.
        </li>
        <li css={listItemStyle}>
          등록 정보
          <br />본 서비스의 회원이 되기 위해 등록이 요구되는, 당사가 정한 회원에 관한 정보를 말합니다.
        </li>
        <li css={listItemStyle}>
          회원정보
          <br />본 서비스를 위해 당사가 관리하는 서버에 저장된 각종 정보 및 통신기록 기타 회원의 모든 정보를 말하며, 등록정보, 본 서비스를
          통해 당사가 제공하거나 회원이 취득한 정보를 포함합니다.
        </li>
      </ol>

      <h3 css={semiTitleStyle}>제3조 (약관의 변경)</h3>
      <p css={leadTextStyle}>회사는 언제든지 이용자의 사전 동의 없이 본 약관의 내용을 변경할 수 있습니다.</p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          당사가 본 약관의 내용을 변경하고 그 변경 내용을 아이패스 이용약관의 규정에 따라 이용자에게 통지한 경우, 통지에서 지정한 기일
          이후(단, 통지에서 기일을 지정하지 않은 경우에는 당사가 통지를 한 날을 기일로 합니다.) 이후에 본 서비스를 이용하는 경우 변경된 본
          약관에 동의한 것으로 간주되며, 변경된 본 약관이 적용됩니다. 회원이 통지에서 지정한 기일 내에 해지 절차를 밟지 않은 경우에는 변경된
          본 약관에 동의한 것으로 간주됩니다.
        </li>
      </ol>

      <h3 css={semiTitleStyle}>제4조 (통지)</h3>
      <p css={leadTextStyle}>
        당사는 본 서비스와 관련하여 이용자에게 통지를 하는 경우, 본 사이트에 게시하는 방법 또는 등록 정보로 등록된 이메일 주소 및 주소로
        이메일 및 문서를 발송하는 방법 등 당사가 적절하다고 판단하는 방법으로 실시합니다.
      </p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          전항에서 정한 방법에 의한 통지는 전자의 경우 통지 내용이 본 사이트에 게시된 시점, 후자의 경우 당사가 전자우편, 문서로 발송한
          시점에 각각 그 효력이 발생하는 것으로 합니다.
        </li>
        <li css={listItemStyle}>본 서비스에 관한 문의 및 기타 이용자로부터 당사에 대한 연락 또는 통지는 당사가 정하는 방법으로 합니다.</li>
      </ol>

      <h3 css={semiTitleStyle}>제2장 계약의 성립</h3>
      <h3 css={semiTitleStyle}>제5조 (본 이용계약의 성립 (가입))</h3>
      <p css={leadTextStyle}>
        본 이용계약은 본 서비스 이용을 희망하는 자가 당사가 지정하는 방법으로 신청하고 당사가 이를 승낙한 시점에 성립하는 것으로 합니다. 본
        서비스 이용을 희망하는 자가 당사가 지정한 방법으로 신청한 시점에서 해당 신청자는 본 약관에 동의한 것으로 간주됩니다.
      </p>
      <ol css={listStyle}>
        <li css={listItemStyle}>회원은 당사가 전항의 신청을 승낙한 날부터 본 서비스를 이용할 수 있습니다.</li>
        <li css={listItemStyle}>
          미성년자가 본 서비스를 이용하고자 하는 경우에는 법정대리인의 동의가 필요합니다. 미성년자가 회원으로 가입한 경우, 본 서비스 이용 및
          본 약관의 내용에 대해 법정대리인의 동의가 있는 것으로 간주합니다.
        </li>
      </ol>

      <h3 css={semiTitleStyle}>제6조 (이용요금 및 결제방법)</h3>
      <p css={leadTextStyle}>
        본 서비스를 이용하기 위해 필요한 통신비(본 소프트웨어의 다운로드 및 이용을 위해 발생하는 통신비를 포함합니다.) 및 통신기기 등은
        이용자의 부담과 책임으로 준비하는 것으로 합니다. 당사는 이용자가 사용하는 통신기기 등에서 본 사이트 및 본 소프트웨어가 정상적으로
        작동할 것을 보증하지 않습니다.
      </p>

      <h3 css={semiTitleStyle}>제3장 서비스 이용 시 주의사항</h3>
      <h3 css={semiTitleStyle}>제7조 (회원 ID 등)</h3>
      <p css={leadTextStyle}>
        {`회원은 본 서비스 신청 시 지정한 회원 ID(이메일 주소) 및 회원 비밀번호(이하 '회원 ID 등'이라 함)를 엄격히 관리해야 합니다. )를 자신의 책임하에 엄격하게 관리하여야 하며, 이를 이용하여 행해진 일체의 행위에 대한 책임은 회원에게 있습니다.`}
      </p>
      <ol css={listStyle}>
        <li css={listItemStyle}>회원은 회원 본인 이외의 타인에게 본 서비스를 이용하게 해서는 안 됩니다.</li>
        <li css={listItemStyle}>
          회원은 회원 비밀번호 또는 회원 ID가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 즉시 회사에 그 사실을 통보하고 회사의
          안내가 있는 경우에는 그에 따라야 합니다.
        </li>
      </ol>

      <h3 css={semiTitleStyle}>제8조 (등록정보의 변경)</h3>
      <p css={leadTextStyle}>
        회원은 등록정보에 변경사항이 있는 경우, 회사가 지정하는 방법으로 즉시 변경사항을 알려야 합니다. 회사는 등록정보의 변경을 신고하지
        않아 회원에게 발생한 손해에 대해 일체의 책임을 지지 않습니다.
      </p>

      <h3 css={semiTitleStyle}>제9조 (금지행위)</h3>
      <p css={leadTextStyle}>이용자는 본 서비스와 관련하여 다음 각 호에서 정한 행위를 해서는 안됩니다.</p>
      <ol css={listStyle}>
        <li css={listItemStyle}>당사에 허위 신고를 하는 행위</li>
        <li css={listItemStyle}>
          본 이용계약에 따라 당사로부터 제공받은 본 사이트 및 본 소프트웨어를 포함한 정보 및 서비스를 본 서비스 이용 이외의 목적으로
          사용하는 행위.
        </li>
        <li css={listItemStyle}>
          당사 또는 제3자의 재산(지적재산권 포함), 프라이버시, 명예, 신용, 초상권, 기타 권리를 침해하는 행위. 프라이버시, 명예, 신용, 초상권
          또는 퍼블리시티에 관한 권리, 기타 권리 및 이익을 침해하는 행위 또는 침해할 우려가 있는 행위.
        </li>
        <li css={listItemStyle}>전호 이외에 당사 또는 제3자의 권리・이익을 침해하는 행위, 또는 침해할 우려가 있는 행위</li>
        <li css={listItemStyle}>법령을 위반하거나 미풍양속에 위배되는 행위 또는 그럴 우려가 있는 행위</li>
        <li css={listItemStyle}>
          제3자의 회원 ID 등을 부정하게 사용 또는 취득하는 행위 기타 회원이 제3자를 가장하여 본 서비스를 이용하는 행위
        </li>
        <li css={listItemStyle}>컴퓨터 바이러스 등 유해한 프로그램을 사용하거나 전송하는 행위 또는 그럴 우려가 있는 행위</li>
        <li css={listItemStyle}>
          전호에 규정된 것 외에 부정 접속 행위 등 당사의 업무 수행, 본 서비스의 실시 또는 당사의 통신 설비 등에 지장을 초래하거나 초래할
          우려가 있다고 당사가 판단하는 행위.
        </li>
        <li css={listItemStyle}>기타 당사가 부적절하다고 판단하는 행위</li>
      </ol>

      <h3 css={semiTitleStyle}>제10조 (시설 체크인 및 동 숙박시설 약관의 동의)</h3>
      <p css={leadTextStyle}>
        본 서비스를 통해 해당 숙박시설에 체크인을 한 경우, 이용자는 해당 숙박시설을 신청하고 해당 숙박시설의 약관에 동의한 것으로
        간주합니다.
      </p>

      <h3 css={semiTitleStyle}>제4장 해약, 해지, 탈퇴</h3>
      <h3 css={semiTitleStyle}>제11조 (회원의 본 서비스 해지)</h3>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          회원은 본 이용계약을 해지하고자 하는 경우, 당사 소정의 방법에 따라 해지 절차를 밟아야 하며, 해당 해지 절차가 완료됨으로써 해당
          서비스 이용계약이 해지되는 것으로 간주합니다. 이 경우, 회원은 자기 책임 하에 회사의 해지 관련 통지를 확인하여야 합니다. 개별
          서비스별로 해당 서비스 이용을 종료하는 경우, 개별 서비스별로 정해진 종료 절차를 밟아야 합니다.
        </li>
        <li css={listItemStyle}>회원이 제1항에 따라 본 이용계약을 해지하는 경우, 회사는 회원정보를 삭제할 수 있습니다.</li>
      </ol>

      <h3 css={semiTitleStyle}>제12조 (당사에 의한 계약 해지)</h3>
      <p css={leadTextStyle}>
        당사는 이용자가 다음 각 호의 어느 하나에 해당하는 경우, 이용자에게 어떠한 통지 통지 없이 본 이용계약의 일부 또는 전부를 해지하고
        이용자에 대한 탈퇴처분을 하거나 본 서비스의 제공을 중지할 수 있습니다.
      </p>
      <ol css={listStyle}>
        <li css={listItemStyle}>본 약관의 조항을 위반하는 행위를 한 경우.</li>
        <li css={listItemStyle}>당사에 제공된 등록 정보의 전부 또는 일부에 대해 허위, 오기재 또는 기재 누락이 있는 경우</li>
        <li css={listItemStyle}>
          반사회적 세력 등(폭력단, 폭력단원, 우익단체, 반사회적 세력, 폭력단 준구성원, 총책, 사회운동 등 표방 폭력배, 특수지능형 폭력집단)
        </li>
        <li css={listItemStyle}>
          기타 이에 준하는 자를 의미합니다. 이하 같음) 인 자금 제공 등을 통해 반사회적 세력 등의 유지, 운영 또는 경영에 협력 또는 관여하는
          등 반사회적 세력 등과 어떠한 교류 또는 관여를 하고 있거나, 반사회적 세력 등이 경영에 실질적으로 관여하여 반사회적 세력 등이 경영에
          실질적으로 관여하고 있는 법인 등이라고 당사가 합리적으로 판단하는 경우.
        </li>
        <li css={listItemStyle}>요금 등 지불 채무 불이행이 있는 경우</li>
        <li css={listItemStyle}>당사의 연락에 대해 90일 이상 답변이 없는 경우</li>
        <li css={listItemStyle}>과거에 본 서비스에 대해 탈퇴 처분을 받은 사실이 확인된 경우.</li>
        <li css={listItemStyle}>기타 당사가 이용자로서 부적합하다고 합리적으로 판단하는 경우</li>
      </ol>
      <p css={leadTextStyle}>
      전항에 따라 탈퇴 처분을 하거나 본 서비스 제공을 중단한 경우에도 당사는 이미 수령한 이용요금을 환불하지 않으며, 이용자 및 제3자에게 발생한 손해 및 불이익에 대해 일체의 책임을 지지 않습니다.
      </p>

      <h3 css={semiTitleStyle}>제13조 (폭력단 등 반사회적 세력의 배제)</h3>
      <p css={leadTextStyle}>
      이용자는 본 서비스 이용 시 자신이 폭력단, 폭력단원, 폭력단 관련 기업, 총책, 사회운동단체, 정치운동단체, 특수공작집단, 기타 반사회적 세력(이하 폭력단 등 반사회적 세력)에 소속되어 있지 않으며, 향후에도 소속되어 있지 않음을 확인합니다. 에 소속되거나 해당하지 않으며, 폭력단 등 반사회적 세력과 관련이 없음을 표명하고, 향후에도 소속되거나 해당되거나 관여하지 않을 것을 확약합니다.
      </p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
        당사는 이용자가 폭력단 등 반사회적 세력에 소속되어 있거나 이에 해당하거나 연루되어 있다고 판단되는 경우, 사전 통지 등을 하지 않고 본 서비스의 이용 정지 조치, 해지 조치를 취할 수 있습니다.
        </li>
        <li css={listItemStyle}>
        회사는 본 조항에 따른 이용자의 위반으로 인한 이용정지 및 해지로 인해 발생한 손해에 대해 일체의 의무와 책임을 지지 않습니다.
        </li>
      </ol>

      <h3 css={semiTitleStyle}>제5장 서비스 중단, 변경 및 종료</h3>
      <h3 css={semiTitleStyle}>제14조 (서비스 중단)</h3>
      <p css={leadTextStyle}>
      당사는 다음 각 호의 사유가 발생한 경우, 이용자에게 사전 통지 없이 본 서비스의 일부 또는 전부를 중단할 수 있습니다.
      </p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
        본 서비스 제공에 필요한 시스템, 설비 등에 장애가 발생하거나 유지보수, 보수 또는 공사 등이 필요한 경우
        </li>
        <li css={listItemStyle}>
        전기통신사업자가 전기통신 서비스 제공을 중단하는 등 당사 이외의 제3자의 행위로 인해 본 서비스의 제공이 곤란하게 된 경우
        </li>
        <li css={listItemStyle}>
        비상사태(천재지변, 전쟁, 테러, 폭동, 소란, 관공서 처분, 노동쟁의 등)의 발생으로 본 서비스 제공이 어려워졌거나 어려워질 가능성이 있는 경우
        </li>
        <li css={listItemStyle}>동기화 가능 서비스 사정으로 인해 동기화 가능 서비스를 이용할 수 없게 된 경우</li>
        <li css={listItemStyle}>법령 규제, 행정명령 등으로 인해 본 서비스 제공이 어려워진 경우</li>
        <li css={listItemStyle}>
        기타 당사의 책임으로 돌릴 수 없는 사유로 인해 당사가 부득이하게 필요하다고 판단하는 경우
        당사는 전항에 따라 본 서비스를 중단함으로써 이용자 및 제3자에게 발생한 손해 및 불이익에 대해 일체의 책임을 지지 않습니다.
        </li>
      </ol>

      <h3 css={semiTitleStyle}>제15조 (서비스 변경)</h3>
      <p css={leadTextStyle}>
      당사는 당사의 재량에 따라 본 서비스의 일부 내용을 추가 또는 변경할 수 있습니다. 당사는 본 조에 따른 본 서비스의 추가 또는 변경으로 인해 변경 전의 본 서비스의 모든 기능 및 성능이 유지된다는 것을 보증하지 않습니다.
      당사는 전항에 따라 본 서비스를 추가 또는 변경함으로써 이용자에게 발생한 손해 및 불이익에 대해 일체의 책임을 지지 않습니다.
      </p>

      <h3 css={semiTitleStyle}>제16조 (서비스 중단 및 종료)</h3>
      <p css={leadTextStyle}>
      당사는 사전에 회원에게 통지한 후, 당사의 재량에 따라 본 서비스의 일부 또는 전부의 제공을 중단 또는 종료할 수 있습니다. 단, 중단 또는 종료의 내용이 중대하지 않은 경우에는 통지 없이 이를 실시할 수 있습니다.
      당사는 전항에 따라 본 서비스를 중단 또는 종료함으로써 이용자에게 손해가 발생한 경우에도 어떠한 책임도 지지 않습니다.
      </p>

      <h3 css={semiTitleStyle}>제17조 (계약상의 지위 양도 등)</h3>
      <p css={leadTextStyle}>
      {`이용자는 당사의 사전 동의 없이 본 이용계약상의 지위 또는 본 이용계약에 따른 권리 또는 의무를 제3자에게 양도, 이전, 담보설정, 기타 처분할 수 없습니다.
      당사는 본 서비스에 관한 사업을 사업양도 또는 회사 분할 기타의 방법(이하 '사업양도 등'이라 합니다)을 이용하여 제3자에게 양도하는 경우, 해당 사업양도 등에 따라 본 이용계약상의 지위, 본 이용계약에 근거한 권리 및 의무 및 이용자에 관한 정보를 해당 사업양도 등의 양수인에게 양도 및 이전할 수 있으며, 이용자는 그러한 양도 및 이전에 대해 사전에 동의한 것으로 간주합니다.`}
      </p>

      <h3 css={semiTitleStyle}>제6장 일반 조항</h3>
      <h3 css={semiTitleStyle}>제18조 (보증)</h3>
      <p css={leadTextStyle}>
      당사는 본 서비스가 권장 환경에서 작동할 수 있도록 합리적인 최대한의 노력을 기울입니다. 단, 당사는 이용자 정보의 정확성, 정당성, 유용성, 완전성 등을 보장하지 않습니다. 이용자는 이용자 정보에 대해 자신의 판단과 책임 하에 필요에 따라 변경, 수정 등을 한 후 이용하는 것으로 합니다.
      </p>

      <h3 css={semiTitleStyle}>제19조 (지적재산권)</h3>
      <p css={leadTextStyle}>
      본 서비스에 관한 저작권, 저작인격권, 특허권, 실용신안권, 의장권, 상표권 및 퍼블리시티권 등 일체의 지적재산권은 당사 및 정당한 권리자인 제3자에게 귀속되며, 본 이용계약의 성립은 본 서비스 이용에 필요한 범위를 초과하는 지적재산권의 이용허락을 의미하지 않습니다.
      </p>

      <h3 css={semiTitleStyle}>제20조 (손해배상 및 면책)</h3>
      <p css={leadTextStyle}>
      당사는 본 서비스와 관련하여 이용자에게 발생한 손해에 대해 당사의 과실이 인정되는 경우, 해당 손해의 직접적인 원인이 된 서비스에 대해 금 1,000엔을 한도로 그 손해를 배상하는 것으로 하며, 그 외의 손해에 대해서는 일체의 책임을 지지 않습니다.
      </p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
        이용자는 본 약관을 위반하거나 본 서비스 이용과 관련하여 당사에 손해를 끼친 경우, 당사에 발생한 손해를 배상해야 합니다.
        </li>
        <li css={listItemStyle}>
        본 서비스와 관련하여 이용자와 제3자 사이에 분쟁이 발생한 경우, 이용자는 자신의 책임과 비용으로 해결하고, 당사에 어떠한 불이익을 주지 않으며, 이로 인해 당사가 입은 손해(변호사 비용 포함)를 배상해야 합니다. 을 배상합니다.
        </li>
      </ol>

      <h3 css={semiTitleStyle}>제21조 (위탁)</h3>
      <p css={leadTextStyle}>당사는 본 서비스에 관한 업무의 일부 또는 전부를 제3자에게 위탁할 수 있습니다.</p>

      <h3 css={semiTitleStyle}>제22조 (정보관리)</h3>
      <ol css={listStyle}>
        <li css={listItemStyle}>
        당사는 회원 정보에 대해 회원의 사전 동의 없이 제3자에게 공개하지 않습니다. 단, 다음 각 호의 경우에는 그러하지 않습니다.
          <ol css={innerListStyle}>
            <li>법령 또는 공공기관의 요청을 받고 그 요청에 응할 필요가 있다고 인정하는 경우</li>
            <li>사람의 생명, 신체 또는 재산의 보호를 위하여 필요한 경우로서 회원의 동의를 얻기가 곤란한 경우</li>
            <li>
            회원에게 이용요금을 청구할 목적으로 본 서비스를 이용하는 등록 사용자의 정보를 결제 시스템 회사, 신용 카드사, 은행 등에 예치하는 경우
            </li>
            <li>본 서비스 제공 또는 기능 향상 또는 개선을 위해 필요한 수탁자 또는 대리인 등에게 정보를 공개하는 경우</li>
            <li>당사 및 당사 자회사 간 연계된 서비스를 제공하기 위해 공동 이용하는 경우.</li>
          </ol>
        </li>
        <li css={listItemStyle}>
        {`전항에도 불구하고, 당사는 이용자 정보의 속성 집계 및 분석을 통해 회원을 식별, 특정할 수 없도록 가공한 자료(이하 '통계자료'라 함)를 작성하여 본 서비스 및 당사의 기타 서비스에 이용할 수 있습니다. 를 작성하여 본 서비스 및 당사의 기타 서비스를 위해 이용할 수 있습니다. 또한 통계자료를 제3자에게 공개할 수 있습니다.`}
        </li>
        <li css={listItemStyle}>회사는 회원정보의 분실, 파괴, 변조, 유출 등의 위험에 대하여 합리적인 최대한의 안전조치를 취합니다.</li>
        <li css={listItemStyle}>
        회사는 전화응대 품질 향상 등을 위해 이용자와의 전화응대 내용을 녹음하고, 녹음 내용을 업무에 사용할 수 있습니다.
        </li>
      </ol>

      <h3 css={semiTitleStyle}>제23조 (개인정보)</h3>
      <p css={leadTextStyle}>
      전조의 규정에도 불구하고, 당사의 개인정보 취급에 관해서는 개인정보취급방침에서 정하는 바에 따릅니다.
      당사는 본 이용계약 종료 후에도 개인정보처리방침에 기재된 이용목적의 범위 내에서 회원의 개인정보를 이용할 수 있습니다.
      </p>

      <h3 css={semiTitleStyle}>제24조 (권리의무의 양도 금지)</h3>
      <p css={leadTextStyle}>
      회원은 당사의 사전 서면 동의 없이 본 이용계약에 따른 권리의무를 제3자에게 양도(합병, 회사 분할 등에 의한 포괄승계도 포함)하거나 담보의 목적으로 하거나 담보의 목적으로 제공할 수 없습니다.
      </p>

      <h3 css={semiTitleStyle}>제25조 (준거법, 관할법원)</h3>
      <p css={leadTextStyle}>
      본 약관 및 본 이용계약은 일본법에 의해 해석되며, 일본법을 준거법으로 합니다.
      본 이용계약에 관하여 이용자와 당사 사이에 분쟁이 발생한 경우, 도쿄 지방법원을 제1심 전속 합의관할법원으로 합니다.
      </p>

      <p css={[revisionStyle, { marginTop: '32px' }]}>2019년09월27일 제정</p>
      <p css={revisionStyle}>2019년 11월 15일 改定</p>
      <p css={revisionStyle}>2020년 01월 08일 改定</p>
      <p css={revisionStyle}>2020년 10월 29일 改定</p>
      <p css={revisionStyle}>2021년 07월 28일 改定</p>
      <p css={revisionStyle}>2022년 04월 15일 改定</p>
    </div>
  )
}

const layoutStyle = css({
  marginTop: '5rem',
  paddingRight: '2.5rem',
  paddingLeft: '2.5rem',
  lineHeight: '1.625',
})

const pageTitleStyle = css({
  fontWeight: '600',
  textAlign: 'center',
  fontSize: '20px',
})

const administratorStyle = css({
  textAlign: 'center',
})

const updateStyle = css({
  marginTop: '24px',
  marginBottom: '24px',
  fontSize: '14px',
  color: '#475569',
})

const semiTitleStyle = css({
  fontWeight: '600',
  marginTop: '32px',
  marginBottom: '8px',
})

const leadTextStyle = css({
  fontSize: '12px',
  marginTop: '1.5rem',
  marginBottom: '1.5rem',
  lineHeight: '1.625',
})

const listStyle = css({
  paddingLeft: '1rem',
  paddingRight: '1rem',
  marginBottom: '1rem',
  listStyleType: 'decimal',
})

const listItemStyle = css({
  fontSize: '12px',
  lineHeight: '1.625',
  listStyleType: 'decimal',
})

const innerListStyle = css({
  marginRight: '32px',
  marginLeft: '32px',
  listStyleType: 'lower-latin',
})

const revisionStyle = css({
  fontWeight: '600',
  fontSize: '14px',
  marginBottom: '4px',
  '&:first-of-type': {
    marginTop: 0,
  },
})
