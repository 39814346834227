import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { http } from '@/apis/aipass'
import { env } from '@/libs/env'
import { LanguageType } from '@/i18n'
import { RootState } from '@/app/store'
import { ButtonRoleType } from './design-slice'
import { CouponScopeUsageType } from '../coupon/coupon-slice'
import { SpaceDetail } from '../space/spaces-slice'

export const fetchAsset = createAsyncThunk(
  'asset/fetchAsset',
  async (arg: { hotelId: string; isForce?: boolean }) => {
    const response = await http({
      method: 'get',
      url: `${env('BASE_API_V3')}/guest/assets`,
      params: { hotelId: arg.hotelId },
    })
    return response.data
  },
  {
    dispatchConditionRejection: true,
    condition: (arg, { getState }) => {
      const { assets } = getState() as RootState
      return arg.isForce || (!assets.isFilled && !assets.loading)
    },
  },
)

export const FormatType = {
  CheckIn: 'checkin',
  Checkout: 'checkout',
  Free: 'free',
  Map: 'map',
  Pulldown: 'pulldown',
  News: 'news',
  Button1: 'button1',
  Button2: 'button2',
  Request: 'request',
  Coupon: 'coupon',
  Image: 'image',
  Space: 'space',
} as const
export type FormatType = (typeof FormatType)[keyof typeof FormatType]

export interface AssetType {
  id: string
  title: Record<LanguageType, string>
  formatType: FormatType
  details: any[]
}
export interface FreeAssetType extends AssetType {
  details: FreeAssetDetailType[]
}
export type FreeAssetDetailType = {
  id: string
  title: Record<LanguageType, string>
  description: Record<LanguageType, string>
  imagePath: string
  updatedAt: string
}

export interface PulldownAssetType extends AssetType {
  details: FreeAssetDetailType[]
}
export type PulldownAssetDetailType = {
  id: string
  description: Record<LanguageType, string>
}

export interface NewsAssetType extends AssetType {
  details: NewsAssetDetailType[]
}
export type NewsAssetDetailType = {
  id: string
  title: Record<LanguageType, string>
  description: Record<LanguageType, string>
  updatedAt: string
}

export interface MapAssetType extends AssetType {
  details: MapDetailType[]
  categories: MapCategoryType[]
}
export type MapCategoryType = {
  id: string
  title: Record<LanguageType, string>
  imagePath: string
}
export type MapBusinessHourType = {
  weekday: string
  startTime: string
  endTime: string | undefined
}
export type MapDetailType = {
  id: string
  title: Record<LanguageType, string>
  category: MapCategoryType
  subCategory: Record<LanguageType, string>
  imagePath: string
  telephone: string
  address: Record<LanguageType, string>
  siteUrl: string
  route: Record<LanguageType, string>
  comment: Record<LanguageType, string>
  businessHours: MapBusinessHourType[]
  isFavorite: boolean
  lat: string
  lng: string
  placeId: string
}

export interface ButtonAssetType extends AssetType {
  details: ButtonAssetDetailType[]
}

export type ButtonAssetDetailType = {
  id: string
  name: Record<LanguageType, string>
  url: string
  designType: ButtonRoleType
}

export interface ImageAssetType extends AssetType {
  details: ImageAssetDetailType[]
}

export type ImageAssetDetailType = {
  id: string
  title: Record<LanguageType, string>
  description: Record<LanguageType, string>
  imagePath: string
  destinationUrl: string
  destinationType: 'url' | 'newPage'
  isTitleShowHome: boolean
  isDisplay: boolean
}

export interface RequestAssetType extends AssetType {
  details: RequestAssetDetailType[]
  categories: RequestAssetCategoryType[]
  tags: RequestAssetTagType[]
}

export type RequestAssetDetailType = {
  id: string
  name: Record<LanguageType, string>
  description: Record<LanguageType, string>
  imagePath: string
  isFavorite: boolean
  isNowOnSale: boolean
  category: RequestAssetCategoryType | undefined
  tags: Array<RequestAssetTagType>
  price: number
  stock: number
  salesSubjectMasterId: string | undefined
}

export type RequestAssetCategoryType = {
  id: number
  name: Record<LanguageType, string>
  imagePath: string
}

export type RequestAssetTagType = {
  id: number
  name: Record<LanguageType, string>
  imagePath: string
}

export interface CouponAssetType extends AssetType {
  details: CouponAssetDetailType[]
}

export type CouponAssetDetailType = {
  id: string
  title: Record<LanguageType, string>
  facilityName: Record<LanguageType, string>
  imagePath: string
  description: Record<LanguageType, string>
  expireFrom: string
  expireTo: string
  url: string
  isUsed: boolean
  scopeUsage: CouponScopeUsageType
  maxNumberOfUse: number
}

export interface SpaceAssetType extends AssetType {
  details: SpaceDetail[]
}

export const isFreeAsset = (asset: AssetType | undefined): asset is FreeAssetType => asset?.formatType === FormatType.Free
export const isPulldownAsset = (asset: AssetType | undefined): asset is PulldownAssetType => asset?.formatType === FormatType.Pulldown
export const isNewsAsset = (asset: AssetType | undefined): asset is NewsAssetType => asset?.formatType === FormatType.News
export const isMapAsset = (asset: AssetType | undefined): asset is MapAssetType => asset?.formatType === FormatType.Map
export const isButtonAsset = (asset: AssetType | undefined): asset is ButtonAssetType =>
  asset?.formatType === FormatType.Button1 || asset?.formatType === FormatType.Button2
export const isImageAsset = (asset: AssetType | undefined): asset is ImageAssetType => asset?.formatType === FormatType.Image
export const isRequestAsset = (asset: AssetType | undefined): asset is RequestAssetType => asset?.formatType === FormatType.Request
export const isCouponAsset = (asset: AssetType | undefined): asset is CouponAssetType => asset?.formatType === FormatType.Coupon
export const isSpaceAsset = (asset: AssetType | undefined): asset is SpaceAssetType => asset?.formatType === FormatType.Space

export const assetSlice = createSlice({
  name: 'asset',
  initialState: {
    assets: [] as AssetType[],
    loading: false,
    isFilled: false,
  },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchAsset.fulfilled, (state, action: PayloadAction<AssetType[]>) => {
      return { assets: action.payload, loading: false, isFilled: true }
    })
    builder.addCase(fetchAsset.rejected, state => {
      state.loading = false
    })
    builder.addCase(fetchAsset.pending, state => {
      state.loading = true
    })
  },
})

export const assetAction = assetSlice.actions
export const assetsReducer = assetSlice.reducer
