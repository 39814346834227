import React from 'react'

import { useAppSelector } from '@/app/hooks'
import { LanguageType } from '@/i18n'
import { getValueByLanguage } from '@/libs/get-value-by-language'
import { useTranslation } from 'react-i18next'
import { SelectBox } from '../atoms/select-box'
import { TextArea } from '../atoms/text-area'
import { setCustomField } from '@/features/checkIn/custom-check-in-field-slice'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@/app/store'
import { Controller, UseFormReturn } from 'react-hook-form'
import { Divider } from '../atoms/divider'
import { css } from '@emotion/react'
import { HtmlContent } from '../atoms/html-content'

interface WrapperProps {
  children: React.ReactNode
  displayBorder: boolean
}
const Wrapper: React.FC<WrapperProps> = ({ children, displayBorder }) => {
  return (
    <>
      {children}
      {displayBorder && <Divider customCss={{ marginLeft: '-2rem', marginRight: '-2rem', marginTop: '2rem' }} />}
    </>
  )
}

interface CustomCheckInFormProps {
  // Forgive ANY because you never know what will come.
  useFormReturn: UseFormReturn<any>
}

export const CustomCheckInForm: React.FC<CustomCheckInFormProps> = ({ useFormReturn }) => {
  const { t, i18n } = useTranslation()

  const customCheckInFields = useAppSelector(state => state.customFields.customCheckin)

  const {
    control,
    formState: { errors },
  } = useFormReturn

  const dispatch = useDispatch<AppDispatch>()

  const language = i18n.language as LanguageType

  return (
    <>
      {customCheckInFields.map((customField, index) => {
        const displayBorder = index + 1 !== customCheckInFields.length
        const spacerTop = index !== 0 ? { marginTop: '2rem' } : undefined

        const label = getValueByLanguage<string>({ object: customField, lang: language, key: 'title' })
        const content = <HtmlContent text={getValueByLanguage({ object: customField, key: 'content', lang: language })} />

        if (customField.inputType === 'selectBox') {
          return (
            <Wrapper key={index} displayBorder={displayBorder}>
              <div css={spacerTop}>
                <Controller
                  control={control}
                  name={label}
                  rules={{ required: customField.isRequired === 1 ? t('This field is required') : undefined }}
                  render={({ field }) => (
                    <SelectBox
                      required={Boolean(customField.isRequired)}
                      label={label}
                      name={label}
                      itemCss={css({ label: { fontSize: '16px' } })}
                      defaultValue={field.value}
                      lead={content}
                      options={getValueByLanguage<string[]>({ object: customField, key: 'inputValue', lang: language }).map(item => ({
                        value: item,
                        label: item,
                      }))}
                      onChange={e => {
                        const value = e.target.value

                        const newCustomCheckInField = { ...customCheckInFields[index] }
                        newCustomCheckInField.value = value

                        dispatch(setCustomField({ customeField: newCustomCheckInField, index }))
                        field.onChange(value)
                      }}
                      error={errors[label]?.message as string}
                    />
                  )}
                />
              </div>
            </Wrapper>
          )
        }

        if (customField.inputType === 'textArea') {
          return (
            <Wrapper key={index} displayBorder={displayBorder}>
              <div css={[textAreaStyle, spacerTop]}>
                <Controller
                  control={control}
                  name={label}
                  rules={{ required: customField.isRequired === 1 ? t('This field is required') : undefined }}
                  render={({ field }) => (
                    <TextArea
                      required={Boolean(customField.isRequired)}
                      label={label}
                      rows={4}
                      value={field.value}
                      placeholder={t('Please write your requests')}
                      name={label}
                      contents={content}
                      onChange={e => {
                        const value = e.target.value

                        const newCustomCheckInField = { ...customCheckInFields[index] }
                        newCustomCheckInField.value = value

                        dispatch(setCustomField({ customeField: newCustomCheckInField, index }))
                        field.onChange(value)
                      }}
                      error={errors[label]?.message as string}
                    />
                  )}
                />
              </div>
            </Wrapper>
          )
        }

        if (customField.inputType === 'none') {
          return (
            <Wrapper key={index} displayBorder={displayBorder}>
              <div css={spacerTop}>
                <label style={{ fontSize: '16px' }}>{label}</label>
                {content}
              </div>
            </Wrapper>
          )
        }

        return <></>
      })}
    </>
  )
}

const textAreaStyle = css({
  label: {
    fontSize: '16px',
  },
})
