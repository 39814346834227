import React, { useEffect, useMemo, useState } from 'react'
import { useAppSelector } from '@/app/hooks'
import { Divider } from '@/components/atoms/divider'
import { RequestDetailModal } from '@/components/organisms/modal/request-detail-modal'
import { RequestCategoryMenu, paramsCategoryKey } from '@/components/organisms/request/request-category-menu'
import { RequestTagMenu, paramsTagKey } from '@/components/organisms/request/request-tag-menu'
import { CUSTOM_BLOCK } from '@/components/template/custom-design-style'
import { Layout } from '@/components/template/layout'
import { RequestAssetDetailType, RequestAssetType } from '@/features/asset/assets-slice'
import { usePickByLanguage } from '@/hooks/use-pick-by-language'
import { displayYen } from '@/libs/text'
import { css } from '@emotion/react'
import { useParams, useSearchParams } from 'react-router-dom'
import { RequestListFooter } from '@/components/organisms/request/request-list-footer'
import { lineClampStyle, pcScreenStyle } from '@/styles/common'

export const RequestList: React.FC = () => {
  const [selectedItem, setSelectedItem] = useState<RequestAssetDetailType | null>(null)

  const [searchParams, setSearchParams] = useSearchParams()

  const { assetId } = useParams<{ assetId: string }>()
  const pickByLanguage = usePickByLanguage()

  const { assets } = useAppSelector(state => state.assets)
  const design = useAppSelector(state => state.design)
  const requestAsset = assets.find(asset => asset.id === assetId)
  const requestCart = useAppSelector(state => state.request.requestCart.cart)

  const requestCartItems = useMemo(() => {
    const items = requestAsset?.details as RequestAssetDetailType[]
    const paramsCategoryId = searchParams.get(paramsCategoryKey)
    const paramsTagId = searchParams.get(paramsTagKey)

    if (!items) {
      return []
    }

    const filterByCategory = items.filter(
      item => !paramsCategoryId || Number(paramsCategoryId) <= 0 || item.category?.id === Number(paramsCategoryId),
    )
    const filterByTag = filterByCategory.filter(
      item => !paramsTagId || Number(paramsTagId) <= 0 || item.tags.map(tag => tag.id).includes(Number(paramsTagId)),
    )

    return filterByTag
  }, [requestAsset, assetId, searchParams])

  const findAddedCartItem = (id: string) => {
    const currentAssetRequestCarts = assetId ? requestCart[assetId] : []

    return currentAssetRequestCarts?.find(currentAssetRequestCart => currentAssetRequestCart.detail.id === id)
  }

  const onCloseModal = () => {
    setSelectedItem(null)
  }

  useEffect(() => {
    const detailId = searchParams.get('detailId')
    if (detailId) {
      const linkedItem = requestCartItems.find(requestCartItem => requestCartItem.id === detailId)
      linkedItem && setSelectedItem(linkedItem)
      setSearchParams({ detailId: '' })
    }
  }, [])

  return (
    <Layout>
      {requestAsset && <RequestCategoryMenu asset={requestAsset as RequestAssetType} />}
      {requestAsset && <RequestTagMenu asset={requestAsset as RequestAssetType} />}
      <Divider />
      <div className={CUSTOM_BLOCK} css={pcScreenStyle}>
        <ul css={listStyle}>
          {requestCartItems.map(requestCartItem => {
            const addedCart = findAddedCartItem(requestCartItem.id)

            return (
              <li
                key={requestCartItem.id}
                className={requestCartItem.isNowOnSale ? '' : 'no-sale'}
                onClick={() => setSelectedItem(requestCartItem)}
              >
                {addedCart && (
                  <span className="count-number" style={{ backgroundColor: design.theme.brandColor }}>
                    {addedCart.count}
                  </span>
                )}
                <div className="img" style={{ background: `url(${requestCartItem.imagePath}) center/cover no-repeat` }}></div>
                <h3 className="title" css={lineClampStyle(3)}>
                  {pickByLanguage(requestCartItem.name)}
                </h3>
                <p className="price">{displayYen(requestCartItem.price.toString())}</p>
              </li>
            )
          })}
        </ul>
      </div>
      {assetId && <RequestListFooter assetId={assetId} />}
      {selectedItem && assetId && <RequestDetailModal assetId={assetId} detail={selectedItem} onClose={onCloseModal} />}
    </Layout>
  )
}

const listStyle = css({
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: '0.75rem',
  rowGap: '1.5rem',
  paddingBlock: '2rem',
  paddingInline: '1rem',
  li: {
    position: 'relative',
    flex: '0 0 106px',
    fontSize: 12,
    '&.no-sale': {
      opacity: 0.5,
      pointerEvents: 'none',
    },
    '.img': {
      width: '100%',
      height: '90px',
      borderRadius: '4px',
    },
    '.count-number': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '14px',
      paddingBottom: 2,
      width: '24px',
      height: '24px',
      position: 'absolute',
      top: '-12px',
      right: '-12px',
      borderRadius: '50%',
      color: '#fff',
    },
    '.title': {
      marginTop: '0.5rem',
      marginBottom: '0.25rem',
      lineHeight: '1.25',
      fontWeight: 'bold',
    },
  },
})
