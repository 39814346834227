import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { NavigateHeader } from '@/components/atoms/navigate-header'
import { LinkWithArrowIcon } from '@/components/molecules/link-with-arrow-icon'
import { Loading } from '@/components/organisms/loading'
import { ContentWrapper } from '@/components/template/content-wrapper'
import { Layout } from '@/components/template/layout'

import { getUserPayment } from '@/features/user/payment/get-user-payment-slice'
import { textOverflowStyle } from '@/styles/common'
import { css } from '@emotion/react'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export const Invoice: React.FC = () => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const { userPaymentInfo, loading } = useAppSelector(state => state.userPayment.getUserPayment)

  useEffect(() => {
    dispatch(getUserPayment())
  }, [])

  return (
    <Layout>
      <NavigateHeader title={t('Invoice')} />
      <Loading loading={loading}>
        <ContentWrapper>
          {!isEmpty(userPaymentInfo) &&
            userPaymentInfo.map((paymentInfo, index) => (
              <LinkWithArrowIcon key={index} linkTo={`/mypage/usage-detail/${paymentInfo.orderId}`}>
                <p css={hotelNameStyle}>{paymentInfo.hotelName}</p>
                <p css={dateTimeStyle}>{dayjs(paymentInfo.createdAt).format('YYYY/MM/DD HH:mm')}</p>
                <p css={[orderIdStyle, textOverflowStyle]}>
                  {t('Transaction Number')}：{paymentInfo.orderId}
                </p>
              </LinkWithArrowIcon>
            ))}
        </ContentWrapper>
      </Loading>
    </Layout>
  )
}

const hotelNameStyle = css({
  fontWeight: 'bold',
  marginBottom: '0.5rem',
})

const dateTimeStyle = css({
  fontSize: '12px',
  marginBottom: '0.5rem',
})

const orderIdStyle = css({
  width: '50%',
  fontSize: '12px',
})
