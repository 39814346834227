import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { SubmitHandler, useForm } from 'react-hook-form'
import { css } from '@emotion/react'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { Layout } from '@/components/template/layout'
import { useQuerySearchParams } from '@/hooks/use-query-search-params'
import { fetchCustomFields } from '@/features/checkIn/custom-check-in-field-slice'
import { CheckInStart } from '@/components/organisms/check-in-start'
import { useTranslation } from 'react-i18next'
import { CheckInData, refineCheckInSchema, setCheckInForm } from '@/features/checkIn/check-in-slice'
import { zodResolver } from '@hookform/resolvers/zod'
import { isEmpty } from 'lodash'
import { searchCheckIn } from '@/features/checkIn/search-reservations-slice'
import { useDeleteCheckIn } from '@/hooks/use-delete-check-in'
import dayjs from 'dayjs'
import { pcScreenStyle } from '@/styles/common'

export const CheckIn: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const searchParams = useQuerySearchParams<{ hotelId: string }>()

  const dispatch = useAppDispatch()

  const inputCheckInData = useAppSelector(state => state.checkIn.checkInData.fields)
  const hotelGuideData = useAppSelector(state => state.hotelGuide)
  const { customCheckin } = useAppSelector(state => state.customFields)

  const useFormReturn = useForm<CheckInData>({
    mode: 'all',
    defaultValues: {
      checkInDate: inputCheckInData?.checkInDate || dayjs().format('YYYY/MM/DD'),
      checkInTime: hotelGuideData.facilityBasicInfo?.checkinBeginTime,
      guests: inputCheckInData?.guests || 1,
      knowContacts: false,
      companions: inputCheckInData?.companions || [],
      telephone: inputCheckInData?.telephone,
    },
    resolver: zodResolver(refineCheckInSchema),
    shouldFocusError: true,
  })
  const { handleSubmit } = useFormReturn

  const nextPageUrl = async (props: { telephone: string }) => {
    if (hotelGuideData.hasReservationPlugins) {
      const resultAction = await dispatch(searchCheckIn({ hotelId: searchParams.hotelId, reservationTel: props.telephone }))

      if (resultAction.meta.requestStatus === 'fulfilled') {
        return `/checkin-reservation-confirm?telephone=${props.telephone}`
      }

      return '/checkin-reservation-search'
    } else {
      return customCheckin.length ? '/custom-checkin' : '/checkin-confirm'
    }
  }

  const { deleteCheckIn } = useDeleteCheckIn()

  const onSubmit: SubmitHandler<CheckInData> = async checkInData => {
    deleteCheckIn()

    dispatch(
      setCheckInForm({
        ...checkInData,
        hotelId: hotelGuideData.facilityBasicInfo.hotelId,
        reservationTel: checkInData.telephone,
        previousAccommodation: 'Home',
        nextAccommodation: 'Home',
      }),
    )
    const pageUrl = await nextPageUrl({ telephone: checkInData.telephone })
    return navigate(pageUrl)
  }

  useEffect(() => {
    dispatch(fetchCustomFields({ hotelId: searchParams.hotelId }))
  }, [])

  return (
    <Layout>
      <div
        css={[titleStyle, { background: `url(${hotelGuideData.facilityBasicInfo.extImageId}) center no-repeat`, backgroundSize: 'cover' }]}
      >
        <h2 className="check-in-title">{t('Check-in')}</h2>
        <div className="background"></div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} css={pcScreenStyle}>
        <div css={layoutStyle}>
          {!isEmpty(hotelGuideData.facilityBasicInfo) && <CheckInStart hotelGuideData={hotelGuideData} useFormReturn={useFormReturn} />}
        </div>
      </form>
    </Layout>
  )
}

const titleStyle = css({
  display: 'flex',
  width: '100%',
  height: '164px',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  '.background': {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.36)',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 8,
  },
  '.check-in-title': {
    fontSize: '20px',
    fontWeight: 'bold',
    marginTop: '-40px',
    letterSpacing: '2px',
    position: 'relative',
    color: '#fff',
    zIndex: 9,
  },
})

const layoutStyle = css({
  position: 'relative',
  zIndex: 10,
  paddingLeft: '1.25rem',
  paddingRight: '1.25rem',
  marginTop: '-40px',
})
