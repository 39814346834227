import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { http } from '@/apis/aipass'
import { env } from '@/libs/env'
import { RootState } from '@/app/store'

export const fetchDesign = createAsyncThunk(
  'design/fetchDesign',
  async (arg: { hotelId: string; isForce?: boolean }) => {
    const response = await http({
      method: 'get',
      url: `${env('BASE_API_V3')}/guest/designs`,
      params: { hotelId: arg.hotelId },
    })
    return response.data
  },
  {
    dispatchConditionRejection: true,
    condition: (arg, { getState }) => {
      const { design } = getState() as RootState
      return arg.isForce || (!design.isFilled && !design.loading)
    },
  },
)

export const ButtonRoleType = {
  Primary: 'primary',
  Secondary: 'secondary',
} as const
export type ButtonRoleType = (typeof ButtonRoleType)[keyof typeof ButtonRoleType]

export type DesignType = {
  theme: ThemeType
  background: BackgroundType
  button: ButtonType[]
  form: FormType
  text: TextType
  icon: IconType
}
export type ThemeType = {
  brandColor: string
}
export type BackgroundType = {
  backgroundColor: string
  cardColor: string
}
export type ButtonType = {
  type: ButtonRoleType
  backgroundColor: string
  borderColor: string
  textColor: string
  borderRadius: string
}
export type FormType = {
  backgroundColor: string
  borderColor: string
  textColor: string
  borderRadius: string
}
export type TextType = {
  fontFamily: string
  headlineColor: string
  textColor: string
  linkColor: string
}
export type IconType = {
  color: string
}

export interface Design {
  theme: ThemeType
  background: BackgroundType
  button: ButtonType[]
  form: FormType
  text: TextType
  icon: IconType
}

const initialState = {
  isFilled: false,
  loading: false,
  theme: {
    brandColor: '#A69785',
  },
  background: {
    backgroundColor: '#F2F2F2',
    cardColor: '#FFFFFF',
  },
  button: [
    {
      type: 'primary',
      backgroundColor: '#A69785',
      borderColor: 'rgba(0,0,0,0)',
      textColor: '#FFFFFF',
      borderRadius: '5px',
    },
    {
      type: 'secondary',
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#A69785',
      textColor: '#A69785',
      borderRadius: '5px',
    },
  ],
  form: {
    backgroundColor: '#FFFFFF',
    borderColor: '#CCCCCC',
    textColor: '#272727',
    borderRadius: '5px',
  },
  text: {
    fontFamily: 'Noto Sans JP, Lato, sans-serif',
    headlineColor: '#A69785',
    textColor: '#272727',
    linkColor: '#A69785',
  },
  icon: {
    color: '#A69785',
  },
}

export const designSlice = createSlice({
  name: 'design',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchDesign.fulfilled, (state, action: PayloadAction<Design>) => {
      return { ...action.payload, loading: false, isFilled: true }
    })
    builder.addCase(fetchDesign.rejected, state => {
      state.loading = false
    })
    builder.addCase(fetchDesign.pending, state => {
      state.loading = true
    })
  },
})

export const designAction = designSlice.actions
export const designReducer = designSlice.reducer
