import { http } from '@/apis/aipass'
import { env } from '@/libs/env'
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { isAxiosError } from 'axios'
import { z } from 'zod'

export const SearchType = {
  Phone: 'phone',
  ReservationId: 'reservationId',
  GuestName: 'guestName',
} as const
export type SearchType = (typeof SearchType)[keyof typeof SearchType]

export const schema = z.discriminatedUnion('searchType', [
  z.object({
    searchType: z.literal(SearchType.ReservationId),
    reservationId: z.string().min(1),
  }),
  z.object({
    searchType: z.literal(SearchType.Phone),
    reservationTel: z.string().min(1),
  }),
  z.object({
    searchType: z.literal(SearchType.GuestName),
    reservationFamilyName: z.string().min(1),
    reservationGivenName: z.string().min(1),
  }),
])

export interface SearchReservationParams {
  hotelId: string
  reservationTel: string
  reservationId?: string
  reservationGivenName?: string
  reservationFamilyName?: string
}
export type SearchReservationInput = SearchReservationParams & {
  searchType: SearchType
}

export interface Reservation {
  checkinDate: string
  checkinTime: string
  checkoutDate: string
  checkoutTime: string
  guestAddr: string
  guestName: string
  guestNameEn: string
  guestNameKana: string
  guestTel: string
  id: string
  nights: number
  paxTotal: number
  plan: string
  planEn: string
  reservationId: string
  sales: any[]
  totalDiscount: number
  totalPayment: number
  totalPrice: number
  userName: string
  userNameEn: string
  userTel: string
  reservationRoom: {
    roomNum: number
    roomType: string
    typeName: string
  }[]
  roomNumber: string
}
interface SearchReservations {
  searchReservations: Reservation[]
}

interface ValidationErrors {
  errorMessage: string
  errorCode: number
}

export const getSessionSearchReservations = (): Reservation[] => {
  const sessionSearchReservations = sessionStorage.getItem('searchReservations')
  const searchReservations = sessionSearchReservations ? JSON.parse(sessionSearchReservations) : []

  return searchReservations
}

export const searchCheckIn = createAsyncThunk<SearchReservations, SearchReservationParams, { rejectValue: ValidationErrors }>(
  'checkIn/searchReservations',
  async (params: SearchReservationParams, { rejectWithValue }) => {
    try {
      const response = await http({
        method: 'get',
        url: `${env('BASE_API_V3')}/search-reservation`,
        params: params,
      })

      return response.data
    } catch (error) {
      if (isAxiosError(error)) {
        return rejectWithValue(error.response?.data)
      }
    }
  },
)

export const searchCheckInSlice = createSlice({
  name: 'reservations',
  initialState: {
    searchReservations: getSessionSearchReservations(),
    reservationIds: [] as string[],
    errorMessage: '' as string,
    loading: false,
  },
  reducers: {
    setReservationIds: (state, action: PayloadAction<string[]>) => {
      state.reservationIds = action.payload
    },
    clearErrorMessage: state => {
      state.errorMessage = ''
    },
    deleteSearchReservations: state => {
      state.searchReservations = []
      sessionStorage.removeItem('searchReservations')
    },
  },
  extraReducers(builder) {
    builder.addCase(searchCheckIn.fulfilled, (state, action: PayloadAction<SearchReservations>) => {
      state.searchReservations = action.payload.searchReservations
      sessionStorage.setItem('searchReservations', JSON.stringify(state.searchReservations))
      state.loading = false
    })
    builder.addCase(searchCheckIn.rejected, (state, action) => {
      if (action.payload) {
        state.errorMessage = action.payload.errorMessage
      }
    })
    builder.addCase(searchCheckIn.pending, state => {
      state.loading = true
    })
  },
})

export const { setReservationIds, clearErrorMessage, deleteSearchReservations } = searchCheckInSlice.actions
export const searchCheckInReducer = searchCheckInSlice.reducer
