import { http } from '@/apis/aipass'
import { RootState } from '@/app/store'
import { env } from '@/libs/env'
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const fetchCustomFields = createAsyncThunk(
  'checkIn/customFields',
  async (arg: { hotelId: string; isForce?: boolean }, { rejectWithValue }) => {
    try {
      const response = await http({
        method: 'get',
        url: `${env('BASE_API_V3')}/custom-checkin/custom-checkin`,
        params: { hotelId: arg.hotelId },
      })
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  {
    dispatchConditionRejection: true,
    condition: (arg, { getState }) => {
      const state = getState() as RootState
      if (arg.isForce) {
        return true
      }
      if (state.customFields.loading) {
        return false
      }
      return !state.customFields.customCheckin?.length
    },
  },
)

interface CustomField {
  customCheckinId: string
  enContent: string
  enInputValue: string[]
  enTitle: string
  inputType: string
  isRequired: number
  jpContent: string
  jpInputValue: string[]
  jpTitle: string
  koContent: string
  koInputValue: string[]
  koTitle: string
  value: string
  zhContent: string
  zhInputValue: string[]
  zhTitle: string
}

interface checkInCustomFields {
  customCheckin: CustomField[]
  loading: boolean
}

const getSessionCustomCheckInData = () => {
  const getSessionCustomCheckInData = sessionStorage.getItem('customCheckinData')
  const customCheckInData = getSessionCustomCheckInData ? JSON.parse(getSessionCustomCheckInData) : []

  return customCheckInData
}

const customFieldsSlice = createSlice({
  name: 'fetchCustomFields',
  initialState: {
    customCheckin: getSessionCustomCheckInData() as CustomField[],
    loading: false,
  },
  reducers: {
    setCustomField: (state, action: PayloadAction<{ customeField: CustomField; index: number }>) => {
      state.customCheckin[action.payload.index] = action.payload.customeField
    },
    restoreCustomCheckInData: (state) => {
      state.customCheckin = getSessionCustomCheckInData()
    },
    setCustomCheckInData: (_, action: PayloadAction<CustomField[]>) => {
      sessionStorage.setItem('customCheckinData', JSON.stringify(action.payload))
    },
    deleteCustomCheckInData: state => {
      state.customCheckin = []
      sessionStorage.removeItem('customCheckinData')
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchCustomFields.fulfilled, (state, action: PayloadAction<checkInCustomFields>) => {
      state.customCheckin = action.payload.customCheckin
      state.loading = false
    })
    builder.addCase(fetchCustomFields.rejected, state => {
      state.loading = false
    })
    builder.addCase(fetchCustomFields.pending, state => {
      state.loading = true
    })
  },
})

export const { setCustomField, restoreCustomCheckInData, setCustomCheckInData, deleteCustomCheckInData } = customFieldsSlice.actions
export const customFieldsReducer = customFieldsSlice.reducer
