import { css } from '@emotion/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { BoxTitle } from '../../atoms/box-title'
import { UseFormReturn } from 'react-hook-form'
import { ReservationReviewFormInput } from '@/features/checkout/checkout-reservation-slice'
import { CUSTOM_FORM } from '@/components/template/custom-design-style'

const REVIEW_POINT_NUMBER = 10

interface CheckoutRecommendScoreProps {
  useFormReturn: UseFormReturn<ReservationReviewFormInput>
}

export const CheckoutRecommendScore: React.FC<CheckoutRecommendScoreProps> = ({ useFormReturn }) => {
  const { t } = useTranslation()

  const { setValue, watch } = useFormReturn

  const watchPoint = watch('point')

  const onSelect = (value: string) => {
    const isSameValue = watchPoint === value

    if (isSameValue) {
      setValue('point', '')
      return
    }

    setValue('point', value.toString())
  }

  return (
    <>
      <BoxTitle title={t('How much do you want to recommend this stay to friends and family?')} />
      <ul css={[reviewList]}>
        {Array.from(Array(REVIEW_POINT_NUMBER)).map((number, index) => {
          const value = (index + 1).toString()
          const isSelected = watchPoint === value

          return (
            <li
              key={index}
              onClick={() => onSelect(value)}
              className={`${CUSTOM_FORM} ${isSelected ? 'selected' : ''}`}
            >
              {value}
            </li>
          )
        })}
      </ul>
    </>
  )
}

const reviewList = css({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '0.5rem',
  marginTop: '1.5rem',
  li: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    letterSpacing: '-0.48px',
    width: '24px',
    height: '32px',
    border: '1px solid',
    borderRadius: '5px',
  },
})
