import { css } from '@emotion/react'
import React from 'react'

export const KoContent: React.FC = () => {
  return (
    <div css={layoutStyle}>
      <div css={administratorStyle}>
        <h2 css={pageTitleStyle}>【제2판】정보보안 정책</h2>

        <p css={updateStyle}>최종 업데이트: 2024년4월1일</p>
      </div>

      <p css={leadTextStyle}>
        ＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿ <br />
        ISMS-01_ <br />
        정보보안 정책서 <br />
        개정 이력 제3판 <br />
        승인자：山田真由美 <br />
        내용 : 최고 경영자 이름 변경 <br />
        승인일： 2024年4月1日 <br />
        ＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿
      </p>

      <h3 css={semiTitleStyle}>1 목적</h3>
      <p css={leadTextStyle}>
        {`aipass 주식회사(이하 '당사')는 웹 애플리케이션의 기획, 개발, 운영 및 판매 사업, 그리고 종업원 관리(이하 '사업')를 수행함에 있어 많은
        정보자산을 이용하고 있기 때문에 정보 보안을 적절히 실현하고 정보자산의 보호에 노력하는 것은 노력하는 것은 사회의 신뢰 속에서 기업
        활동을 추진하기 위한 필수적인 요건이며, 중대한 사회적 책무라고 인식하고 있습니다. 따라서 당사는 정보보안의 중요성을 감안하여 본
        정보보안 방침(이하 '본 방침'이라 한다)을 정하고, 이를 구체적으로 실행하기 위한 정보보안 관리체계를 수립, 시행, 유지 및 개선해 나갈
        것입니다.`}
      </p>

      <h3 css={semiTitleStyle}>2 정보 보안의 정의</h3>
      <p css={leadTextStyle}>정보보안은 기밀성, 무결성 및 가용성을 유지하는 것으로 정의하고 있다.</p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          기밀성 <br />
          정보자산을 무단 접근 등으로부터 보호하고, 참조할 권한이 없는 자에게 유출되지 않도록 하는 것을 의미한다. (권한이 없는 개인, 단체
          또는 프로세스에 정보를 사용하게 하지 않고, 공개하지 않는 특성)
        </li>
        <li css={listItemStyle}>
          무결성 <br />
          정보자산이 변조나 오류로부터 보호되고, 정확하고 완전하게 유지되는 것을 의미한다. (정확성 및 완전성의 특성)
        </li>
        <li css={listItemStyle}>
          가용성 <br />
          정보자산을 분실, 훼손, 시스템 중단 등으로부터 보호하고, 필요할 때 이용할 수 있는 것을 의미한다. (인가된 주체가 요청할 때 접근 및
          사용이 가능한 특성)
        </li>
      </ol>

      <h3 css={semiTitleStyle}>3 적용 범위</h3>
      <p css={leadTextStyle}>
        본 방침은 당사가 관리하는 모든 정보자산에 대하여 적용합니다. 정보자산의 범위는 전자기기 및 전자 데이터에 국한되지 않고, 종이 매체를
        포함한 모든 형태를 포함합니다.
      </p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          조직 <br />
          aipass 주식회사 (전 직원)
        </li>
        <li css={listItemStyle}>
          시설 <br />
          本社본사（주소：Shinoda Building 3F, 23-3 Sakuragaokacho, Shibuya-ku, Tokyo）
        </li>
        <li css={listItemStyle}>
          업무 <br />웹 애플리케이션 기획, 개발, 운영 및 판매
        </li>
        <li css={listItemStyle}>
          자산 <br />
          상기 사업 및 각종 서비스와 관련된 문서, 데이터, 정보시스템, 네트워크 등
        </li>
      </ol>

      <h3 css={semiTitleStyle}>4 시행사항</h3>
      <p css={leadTextStyle}>본 방침 및 당사의 정보보안경영시스템에 따라 다음과 같은 사항을 시행합니다.</p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          정보 보안 목적에 대해 <br />본 방침과 일치하고, 적용되는 정보보안 요구사항 및 리스크 평가 및 리스크 대응 결과를 고려한 정보보안
          목적을 수립하여 전 임직원에게 공지하고, 회사의 환경변화에 따라 수시로, 또는 변경 없이 정기적인 재검토를 실시합니다.
        </li>
        <li css={listItemStyle}>
          정보자산의 취급에 대하여 <br />
          <ol css={listDiscStyle}>
            <li>접근 권한은 업무상 필요한 자에게만 부여한다.</li>
            <li>법적, 규제적 요구사항 및 계약상 요구사항, 당사의 정보보안관리시스템의 규정에 따라 관리합니다.</li>
            <li>정보자산의 가치, 기밀성, 무결성, 가용성 측면에서 중요도에 따라 적절히 분류하고 관리한다.</li>
            <li>정보 자산이 적절하게 관리되고 있는지 확인하기 위해 지속적인 모니터링을 실시합니다.</li>
          </ol>
        </li>
        <li css={listItemStyle}>
          리스크 평가에 대해 <br />
          <ol css={listDiscStyle}>
            <li>
              위험평가를 실시하여 사업 특성상 가장 중요하다고 판단되는 정보자산에 대해 적절한 위험 대응을 실시하고 관리 방안을 도입합니다.
            </li>
            <li>정보보안 관련 사고 원인을 분석하고 재발 방지책을 마련한다.</li>
          </ol>
        </li>
        <li css={listItemStyle}>
          비즈니스 연속성 관리에 대하여 <br />
          재해, 고장 등으로 인한 사업 중단을 최소화하여 사업 연속성을 확보합니다.
        </li>
        <li css={listItemStyle}>
          교육에 대하여 <br />전 직원을 대상으로 정보보안 교육 및 훈련을 실시합니다.
        </li>
        <li css={listItemStyle}>
          규정 및 절차 준수 <br />
          정보보안경영시스템의 규정 및 절차를 준수합니다.
        </li>
        <li css={listItemStyle}>
          법적, 규제적 요구사항 및 계약상 요구사항 준수 <br />
          정보 보안에 관한 법적, 규제적 요구사항 및 계약상 요구사항을 준수합니다.
        </li>
        <li css={listItemStyle}>
          지속적인 개선 <br />
          정보 보안 관리 시스템을 지속적으로 개선하기 위해 노력합니다.
        </li>
        <li css={listItemStyle}>
          정보보안을 위한 정책군 <br />
          정보보안정책과 관련된 정보보안을 위한 정책군으로 다음과 같이 정한다.
        </li>
      </ol>
      <p css={leadTextStyle}>
        모바일 기기 관련 정책군a <br />
        액세스 제어 <br />
        암호화를 통한 관리 방안 이용 정책 <br />
        클리어 데스크, 클리어 스크린 정책 <br />
        정보 전송에 관한 정책 <br />
        보안을 고려한 개발을 위한 정책 <br />
        공급자 관계를 위한 정보 보안 정책 <br />
      </p>

      <h3 css={semiTitleStyle}>5 책임과 의무 및 벌칙</h3>
      <p css={leadTextStyle}>
        본 방침을 포함한 정보보안 관리체계에 대한 책임은 최고 경영자가 지며, 적용범위 내 임직원은 수립된 규정과 절차를 준수할 의무가 있다.
        또한 의무를 소홀히 하고 위반행위를 한 직원은 취업규칙에서 정하는 바에 따라 처분한다. 협력사 직원에 대해서는 개별적으로 정한 계약
        등에 따라 대응합니다.
      </p>

      <h3 css={semiTitleStyle}>6 정기적인 검토</h3>
      <p css={leadTextStyle}>정보보안 관리체계의 검토는 정기적 및 필요에 따라 검토하고 유지, 관리하여야 한다.</p>

      <p css={[leadTextStyle, { marginTop: '2rem' }]}>
        제정：2021년4월1일 <br />
        최종개정일：2024年년4월1일 <br />
        최고 경영자 山田 真由美
        <br />
      </p>
    </div>
  )
}

const layoutStyle = css({
  marginTop: '5rem',
  paddingRight: '2.5rem',
  paddingLeft: '2.5rem',
  lineHeight: '1.625',
})

const pageTitleStyle = css({
  fontWeight: '600',
  textAlign: 'center',
  fontSize: '20px',
})

const administratorStyle = css({
  textAlign: 'center',
})

const updateStyle = css({
  marginTop: '24px',
  marginBottom: '24px',
  fontSize: '14px',
  color: '#475569',
})

const semiTitleStyle = css({
  fontWeight: '600',
  marginTop: '32px',
  marginBottom: '8px',
})

const leadTextStyle = css({
  fontSize: '12px',
  marginTop: '1.5rem',
  marginBottom: '1.5rem',
  lineHeight: '1.625',
})

const listDiscStyle = css({
  listStyle: 'disc',
  marginRight: '1rem',
  marginLeft: '1rem',
  li: {
    listStyle: 'disc',
  },
})

const listStyle = css({
  paddingLeft: '1rem',
  paddingRight: '1rem',
  marginBottom: '1rem',
  listStyleType: 'decimal',
})

const listItemStyle = css({
  fontSize: '12px',
  lineHeight: '1.625',
  listStyleType: 'decimal',
})
