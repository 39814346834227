import React, { CSSProperties, useEffect, useMemo, useState } from 'react'
import { css } from '@emotion/react'
import { useAppSelector } from '@/app/hooks'
import { AssetType, FormatType, isMapAsset, isNewsAsset, isRequestAsset } from '@/features/asset/assets-slice'
import { usePickByLanguage } from '@/hooks/use-pick-by-language'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { MenuDeleteIcon, MenuHamburgerIcon } from '../atoms/icon'
import { v4 as uuid } from 'uuid'
import { CUSTOM_BLOCK, CUSTOM_LINK } from '../template/custom-design-style'
import { Divider } from '../atoms/divider'
import { pcScreenStyle } from '@/styles/common'

export const MainMenu: React.FC<{ style: CSSProperties }> = ({ style }) => {
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false)
  const { assets } = useAppSelector(state => state.assets)
  const { t } = useTranslation()
  const pickByLanguage = usePickByLanguage()
  const navigate = useNavigate()

  const navigateCustomAsset = (asset: AssetType) => {
    const homeAnchorAsset = [FormatType.Free, FormatType.Pulldown, FormatType.Coupon, FormatType.Space] as FormatType[]
    if (homeAnchorAsset.includes(asset.formatType)) {
      navigate(`/?hash=${asset.id}&key=${uuid()}`)
    }

    if (isMapAsset(asset)) {
      navigate(`/around-spot/${asset.id}`)
    }

    if (isNewsAsset(asset)) {
      navigate(`/notice/${asset.id}`)
    }

    if (isRequestAsset(asset)) {
      navigate(`/request/${asset.id}`)
    }

    closeMenu()
  }

  const openMenu = () => {
    setIsOpenMenu(true)
    setPageScroll(false)
  }
  const closeMenu = () => {
    setIsOpenMenu(false)
    setPageScroll(true)
  }

  const setPageScroll = (isEnabled: boolean) => {
    const body = document.getElementsByTagName('body')[0]
    isEnabled ? body?.attributeStyleMap?.delete('overflow-y') : body?.attributeStyleMap?.set('overflow-y', 'hidden')
  }

  const pickAssetByType = (type: FormatType) => {
    return assets.find(asset => asset.formatType === type)
  }

  const wrapNavigate = (to: string) => {
    closeMenu()
    navigate(to)
  }

  const fixAssets = useMemo(
    () => assets.filter(asset => ([FormatType.CheckIn, FormatType.Checkout] as FormatType[]).includes(asset.formatType)),
    [assets],
  )

  const customAssets = useMemo(
    () =>
      assets.filter(
        asset =>
          !([FormatType.CheckIn, FormatType.Checkout, FormatType.Button1, FormatType.Button2, FormatType.Image] as FormatType[]).includes(
            asset.formatType,
          ),
      ),
    [assets],
  )

  useEffect(() => closeMenu, [])

  return (
    <>
      {isOpenMenu && (
        <>
          <MenuDeleteIcon onClick={() => closeMenu()} />
          <div css={[overlayMenu]} style={style} className={CUSTOM_BLOCK}>
            {!!fixAssets.length && (
              <div className="item-section" css={pcScreenStyle}>
                {pickAssetByType(FormatType.CheckIn) && (
                  <div className={`menu-item ${CUSTOM_LINK}`} onClick={() => wrapNavigate('/checkin')}>
                    {pickByLanguage(pickAssetByType(FormatType.CheckIn)!.title)}
                  </div>
                )}
                {pickAssetByType(FormatType.Checkout) && (
                  <div className={`menu-item ${CUSTOM_LINK}`} onClick={() => wrapNavigate('/checkout')}>
                    {pickByLanguage(pickAssetByType(FormatType.Checkout)!.title)}
                  </div>
                )}
                <Divider customCss={css({ margin: '24px 0 0 0' })} />
              </div>
            )}
            {!!customAssets.length && (
              <div className="item-section" css={pcScreenStyle}>
                {customAssets.map(asset => (
                  <div key={asset.id} className={`menu-item ${CUSTOM_LINK}`} onClick={() => navigateCustomAsset(asset)}>
                    {pickByLanguage(asset.title)}
                  </div>
                ))}
                <Divider customCss={css({ margin: '24px 0 0 0' })} />
              </div>
            )}
            <div className="item-section" css={pcScreenStyle}>
              <div className={`menu-item ${CUSTOM_LINK}`} onClick={() => wrapNavigate('/mypage')}>
                {t('My Page')}
              </div>
              <div className={`menu-item ${CUSTOM_LINK}`} onClick={() => wrapNavigate('/mypage/checkin-history')}>
                {t('Check-in Information')}
              </div>
            </div>
          </div>
        </>
      )}
      {!isOpenMenu && <MenuHamburgerIcon style={{ height: '24px' }} onClick={() => openMenu()} />}
    </>
  )
}
const overlayMenu = css({
  padding: '0 24px',
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100vh',
  width: '100vw',
  overflow: 'auto',
  '.item-section': {
    display: 'flex',
    flexFlow: 'column',
    '.menu-item': {
      padding: 12,
      fontSize: 14,
    },
  },
})
