import { http } from '@/apis/aipass'
import { env } from '@/libs/env'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type requestOrderType = {
  checkinId?: string
  roomNumber: string
  firstName?: string
  lastName?: string
  paymentMethod: number
  mobileRequestOrderId: string | undefined | null
  products: Array<{ id: string; numberOfItems: number }>
}

export const makeRequestOrder = async (data: { hotelId: string; order: requestOrderType }) => {
  const response = await http<{ orderId: string }>({
    method: 'post',
    url: `${env('BASE_API_V3')}/mobile-request/order`,
    data,
  })

  return response.data
}

interface OrdersInfo {
  firstName: string
  lastName: string
  roomNumber: string
}
export const requestOrderSlice = createSlice({
  name: 'requestOrder',
  initialState: {
    selectedRoom: '',
    ordersInfo: {} as OrdersInfo,
  },
  reducers: {
    setSelectedRoom: (state, action: PayloadAction<{ room: string }>) => {
      state.selectedRoom = action.payload.room
    },
    ordersInfoForNoHasReservationPlugin: (state, action: PayloadAction<OrdersInfo>) => {
      state.ordersInfo = action.payload
    },
  },
})

export const { setSelectedRoom, ordersInfoForNoHasReservationPlugin } = requestOrderSlice.actions
export const requestOrderReducer = requestOrderSlice.reducer
