import React, { useEffect, useState } from 'react'
import { CouponAssetDetailType, fetchAsset } from '@/features/asset/assets-slice'
import { css } from '@emotion/react'
import { usePickByLanguage } from '@/hooks/use-pick-by-language'
import { CUSTOM_BLOCK } from '@/components/template/custom-design-style'
import { Link, useLocation } from 'react-router-dom'
import { Button } from '@/components/atoms/button'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { LoginBlock } from '../login-block'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { useCoupon as callUseCoupon, isAuthCoupon, isNeedCheckedInCoupon } from '@/features/coupon/coupon-slice'
import { useHotelId } from '@/hooks/use-hotel-id'
import { getUserCheckInHistoryList } from '@/features/user/check-in-history-slice'
import { isEmpty } from 'lodash'
import { ContentWrapper } from '@/components/template/content-wrapper'
import { minScreen } from '@/styles/media-query'

type Props = {
  coupon: CouponAssetDetailType
}

export const CouponDetail: React.FC<Props> = ({ coupon }) => {
  const [isUsed, setIsUsed] = useState(false)

  const { t } = useTranslation()
  const { pathname } = useLocation()
  const pickByLanguage = usePickByLanguage()
  const dispatch = useAppDispatch()
  const { getHotelId } = useHotelId()
  const hotelId = getHotelId()
  const accountInfo = useAppSelector(state => state.accountInfo.user)
  const userCheckInHistoryList = useAppSelector(state => state.userCheckInHistoryList.checkinHistorys)
  const { isLoading } = useAppSelector(state => state.coupon)

  const usingCoupon = async (couponId: string) => {
    await dispatch(callUseCoupon(couponId))
    setIsUsed(true)
  }

  const _getUserCheckInHistoryList = async () => {
    await dispatch(getUserCheckInHistoryList(accountInfo.id)).unwrap()
  }

  useEffect(() => {
    if (accountInfo.id && isEmpty(userCheckInHistoryList)) {
      _getUserCheckInHistoryList()
    }
  }, [accountInfo])

  useEffect(() => {
    if (hotelId) {
      dispatch(fetchAsset({ hotelId, isForce: true }))
    }
  }, [hotelId])

  const hasCheckedInHistory = !isEmpty(userCheckInHistoryList.filter(userCheckInHistory => userCheckInHistory.hotelId === hotelId))
  const noCheckedInHistory = isNeedCheckedInCoupon(coupon.scopeUsage) && !hasCheckedInHistory

  return (
    <div css={pageStyle}>
      <img src={coupon.imagePath}></img>
      <ContentWrapper>
        <div className={`content-area ${CUSTOM_BLOCK}`}>
          <div className="title">{pickByLanguage(coupon.title)}</div>
          <div className="facility">{pickByLanguage(coupon.facilityName)}</div>
          <div className="description">{pickByLanguage(coupon.description)}</div>
          <div className="expire">
            {`${t('Expiration Date')}：${dayjs(coupon.expireFrom).format('YYYY/MM/DD')}~${dayjs(coupon.expireTo).format('YYYY/MM/DD')}`}
          </div>
          {coupon.url && (
            <div className="link">
              <Link to={coupon.url} target="_blank" rel="noopener noreferrer">
                {t('click here for store details')}
              </Link>
            </div>
          )}
        </div>

        <div className={`usage ${CUSTOM_BLOCK}`}>
          {isAuthCoupon(coupon.scopeUsage) && !accountInfo?.id ? (
            <>
              <p className="need-login">
                {t(
                  isNeedCheckedInCoupon(coupon.scopeUsage)
                    ? 'Please check-in in advance and login to use this coupon'
                    : 'Email address and password are required to use this coupon',
                )}
              </p>
              <LoginBlock redirectFrom={pathname} />
            </>
          ) : (
            <>
              <div className="message">{t('show this screen to store staff')}</div>
              {isUsed || coupon.isUsed ? (
                <Button text={t('already used')} disabled />
              ) : (
                <Button
                  text={t('mark as used')}
                  loading={isLoading}
                  disabled={isLoading || noCheckedInHistory}
                  onClick={() => usingCoupon(coupon.id)}
                />
              )}
            </>
          )}
        </div>
      </ContentWrapper>
    </div>
  )
}

const pageStyle = css({
  '>img': {
    width: '100%',
    [minScreen()]: {
      height: 250,
      objectFit: 'cover',
    }
  },
  '.content-area': {
    '*': {
      fontSize: 14,
      letterSpacing: 0.7,
      whiteSpace: 'pre-wrap',
    },
    padding: '24px 16px 32px 16px',
    '.title': {
      fontSize: 20,
      fontWeight: 'bold',
      letterSpacing: 1,
    },
    '.facility': { paddingTop: 8 },
    '.description': { paddingTop: 24, lineHeight: 1.5 },
    '.expire': { paddingTop: 24 },
    '.link': { paddingTop: 8 },
  },
  '.usage': {
    padding: '32px 16px',
    marginTop: 8,
    '.message': {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      paddingBottom: 24,
      fontSize: 16,
      fontWeight: 'bold',
      lineHeight: '24px',
    },
    '.need-login': {
      fontSize: 16,
      whiteSpace: 'pre-wrap',
      textAlign: 'center',
      fontWeight: 'bold',
      lineHeight: 1.5,
    },
  },
})
