import React from 'react'
import { BorderButton } from '@/components/atoms/button'
import { ModalWrapper } from '@/components/atoms/modal-wrapper'
import { css } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useBreakPoint } from '@/hooks/use-break-point'
import { SpaceDetail } from '@/features/space/spaces-slice'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@/app/store'
import { setHasRoomSettingKey } from '@/features/space/space-reservation-slice'

interface ReservationConfirmModalProps {
  modalOpen: boolean
  spaceInfo: SpaceDetail
}

export const ReservationConfirmModal: React.FC<ReservationConfirmModalProps> = ({ modalOpen, spaceInfo }) => {
  const { t } = useTranslation()

  const { spaceId } = useParams<{ assetId: string; spaceId: string }>()

  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  const { isPc } = useBreakPoint()

  const navigateSearchReservation = () => {
    dispatch(setHasRoomSettingKey(spaceInfo.hasRoomKeySetting))
    navigate('/space/reservation-search')
  }

  return (
    <ModalWrapper isOpen={modalOpen} modalStyle={{ height: 160, padding: '32px 0', width: isPc ? '343px' : 'auto' }}>
      <div css={contentStyle}>
        <p>{t('Have you booked our hotel?')}</p>

        <div className="button-wrapper">
          <BorderButton text={t('Yes')} onClick={() => navigateSearchReservation()} />
          <BorderButton text={t('No')} onClick={() => navigate(`/space/${spaceId}/application-confirm`)} />
        </div>
      </div>
    </ModalWrapper>
  )
}

const contentStyle = css({
  p: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 24,
    letterSpacing: 1.8,
  },
  '.button-wrapper': {
    display: 'flex',
    gap: 24,
    paddingInline: 36,
  },
})
