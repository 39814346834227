import { SerializedStyles, css } from '@emotion/react'
import React from 'react'
import { CUSTOM_BLOCK } from './custom-design-style'

interface FormLayoutProps {
  id?: string
  children: React.ReactNode
  layoutCss?: SerializedStyles
}

export const FormLayout: React.FC<FormLayoutProps> = ({ id, children, layoutCss }) => {
  return (
    <div id={id} css={[formLayoutStyle, layoutCss]} className={CUSTOM_BLOCK}>
      {children}
    </div>
  )
}

const formLayoutStyle = css({
  padding: '2rem',
})
