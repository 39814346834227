import React from 'react'
import { Layout } from '../../template/layout'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@/app/store'
import { SearchReservationInput, schema } from '@/features/checkIn/search-reservations-slice'

import { useQuerySearchParams } from '@/hooks/use-query-search-params'
import { useHotelId } from '@/hooks/use-hotel-id'
import { Search } from '@/components/organisms/reservation/search'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { zodResolver } from '@hookform/resolvers/zod'
import { SpaceSearchReservationParams, searchReservationForSpace } from '@/features/space/space-search-reservation-slice'
import { useAppSelector } from '@/app/hooks'
import dayjs from 'dayjs'
import { errorHandler } from '@/libs/errors'
import { NavigateHeader } from '@/components/atoms/navigate-header'
import { css } from '@emotion/react'
import { useTranslation } from 'react-i18next'

export const SpaceReservationSearch: React.FC = () => {
  const { hotelId } = useQuerySearchParams<{ hotelId: string }>()
  const { getHotelId } = useHotelId()
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()

  const navigate = useNavigate()

  const useFormReturn = useForm<SearchReservationInput>({
    resolver: zodResolver(schema),
  })

  const { formValues } = useAppSelector(state => state.spaces)

  const onSearch: SubmitHandler<SearchReservationInput> = async submitData => {
    if (!formValues) {
      return
    }

    const params: SpaceSearchReservationParams = {
      hotelId: getHotelId() || hotelId,
      reservationTel: submitData.reservationTel,
      reservationId: submitData.reservationId,
      reservationGivenName: submitData.reservationGivenName,
      reservationFamilyName: submitData.reservationFamilyName,
      searchDate: dayjs(formValues.useDay).format('YYYY-MM-DD'),
    }

    try {
      await dispatch(searchReservationForSpace(params)).unwrap()

      return navigate('/space/reservation-select')
    } catch (error) {
      errorHandler({ error })
    }
  }

  return (
    <Layout>
      <NavigateHeader title={t('Search scheduled reservation')} />
      <p css={leadTextStyle}>{t('Please enter your reservation information')}</p>
      <Search onSearch={onSearch} useFormReturn={useFormReturn} />
    </Layout>
  )
}
const leadTextStyle = css({
  textAlign: 'center',
  fontSize: '14px',
  padding: '16px 0',
})
