import { textOverflowStyle } from '@/styles/common'
import { css } from '@emotion/react'
import React from 'react'

interface ListItemProps {
  title: string
  text?: string | number
  textSmall?: boolean
}

export const ListItem: React.FC<ListItemProps> = ({ title, text, textSmall }) => {
  return (
    <div css={[listItemStyle]}>
      <span>{title}</span>
      <span css={[textOverflowStyle, { fontSize: textSmall ? '12px' : '14px' }]}>{text || '-'}</span>
    </div>
  )
}

const listItemStyle = css({
  display: 'flex',
  span: {
    lineHeight: '14px',
    '&:first-of-type': {
      flex: '0 0 33%',
      fontSize: '12px',
      fontWeight: 'bold',
      marginRight: '2%',
    },
    '&:last-of-type': {
      flex: '0 0 65%',
      lineHeight: 1.5,
      whiteSpace: 'pre-wrap',
      marginTop: 'calc((14px * 1.5 - 14px) / 2 * -1)',
    },
  },
})
