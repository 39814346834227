import { http } from '@/apis/aipass'
import { env } from '@/libs/env'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

interface Payment {
  accountId: string
  amount: number
  checkinId: string | null
  createdAt: string
  hotelId: string
  hotelName: string
  orderId: string
  reservationIds: string[]
}

export const getUserPayment = createAsyncThunk('user/getUserPayment', async (_, { rejectWithValue }) => {
  try {
    const response = await http({
      method: 'get',
      url: `${env('BASE_API_V3')}/me/account-payment`,
    })
  
    return response.data.userPayment
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const getUserPaymentSlice = createSlice({
  name: 'userPayment',
  initialState: {
    userPaymentInfo: [] as Payment[],
    loading: true,
  },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getUserPayment.fulfilled, (state, action) => {
      state.userPaymentInfo = action.payload
      state.loading = false
    })
  },
})

export const getUserPaymentReducer = getUserPaymentSlice.reducer
