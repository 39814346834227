import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { t } from 'i18next'
import { z } from 'zod'
import { v4 as uuid } from 'uuid'
import { Reservation } from './search-reservations-slice'

export const getSessionCheckInData = (): CheckInData => {
  const sessionCheckInData = sessionStorage.getItem('checkinData')
  const checkInData = sessionCheckInData ? JSON.parse(sessionCheckInData) : {}

  return checkInData
}
export const checkInSchema = z.object({
  checkInTime: z.string().min(1),
  guests: z.coerce.number(),
  telephone: z.string().min(1),
  checkInDate: z.string().min(1),
  companions: z.array(z.object({ firstName: z.string().nullable(), lastName: z.string().nullable() })),
  knowContacts: z.boolean().nullable(),
})
export const refineCheckInSchema = checkInSchema.superRefine((value, ctx) => {
  if (value.guests >= 2 && !value.knowContacts) {
    const errorText = t('This field is required')
    value.companions.forEach((value, index) => {
      if (!value.lastName) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: errorText,
          path: ['companions', index, 'lastName']
        })
      }

      if (!value.firstName) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: errorText,
          path: ['companions', index, 'firstName']
        })
      }
    })
  }
})

export interface CheckInData {
  id: string
  telephone: string
  checkInDate: string
  checkInTime: string
  guests: number
  knowContacts: boolean
  companions: { firstName: string; lastName: string }[]
  previousAccommodation: string
  nextAccommodation: string
  reservationGivenName?: string
  reservationFamilyName?: string
  previousPlace?: string
  nextPlace?: string
  hotelId?: string
  reservationTel?: string
  reservationId?: string
  reservationIds?: string[]
  selectedReservations?: Reservation[]
}

export const initialState = {
  fields: getSessionCheckInData(),
}

export const checkInDataSlice = createSlice({
  name: 'checkIn',
  initialState,
  reducers: {
    setCheckInForm: (state, action: PayloadAction<CheckInData>) => {
      state.fields = {
        ...action.payload,
        id: action.payload.id || uuid(),
      }
      sessionStorage.setItem('checkinData', JSON.stringify(state.fields))
    },
    updateCheckInData: (state, action: PayloadAction<Partial<CheckInData>>) => {
      const field = { ...state.fields, ...action.payload } as CheckInData
      state.fields = field
      sessionStorage.setItem('checkinData', JSON.stringify(field))
    },
    deleteCheckInData: state => {
      state.fields = {} as CheckInData
      sessionStorage.removeItem('checkinData')
    },
  },
})

export const { setCheckInForm, updateCheckInData, deleteCheckInData } = checkInDataSlice.actions
export const checkInDataReducer = checkInDataSlice.reducer
