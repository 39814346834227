import { combineReducers } from "@reduxjs/toolkit";
import { creditCardPaymentReducer } from "./credit-card-pay-slice";
import { checkoutDataReducer } from "./checkout-data-slice";
import { checkoutReservationReducer } from "./checkout-reservation-slice";
import { reservationsPaymentReducer } from "./checkout-payment-slice";

export const checkoutReducer = combineReducers({
  creditCardPayment: creditCardPaymentReducer,
  checkoutData: checkoutDataReducer,
  checkoutReservation: checkoutReservationReducer,
  reservationsPayment: reservationsPaymentReducer
})