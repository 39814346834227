import { http } from '@/apis/aipass'
import { env } from '@/libs/env'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AxiosError, isAxiosError } from 'axios'

export const checkEmail = createAsyncThunk('auth/checkEmail', async (email: string, { rejectWithValue }) => {
  try {
    const response = await http({
      method: 'post',
      url: `${env('BASE_API_V3')}/auth/check-email`,
      data: { email },
    })
    return response.data
  } catch (error) {
    return rejectWithValue(error)
  }
})

interface forgotPasswordParams {
  email: string
  hotelId: string
  language: string
}
export const forgotPassword = createAsyncThunk('auth/forgotPassword', async (data: forgotPasswordParams, { rejectWithValue }) => {
  try {
    const response = await http({
      method: 'post',
      url: `${env('BASE_API_V3')}/auth/forgot-password`,
      data,
    })
    return response.data
  } catch (error) {
    return rejectWithValue(error)
  }
})
export const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState: {
    loading: false
  },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(forgotPassword.pending, (state) => {
      state.loading = true
    })
    builder.addCase(forgotPassword.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(forgotPassword.rejected, (state) => {
      state.loading = false
    })
  },
})
export const forgotPasswordReducer = forgotPasswordSlice.reducer

export const checkEmailSlice = createSlice({
  name: 'checkEmail',
  initialState: {},
  reducers: {},
})

interface signInParams {
  email: string
  password: string
}
export const signIn = createAsyncThunk<{ token: string }, signInParams, { rejectValue: AxiosError | string }>(
  'auth/signIn',
  async (user: signInParams, { rejectWithValue }) => {
    try {
      const response = await http({
        method: 'post',
        url: `${env('BASE_API_V3')}/auth/login`,
        data: { ...user },
      })

      return response.data
    } catch (error) {
      if (isAxiosError(error)) {
        if (error.response?.status === 400) {
          return rejectWithValue('Wrong email or password.')
        }
        return rejectWithValue(error)
      }
    }
  },
)
interface SignIn {
  token: string
  errorMessage?: string
  errorCode?: string
}
const signInSlice = createSlice({
  name: 'signIn',
  initialState: {
    token: '',
  } as SignIn,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(signIn.fulfilled, (state, action) => {
      state.token = action.payload?.token
      localStorage?.setItem('access_token', action.payload?.token)
    })
    builder.addCase(signIn.rejected, (state, action) => {
      state.errorCode = action.error?.code
    })
  },
})

export const signInBySns = createAsyncThunk<{ user: object; token: string }, { idToken: string }, { rejectValue: AxiosError | string }>(
  'auth/auth0-callback',
  async (params, { rejectWithValue }) => {
    try {
      const response = await http({
        method: 'post',
        url: `${env('BASE_API_V3')}/auth/auth0-callback`,
        headers: { Authorization: params.idToken },
      })
      return response.data
    } catch (error) {
      return isAxiosError(error) ? rejectWithValue(error) : error
    }
  },
)
const signInBySnsSlice = createSlice({
  name: 'signInBySns',
  initialState: {
    token: '',
  } as SignIn,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(signInBySns.fulfilled, (state, action) => {
      state.token = action.payload?.token
      localStorage?.setItem('access_token', action.payload?.token)
    })
    builder.addCase(signInBySns.rejected, (state, action) => {
      state.errorCode = action.error?.code
    })
  },
})

interface signUpParams {
  email: string
  password: string
  language: string
}
export const signUp = createAsyncThunk('auth/signUp', async (user: signUpParams, { rejectWithValue }) => {
  try {
    const response = await http({
      method: 'post',
      url: `${env('BASE_API_V3')}/auth/register`,
      data: { ...user },
    })

    return response.data
  } catch (error) {
    return rejectWithValue(error)
  }
})
const signUpSlice = createSlice({
  name: 'signUp',
  initialState: {},
  reducers: {},
  extraReducers(builder) {
    builder.addCase(signUp.fulfilled, (_, action) => {
      localStorage?.setItem('access_token', action.payload?.token)
    })
  },
})

export const checkEmailReducer = checkEmailSlice.reducer
export const signInReducer = signInSlice.reducer
export const signInBySnsReducer = signInBySnsSlice.reducer
export const signUpReducer = signUpSlice.reducer
