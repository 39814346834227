import React, { useEffect, useMemo, useRef, useState } from 'react'
import { css } from '@emotion/react'
import { Button } from '@/components/atoms/button'
import { PictureIcon } from '@/components/atoms/icon'
import { useTranslation } from 'react-i18next'
import html2canvas from 'html2canvas'
import dayjs from 'dayjs'
import { omit } from 'lodash'
import { Base64 } from 'js-base64'
import { QrCode } from '@/components/atoms/qr-code'
import { CUSTOM_BLOCK, CUSTOM_BORDER } from '@/components/template/custom-design-style'
import { ContentWrapper } from '@/components/template/content-wrapper'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { getAccommodationInfo } from '@/features/reservation/reservation-slice'

export const CheckInQrDownload: React.FC = () => {
  const { t } = useTranslation()
  const qrCodeBlockRef = useRef<HTMLDivElement>(null)
  const checkInApproveData = useAppSelector(state => state.smartCheckIn.approvedCheckInData)
  const dispatch = useAppDispatch()
  const [accommodationInfo, setAccommodationInfo] = useState<any>()

  const downloadImageName = useMemo(() => {
    return `${accommodationInfo?.facilityBasicInfo.hotelName}_${accommodationInfo?.checkin.checkinCode}.jpeg`
  }, [accommodationInfo])

  const checkInQrCode = useMemo(() => {
    if (!checkInApproveData?.qrJson) {
      return ''
    }
    const qrJsonObj = omit(JSON.parse(checkInApproveData.qrJson), ['pass_id'])
    return Base64.encode(JSON.stringify(qrJsonObj))
  }, [checkInApproveData])

  useEffect(() => {
    dispatch(getAccommodationInfo(checkInApproveData.checkinId))
      .unwrap()
      .then(accommodationInfo => {
        setAccommodationInfo(accommodationInfo)
      })
  }, [])

  return (
    <>
      <div ref={qrCodeBlockRef} style={{ display: 'none' }} id="qr-code-block">
        <ContentWrapper>
          <div css={infoBlockStyle} className={CUSTOM_BLOCK}>
            <h3 css={hotelNameStyle}>{accommodationInfo?.facilityBasicInfo.hotelName}</h3>
            <div>
              <dl css={dlStyle}>
                <dt>{t('Check-in datetime')}</dt>
                <dd>{dayjs(accommodationInfo?.checkin.checkinTime).format('YY/MM/DD HH:mm')}</dd>
              </dl>
              <dl css={dlStyle}>
                <dt>{t('Check-in ID')}</dt>
                <dd>{accommodationInfo?.checkin.checkinCode}</dd>
              </dl>
              <dl css={dlStyle}>
                <dt>{t('Full name.2')}</dt>
                <dd>{accommodationInfo?.checkin.name}</dd>
              </dl>
            </div>
            <div css={qrCodeStyle} className={CUSTOM_BORDER}>
              <QrCode qrCode={checkInQrCode} />
            </div>
          </div>
        </ContentWrapper>
      </div>

      <Button
        text={t('Save to photo folder')}
        buttonCss={css({ padding: '0.5rem 1.5rem 0 1.5rem' })}
        icon={<PictureIcon />}
        onClick={async () => {
          if (!qrCodeBlockRef?.current) {
            return
          }
          const qrBlockCanvas = await html2canvas(qrCodeBlockRef.current!, {
            onclone: doc => {
              const div = doc.getElementById('qr-code-block')
              div!.style.display = 'block'
            },
          })
          const aElement = document.createElement('a')
          aElement.href = qrBlockCanvas.toDataURL('image/jpeg')
          aElement.download = downloadImageName
          aElement.target = '_blank'
          aElement.click()
        }}
      />
    </>
  )
}

const infoBlockStyle = css({ padding: '60px 38px 30px 38px' })
const hotelNameStyle = css({
  fontSize: '24px',
  fontWeight: 'bold',
  marginBottom: '30px',
})
const dlStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: '14px',
  marginBottom: '0.75rem',
  '&:last-of-type': {
    marginBottom: 0,
  },
})
const qrCodeStyle = css({
  padding: '2rem',
  marginTop: '30px',
  borderRadius: '16px',
  background: 'white',
})
