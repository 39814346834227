/**
 * Auth0 Api Module
 * @see https://auth0.com/
 * @see https://www.npmjs.com/package/auth0-js
 */
import * as Auth0 from 'auth0-js'
import { env } from '@/libs/env'

type AuthOptions = {
  domain: string
  clientID: string
  responseType?: string
  responseMode?: string
  redirectUri?: string
  scope?: string
  audience?: string
  leeway?: number
  plugins?: any[]
  _disableDeprecationWarnings?: boolean
  _sendTelemetry?: boolean
  _telemetryInfo?: any
  __tryLocalStorageFirst?: boolean
}

/**
 * sns socialサインイン
 */
export const socialSignUp = name => {
  return new Promise(() => {
    const { domain, clientID, responseType, redirectUri } = _buildWebAuthParam()
    const webAuth: Auth0.WebAuth = new Auth0.WebAuth({
      domain,
      clientID,
      responseType,
      redirectUri,
    })
    webAuth.authorize({
      connection: name,
    })
  })
}

/**
 * サインアウト
 * @return Promise
 */
export const signOut = (props: { hotelId: string; lang: string }) => {
  return new Promise(() => {
    const { domain, clientID, redirectUri } = _buildWebAuthSignOutParam(props)
    const webAuth: Auth0.WebAuth = new Auth0.WebAuth({
      domain,
      clientID,
      state: `hotelId=${props.hotelId}`,
    })
    localStorage.removeItem('access_token')
    webAuth.logout({ returnTo: redirectUri })
  })
}

/**
 * トークン解析
 * @return Promise
 */
export const parseToken = () => {
  return new Promise<{ idToken: string }>((resolve, reject) => {
    const { domain, clientID } = _buildWebAuthParam()
    const webAuth: Auth0.WebAuth = new Auth0.WebAuth({
      domain,
      clientID,
    })
    webAuth.parseHash((error, result) => {
      if (result) {
        resolve(result as { idToken: string })
      } else {
        console.error(error)
        reject(error)
      }
    })
  })
}

/**
 * パラメータ構築
 * @private
 */
const _buildWebAuthParam = (): AuthOptions => {
  const { origin } = window.location
  return {
    domain: env('AUTH_DOMAIN') as string,
    clientID: env('AUTH_CLIENT_ID') as string,
    responseType: 'token id_token',
    redirectUri: `${origin}/auth-cb`,
  }
}

/**
 * パラメータ構築（signOut用）
 */
const _buildWebAuthSignOutParam = ({ hotelId, lang }: { hotelId: string; lang: string }) => {
  const { origin } = window.location
  return {
    domain: env('AUTH_DOMAIN') as string,
    clientID: env('AUTH_CLIENT_ID') as string,
    responseType: 'token id_token',
    redirectUri: `${origin}/?hotelId=${hotelId}&lang=${lang}`,
    dbName: env('AUTH_DBNAME'),
    state: `hotelId=${hotelId}`,
  }
}
