import React from 'react'

import { Link, useSearchParams } from 'react-router-dom'
import { css } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import { AccountIcon, CardIcon, CheckInIcon, HelpIcon, InvoiceIcon, PrivacyPolicyIcon, SecurityIcon, TermsOfUseIcon } from '../atoms/icon'
import { CUSTOM_BLOCK, CUSTOM_BORDER, CUSTOM_TEXT_COLOR_LINK } from '../template/custom-design-style'

const userListItems = [
  {
    label: 'Member Information',
    path: '/mypage/account',
    icon: <AccountIcon />,
  },
  {
    label: 'Check-in Information',
    path: '/mypage/checkin-history',
    icon: <CheckInIcon />,
  },
  {
    label: 'Credit Card',
    path: '/mypage/credit-card',
    icon: <CardIcon />,
  },
  {
    label: 'Invoice',
    path: '/mypage/usage-detail',
    icon: <InvoiceIcon />,
  },
]
const serviceListItems = [
  {
    label: 'System help',
    path: 'https://aipass.zendesk.com/hc/ja/categories/900001310303-%E3%81%94%E5%AE%BF%E6%B3%8A%E3%81%AE%E6%96%B9%E3%81%B8',
    icon: <HelpIcon />,
    target: true,
  },
  {
    label: 'Terms of use',
    path: '/about-terms-of-use',
    icon: <TermsOfUseIcon />,
    isInGuest: true,
    target: true,
  },
  {
    label: 'Privacy policy',
    path: '/about-privacy-policy',
    icon: <PrivacyPolicyIcon />,
    isInGuest: true,
    target: true,
  },
  {
    label: 'Security policy',
    path: '/about-security-policy',
    icon: <SecurityIcon />,
    isInGuest: true,
    target: true,
  },
]

export const MyPageLinkList: React.FC = () => {
  const { t } = useTranslation()

  const [searchParams] = useSearchParams()

  return (
    <>
      <div css={blockStyle} className={`${CUSTOM_BLOCK} ${CUSTOM_TEXT_COLOR_LINK}`}>
        {userListItems.map(linkListItem => (
          <Link css={linkStyle} key={linkListItem.label} to={linkListItem.path} className={CUSTOM_BORDER}>
            <div css={linkListItemStyle}>
              {linkListItem.icon}
              <p>{t(linkListItem.label)}</p>
            </div>
          </Link>
        ))}
      </div>

      <div css={blockStyle} className={`${CUSTOM_BLOCK} ${CUSTOM_TEXT_COLOR_LINK}`}>
        {serviceListItems.map(linkListItem => {
          const linkChildren = (
            <div css={linkListItemStyle}>
              {linkListItem.icon}
              <p>{t(linkListItem.label)}</p>
            </div>
          )

          if (linkListItem?.target) {
            return (
              <Link
                key={linkListItem.label}
                css={linkStyle}
                to={linkListItem?.isInGuest ? `${linkListItem.path}?${searchParams.toString()}` : linkListItem.path}
                target="_blank"
                rel="noopener noreferrer"
                className={CUSTOM_BORDER}
              >
                {linkChildren}
              </Link>
            )
          }

          return (
            <Link css={linkStyle} key={linkListItem.label} to={linkListItem.path} className={CUSTOM_BORDER}>
              {linkChildren}
            </Link>
          )
        })}
      </div>
    </>
  )
}

const blockStyle = css({
  // background: '#fff',
  paddingLeft: '2rem',
  paddingRight: '2rem',
  marginTop: '1rem',
})

const linkStyle = css({
  display: 'block',
  paddingBottom: '1rem',
  paddingTop: '1rem',
  borderTop: 'none',
  borderLeft: 'none',
  borderRight: 'none',
  '&:last-of-type': {
    marginBottom: 0,
  },
})

const linkListItemStyle = css({
  display: 'flex',
  alignItems: 'center',
  p: {
    paddingLeft: '1.5rem',
    fontSize: '14px',
    fontWeight: 'bold',
  },
})
