import { LanguageType } from '@/i18n'

export const getFirstName = (language: LanguageType, fullName?: string) => {
  if (!fullName) return ''
  if (language === 'ja') return fullName.split(' ')[1] || ''

  return fullName.split(' ')[0] || ''
}

export const getSurname = (language: LanguageType, fullName?: string) => {
  if (!fullName) return ''
  if (language === 'ja') return fullName.split(' ')[0] || ''

  return fullName.split(' ')[1] || ''
}

export const convertToNameKatakana = (text: string) => {
  return text.replace(/[\u3041-\u3096]/g, t => String.fromCharCode(t.charCodeAt(0) + 96))
}