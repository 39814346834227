import { AxiosError } from 'axios'
import I18n from '../i18n'
import { store } from '@/app/store'
import { MessageType, toastAction } from '@/features/notification/toast-slice'

export type ApiError<T = any> = AxiosError<
  {
    message?: string
    errorMessage?: string
    errors?: { [propName: string]: string[] }
  } & T
>

export const isApiError = (arg: any): arg is ApiError => {
  return (
    arg?.response?.data?.message !== undefined ||
    arg?.response?.data?.errorMessage !== undefined ||
    arg?.response?.data?.errors !== undefined
  )
}

export const errorHandler = (error: { error: AxiosError | any }, options?: { skipUnauthorizedError: boolean }) => {
  if (error?.error?.response?.status === 401 && options?.skipUnauthorizedError) return

  let errorText = error && typeof error.error === 'string' ? error.error : 'unknown error'

  if (error?.error?.response?.data) {
    const errorData = error?.error?.response?.data

    if (errorData.message) errorText = errorData.message
    else if (errorData.errorMessage) errorText = errorData.errorMessage
    else if (errorData.description) errorText = errorData.description

    if (typeof errorData?.errors === 'object') {
      for (const [indexTop, topErrors] of Object.values(errorData?.errors).entries() as any) {
        if (indexTop === 0) errorText = ''
        if (Array.isArray(topErrors)) {
          for (const [index, error] of topErrors.entries() as any) {
            if (typeof error === 'object') {
              errorText +=
                indexTop !== 0 || index !== 0 ? ', ' : '' + I18n.exists(error.message) ? I18n.t(error.message, error.params) : error.message
            } else if (typeof error === 'string') {
              errorText += indexTop !== 0 || index !== 0 ? ', ' : '' + I18n.exists(errorText) ? I18n.t(error) : error
            }
          }
        }
      }
    }
  } else if (error?.error?.policy) errorText = error.error.policy
  else if (error?.error?.description) errorText = error.error.description
  else if (error?.error?.message) errorText = error.error.message
  else if (error?.error?.errorMessage) errorText = error.error.errorMessage

  if (error?.error?.code === 'invalid_password') {
    errorText = 'Please set the password with 8 characters or more using lowercase, uppercase characters, and number.'
  }

  if (I18n.exists(errorText)) {
    errorText = I18n.t(errorText, error?.error?.response?.data?.messageParameter) as string
  }

  store.dispatch(toastAction.setMessage(MessageType.Error, errorText))
}
