import React, { useState } from 'react'
import { v4 as uuid } from 'uuid'
import Modal from 'react-modal'
import { ReactComponent as ArrowIcon } from '@/static/images/controls/icon_arrow.svg'
import { ReactComponent as DeleteIcon } from '@/static/images/controls/icon_delete.svg'
import { css } from '@emotion/react'

interface ImgPopupProps {
  imagePaths: string[]
}

export const ImagePopup: React.FC<ImgPopupProps> = ({ imagePaths }) => {
  const [selectImageInfo, setSelectImageInfo] = useState<{ path: string; index: number }>()

  const setImage = (e: React.MouseEvent<HTMLImageElement>) => {
    const target = e.currentTarget
    const index = target.dataset.index!

    setSelectImageInfo({ path: target.src, index: Number(index) })
  }

  Modal.setAppElement('#root')

  const switchImage = (direction: 'next' | 'prev') => {
    if (!selectImageInfo) {
      return
    }

    const currentIndex = selectImageInfo.index
    const index = direction === 'next' ? currentIndex + 1 : currentIndex - 1

    setSelectImageInfo({ path: imagePaths[index], index })
  }

  return (
    <>
      {imagePaths.map((imagePath, i) => (
        <div key={uuid()} style={{ cursor: 'pointer' }}>
          <img src={imagePath} data-index={i} onClick={e => setImage(e)} />
        </div>
      ))}
      <Modal
        onRequestClose={() => setSelectImageInfo(undefined)}
        shouldCloseOnOverlayClick={true}
        css={css({ backgroundColor: 'transparent !important' })}
        style={{
          overlay: { zIndex: 400, backgroundColor: 'rgba(0, 0, 0, 0.5)' },
          content: {
            border: 'none',
            padding: '40px',
            inset: 0,
            top: 0,
            left: 0,
            margin: 'auto',
            position: 'relative',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'none'
          },
        }}
        isOpen={!!selectImageInfo}
      >
        {selectImageInfo && (
          <div css={modalContentStyle}>
            {selectImageInfo.index !== 0 && (
              <ArrowIcon
                className="left"
                stroke="#fff"
                style={{ cursor: 'pointer', transform: 'rotate(90deg)' }}
                onClick={() => switchImage('prev')}
              />
            )}
            <div>
              <img src={selectImageInfo?.path} style={{ width: 800, height: 'auto', objectFit: 'cover' }} />
              <span style={{ display: 'flex', justifyContent: 'flex-end', color: '#fff', marginTop: 16 }}>
                {selectImageInfo.index + 1}/{imagePaths.length}
              </span>
            </div>
            {selectImageInfo.index + 1 < imagePaths.length && (
              <ArrowIcon
                className="right"
                stroke="#fff"
                style={{ cursor: 'pointer', transform: 'rotate(-90deg)' }}
                onClick={() => switchImage('next')}
              />
            )}
          </div>
        )}
        <DeleteIcon style={{ cursor: 'pointer', position: 'absolute', top: 32, right: 32 }} onClick={() => setSelectImageInfo(undefined)} />
      </Modal>
    </>
  )
}

const modalContentStyle = css({
  position: 'relative',
  pointerEvents: 'auto',
  svg: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    margin: 'auto',
    '&.left': {
      left: -36,
    },
    '&.right': {
      right: -36,
    },
  },
})
