import React, { useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { NavigateHeader } from '@/components/atoms/navigate-header'
import { Layout } from '@/components/template/layout'
import { TicketType, getUserCheckInDetails } from '@/features/user/check-in-history-slice'
import { useQuerySearchParams } from '@/hooks/use-query-search-params'
import { css } from '@emotion/react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { QrCode } from '@/components/atoms/qr-code'
import { errorHandler } from '@/libs/errors'
import { CUSTOM_BG, CUSTOM_BLOCK, CUSTOM_BORDER } from '@/components/template/custom-design-style'
import { omit } from 'lodash'
import { Base64 } from 'js-base64'
import { TicketQrModal } from '@/components/organisms/modal/ticket-qr-modal'
import { pcScreenStyle } from '@/styles/common'

export const CheckInHistoryInfo: React.FC = () => {
  const { t } = useTranslation()

  const params = useParams()
  const { checkInCode } = useQuerySearchParams<{ checkInCode: string }>()

  const dispatch = useAppDispatch()

  const checkInHistoryInfo = useAppSelector(state => state.userCheckInDetails.checkInHistoryInfo)
  const { hasTicketPlugin } = useAppSelector(state => state.hotelGuide)

  const [showDetailTickets, setShowDetailTickets] = useState<TicketType[] | undefined>(undefined)

  const _getCheckInHistoryInfo = async () => {
    if (!params.id || !checkInCode) {
      // @TODO not params id
      return
    }

    try {
      await dispatch(getUserCheckInDetails({ checkinCode: checkInCode, checkinId: params.id })).unwrap()
    } catch (error) {
      errorHandler({ error })
    }
  }

  const checkInQrCode = useMemo(() => {
    if (!checkInHistoryInfo?.qr_json) {
      return ''
    }
    const qrJsonObj = omit(JSON.parse(checkInHistoryInfo.qr_json), ['pass_id'])
    return Base64.encode(JSON.stringify(qrJsonObj))
  }, [checkInHistoryInfo])

  useEffect(() => {
    if (checkInHistoryInfo.checkin_id !== params.id) {
      _getCheckInHistoryInfo()
    }
  }, [checkInHistoryInfo])

  return (
    <Layout>
      {checkInHistoryInfo && (
        <>
          <NavigateHeader title={t('Check-in Information')} style={{ marginBottom: '1rem' }} />
          <div css={pcScreenStyle}>
            <div css={infoBlockStyle} className={CUSTOM_BLOCK}>
              <h3 css={hotelName}>{checkInHistoryInfo.hotel_name}</h3>

              <div>
                <dl css={dlStyle}>
                  <dt>{t('Check-in datetime')}</dt>
                  <dd>{dayjs(checkInHistoryInfo.checkin_time).format('YY/MM/DD HH:mm')}</dd>
                </dl>
                <dl css={dlStyle}>
                  <dt>{t('Check-in ID')}</dt>
                  <dd>{checkInHistoryInfo.checkin_code}</dd>
                </dl>
                <dl css={dlStyle}>
                  <dt>{t('Full name.2')}</dt>
                  <dd>{checkInHistoryInfo.guest_name}</dd>
                </dl>
              </div>

              <div css={qrCodeStyle} className={CUSTOM_BORDER}>
                <QrCode qrCode={checkInQrCode} />
              </div>
            </div>

            {hasTicketPlugin && checkInHistoryInfo?.ticket && (
              <div css={infoBlockStyle} style={{ marginTop: 6 }} className={CUSTOM_BLOCK}>
                <h2 css={ticketAreaTitle}>{t('Available tickets')}</h2>
                {Object.entries(checkInHistoryInfo.ticket).map(([name, tickets]) => (
                  <div key={name} css={ticketBlockStyle} className={CUSTOM_BG} onClick={() => setShowDetailTickets(tickets)}>
                    <div className="name">{name}</div>
                    <div className="length">{tickets.length}</div>
                  </div>
                ))}
                {showDetailTickets && (
                  <TicketQrModal
                    isOpen={!!showDetailTickets}
                    onClose={() => setShowDetailTickets(undefined)}
                    shouldCloseOnOverlayClick={true}
                    tickets={showDetailTickets}
                  />
                )}
              </div>
            )}
          </div>
        </>
      )}
    </Layout>
  )
}

const infoBlockStyle = css({
  padding: '30px 38px',
})

const hotelName = css({
  fontSize: '18px',
  fontWeight: 'bold',
  marginBottom: '1rem',
})
const ticketAreaTitle = css({
  fontSize: '14px',
  fontWeight: 'bold',
  marginBottom: '1rem',
})

const ticketBlockStyle = css({
  cursor: 'pointer',
  borderRadius: 8,
  padding: 16,
  marginBottom: 16,
  lineHeight: '20px',
  '.name': {
    fontWeight: 'bold',
    fontSize: 14,
  },
  '.length': { fontSize: 12 },
  ':last-of-type': {
    marginBottom: 0,
  },
})

const dlStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: '14px',
  marginBottom: '0.75rem',
  '&:last-of-type': {
    marginBottom: 0,
  },
})

const qrCodeStyle = css({
  padding: '2rem',
  marginTop: '1.5rem',
  borderRadius: '16px',
  background: 'white',
})
