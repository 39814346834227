import React from 'react'
import { MessageType, Toast, toastAction } from '@/features/notification/toast-slice'
import { notification } from 'antd'
import { Global, css } from '@emotion/react'
import { v4 as uuid } from 'uuid'
import { useAppDispatch } from '@/app/hooks'
import { ErrorDeleteIcon } from '@/components/atoms/icon'

export const useNotification = () => {
  const [api, Notification] = notification.useNotification()

  const dispatch = useAppDispatch()

  const openNotification = (toast: Toast) => {
    if (!toast.message || !toast.type) {
      return
    }

    api.open({
      icon: getIconOfType(toast.type),
      message: <>{toast.message?.split('\n').map(m => <div key={uuid()}>{m}</div>)}</>,
      placement: 'top',
      closeIcon: <ErrorDeleteIcon />,
      duration: 5,
      className: toast.type,
    })
    dispatch(toastAction.clearMessage())
  }

  const getIconOfType = (type: MessageType) => {
    const iconMap = {
      [MessageType.Error]: <img src={require('@/static/images/icon/error.svg').default} />,
      [MessageType.Info]: <img src={require('@/static/images/icon/edit.svg').default} />,
    }
    return iconMap[type]
  }

  return {
    Notification: (
      <>
        <Global styles={globalNotificationStyle} />
        {Notification}
      </>
    ),
    openNotification,
  }
}

const globalNotificationStyle = css({
  '.ant-notification': {
    '.ant-notification-notice-wrapper': {
      boxShadow: '0px 2px 10px #00000029',
      borderRadius: 6,
      '.ant-notification-notice': {
        padding: '12px 18px',
        display: 'flex',
        placeItems: 'center',
        justifyContent: 'space-between',
        borderRadius: 6,
        '.ant-notification-notice-content': {
          flexGrow: 1,
          marginRight: 18,
          '.ant-notification-notice-with-icon': {
            display: 'flex',
            placeItems: 'center',
            '.ant-notification-notice-icon': {
              position: 'inherit',
            },
            '.ant-notification-notice-message': {
              marginBottom: 0,
              marginInlineStart: 0,
              marginLeft: 16,
              letterSpacing: 1.2,
              fontSize: 12,
              fontWeight: 600,
            },
          },
          '.ant-notification-notice-description': {
            display: 'none',
          },
        },
        '.ant-notification-notice-close': {
          position: 'inherit',
        },
        '&.info': {
          border: '2px solid blue',
          '.ant-notification-notice-content': {
            '.ant-notification-notice-with-icon': {
              '.ant-notification-notice-message': {
                div: { color: 'blue' },
              },
            },
          },
        },
        '&.error': {
          border: '2px solid #E6483B',
          '.ant-notification-notice-content': {
            '.ant-notification-notice-with-icon': {
              '.ant-notification-notice-message': {
                div: { color: '#E6483B' },
              },
            },
          },
        },
      },
    },
  },
})
