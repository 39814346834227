import { http } from '@/apis/aipass'
import { env } from '@/libs/env'

export const sendmailMobileRequestInfoForUser = async (data: any) => {
  const response = await http({
    method: 'post',
    url: `${env('BASE_API_V3')}/frontend-sendmail/mobile-request-info`,
    data,
  })

  return response.data
}

export const sendmailMobileRequestInfoForAdmin = async (data: any) => {
  const response = await http({
    method: 'post',
    url: `${env('BASE_API_V3')}/frontend-sendmail/mobile-request-order`,
    data,
  })

  return response.data
}
