import { http } from '@/apis/aipass'
import { env } from '@/libs/env'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export interface PaymentResult {
  status: string
  accountId: string
  cardId: string
  cardNumber: string
  cardBrand: string
  orderId: string
  amount: string
  txnResultCode: string
  processId: string
}

interface creditCardPayInput {
  hotelId: string
  paymentAmount: string
  checkinId: string
  reservationIds: string[]
  cardId?: string
  cuicinUserId?: string
  cardDetail: { cardNumber: string; cardExpire: string; securityCode: string }
}
export const creditCardPay = createAsyncThunk('checkOut/creditCardPay', async (data: Partial<creditCardPayInput>, { rejectWithValue }) => {
  try {
    const response = await http({
      method: 'post',
      url: `${env('BASE_API_V3')}/payment/credit-card-payment`,
      data,
    })

    return response.data.processResult
  } catch (error) {
    return rejectWithValue(error)
  }
})

const creditCardPaySlice = createSlice({
  name: 'creditCardPay',
  initialState: {
    paymentResult: {} as PaymentResult,
    loading: false,
  },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(creditCardPay.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(creditCardPay.fulfilled, (state, action) => {
      state.paymentResult = action.payload
      state.loading = false
    })
    builder.addCase(creditCardPay.rejected, (state, action) => {
      state.loading = false
    })
  },
})


export const creditCardPaymentReducer = creditCardPaySlice.reducer
