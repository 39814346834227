import { Reservation } from '@/features/checkIn/search-reservations-slice'
import { displayYen, translateObject } from '@/libs/text'
import { textOverflowStyle } from '@/styles/common'
import { CSSObject, css } from '@emotion/react'
import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { CUSTOM_BLOCK, CUSTOM_BORDER } from '../template/custom-design-style'

interface RoomBillDetailProps {
  reservation: Reservation
  roomCss?: CSSObject
}

export const RoomBillDetail: React.FC<RoomBillDetailProps> = ({ reservation, roomCss }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  return (
    <div key={reservation.id} css={roomCss}>
      <div css={blockStyle} className={CUSTOM_BLOCK}>
        <div css={[listStyle, reservationIdStyle]}>
          <span>{t('Reservation ID')}</span>
          <span css={textOverflowStyle}>{reservation.reservationId}</span>
        </div>

        <div css={[nightsStyle, listStyle]}>
          <span>
            {dayjs(reservation.checkinDate).format('MM/DD')} {reservation?.nights}
            {reservation.nights === 1 ? t('night') : t('nights')}
          </span>
        </div>
      </div>

      <table css={tableStyle}>
        <thead>
          <tr css={theadStyle} className={CUSTOM_BORDER}>
            <th style={{ width: '25%' }}>{t('Date')}</th>
            <th style={{ width: '30%' }}>{t('Reference')}</th>
            <th style={{ width: '50%' }}>{t('Amount used')}</th>
          </tr>
        </thead>
        <tbody css={tbodyStyle} className={CUSTOM_BLOCK}>
          {reservation.sales.map((sale, index) => {
            return (
              <tr key={index} className={CUSTOM_BORDER}>
                <td style={{ width: '20%' }}>{dayjs(sale.salesDate).format('MM/DD')}</td>
                <td style={{ width: '30%' }}>{translateObject(sale, 'salesSubjectName' as any, language)}</td>
                <td style={{ width: '50%' }}>{displayYen(sale.salesPrice)}</td>
              </tr>
            )
          })}
        </tbody>
      </table>

      <div css={blockStyle} className={CUSTOM_BLOCK}>
        <div css={totalAmountStyle}>
          <div>{t('Amount used')}</div>
          <div>{displayYen(String(reservation.totalPrice))}</div>
        </div>

        <div css={[totalAmountStyle, { marginTop: '1rem' }]}>
          <div>{t('Credit')}</div>
          <div>
            {displayYen(String(reservation.totalDiscount))}
            <span css={[breakdownStyle]}>{`(${t('discount')}：${displayYen(String(reservation.totalDiscount))})`}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

interface AmountBillProps {
  totalAmount: number
  totalTaxPrice: number
}
export const AmountBill: React.FC<AmountBillProps> = ({ totalAmount, totalTaxPrice }) => {
  const { t } = useTranslation()

  return (
    <div css={[blockStyle, totalAmountStyle]} className={CUSTOM_BLOCK}>
      <div>{t('Amount billed')}</div>
      <div>
        {displayYen(String(totalAmount))}
        <span css={[breakdownStyle]}>{`(${t('tax')}：${displayYen(String(totalTaxPrice))})`}</span>
      </div>
    </div>
  )
}

const reservationIdStyle = css({
  fontSize: '16px',
  fontWeight: 'bold',
  marginBottom: '0.5rem',
  span: {
    '&:last-of-type': {
      flex: 1,
    },
  },
})

const nightsStyle = css({
  fontSize: '12px',
  fontWeight: 'bold',
})

const blockStyle = css({
  padding: '2rem',
})

const totalAmountStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  fontWeight: 'bold',
  div: {
    '&:first-of-type': {
      fontSize: '12px',
    },
    '&:last-child': {
      textAlign: 'right',
    },
  },
})

const tableStyle = css({
  width: '100%',
  padding: '2rem',
})

const theadStyle = css({
  width: '100%',
  fontSize: '10px',
  th: {
    padding: '0.5rem 0',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    '&:first-of-type': {
      paddingLeft: '2rem',
    },
    '&:last-child': {
      paddingRight: '2rem',
      textAlign: 'right',
    },
  },
})

const tbodyStyle = css({
  width: '100%',
  tr: {
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
  },
  td: {
    padding: '0.5rem 0',
    fontSize: '12px',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    '&:first-of-type': {
      paddingLeft: '2rem',
    },
    '&:last-child': {
      paddingRight: '2rem',
      textAlign: 'right',
    },
  },
})

const breakdownStyle = css({
  display: 'block',
  marginTop: '0.25rem',
  fontWeight: 'normal',
  fontSize: '10px',
})

const listStyle = css({
  display: 'flex',
  gap: '1rem',
})
