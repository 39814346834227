import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { http } from '@/apis/aipass'
import { env } from '@/libs/env'
import { RootState } from '@/app/store'
import { isEmpty } from 'lodash'

export const fetchHotelGuide = createAsyncThunk(
  'hotel/fetchHotelGuide',
  async (hotelId: string, { rejectWithValue }) => {
    try {
      const response = await http({
        method: 'get',
        url: `${env('BASE_API_V3')}/hotel-guide`,
        params: { hotelId: hotelId },
      })
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  {
    dispatchConditionRejection: true,
    condition: (arg, { getState }) => {
      const { hotelGuide } = getState() as RootState
      return !hotelGuide.loading
    },
  },
)

interface Plugins {
  code: string
  description: string
  descriptionEn: string
  id: string
  isEnabled: number
  name: string
  nameEn: string
}

interface FacilityBasicInfo {
  hotelId: string
  pageId: string
  hotelName: string
  hotelNameLatin: string
  telephone: string
  checkinBeginTime: string
  checkinEndTime: string
  checkoutBeginTime: string | null
  checkoutEndTime: string | null
  frontOpenTime: string
  frontCloseTime: string
  doorLockingBeginTime: string | null
  doorLockingEndTime: string | null
  logoImageId: string
  extImageId: string
  facilityRecommendImageId: string
  foodImageId: string
  playImageId: string
  buyImageId: string
  facilityRecommendLinkUrl: string
  receiptHotelName: string
  receiptAddress: string
  receiptTelephone: string
}

export interface HotelGuide {
  hotelId: string
  facilityBasicInfo: FacilityBasicInfo
  plugins: Plugins[]
  hasReservationPlugins?: boolean
  hasPaymentPlugins?: boolean
  hasCustomCheckInPlugins?: boolean
  hasPlugin?: boolean
  isEnabledPlugin?: boolean
  hasTicketPlugin?: boolean
  loading: boolean
}
const initialState: HotelGuide = {
  hotelId: '',
  facilityBasicInfo: {} as FacilityBasicInfo,
  plugins: [] as Plugins[],
  loading: false,
}

export const hotelGuideSlice = createSlice({
  name: 'hotelGuide',
  initialState: initialState,
  reducers: {
    hasPaymentPlugin: state => {
      state.hasPaymentPlugins = Boolean(state.plugins.find(element => element.code === 'PA')?.isEnabled)
    },
    isEnabledPlugin: (state, action: PayloadAction<{ pluginCode: string }>) => {
      state.isEnabledPlugin = !!state.plugins?.find(element => element.code === action.payload.pluginCode)?.isEnabled
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchHotelGuide.fulfilled, (state, action: PayloadAction<HotelGuide>) => {
      state.facilityBasicInfo = action.payload.facilityBasicInfo
      state.plugins = action.payload.plugins
      state.hotelId = action.payload.hotelId

      const hasPaymentPlugin = !!state.plugins.find(element => element.code === 'PA')?.isEnabled
      const hasReservationPlugin = !isEmpty(
        state.plugins
          .filter(
            element =>
              element.code === 'TL' ||
              element.code === 'TM' ||
              element.code === 'NP' ||
              element.code === 'CN' ||
              element.code === 'RK' ||
              element.code === 'RK_TOYO',
          )
          .filter(plugin => plugin.isEnabled),
      )
      const hasCustomCheckInPlugins = state.plugins.find(element => element.code === 'CC')?.isEnabled

      state.hasPaymentPlugins = hasPaymentPlugin
      state.hasReservationPlugins = hasReservationPlugin
      state.hasPlugin = hasPaymentPlugin || hasReservationPlugin
      state.hasCustomCheckInPlugins = !!hasCustomCheckInPlugins
      state.hasTicketPlugin = !!state.plugins.find(element => element.code === 'TKT')?.isEnabled
      state.loading = false
    })
    builder.addCase(fetchHotelGuide.pending, state => {
      state.loading = true
    })
    builder.addCase(fetchHotelGuide.rejected, state => {
      state.loading = false
    })
  },
})

export const { hasPaymentPlugin, isEnabledPlugin } = hotelGuideSlice.actions
export const selectHotelGuide = state => state.facilityBasicInfo
export const hotelGuideReducer = hotelGuideSlice.reducer
