// import { AssetType, PulldownAssetType } from '@/features/asset/assets-slice'
import React, { useMemo } from 'react'
import parse from 'html-react-parser'
import { css } from '@emotion/react'
import { useAppSelector } from '@/app/hooks'
import { ButtonRoleType } from '@/features/asset/design-slice'
import { CUSTOM_BUTTON, CUSTOM_LINK } from '../template/custom-design-style'

type Props = {
  text: string
}

export const HtmlContent: React.FC<Props> = ({ text }) => {
  const design = useAppSelector(state => state.design)

  const customDesignStyle = useMemo(() => {
    const primaryButtonStyle = design.button.find(b => b.type === ButtonRoleType.Primary)
    return css({
      '> p:has(> br:only-child):first-of-type, > p:has(+ div > .custom-design-button):has(br:only-child), > div:has(> .custom-design-button) + p:has(br:only-child)':
        {
          display: 'none',
        },
      lineHeight: 1.5,
      'h1, h2': {
        color: design.text.textColor,
        fontWeight: 'bold',
      },
      h1: { fontSize: 20, marginBottom: 16 },
      h2: { fontSize: 16, marginBottom: 8 },
      ol: {
        listStyleType: 'decimal',
        marginBottom: 8,
      },
      ul: {
        listStyleType: 'disc',
        marginBottom: 8,
      },
      li: {
        listStyle: 'inherit',
        listStylePosition: 'inside',
      },
      u: {
        textDecoration: 'underline',
      },
      'li, p': {
        fontSize: 14,
      },
      p: {
        marginBottom: 8,
      },
      img: {
        width: '100%',
        paddingBottom: 16,
      },
      [`.${CUSTOM_LINK}`]: {
        '*': { color: design.text.linkColor },
      },
      [`.${CUSTOM_BUTTON}`]: {
        width: '100%',
        height: '48px',
        lineHeight: '48px',
        appearance: 'none',
        borderStyle: 'none',
        color: primaryButtonStyle?.textColor,
        background: primaryButtonStyle?.backgroundColor,
        borderRadius: primaryButtonStyle?.borderRadius,
        fontFamily: design.text.fontFamily,
        display: 'inline-block',
        textAlign: 'center',
        margin: '16px 0',
      },
    })
  }, [design])

  return <div css={customDesignStyle}>{parse(text)}</div>
}
