import React, { useEffect } from 'react'
import { Layout } from '../../template/layout'
import { useSearchParams } from 'react-router-dom'
import { useSmoothScroll } from '@/hooks/use-smooth-scroll'
import { useAppSelector } from '@/app/hooks'
import { CheckInEditTemplate } from '@/components/template/check-in-edit-template'
import { isEmpty } from 'lodash'
import { CheckInStep } from '@/components/organisms/checkIn/check-in-step'

export const CheckInEdit: React.FC = () => {
  const { smoothScroll } = useSmoothScroll()
  const [searchParams] = useSearchParams()
  const accountInfo = useAppSelector(state => state.accountInfo.user)

  useEffect(() => {
    const hash = searchParams.get('hash')
    if (hash) {
      smoothScroll(hash)
    }
  }, [searchParams])

  return (
    <Layout>
      <CheckInStep current={2} />
      {!isEmpty(accountInfo) && <CheckInEditTemplate />}
    </Layout>
  )
}
