import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { css } from '@emotion/react'
import { Button } from '@/components/atoms/button'
import { useNavigate } from 'react-router-dom'
import { CUSTOM_BLOCK, CustomDesignStyle } from '@/components/template/custom-design-style'
import { OkImage } from '@/components/atoms/icon'
import { useAppDispatch } from '@/app/hooks'
import { clearReservationInfo } from '@/features/space/space-reservation-slice'
import { deleteSearchReservations } from '@/features/space/space-search-reservation-slice'

export const SpaceThanks: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(clearReservationInfo())
    dispatch(deleteSearchReservations())
  }, [])

  return (
    <>
      <CustomDesignStyle />
      <div css={[layoutStyle]} className={CUSTOM_BLOCK}>
        <OkImage />
        <h2 css={thanksMessageStyle}>{t('Reservations accepted')}</h2>
        <p css={leadStyle}>{t('Thank you for your reservation. \nA confirmation email has been sent.')}</p>

        <Button buttonCss={buttonStyle} text={t('Back to main')} onClick={() => navigate('/')} />
      </div>
    </>
  )
}

const layoutStyle = css({
  display: 'flex',
  height: '100vh',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: '6rem',
  paddingRight: '1.5rem',
  paddingLeft: '1.5rem',
})

const thanksMessageStyle = css({
  fontSize: '22px',
  fontWeight: 'bold',
  marginTop: '2.5rem',
  marginBottom: '2rem',
  textAlign: 'center',
  lineHeight: '1.54',
  whiteSpace: 'pre-wrap',
})

const leadStyle = css({
  textAlign: 'center',
  fontSize: '14px',
  lineHeight: '1.4',
  whiteSpace: 'pre-wrap',
})

const buttonStyle = css({
  maxWidth: 568,
  marginTop: 'auto',
  marginBottom: '2.5rem',
})
