import { Step } from '@/components/atoms/step'
import React from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  current: number
}

export const CheckInStep: React.FC<Props> = props => {
  const { t } = useTranslation()

  return (
    <Step
      items={[t('Search scheduled reservation'), t('Login / Sign up'), t('Enter guest information'), t('Check and Payment')]}
      {...props}
    />
  )
}
