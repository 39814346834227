export const useSmoothScroll = () => {
  const smoothScroll = (id: string) => {
    const targetDom = document.getElementById(id)
    if (targetDom) {
      setTimeout(() => {
        const scrollTo = targetDom.getBoundingClientRect().top + window.scrollY
        window.scroll({
          top: scrollTo,
          behavior: 'smooth',
        })
      }, 300)
    }
  }

  return { smoothScroll }
}
