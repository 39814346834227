import { css } from '@emotion/react'
import dayjs from 'dayjs'
import React, { useMemo, useState } from 'react'
import { ModalWrapper } from '../atoms/modal-wrapper'
import { useTranslation } from 'react-i18next'
import { LanguageType } from '@/i18n'
import { LanguageIcon, PullDownIcon } from '../atoms/icon'
import { CUSTOM_CARD, CUSTOM_LINK } from '../template/custom-design-style'
import { useMatches } from 'react-router-dom'
import { useBreakPoint } from '@/hooks/use-break-point'

interface FooterProps {
  contentHeight?: number
}

export const Footer: React.FC<FooterProps> = ({ contentHeight }) => {
  const [isLanguageOpen, setIsLanguageOpen] = useState(false)

  const { isPc } = useBreakPoint()

  const matches = useMatches()
  const smallMargin = !!matches.find(match => (match.handle as { smallFooterMargin?: boolean })?.smallFooterMargin)

  const footerHeight = useMemo(() => {
    const fixHeight = isPc ? 159 : 125
    if (!contentHeight) {
      return fixHeight
    }

    const totalHeight = contentHeight + fixHeight

    const windowHeight = window.innerHeight
    if (totalHeight < windowHeight) {
      const adjustedHeight = windowHeight - totalHeight
      return fixHeight + adjustedHeight
    }
    return fixHeight
  }, [contentHeight, window])

  return (
    <>
      <footer css={[footerStyle, { marginTop: smallMargin ? 8 : 50, height: footerHeight }]} className={CUSTOM_CARD}>
        <p css={languageStyle} onClick={() => setIsLanguageOpen(true)}>
          <LanguageIcon />
          <span className={CUSTOM_LINK}>Language</span>
          <PullDownIcon width={12} />
        </p>

        <p css={copyRightStyle}>&copy;{dayjs().format('YYYY')} aipass Inc.</p>
      </footer>
      <LanguageModal isOpen={isLanguageOpen} onClose={() => setIsLanguageOpen(false)} />
    </>
  )
}

interface LanguageModalProps {
  isOpen: boolean
  onClose: () => void
}
const LanguageModal: React.FC<LanguageModalProps> = ({ isOpen, onClose }) => {
  const { i18n } = useTranslation()

  const onChangeLanguage = (language: LanguageType) => {
    i18n.changeLanguage(language)
    onClose()
  }

  return (
    <ModalWrapper shouldCloseOnOverlayClick modalStyle={{ inset: 'auto 0 0 0', padding: '40px 10px' }} isOpen={isOpen} onClose={onClose}>
      <div css={languageModalStyle}>
        <button onClick={() => onChangeLanguage('ja')} css={languageButtonStyle} type="button" className={CUSTOM_LINK}>
          日本語
        </button>
        <button onClick={() => onChangeLanguage('en')} css={languageButtonStyle} type="button" className={CUSTOM_LINK}>
          English
        </button>
        <button onClick={() => onChangeLanguage('zh')} css={languageButtonStyle} type="button" className={CUSTOM_LINK}>
          簡体
        </button>
        <button onClick={() => onChangeLanguage('zhTw')} css={languageButtonStyle} type="button" className={CUSTOM_LINK}>
          繁體
        </button>
        <button onClick={() => onChangeLanguage('ko')} css={languageButtonStyle} type="button" className={CUSTOM_LINK}>
          한국어
        </button>
      </div>
    </ModalWrapper>
  )
}

const footerStyle = css({
  width: '100%',
  paddingTop: '2rem',
  paddingBottom: '2rem',
  textAlign: 'center',
})

const languageModalStyle = css({
  display: 'flex',
  justifyContent: 'space-evenly',
})

const languageStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  span: {
    fontSize: '14px',
    paddingInline: '0.5rem',
  },
  svg: {
    marginTop: '2px',
  },
})

const languageButtonStyle = css({
  appearance: 'none',
  background: 'none',
  border: 'none',
  fontSize: '14px',
  width: '100%',
  borderRight: '1px solid #F2F2F2',
  '&:last-of-type': {
    border: 'none',
  },
})

const copyRightStyle = css({
  fontSize: '12px',
  marginTop: '32px',
})
