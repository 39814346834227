import { http } from '@/apis/aipass'
import { env } from '@/libs/env'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

interface CheckInHistory {
  userId: string
  checkinId: string
  hotelId: string
  hotelName: string
  hotelNameLatin: string
  checkinCode: string
  previousPlaceToStay: string
  nextPlaceToStay: string
  checkinTime: string
  qrJson: {
    id: string
    code: string
    nameKana: string
    age: string
    gender: string
    telephone: string
    address: string
    previousPlaceToStay: string
    nextPlaceToStay: string
    occupation: string
  }
}

export interface CheckInHistoriesResponse {
  checkinHistorys: CheckInHistory[]
}

export const getUserCheckInHistoryList = createAsyncThunk('user/checkInHistory', async (userId: string, { rejectWithValue }) => {
  try {
    const response = await http({
      method: 'get',
      url: `${env('BASE_API_V3')}/checkin-history`,
      params: { userId },
    })

    return response.data
  } catch (error) {
    return rejectWithValue(error)
  }
})

const getUserCheckInHistoryListSlice = createSlice({
  name: 'checkInHistory',
  initialState: {
    checkinHistorys: [] as CheckInHistory[],
    checkInLength: 0,
  },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getUserCheckInHistoryList.fulfilled, (state, action) => {
      state.checkinHistorys = action.payload.checkinHistorys
      state.checkInLength = action.payload.checkinHistorys.length
    })
  },
})

export const getUserCheckInDetails = createAsyncThunk(
  'user/checkInHistoryDetail',
  async (props: { checkinId: string; checkinCode: string }, { rejectWithValue }) => {
    try {
      const response = await http({
        method: 'get',
        url: `${env('BASE_API_V3')}/checkin-history-detail`,
        params: props,
      })

      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export type TicketType = {
  id: string
  ticket_name: string
  exchange_code: string
}

interface UserCheckInDetails {
  checkin_code: string
  checkin_id: string
  checkin_time: string
  guest_name: string
  hotel_id: string
  hotel_name: string
  hotel_name_latin: string
  qr_json: string
  ticket?: Record<string, TicketType[]>
}
const getUserCheckInDetailsSlice = createSlice({
  name: 'checkInHistoryDetail',
  initialState: {
    checkInHistoryInfo: {} as UserCheckInDetails,
  },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getUserCheckInDetails.fulfilled, (state, action) => {
      state.checkInHistoryInfo = action.payload.checkinHistory[0]
    })
  },
})

export const getUserCheckInHistoryListReducer = getUserCheckInHistoryListSlice.reducer
export const getUserCheckInDetailsReducer = getUserCheckInDetailsSlice.reducer
