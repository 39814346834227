import { useNavigate } from 'react-router-dom'

const STORAGE_KEY = 'redirectFrom'
export const useLoginRedirect = () => {
  const navigate = useNavigate()

  const ifNeedLoginRedirect = (): boolean => {
    const redirectFrom = sessionStorage.getItem(STORAGE_KEY)
    if (redirectFrom) {
      navigate(redirectFrom)
      return true
    }
    return false
  }

  const storeRedirectFromUrl = (pathname: string, queryString?: string) => {
    queryString = queryString ? `?${queryString}` : ''
    sessionStorage.setItem(STORAGE_KEY, `${pathname}${queryString}`)
  }

  return { ifNeedLoginRedirect, storeRedirectFromUrl }
}
