import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { NavigateHeader } from '@/components/atoms/navigate-header'
import { LinkWithArrowIcon } from '@/components/molecules/link-with-arrow-icon'
import { Layout } from '@/components/template/layout'
import { getUserCheckInHistoryList } from '@/features/user/check-in-history-slice'
import { pcScreenStyle } from '@/styles/common'
import { css } from '@emotion/react'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export const CheckInHistory: React.FC = () => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const userData = useAppSelector(state => state.accountInfo.user)
  const userCheckInHistoryList = useAppSelector(state => state.userCheckInHistoryList.checkinHistorys)

  const _getUserCheckInHistoryList = async () => {
    await dispatch(getUserCheckInHistoryList(userData.id)).unwrap()
  }

  useEffect(() => {
    if (userData.id && isEmpty(userCheckInHistoryList)) {
      _getUserCheckInHistoryList()
    }
  }, [userData])

  return (
    <Layout>
      <NavigateHeader title={t('Check-in Information')} />
      <div css={pcScreenStyle}>
        {userCheckInHistoryList &&
          userCheckInHistoryList.map((history, index) => (
            <LinkWithArrowIcon
              key={index}
              linkTo={{ pathname: `/mypage/checkin-history/${history.checkinId}`, search: `checkInCode=${history.checkinCode}` }}
            >
              <p css={hotelNameStyle}>{history.hotelName}</p>
              <span css={dateTimeStyle}>{dayjs(history.checkinTime).format('YYYY/MM/DD HH:mm')}</span>
            </LinkWithArrowIcon>
          ))}
      </div>
    </Layout>
  )
}

const hotelNameStyle = css({
  fontWeight: 'bold',
  marginBottom: '0.5rem',
})

const dateTimeStyle = css({
  fontSize: '12px',
})
