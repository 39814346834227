import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { v4 as uuid } from 'uuid'

export const MessageType = {
  Error: 'error',
  Info: 'info',
} as const
export type MessageType = (typeof MessageType)[keyof typeof MessageType]

export interface Toast {
  id: string | undefined
  type: MessageType | undefined
  message: string | undefined
}
const initialState: Toast = {
  id: undefined,
  type: undefined,
  message: undefined,
}

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    setMessage: {
      reducer(state, action: PayloadAction<{ id: string; type: MessageType; message: string }>) {
        state.id = action.payload.id
        state.type = action.payload.type
        state.message = action.payload.message
      },
      prepare(type: MessageType, message: string) {
        const id = uuid()
        return { payload: { id, type, message } }
      },
    },
    clearMessage: (state) => {
      state.message = undefined
    }
  },
})

export const toastAction = toastSlice.actions
export const toastReducer = toastSlice.reducer
