import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RequestAssetDetailType } from '../asset/assets-slice'

export interface RequestItem {
  count: number
  detail: RequestAssetDetailType
}
// interface RequestCart {
//   [id: string]: RequestItem
// }
interface RequestCarts {
  [assetId: string]: RequestItem[]
}

const sessionStorageKey = 'requestsCart'
export const getSessionRequestCart = (): RequestCarts => {
  const sessionRequestCart = sessionStorage.getItem(sessionStorageKey)
  const requestCart = sessionRequestCart ? JSON.parse(sessionRequestCart) : {}

  return requestCart
}

const requestCartSlice = createSlice({
  name: 'requestCart',
  initialState: {
    cart: getSessionRequestCart(),
  },
  reducers: {
    changeCart: (state, action: PayloadAction<RequestItem & { assetId: string }>) => {
      const props = action.payload
      const currentCarts = state.cart[props.assetId] || []
      const targetItem = currentCarts.find(currentCart => currentCart.detail.id === props.detail.id)

      if (!targetItem) {
        currentCarts.push({ count: props.count, detail: props.detail })
      } else {
        targetItem.count = props.count
        targetItem.detail = props.detail
      }

      const hasCountCarts = currentCarts.filter(currentCart => currentCart.count > 0)
      state.cart[props.assetId] = hasCountCarts
      sessionStorage.setItem(sessionStorageKey, JSON.stringify(state.cart))
    },
    removeCart: (state, action: PayloadAction<{ detailId: string, assetId: string }>) => {
      const props = action.payload
      const currentCarts = state.cart[props.assetId] || []

      const removedCarts = currentCarts.filter(currentCart => currentCart.detail.id !== props.detailId)

      state.cart[props.assetId] = removedCarts
      sessionStorage.setItem(sessionStorageKey, JSON.stringify(state.cart))
    },
    clearCart: (state, action: PayloadAction<{ assetId: string }>) => {
      const props = action.payload
      state.cart[props.assetId] = []
      sessionStorage.setItem(sessionStorageKey, JSON.stringify(state.cart))
    },
  },
})

export const { changeCart, removeCart, clearCart } = requestCartSlice.actions
export const requestCartReducer = requestCartSlice.reducer
