import React from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { Link, useNavigate } from 'react-router-dom'
import { SubmitHandler, useForm } from 'react-hook-form'
import { z } from 'zod'

import { Input } from '../atoms/input'
import { useTranslation } from 'react-i18next'
import { FormLayout } from '../template/form-layout'
import { Button } from '../atoms/button'
import { horizontalSpacing, spacerTopStyle } from '@/styles/common'
import { css } from '@emotion/react'
import { useAppDispatch } from '@/app/hooks'
import { checkEmail, forgotPassword, signIn, signUp } from '@/features/auth/auth-slice'
import { useQuerySearchParams } from '@/hooks/use-query-search-params'
import { passwordRefine } from '@/utils/zod-validate-map'
import { errorHandler } from '@/libs/errors'
import { MessageType, toastAction } from '@/features/notification/toast-slice'
import { useLoginRedirect } from '@/hooks/use-login-redirect'

const emailSchema = z.string().email()

export const EmailLoginBlock: React.FC = () => {
  const searchParams = useQuerySearchParams<{ hotelId: string; userStatus: string; email: string }>()
  const { ifNeedLoginRedirect } = useLoginRedirect()
  const navigate = useNavigate()

  const { t, i18n } = useTranslation()

  const dispatch = useAppDispatch()

  interface SubmitData {
    email: string
    password: string
  }

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid, isDirty },
  } = useForm<SubmitData>({
    mode: 'onBlur',
    resolver: zodResolver(
      z.object({
        password: passwordRefine,
        ...(searchParams.email ? {} : { email: emailSchema }),
      }),
    ),
  })

  const watchEmail = watch('email')

  const onSubmit: SubmitHandler<SubmitData> = async submitData => {
    // check email
    try {
      let userStatus = searchParams.userStatus

      if (!userStatus) {
        userStatus = (await dispatch(checkEmail(submitData?.email)).unwrap()).status
      }

      const email = submitData.email || searchParams.email
      switch (userStatus) {
        case 'exists':
          await dispatch(signIn({ email: email, password: submitData.password })).unwrap()
          break
        case 'reset':
          await dispatch(
            forgotPassword({
              email: email,
              hotelId: searchParams.hotelId,
              language: i18n.language,
            }),
          ).unwrap()
          // reset password modal

          return
        default:
          await dispatch(
            signUp({
              email: email,
              password: submitData.password,
              language: i18n.language,
            }),
          ).unwrap()
      }

      if (ifNeedLoginRedirect()) {
        return
      }
      return navigate('/mypage')
    } catch (error) {
      if (typeof error === 'string') {
        return dispatch(toastAction.setMessage(MessageType.Error, t(error)))
      }

      errorHandler({ error })
    }
  }

  return (
    <>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormLayout>
            {!searchParams.email && (
              <Input
                required
                {...register('email')}
                itemCss={{ marginBottom: '1.5rem' }}
                name="email"
                type="email"
                label={t('Email')}
                placeholder={t('Email address')}
                error={errors.password?.message}
              />
            )}

            <Input
              required
              {...register('password')}
              name="password"
              type="password"
              label={t('Password')}
              placeholder={t('Must include lowercase, uppercase letters and numbers')}
              error={errors.password?.message}
            />

            <div css={passwordTextStyle}>
              <p>{t('The first time you use aiPass, please set a password of your choice.')}</p>

              <Link to={`/forget-password?email=${searchParams.email || watchEmail}`}>{t('Did you forget your password?')}</Link>
            </div>
          </FormLayout>

          <Button disabled={!isDirty || !isValid} buttonCss={[spacerTopStyle, horizontalSpacing]} type="submit" text={t('Next')} />
        </form>
      </div>
    </>
  )
}

const passwordTextStyle = css({
  fontSize: '12px',
  textAlign: 'center',
  paddingTop: '0.75rem',
  paddingBottom: '2rem',
  p: {
    marginBottom: '1rem',
  },
})
