import React from 'react'

import { css } from '@emotion/react'
import { PaymentBlock } from '@/components/organisms/payment-block'
import { useTranslation } from 'react-i18next'
import { spacerTopStyle } from '@/styles/common'
import { Button } from '../atoms/button'
import { SubmitHandler, useForm } from 'react-hook-form'
import { CardDetail, addCreditCard, cardSchema } from '@/features/user/user-card-slice'
import { formButtonStyle } from '@/styles/form'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { isEmpty } from 'lodash'
import { MessageType, toastAction } from '@/features/notification/toast-slice'
import { errorHandler } from '@/libs/errors'
import { formatCardExpire } from '@/utils/creditCard/card-expire'
import { setCheckInPaymentData } from '@/features/checkIn/check-in-payment-slice'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'

export const CheckInPaymentForm: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const accountInfo = useAppSelector(state => state.accountInfo.user)
  const payments = useAppSelector(state => state.checkIn.checkInPayments.payments)
  const { userCreditCardData, addLoading } = useAppSelector(state => state.creditCard)
  const checkInPaymentData = useAppSelector(state => state.checkIn.checkInPayments.checkInPaymentData)
  const paymentSetting = useAppSelector(state => state.paymentSetting.settings)

  // check has payment credit option
  const checkInPaymentOption = paymentSetting?.payment_time?.find(v => v.is_enabled && v.value === 'CHECKIN')

  const schema = z.discriminatedUnion('paymentType', [
    z.object({
      paymentType: z.literal('creditCard'),
      cardNumber: userCreditCardData.cardNumber ? z.string().optional() : cardSchema.shape.cardNumber,
      cardExpireYear: userCreditCardData.cardNumber ? z.string().optional() : cardSchema.shape.cardExpireYear,
      cardExpireMonth: userCreditCardData.cardNumber ? z.string().optional() : cardSchema.shape.cardExpireMonth,
      securityCode: userCreditCardData.cardNumber ? z.string().optional() : cardSchema.shape.securityCode,
      name: userCreditCardData.cardNumber ? z.string().optional() : cardSchema.shape.name,
      receiptName: cardSchema.shape.receiptName,
    }),
    z.object({
      paymentType: z.literal('checkout'),
    }),
  ])

  const createSchema = () => {
    if (!checkInPaymentOption) {
      return undefined
    }

    return zodResolver(schema)
  }

  const useFormReturn = useForm<CardDetail>({
    mode: 'all',
    defaultValues: {
      paymentType: checkInPaymentData.paymentType,
    },
    resolver: createSchema(),
  })
  const {
    formState: { isValid },
    handleSubmit,
    watch,
  } = useFormReturn

  const watchPaymentType = watch('paymentType')

  const onSubmit: SubmitHandler<CardDetail> = async submitData => {
    try {
      if (watchPaymentType === 'creditCard' && !userCreditCardData?.cardId && submitData) {
        const processResult = await dispatch(
          addCreditCard({
            cuicinUserId: accountInfo.id,
            cardDetail: {
              cardNumber: submitData.cardNumber.replace(/ /g, ''),
              cardExpire: formatCardExpire({ cardExpireMonth: submitData.cardExpireMonth, cardExpireYear: submitData.cardExpireYear }),
              securityCode: submitData.securityCode,
            },
          }),
        ).unwrap()

        if (processResult?.status !== 'success') {
          return dispatch(
            toastAction.setMessage(
              MessageType.Error,
              t(processResult.errorMessage || 'Cannot register credit card. Please check it again.'),
            ),
          )
        }
      }

      const paymentData = {
        paymentType: submitData.paymentType,
        paymentAmount: payments.totalAmount,
        receiptName: submitData.receiptName,
      }
      dispatch(setCheckInPaymentData(paymentData))

      return navigate('/checkin-confirm', { state: { receiptName: submitData.receiptName } })
    } catch (error) {
      errorHandler({ error })
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PaymentBlock useFormReturn={useFormReturn} paymentPlace="CHECKIN" />

      <Button
        loading={addLoading}
        disabled={isEmpty(watchPaymentType) || !isValid || addLoading}
        buttonCss={[spacerTopStyle, formButtonStyle]}
        text={t('Next')}
        type="submit"
      />
    </form>
  )
}

export const totalAmountStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  fontWeight: 'bold',
  div: {
    '&:first-of-type': {
      fontSize: '12px',
    },
    '&:last-child': {
      textAlign: 'right',
    },
  },
})
