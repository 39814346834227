import React from 'react'
import { useAppSelector } from '@/app/hooks'
import { Button } from '@/components/atoms/button'
import { FormatType } from '@/features/asset/assets-slice'
import { css } from '@emotion/react'
import { useNavigate } from 'react-router-dom'
import { usePickByLanguage } from '@/hooks/use-pick-by-language'
import { pcScreenStyle } from '@/styles/common'
import { minScreen } from '@/styles/media-query'

export const CheckInOutBlock: React.FC = () => {
  const { assets } = useAppSelector(state => state.assets)
  const hotelGuideData = useAppSelector(state => state.hotelGuide)
  const pickByLanguage = usePickByLanguage()
  const navigate = useNavigate()

  const pickAssetByType = (type: FormatType) => {
    return assets.find(asset => asset.formatType === type)
  }

  return (
    <div>
      <img src={hotelGuideData.facilityBasicInfo.extImageId} css={ImageStyle} />
      <div css={[checkInOutAreaStyle, pcScreenStyle]}>
        {pickAssetByType(FormatType.CheckIn) && (
          <Button text={pickByLanguage(pickAssetByType(FormatType.CheckIn)!.title)} onClick={() => navigate('/checkin')} />
        )}
        {pickAssetByType(FormatType.Checkout) && (
          <Button text={pickByLanguage(pickAssetByType(FormatType.Checkout)!.title)} onClick={() => navigate('/checkout')} />
        )}
      </div>
    </div>
  )
}

const ImageStyle = css({
  width: '100vw',
  [minScreen()]: {
    height: 200,
    objectFit: 'cover',
  }
})

const checkInOutAreaStyle = css({
  display: 'flex',
  padding: '0 16px',
  transform: 'translate(0%, -50%)',
  ':has(> :nth-of-type(2):last-child) div ': {
    ':first-of-type': {
      paddingRight: 8,
    },
  },
})
