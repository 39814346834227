import { css } from '@emotion/react'
import React from 'react'

export const KoContent: React.FC = () => {
  return (
    <div css={layoutStyle}>
      <div css={administratorStyle}>
        <h2 css={pageTitleStyle}>aipass의 개인 정보 보호 정책</h2>

        <p css={updateStyle}>최종 업데이트: 2022년 4월 15일</p>
      </div>

      <p css={leadTextStyle}>
        {`aipass 주식회사(이하 '당사'라고 합니다.) 는 본 웹사이트에서 제공하는 서비스 aipass(아이패스)(이하 '본 서비스'라고 합니다.) 에서 이용자의 개인정보 취급에 대해 다음과 같이 개인정보처리방침(이하 '본 방침'이라 합니다.)을 정합니다. 을 정합니다.`}
      </p>

      <h3 css={semiTitleStyle}>개인정보처리방침(개인정보)</h3>
      <p css={leadTextStyle}>{`"개인정보"라 함은 생존하는 개인에 관한 정보로서 다음 각 호의 어느 하나에 해당하는 것을 말합니다.`}</p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          해당 정보에 포함된 성명, 주소, 생년월일, 성별, 직업, 전화번호, 이메일 주소 등으로 특정 개인을 식별할 수 있는 정보.
        </li>
        <li css={listItemStyle}>{`개인식별부호(개인정보 보호에 관한 법률(이하 '개인정보보호법'이라 합니다.) 제2조 제2항)이 포함된 것.`}</li>
      </ol>

      <h3 css={semiTitleStyle}>제2조(개인정보 수집방법)</h3>
      <p css={leadTextStyle}>
        당사는 이용자가 이용 등록을 할 때 이름, 생년월일, 주소, 전화번호, 이메일 주소, 은행 계좌번호, 신용카드 번호, 운전면허증 번호 등의
        개인정보를 요구할 수 있습니다. 또한, 이용자와 제휴사 등과의 사이에서 이루어진 이용자의 개인정보를 포함한 거래 기록 및 결제에 관한
        정보를 당사의 제휴사(정보 제공처, 광고주, 광고 게재처 등을 포함합니다.) 등으로부터 수집할 수 있습니다.
      </p>

      <h3 css={semiTitleStyle}>제3조 (개인정보의 수집 및 이용 목적)</h3>
      <p css={leadTextStyle}>당사가 개인정보를 수집-이용하는 목적은 다음과 같습니다.</p>
      <ol css={listStyle}>
        <li css={listItemStyle}>이용자의 개인 인증 및 이용자를 위한 본 서비스 제공.</li>
        <li css={listItemStyle}>본 서비스에 관한 의견, 문의, 입소문 게시 내용 확인 및 답변(본인 확인 포함)</li>
        <li css={listItemStyle}>본 서비스 이용에 따른 연락, 메일 매거진, DM, 각종 공지 사항 등의 전달 및 발송</li>
        <li css={listItemStyle}>이용약관을 위반한 이용자 및 부정-부당한 목적으로 서비스를 이용하려는 이용자에 대한 조사 및 상세 확인</li>
        <li css={listItemStyle}>이용자가 자신의 등록 정보를 열람, 변경, 삭제, 이용 현황을 열람할 수 있도록 하기 위해</li>
        <li css={listItemStyle}>이용자의 동의・신청에 따른 본 서비스 이용 기업 등에 대한 개인정보 제공</li>
        <li css={listItemStyle}>
          속성정보에 대한 정보, 단말기 정보, 위치 정보, 행동 이력 등을 기반으로 한 광고, 콘텐츠 등의 배포 및 표시, 본 서비스 제공
        </li>
        <li css={listItemStyle}>본 서비스 개선, 신규 서비스 개발, 포인트 이용 현황 조사, 분석 및 마케팅</li>
        <li css={listItemStyle}>캠페인, 설문조사, 모니터, 취재 등 실시</li>
        <li css={listItemStyle}>유료 서비스에서 이용자에게 이용요금을 청구하기 위해</li>
        <li css={listItemStyle}>상기 이용목적에 부수되는 목적</li>
      </ol>

      <h3 css={semiTitleStyle}>제4조 (이용목적의 변경)</h3>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          당사는 이용 목적이 변경 전과 관련성이 있다고 합리적으로 인정되는 경우에만 개인정보의 이용 목적을 변경합니다.
        </li>
        <li css={listItemStyle}>
          이용 목적을 변경한 경우에는 변경 후의 목적에 대해 당사 소정의 방법으로 이용자에게 통지하거나 본 웹사이트에 공표하는 것으로 합니다.
        </li>
      </ol>

      <h3 css={semiTitleStyle}>제5조 (개인정보의 제3자 제공)</h3>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          당사는 원칙적으로 이용자 본인의 동의 없이 개인정보를 제3자에게 제공하지 않습니다. 제공처 및 제공 정보 내용을 명시한 후 이용자의
          동의를 얻은 경우에만 제공합니다. 제공처 및 목적에 대해서는 본 서비스 이용 기업 등에 대한 자료 요청, 응모, 예약, 구매 등의 서비스
          제공 등을 위한 것입니다.
        </li>
        <li css={listItemStyle}>
          제공하는 개인정보 항목은 이용자로부터 취득한 정보(서비스 이용 이력 외에 열람, 검색, 북마크 등 모든 행동 이력에 해당하는 정보 포함)
          중 이용목적 달성을 위해 필요한 범위의 정보 항목으로 합니다. 제공의 수단 또는 방법은 서면 또는 전자적 방법에 의한 송부 또는
          전송으로 합니다.
        </li>
        <li css={listItemStyle}>
          다음의 경우에는 관계법령에 위배되지 않는 범위 내에서 이용자의 동의 없이 개인정보를 제공할 수 있습니다.
          <ol css={listDiscStyle}>
            <li>이용자가 제3자에게 불이익을 준다고 판단되는 경우.</li>
            <li>공중위생의 향상 또는 아동의 건전한 육성을 위하여 특별히 필요한 경우로서 이용자 본인의 동의를 얻기가 곤란한 경우</li>
            <li>
              국가기관 또는 지방자치단체 또는 그 위탁을 받은 자가 법령에 규정된 사무를 수행하는데 협조할 필요가 있는 경우로서, 이용자 본인의
              동의를 얻음으로써 해당 사무의 수행에 지장을 초래할 우려가 있는 경우
            </li>
            <li>법원, 검찰청, 경찰 또는 이에 준하는 권한을 가진 기관으로부터 개인정보에 대한 공개를 요구받은 경우</li>
            <li>이용자 본인이 명시적으로 제3자에게 공개 또는 제공을 요청한 경우.</li>
            <li>법령에 의해 공개 또는 제공이 허용되는 경우.</li>
            <li>합병 기타 사유로 인한 사업승계에 따라 개인정보를 제공하는 경우로서 승계 전의 이용목적의 범위 내에서 취급하는 경우</li>
          </ol>
        </li>
      </ol>

      <h3 css={semiTitleStyle}>제6조 (제3자 제공에 관한 면책사항)</h3>
      <p css={leadTextStyle}>다음과 같은 경우, 당사는 제3자에 의한 개인정보 취득에 대해 어떠한 책임도 지지 않습니다.</p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          이용자 자신이 본 서비스의 기능 또는 다른 수단을 이용하여 이용기업 등에 개인정보를 공개한 경우(이용기업 등의 개인정보 취급에
          대해서는 각 이용기업 등에 직접 문의해 주시기 바랍니다).
        </li>
        <li css={listItemStyle}>본 서비스에 입력한 정보로 인해 본인을 식별할 수 있게 된 경우</li>
        <li css={listItemStyle}>본 서비스에서 링크된 외부 사이트에서 이용자로부터 개인정보가 제공되거나 이용된 경우</li>
        <li css={listItemStyle}>이용자 본인 이외의 자가 이용자 개인을 식별할 수 있는 정보(아이디, 비밀번호 등)를 취득한 경우</li>
      </ol>

      <h3 css={semiTitleStyle}>제7조 (개인정보 처리의 위탁)</h3>
      <p css={leadTextStyle}>
        당사는 개인정보 취급 업무의 일부 또는 전부를 외부에 위탁할 수 있습니다. 또한, 위탁처의 개인정보 취급에 대해서는 당사가 책임을
        집니다.
      </p>

      <h3 css={semiTitleStyle}>제8조 (통계처리된 데이터의 이용)</h3>
      <p css={leadTextStyle}>
        당사는 제공받은 개인정보를 바탕으로 개인을 식별할 수 없도록 가공한 통계자료(개인정보보호법 제2조 제9항에 규정된 것을 의미하며, 동법
        제2조 제10항에 규정된 익명가공정보 데이터베이스 등을 구성하는 것에 한합니다. 이하 같습니다.) 를 작성할 수 있습니다. 개인을 식별할 수
        없는 통계 데이터에 대해서는 개인정보보호위원회 규정에서 정한 기준에 따라 개인정보를 가공하여 안전관리를 위한 조치를 취합니다.
        당사에서 해당 데이터를 작성한 경우, 개인정보보호위원회 규정에서 정하는 바에 따라 해당 익명가공정보에 포함된 개인에 관한 정보의
        항목을 공표하고, 당사는 해당 데이터를 아무런 제한 없이 이용할 수 있습니다. 개인에 관한 정보의 항목은 성별, 국적, 나이, 거주지,
        우편번호, 숙박 예약, 숙박 이력, 동반자 수, 거래 금액 등
      </p>

      <h3 css={semiTitleStyle}>제9조 (개인정보의 변경 등)</h3>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          원칙적으로 이용자 본인에 한하여 「개인정보의 이용목적」의 고지, 등록한 개인정보의 개시, 정정, 추가 또는 삭제, 이용정지 및 제3자
          제공의 정지(이하 「개인정보의 변경 등」이라 합니다)를 요구할 수 있습니다. 구체적인 방법은 아래의 개인정보 관리 문의처로 연락
          주시기 바랍니다. 단, 다음과 같은 경우에는 개인정보 변경 등에 응하지 않을 수 있습니다.
          <ol css={listDiscStyle}>
            <li>본인 또는 제3자의 생명, 신체, 재산, 기타 권익을 해칠 우려가 있을 경우</li>
            <li>당사 업무의 적정한 수행에 현저한 지장을 초래할 우려가 있는 경우.</li>
            <li>기타 법령에 위반되는 경우</li>
          </ol>
        </li>
        <li css={listItemStyle}>
          전항의 규정에도 불구하고, 이력정보 및 속성정보 등 개인정보 이외의 정보는 원칙적으로 공개하지 않습니다. 또한, 해당 개인정보의 변경
          등에 다액의 비용이 소요되는 경우, 기타 개인정보의 변경 등을 하는 것이 곤란한 경우로서 이용자의 권리・이익을 보호하기 위해 필요한
          대체 조치를 취하는 경우에는 개인정보의 변경 등에 응하지 않을 수 있습니다.
        </li>
      </ol>

      <h3 css={semiTitleStyle}>제10조 (개인정보의 정정 및 삭제)</h3>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          {`이용자는 당사가 보유하고 있는 자신의 개인정보가 잘못된 정보인 경우, 당사가 정하는 절차에 따라 당사에 개인정보의 정정, 추가 또는
          삭제(이하 '정정 등'이라 합니다.)를 청구할 수 있습니다. 를 청구할 수 있습니다.`}
        </li>
        <li css={listItemStyle}>
          당사는 이용자로부터 전항의 청구를 받아 그 청구에 응할 필요가 있다고 판단한 경우에는 지체 없이 해당 개인정보의 정정 등을
          실시합니다.
        </li>
        <li css={listItemStyle}>
          당사는 전항의 규정에 따라 정정 등을 실시한 경우 또는 정정 등을 실시하지 않기로 결정한 경우에는 지체 없이 이를 이용자에게
          통지합니다.
        </li>
      </ol>

      <h3 css={semiTitleStyle}>제11조 (개인정보의 이용정지 등)</h3>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          {`당사는 본인으로부터 개인정보가 이용 목적의 범위를 초과하여 취급되고 있다는 이유 또는 부정한 방법으로 취득한 것이라는 이유로 그 이용 정지 또는 삭제(이하 '이용 정지 등'이라 합니다.)를 요청받은 경우, 지체 없이 필요한 조사를 실시합니다. 를 요구받은 경우에는 지체 없이 필요한 조사를 실시합니다.`}
        </li>
        <li css={listItemStyle}>
          전항의 조사 결과에 따라 그 청구에 응할 필요가 있다고 판단한 경우에는 지체 없이 해당 개인정보의 이용 정지 등을 실시합니다.
        </li>
        <li css={listItemStyle}>
          당사는 전항의 규정에 따라 이용정지 등을 실시한 경우 또는 이용정지 등을 실시하지 않기로 결정한 경우에는 지체 없이 이를 이용자에게
          통지합니다.
        </li>
        <li css={listItemStyle}>
          전 2항에도 불구하고 이용정지 등에 많은 비용이 소요되는 경우 기타 이용정지 등을 하는 것이 곤란한 경우로서 이용자의 권익을 보호하기
          위해 필요한 대체조치를 취할 수 있는 경우에는 이 대체조치를 취합니다.
        </li>
      </ol>

      <h3 css={semiTitleStyle}>제12조 (개인정보의 정확성)</h3>
      <p css={leadTextStyle}>
        당사는 제공받은 개인정보를 정확하게 처리하기 위해 노력합니다. 단, 제공하신 개인정보의 내용이 정확하고 최신의 것임에 대한 책임은
        이용자에게 있습니다.
      </p>

      <h3 css={semiTitleStyle}>제13조 (본인 확인에 대하여)</h3>
      <p css={leadTextStyle}>
        당사는 각 웹서비스에 회원으로 가입하거나 회원이 본 서비스를 이용하는 경우, 개인정보의 공개, 정정, 삭제 또는 이용정지 요청에 응할
        경우 등 개인을 식별할 수 있는 정보(성명, 주소, 전화번호, 생년월일, 이메일 주소, 회원번호, 비밀번호 등)를 통해 본인임을 확인합니다.
        합니다. 단, 본인 이외의 사람이 개인을 식별할 수 있는 정보를 입수하여 사용하는 경우, 당사는 책임을 지지 않습니다.
      </p>

      <h3 css={semiTitleStyle}>제14조 (속성정보, 단말기정보, 위치정보, 행동이력 등의 취득 및 이용에 관한 사항)</h3>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          {`속성 정보, 단말기 정보, 위치 정보, 행동 이력 등(이하 '행동 이력 등'이라 함) 중 쿠키 등에 대해 당사는 이용자의 프라이버시 보호,
          편의성 향상, 광고 전달 및 통계 데이터 취득을 위해 쿠키를 사용합니다. 또한, 쿠키 및 JavaScript 등의 기술을 이용하여 제공받은 정보
          중 연령, 성별, 직업, 거주 지역 등 개인을 식별할 수 없는 속성 정보(조합하여도 개인을 식별할 수 없는 것에 한함) 및 단말기 정보, 본
          서비스 사이트 내에서의 이용자의 행동 이력(접속한 URL, 콘텐츠, 참조 순서 등) 및 스마트폰 등 이용 시 이용자의 동의・신청에 따른
          위치정보를 취득할 수 있습니다. 단, 쿠키 및 행동 이력 등에는 개인정보는 일절 포함되지 않습니다.`}
        </li>
        <li css={listItemStyle}>
          기기의 쿠키는 이용자가 직접 삭제하거나 일정 기간이 만료될 때까지 기기에 남아있게 됩니다. 대부분의 브라우저는 기본적으로 쿠키를
          자동으로 허용하도록 설정되어 있지만, 사용자가 브라우저 설정을 변경하여 쿠키를 비활성화할 수 있습니다. 단, 쿠키를 비활성화하면
          웹사이트의 일부 기능을 사용할 수 없거나 일부 페이지가 제대로 표시되지 않을 수 있습니다.
        </li>
        <li css={listItemStyle}>
          행동 이력 등 중 다음과 같은 경우의 접속 로그 등에 대해 당사는 이용자가 로그인하여 본 서비스를 이용하는 경우, 개인을 특정하여 해당
          로그인 이전부터의 행동 이력 등을 이용하여 광고・컨텐츠 등의 게재・표시 및 본 서비스 제공을 할 수 있습니다. 이 광고 등의 수신거부에
          대해서는 개인정보 관리 문의처로 연락해 주시기 바랍니다.
        </li>
      </ol>

      <h3 css={semiTitleStyle}>제15조 (개인정보처리방침의 변경)</h3>
      <p css={leadTextStyle}>
        본 정책의 내용은 법령 기타 본 정책에 별도로 정한 사항을 제외하고는 이용자에게 통지하지 않고 변경할 수 있습니다. 또한 당사가 별도로
        정한 경우를 제외하고, 변경된 개인정보처리방침은 본 웹사이트에 게시한 시점부터 효력이 발생하는 것으로 합니다.
      </p>

      <h3 css={semiTitleStyle}>제16조 (문의 창구)</h3>
      <p css={leadTextStyle}>본 정책에 관한 문의는 아래 연락처로 연락 주시기 바랍니다.</p>

      <p css={infoStyle}>
        주소 <br />
        150-0031, Japan <br />
        Shinoda Building 3F, 23-3 Sakuragaokacho, Shibuya-ku, Tokyo
      </p>
      <p css={infoStyle}>
        회사명 <br />
        aipass株式会社
      </p>
      <p css={infoStyle}>
        이메일 주소 <br />
        support@aipass.jp
      </p>
    </div>
  )
}

const layoutStyle = css({
  marginTop: '5rem',
  paddingRight: '2.5rem',
  paddingLeft: '2.5rem',
  lineHeight: '1.625',
})

const pageTitleStyle = css({
  fontWeight: '600',
  textAlign: 'center',
  fontSize: '20px',
})

const administratorStyle = css({
  textAlign: 'center',
})

const updateStyle = css({
  marginTop: '24px',
  marginBottom: '24px',
  fontSize: '14px',
  color: '#475569',
})

const semiTitleStyle = css({
  fontWeight: '600',
  marginTop: '32px',
  marginBottom: '8px',
})

const leadTextStyle = css({
  fontSize: '12px',
  marginTop: '1.5rem',
  marginBottom: '1.5rem',
  lineHeight: '1.625',
})

const listDiscStyle = css({
  listStyle: 'disc',
  marginRight: '1rem',
  marginLeft: '1rem',
  li: {
    listStyle: 'disc',
  },
})

const listStyle = css({
  paddingLeft: '1rem',
  paddingRight: '1rem',
  marginBottom: '1rem',
  listStyleType: 'decimal',
})

const listItemStyle = css({
  fontSize: '12px',
  lineHeight: '1.625',
  listStyleType: 'decimal',
})

const infoStyle = css({
  fontSize: '14px',
  marginTop: '1rem',
  lineHeight: '1.625',
})
