import { logout } from '@/features/auth/logout-slice'
import { globalHotelId } from '@/hooks/use-hotel-id'
import i18n from '@/i18n'
import { fingerprint } from '@/libs/fingerprint'
import axios, { AxiosError, AxiosResponse } from 'axios'

const ourRequest = axios.CancelToken.source()

export type HttpParams = {
  url: string
  method: 'get' | 'post' | 'put' | 'delete' | 'patch'
  headers?: any
  params?: any
  data?: any
  responseType?: any
  paramsSerializer?: (params: any) => string
}

export const LatestVersionKey = 'latestVersion'
export const CurrentVersionKey = 'currentVersion'

export async function http<T = any>(config: HttpParams, dispatch?): Promise<AxiosResponse<T>> {
  try {
    const { params, data, responseType, paramsSerializer } = config
    const headers = config.headers || {}
    headers['x-front-path'] = window?.location?.pathname || 'N/A'
    headers['x-version'] = sessionStorage.getItem(CurrentVersionKey) || 'N/A'
    headers['x-fingerprint'] = fingerprint

    const Authorization = getIdToken()
    if (Authorization) headers['Authorization'] = Authorization

    axios.interceptors.response.use(response => {
      const latestVersion = response.headers['x-guest-app-version']
      if (!latestVersion) {
        return response
      }
      sessionStorage.setItem(LatestVersionKey, latestVersion)
      const currentVersion = sessionStorage.getItem(CurrentVersionKey)
      if (!currentVersion) {
        sessionStorage.setItem(CurrentVersionKey, latestVersion)
      }
      return response
    })

    switch (config.method) {
      case 'get':
        return await axios.get<T>(config.url, {
          headers,
          params,
          data,
          responseType,
          cancelToken: ourRequest.token,
          paramsSerializer,
          withCredentials: true,
        })
      case 'post':
        return await axios.post<T>(config.url, data, { headers, params, cancelToken: ourRequest.token, withCredentials: true })
      case 'patch':
        return await axios.patch<T>(config.url, data, { headers, params, cancelToken: ourRequest.token, withCredentials: true })
      case 'put':
        return await axios.put<T>(config.url, data, { headers, params, cancelToken: ourRequest.token, withCredentials: true })
      case 'delete':
        return await axios.delete<T>(config.url, {
          headers,
          params,
          data,
          cancelToken: ourRequest.token,
          paramsSerializer,
          withCredentials: true,
        })
      default:
        throw new Error('not reachable')
    }
  } catch (e) {
    const error = e as AxiosError
    if (error.response?.status === 401 && dispatch) {
      ourRequest.cancel()
      dispatch(logout({ hotelId: globalHotelId(), lang: i18n.language }))
    }
    return Promise.reject(error)
  }
}

function getIdToken(): string | null {
  return localStorage.getItem('access_token')
}
