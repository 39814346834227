import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { BorderButton, Button } from '@/components/atoms/button'
import { ModalWrapper } from '@/components/atoms/modal-wrapper'
import { forgotPassword } from '@/features/auth/auth-slice'
import { useQuerySearchParams } from '@/hooks/use-query-search-params'
import { errorHandler } from '@/libs/errors'
import { spacerTopStyle } from '@/styles/common'
import { CSSObject, css } from '@emotion/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface resetPasswordModalProps {
  isOpen: boolean
  onClose: () => void
  mailStatus: string
  email: string
  modalStyle?: CSSObject
  shouldCloseOnOverlayClick?: boolean
}

export const ResetPasswordModal: React.FC<resetPasswordModalProps> = ({ isOpen, onClose, mailStatus, email }) => {
  const [resetPasswordDisabled, setResetPasswordDisabled] = useState(true)

  const {
    t,
    i18n: { language },
  } = useTranslation()

  const { hotelId } = useQuerySearchParams<{ hotelId: string }>()

  const dispatch = useAppDispatch()

  const loading = useAppSelector(state => state.auth.forgotPassword.loading)

  const resetPassword = async () => {
    try {
      await dispatch(
        forgotPassword({
          email,
          language,
          hotelId,
        }),
      )

      setResetPasswordDisabled(true)
    } catch (error) {
      errorHandler({ error })
    }
  }

  useEffect(() => {
    if (resetPasswordDisabled) {
      const timer = setTimeout(() => {
        setResetPasswordDisabled(false)
      }, 6000)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [resetPasswordDisabled])

  return (
    <ModalWrapper modalStyle={{ maxHeight: '350px' }} isOpen={isOpen} onClose={onClose}>
      <h3 css={titleStyle}>
        {t(mailStatus === 'reset' ? 'Please reset your password for system migration' : 'Please reset your password')}
      </h3>

      <div css={noticeList}>
        <p>
          {t(
            'We have sent a password reset notification email to your registered email address. Please click on the reset link provided and enter your new password.',
          )}
        </p>
        <span>{t('Sent from')}: noreply@aipass.jp</span>
      </div>

      <Button onClick={onClose} text={t('Change is complete')} />
      <BorderButton
        disabled={loading}
        loading={loading}
        onClick={() => resetPassword()}
        buttonCss={spacerTopStyle}
        text={t(!resetPasswordDisabled ? 'Resend' : 'Resend completed')}
      />
    </ModalWrapper>
  )
}

const titleStyle = css({
  textAlign: 'center',
  fontSize: '18px',
  fontWeight: 'bold',
  lineHeight: '1.5',
  marginBottom: '1.5rem',
})

const noticeList = css({
  textAlign: 'center',
  fontSize: '12px',
  lineHeight: '1.5',
  marginBottom: '1.5rem',
  P: {
    whiteSpace: 'pre-wrap',
  },
})
