import { useCallback } from 'react'
import { useQuerySearchParams } from './use-query-search-params'

const STORAGE_KEY = 'app.hotel.id'
export const useHotelId = () => {
  const { hotelId } = useQuerySearchParams<{ hotelId: string }>()

  const getHotelId = useCallback(() => {
    if (hotelId) {
      localStorage.setItem(STORAGE_KEY, hotelId)
    }
    return localStorage.getItem(STORAGE_KEY)
  }, [hotelId])

  return { getHotelId }
}
export const globalHotelId = () => localStorage.getItem(STORAGE_KEY) || ''
