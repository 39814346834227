import { css } from '@emotion/react'
import React from 'react'

export const ZhContent: React.FC = () => {
  return (
    <div css={layoutStyle}>
      <div css={administratorStyle}>
        <h2 css={pageTitleStyle}>aipass 的隐私政策</h2>

        <p css={updateStyle}>最后更新日期：2022年4月15日</p>
      </div>

      <p css={leadTextStyle}>
        {`aipass 有限公司（以下简称 "公司） (aipass 有限公司（以下简称 "本公司"）就本网站提供的 aipass 服务（以下简称 "本服务"）的用户个人信息的处理制定了以下隐私政策（以下简称 "本政策"）。 以下隐私政策（以下简称 "政策"）适用于 aipass 网站提供的 aipass 服务（以下简称 "服务"）中用户个人信息的处理。 以下为隐私政策（以下简称 "政策"）。`}
      </p>

      <h3 css={semiTitleStyle}>第 1 条（个人信息）</h3>
      <p css={leadTextStyle}>{`个人数据 "是指与在世个人有关的信息，这些信息`}</p>
      <ol css={listStyle}>
        <li css={listItemStyle}>姓名、地址、出生日期、性别、职业、电话号码、电子邮件地址或此类信息中可识别特定个人的其他信息。</li>
        <li css={listItemStyle}>个人身份代码（根据《个人数据保护法》（）第 2.2 条定义），其中包含 第 2.2 条），其中包含</li>
      </ol>

      <h3 css={semiTitleStyle}>第 2 条（收集个人资料的方法）</h3>
      <p css={leadTextStyle}>
        用户注册使用时，本公司可能会要求用户提供姓名、出生日期、地址、电话号码、电子邮件地址、银行账号、信用卡号和驾驶执照号等个人信息。
        本公司还可能从合作伙伴（包括信息提供商、广告商和广告分销商）以及用户与合作伙伴之间的其他方收集交易记录和付款信息，包括个人信息。
        这些信息可能会从我们的合作伙伴（包括信息提供商、广告商、广告分销商等）等处收集。
      </p>

      <h3 css={semiTitleStyle}>第 3 条（收集和使用个人信息的目的）</h3>
      <p css={leadTextStyle}>我们收集和使用个人数据的目的如下</p>
      <ol css={listStyle}>
        <li css={listItemStyle}>用户的个人身份识别和为用户提供服务。</li>
        <li css={listItemStyle}>确认和回复有关本服务的意见、询问和口碑（包括进行身份检查）。</li>
        <li css={listItemStyle}>分发和发送与使用本服务相关的通讯、电子通讯、直邮、DM、各种通知等。</li>
        <li css={listItemStyle}>调查并核实违反使用条款或试图以欺诈或不正当目的使用服务的用户的详细信息。</li>
        <li css={listItemStyle}>允许用户查看、更改或删除自己的注册信息，并查看自己的使用状态。</li>
        <li css={listItemStyle}>根据用户的同意或申请，向使用服务的公司和其他公司提供个人信息。</li>
        <li css={listItemStyle}>根据属性信息、设备信息、位置信息、操作记录等分发和显示广告、内容等，并提供服务。</li>
        <li css={listItemStyle}>改进这项服务，开发新的服务，研究、分析和营销点的使用情况</li>
        <li css={listItemStyle}>开展宣传、问卷调查、监测、访谈等活动。</li>
        <li css={listItemStyle}>向用户收取付费服务费。</li>
        <li css={listItemStyle}>上述使用目的的附带目的。</li>
      </ol>

      <h3 css={semiTitleStyle}>第 4 条（改变用途）</h3>
      <ol css={listStyle}>
        <li css={listItemStyle}>只有在使用目的被合理视为与变更前相关的情况下，公司方可变更个人数据的使用目的。</li>
        <li css={listItemStyle}>使用目的发生变更时，应按照本公司规定的方式通知用户或在本网站上公布变更后的使用目的。</li>
      </ol>

      <h3 css={semiTitleStyle}>第 5 条（向第三方提供个人资料）</h3>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          原则上，未经用户同意，公司不会向第三方提供个人信息。 只有在征得用户同意后，在明确接收方和提供信息的内容后，才会提供信息。
          接收方和提供目的是为了向使用服务的公司提供信息请求、申请、预订、购买等服务。
        </li>
        <li css={listItemStyle}>
          提供的个人数据项应为从用户处获取的信息（包括服务使用记录和适用于所有操作记录的信息，如浏览、搜索、书签等）中为实现使用目的所必需的范围内的信息项。
          提供的方式或方法应为书面或电磁传输。
        </li>
        <li css={listItemStyle}>
          在以下情况下，可在不违反相关法律法规的前提下，在未经用户同意的情况下提供个人数据。
          <ol css={listDiscStyle}>
            <li>如果用户被认为对第三方不利。</li>
            <li>为改善公众健康或促进儿童健康成长而特别需要，且难以征得用户同意的情况。</li>
            <li>有必要与国家机关、地方当局或受其委托执行法律规定事务的人员合作，而征得用户同意可能会妨碍这些事务的执行。</li>
            <li>如果法院、检察院、警方或具有同等权力的机构要求披露个人信息。</li>
            <li>用户本人明确要求向第三方披露或提供信息。</li>
            <li>法律允许披露或提供的情况。</li>
            <li>因合并或其他原因导致企业继承，并在继承前的使用目的范围内处理个人信息时。</li>
          </ol>
        </li>
      </ol>

      <h3 css={semiTitleStyle}>第 6 条（关于向第三方提供的免责声明）</h3>
      <p css={leadTextStyle}>在以下情况下，我们对第三方获取个人数据不承担任何责任</p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          用户本人利用服务功能或通过其他方式向用户公司等透露个人信息（有关用户公司处理个人信息的信息，请直接联系各用户公司）。
        </li>
        <li css={listItemStyle}>如果输入服务的信息无意中使相关人员的身份得以确认。</li>
        <li css={listItemStyle}>用户提供个人信息并在与本服务链接的外部网站上使用时。</li>
        <li css={listItemStyle}>非用户本人获取可识别个人用户的信息（ID、密码等）。</li>
      </ol>

      <h3 css={semiTitleStyle}>第 7 条（个人数据处理的外包）</h3>
      <p css={leadTextStyle}>公司可外包部分或全部个人数据处理业务。 公司对外包公司的个人数据处理负责。</p>

      <h3 css={semiTitleStyle}>第 8 条（统计处理数据的使用）</h3>
      <p css={leadTextStyle}>
        公司将根据所提供的个人数据，以无法识别个人的方式处理统计数据（指《个人数据保护法》第 2(9)条规定的数据，并仅限于该法第
        2(10)条规定的构成匿名处理信息数据库等的数据）。 下同）。 下同）。
        在处理无法识别个人身份的统计数据时，应遵守个人数据保护委员会规则中规定的标准，并采取措施确保安全管理。
        当我们创建此类数据时，我们应根据《个人数据保护委员会规则》公布匿名处理信息中包含的与个人相关的信息项目，并且我们可以不受限制地使用此类数据。
        个人相关信息包括性别、国籍、年龄、居住省份、邮编、住宿预订、住宿历史、随行人数、交易金额等。
      </p>

      <h3 css={semiTitleStyle}>第 9 条（个人信息的变更等）</h3>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          {`原则上，只有用户本人可以要求通知 "个人信息的使用目的"、公开、修改、追加或删除已注册的个人信息、停止使用以及停止向第三方提供个人信息（以下简称 "个人信息变更"）。 有关具体说明，请联系以下个人信息管理联系人；但是，在以下情况下，我们可能无法办理个人信息变更等手续。`}
          <ol css={listDiscStyle}>
            <li>如果当事人或第三方的生命、身体、财产或其他权利或利益可能受到伤害</li>
            <li>我们的正常业务有可能受到严重干扰。</li>
            <li>否则将违反法律。</li>
          </ol>
        </li>
        <li css={listItemStyle}>
          虽有前款规定，但原则上不公开历史信息、属性信息等个人信息以外的信息。
          此外，如果更改个人信息有困难，例如更改个人信息需要大量资金，或者需要采取其他措施保护用户的权益，则可能无法更改个人信息。
        </li>
      </ol>

      <h3 css={semiTitleStyle}>第 10 条（个人资料的更正和删除）</h3>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          {`当本公司持有的用户个人信息有误时，用户可根据本公司规定的手续，要求本公司对其个人信息进行更正、追加或删除（以下简称 "更正等"）。 用户可根据本公司规定的手续，要求更正、追加或删除其个人信息（以下简称 "更正等"）。`}
        </li>
        <li css={listItemStyle}>如果公司收到用户提出的前段所述请求，并确定有必要回应该请求，公司应立即更正相关个人数据。</li>
        <li css={listItemStyle}>公司根据前款规定进行更正等，或决定不进行更正等时，应及时通知用户。</li>
      </ol>

      <h3 css={semiTitleStyle}>第 11 条（暂停使用个人信息等）</h3>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          {`如果当事人以超出使用目的范围或以不正当手段获取为由要求停止使用或删除（以下简称 "停止使用"）其个人信息，公司应立即进行必要的调查。 如果以超出使用目的范围或以欺诈手段获取为由要求停止使用或删除（以下简称 "停止使用"）个人信息，公司将立即进行必要的调查。`}
        </li>
        <li css={listItemStyle}>如果根据前段所述的调查结果，认为有必要满足该要求，则将立即停止使用相关个人数据。</li>
        <li css={listItemStyle}>如果公司决定不按照前款规定暂停使用数据，或者如果公司决定不暂停使用数据，公司应立即通知用户。</li>
        <li css={listItemStyle}>
          尽管有前两款的规定，如果中止使用数据或采取保护用户权利和利益所需的替代措施有
          困难，在中止使用数据会产生巨额费用或因其他原因难以中止使用数据的情况下，应采取这 种替代措施。
        </li>
      </ol>

      <h3 css={semiTitleStyle}>第 12 条（个人资料的准确性）</h3>
      <p css={leadTextStyle}>我们将尽力准确处理您提供的个人数据。 但是，用户有责任确保所提供的个人数据准确无误并及时更新。</p>

      <h3 css={semiTitleStyle}>第 13 条（身份识别）</h3>
      <p
        css={leadTextStyle}
      >{`会员注册网络服务时、会员使用服务时、会员对个人信息的公开、修改、删除、停止使用等要求做出答复时，公司通过可识别个人身份的信息（姓名、地址、电话号码、出生年月日、电子邮件地址、会员号、密码等）确认会员的身份。 通过个人身份信息（姓名、地址、电话号码、出生日期、电子邮件地址、会员号、密码等）确认个人身份。 但是，如果本人以外的任何人获取并使用个人身份信息，本公司概不负责。`}</p>

      <h3 css={semiTitleStyle}>第 14 条（属性信息、设备信息、位置信息和操作记录的获取和使用）</h3>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          {`关于属性信息、终端信息、位置信息、操作履历等（以下简称 "操作履历等"）中的Cookie等，本公司为保护用户隐私、提高便利性、发送广告、获取统计数据等目的而使用Cookie。 此外，Cookie、JavaScript 等技术还用于收集所提供信息中无法识别个人身份的属性信息，如年龄、性别、职业和居住地区（仅限于合并后也无法识别个人身份的信息）、设备信息以及用户在本服务网站内的行为。 在使用智能手机或类似设备时，本服务还可能根据用户的同意或应用程序收集用户的位置信息。 但是，cookies 和操作记录不包含任何个人信息。`}
        </li>
        <li css={listItemStyle}>
          {`设备上的 Cookie 会一直保留在设备上，直到用户自行删除或某个有效期到期。 大多数浏览器默认自动接受 Cookie，但用户可以通过更改浏览器设置禁用 Cookie。 但是，如果禁用 Cookie，某些网站功能可能无法使用，某些页面可能无法正确显示。`}
        </li>
        <li css={listItemStyle}>
          关于以下情况下的访问记录等行为记录等，如果用户登录并使用本服务，本公司可能会在识别个人身份后，利用登录前的行为记录等发送和显示广告、内容等，并提供本服务。
          如需退订这些广告等，请联系个人信息管理员。
        </li>
      </ol>

      <h3 css={semiTitleStyle}>第 15 条（隐私政策的变更）</h3>
      <p css={leadTextStyle}>
        本政策的内容可在不通知用户的情况下进行更改，除非法律或本政策的其他条款另有规定。
        除非本公司另有规定，否则修改后的隐私政策将自其在本网站上公布之时起生效。
      </p>

      <h3 css={semiTitleStyle}>第 16 条（咨询联系人）</h3>
      <p css={leadTextStyle}>如对本政策有任何疑问，请联系</p>

      <p css={infoStyle}>
        地址 <br />
        150-0031, Japan <br />
        Shinoda Building 3F, 23-3 Sakuragaokacho, Shibuya-ku, Tokyo
      </p>
      <p css={infoStyle}>
        公司名称 <br />
        aipass株式会社
      </p>
      <p css={infoStyle}>
        电子邮件地址 <br />
        support@aipass.jp
      </p>
    </div>
  )
}

const layoutStyle = css({
  marginTop: '5rem',
  paddingRight: '2.5rem',
  paddingLeft: '2.5rem',
  lineHeight: '1.625',
})

const pageTitleStyle = css({
  fontWeight: '600',
  textAlign: 'center',
  fontSize: '20px',
})

const administratorStyle = css({
  textAlign: 'center',
})

const updateStyle = css({
  marginTop: '24px',
  marginBottom: '24px',
  fontSize: '14px',
  color: '#475569',
})

const semiTitleStyle = css({
  fontWeight: '600',
  marginTop: '32px',
  marginBottom: '8px',
})

const leadTextStyle = css({
  fontSize: '12px',
  marginTop: '1.5rem',
  marginBottom: '1.5rem',
  lineHeight: '1.625',
})

const listDiscStyle = css({
  listStyle: 'disc',
  marginRight: '1rem',
  marginLeft: '1rem',
  li: {
    listStyle: 'disc',
  },
})

const listStyle = css({
  paddingLeft: '1rem',
  paddingRight: '1rem',
  marginBottom: '1rem',
  listStyleType: 'decimal',
})

const listItemStyle = css({
  fontSize: '12px',
  lineHeight: '1.625',
  listStyleType: 'decimal',
})

const infoStyle = css({
  fontSize: '14px',
  marginTop: '1rem',
  lineHeight: '1.625',
})
