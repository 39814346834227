import React from 'react'
import QRCode from 'qrcode.react'

interface QrCodeProps {
  qrCode: string
}

export const QrCode: React.FC<QrCodeProps> = ({ qrCode }) => {
  return (
    <div>
      <QRCode style={{ width: '100%', height: '100%' }} value={qrCode} />
    </div>
  )
}
