import { http } from "@/apis/aipass"
import { env } from "@/libs/env"


export const fetchZipCode = async (zipcode: string) => {
  const response = await http({
    method: 'get',
    url: `${env('BASE_API_V3')}/zipcode-search`,
    params: { zipcode },
  })

  return response.data
}
