import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { NavigateHeader } from '@/components/atoms/navigate-header'
import { Layout } from '@/components/template/layout'

import { ReactComponent as UserPicture } from '@/static/images/icon/icon_account.svg'
import { FormLayout } from '@/components/template/form-layout'
import { css } from '@emotion/react'
import { MyPageLinkList } from '@/components/organisms/mypage-link-list'
import { getUserCheckInHistoryList } from '@/features/user/check-in-history-slice'
import { isEmpty } from 'lodash'
import { useHotelId } from '@/hooks/use-hotel-id'
import { logout } from '@/features/auth/logout-slice'
import { errorHandler } from '@/libs/errors'
import { CUSTOM_LINK } from '@/components/template/custom-design-style'
import { ContentWrapper } from '@/components/template/content-wrapper'

export const MyPage: React.FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const { getHotelId } = useHotelId()

  const dispatch = useAppDispatch()

  const userData = useAppSelector(state => state.accountInfo.user)
  const userCheckInHistoryList = useAppSelector(state => state.userCheckInHistoryList.checkinHistorys)

  const _getUserCheckInHistoryList = async () => {
    await dispatch(getUserCheckInHistoryList(userData.id)).unwrap()
  }

  useEffect(() => {
    if (userData.id && isEmpty(userCheckInHistoryList)) {
      _getUserCheckInHistoryList()
    }
  }, [userData])

  const _logout = async () => {
    const hotelId = getHotelId()
    if (!hotelId) {
      return
    }

    try {
      dispatch(logout({ hotelId, lang: language })).unwrap()
    } catch (error) {
      errorHandler({ error })
    }
  }

  return (
    <Layout>
      <NavigateHeader title={t('My Page')} style={{ marginBottom: '1rem' }} />
      <ContentWrapper>
        {/* user Info */}
        <FormLayout layoutCss={userInfoStyle}>
          <div css={userIconStyle}>
            {userData?.picture ? <img src={userData.picture} alt="user" css={userInfoImgStyle} /> : <UserPicture />}
          </div>
          <div>
            <p css={userNameStyle}>{userData.name}</p>
            <p css={numberCheckInsStyle}>
              {t('Number of check-ins')}：{userCheckInHistoryList.length}
            </p>
          </div>
        </FormLayout>

        <MyPageLinkList />
      </ContentWrapper>

      <p css={logoutTextStyle} className={CUSTOM_LINK} onClick={() => _logout()}>
        {t('Sign Out')}
      </p>
    </Layout>
  )
}

const userInfoImgStyle = css({
  width: '48px',
  height: '48px',
  borderRadius: '50%',
})

const userInfoStyle = css({
  display: 'flex',
  alignItems: 'center',
})

const userIconStyle = css({
  marginRight: '1.5rem',
})

const userNameStyle = css({
  fontWeight: 'bold',
})

const numberCheckInsStyle = css({
  fontSize: '12px',
  marginTop: '0.25rem',
})

const logoutTextStyle = css({
  textAlign: 'center',
  fontSize: '12px',
  marginTop: '2rem',
})
