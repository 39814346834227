import { http } from '@/apis/aipass'
import { env } from '@/libs/env'
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Reservation } from '../checkIn/search-reservations-slice'

interface getReservationsPaymentsParams { hotelId: string; reservationIds: string[] }
export const getReservationsPayments = createAsyncThunk(
  'checkout/reservationsPayments',
  async (data: getReservationsPaymentsParams, { rejectWithValue }) => {
    try {
      const response = await http({
        method: 'post',
        url: `${env('BASE_API_V3')}/checkout`,
        data,
      })
  
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

type checkoutReservation = Reservation & {
  roomInfo: any[]
}
export interface Payments {
  reservations: checkoutReservation[]
  totalAmount: number
  totalDiscount: number
  totalPayment: number
  totalPrice: number
  totalTaxPrice: number
}
const reservationsPayment = createSlice({
  name: 'reservationsPayment',
  reducers: {},
  initialState: {
    payments: {} as Payments,
    loading: false
  },
  extraReducers(builder) {
    builder.addCase(getReservationsPayments.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getReservationsPayments.fulfilled, (state, action: PayloadAction<Payments>) => {
      state.payments = action.payload
      state.loading = false
    })
  },
})

export const reservationsPaymentReducer = reservationsPayment.reducer
