import React from 'react'
import { CSSObject, SerializedStyles, css } from '@emotion/react'

interface ItemLabelProps {
  label: string
  value?: string | string[]
  itemCss?: CSSObject | SerializedStyles
}

export const ItemLabel: React.FC<ItemLabelProps> = ({ label, value, itemCss }) => {
  return (
    <div css={[itemCss, itemLabelStyle]}>
      <p>{label}</p>
      {Array.isArray(value) ? (
        value.map((v, index) => (
          <p key={index} className="item-value">
            {v}
          </p>
        ))
      ) : (
        <p className="item-value">{value || '-'}</p>
      )}
    </div>
  )
}

const itemLabelStyle = css({
  p: {
    '&:first-of-type': {
      fontSize: '12px',
    },
  },
  '.item-value': {
    fontWeight: 'bold',
    marginTop: '0.5rem',
    fontSize: '14px',
  },
})
