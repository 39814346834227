import { labelStyle } from '@/styles/form'
import { CSSObject, SerializedStyles, css } from '@emotion/react'
import React, { ComponentPropsWithRef, HTMLInputTypeAttribute, forwardRef } from 'react'
import { RequiredMark } from './required-mark'
import { ErrorMessage } from './error-message'
import { CUSTOM_FORM } from '../template/custom-design-style'

interface InputProps {
  label?: string
  name: string
  value?: string
  required?: boolean
  type?: HTMLInputTypeAttribute
  placeholder?: string
  defaultValue?: string
  itemCss?: SerializedStyles | CSSObject
  radiusStyle?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void
  pattern?: string
  error?: string
}

type InputType = ComponentPropsWithRef<'input'> & InputProps

export const Input: React.FC<InputType> = forwardRef(
  (
    { type = 'text', error, label, itemCss, required, placeholder, name, value, defaultValue, onChange, onBlur, pattern, radiusStyle },
    ref,
  ) => {
    return (
      <div css={itemCss}>
        {label && (
          <label css={labelStyle}>
            {label}
            {required && <RequiredMark />}
          </label>
        )}
        <input
          className={CUSTOM_FORM}
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
          css={[inputStyle, radiusStyle ? { borderRadius: '22px' } : undefined]}
          placeholder={placeholder}
          type={type}
          name={name}
          value={value}
          defaultValue={defaultValue}
          pattern={pattern}
        />
        {error && <ErrorMessage text={error} />}
      </div>
    )
  },
)
Input.displayName = 'Input'

export const inputStyle = css({
  display: 'block',
  width: '100%',
  appearance: 'none',
  paddingLeft: '20px',
  height: '44px',
})
