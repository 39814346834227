import React, { HTMLAttributes, useEffect, useMemo, useRef } from 'react'
import { Header } from '../molecules/header'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { fetchHotelGuide } from '@/features/hotel/hotel-guide-slice'
import { isEmpty } from 'lodash'
import { Footer } from '../molecules/footer'
import { Toast } from '@/features/notification/toast-slice'
import { useNotification } from '@/hooks/use-notification'
import { AccountInfo, getAccountInfo } from '@/features/user/account-slice'
import { useHotelId } from '@/hooks/use-hotel-id'
import { fetchAsset } from '@/features/asset/assets-slice'
import { fetchDesign } from '@/features/asset/design-slice'
import { CUSTOM_BG, CustomDesignStyle } from './custom-design-style'
import { useTranslation } from 'react-i18next'
import { LanguageType } from '@/i18n'
import { CurrentVersionKey, LatestVersionKey } from '@/apis/aipass'
import { useCustomScript } from '@/hooks/use-custom-script'

interface LayoutProps {
  children: React.ReactNode
}

export const Layout: React.FC<HTMLAttributes<LayoutProps>> = ({ children, style: parentStyle }) => {
  const auth = localStorage.getItem('access_token')
  const { Notification, openNotification } = useNotification()
  const { getHotelId } = useHotelId()
  const { i18n } = useTranslation()
  useCustomScript()
  const contentRef = useRef<HTMLDivElement>(null)

  const toast = useAppSelector<Toast>(state => state.toast)
  const dispatch = useAppDispatch()

  const accountInfo = useAppSelector<AccountInfo>(state => state.accountInfo.user)
  const hotelGuide = useAppSelector(state => state.hotelGuide)

  const validAppVersion = () => {
    if (sessionStorage.getItem(CurrentVersionKey) !== sessionStorage.getItem(LatestVersionKey)) {
      sessionStorage.removeItem(CurrentVersionKey)
      sessionStorage.removeItem(LatestVersionKey)
      window.location.reload()
    }
  }

  useEffect(() => {
    if (toast.message) openNotification(toast)
  }, [toast])

  useEffect(() => {
    const hotelId = getHotelId()
    if (!hotelId) {
      return
    }
    dispatch(fetchHotelGuide(hotelId))
    dispatch(fetchAsset({ hotelId }))
    dispatch(fetchDesign({ hotelId }))
    if (auth && isEmpty(accountInfo)) {
      dispatch(getAccountInfo())
    }
    validAppVersion()
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const hotelName = useMemo(
    () => (i18n.language === LanguageType.ja ? hotelGuide.facilityBasicInfo.hotelName : hotelGuide.facilityBasicInfo.hotelNameLatin),
    [hotelGuide, i18n.language],
  )

  return (
    <>
      <CustomDesignStyle />
      <div className={CUSTOM_BG} style={parentStyle}>
        {Notification}
        <div ref={contentRef}>
          {hotelName && <Header hotelName={hotelName} />}
          {children}
        </div>
        {contentRef.current && <Footer contentHeight={contentRef.current.getBoundingClientRect().height} />}
      </div>
    </>
  )
}
