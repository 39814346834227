import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FormLayout } from '@/components/template/form-layout'
import { AccountInfo, jpnSchema, njpSchema, putAccountInfo } from '@/features/user/account-slice'
import { SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from '@/components/atoms/button'
import { spacerTopStyle } from '@/styles/common'
import { formButtonStyle } from '@/styles/form'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@/app/store'
import { useAppSelector } from '@/app/hooks'
import dayjs from 'dayjs'
import { getFirstName, getSurname } from '@/utils/name'
import { LanguageType } from '@/i18n'
import { RegisterAccountForm } from '@/components/organisms/register-account-form'
import { errorHandler } from '@/libs/errors'
import { useLoginRedirect } from '@/hooks/use-login-redirect'
import { z } from 'zod'

export const RegisterAccountTemplate: React.FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const dispatch = useDispatch<AppDispatch>()

  const accountInfo = useAppSelector<AccountInfo>(state => state.accountInfo.user)
  const checkInData = useAppSelector(state => state.checkIn.checkInData.fields)
  const { hasPaymentPlugins } = useAppSelector(state => state.hotelGuide)
  const getPaymentData = useAppSelector(state => state.checkIn.checkInPayments)
  const paymentSettings = useAppSelector(state => state.paymentSetting.settings)
  const { customCheckin } = useAppSelector(state => state.customFields)
  const { ifNeedLoginRedirect } = useLoginRedirect()

  const navigate = useNavigate()
  const schema = z.union([jpnSchema, njpSchema])
  const useFormReturn = useForm<AccountInfo>({
    mode: 'all',
    resolver: zodResolver(schema),
    defaultValues: accountInfo,
    shouldFocusError: true,
  })

  const { handleSubmit, reset } = useFormReturn

  const onSubmit: SubmitHandler<AccountInfo> = async submitData => {
    let birthDate = accountInfo.birthDate
    if (submitData.birthYear && submitData.birthMonth && submitData.birthDay) {
      birthDate = `${submitData.birthYear}-${submitData.birthMonth}-${submitData.birthDay}`
      birthDate = dayjs(birthDate).format('YYYY-MM-DD')
    }

    const name = language === 'ja' ? `${submitData.lastName} ${submitData.firstName}` : `${submitData.firstName} ${submitData.lastName}`

    const formData: AccountInfo = {
      ...submitData,
      birthDate,
      name,
      nameKana: submitData.familyNameKana && submitData.givenNameKana ? `${submitData.familyNameKana} ${submitData.givenNameKana}` : '',
      telephone: submitData.telephone.toString(),
      email: accountInfo.email,
      picture: accountInfo.picture,
      nameLatin: accountInfo.nameLatin,
    }

    try {
      await dispatch(putAccountInfo(formData)).unwrap()

      if (ifNeedLoginRedirect()) {
        return
      }

      if (customCheckin.length) {
        return navigate('/custom-checkin')
      }

      if (hasPaymentPlugins && getPaymentData?.payments?.totalAmount > 0 && paymentSettings?.payment_time.length) {
        return navigate('/checkin-payment')
      }

      return navigate('/mypage')
    } catch (error) {
      errorHandler({ error })
    }
  }

  const suggestedAccountInfo: AccountInfo = useMemo(() => {
    const suggestSourceReservation = checkInData.selectedReservations
    const defaultFormValues: AccountInfo = {
      ...accountInfo,
      nationality: language === 'ja' ? 'JPN' : 'NJP',
      telephone: checkInData.telephone,
    }
    if (!suggestSourceReservation?.length) {
      return defaultFormValues
    }
    const name = suggestSourceReservation[0].guestName || suggestSourceReservation[0].userName || ''
    const nameKana = suggestSourceReservation[0].guestNameKana || ''
    const address = suggestSourceReservation[0].guestAddr || ''
    return {
      ...defaultFormValues,
      nationality: language === 'ja' ? 'JPN' : 'NJP',
      name,
      nameKana,
      lastName: getSurname(language as LanguageType, name),
      firstName: getFirstName(language as LanguageType, name),
      givenNameKana: nameKana.split(' ')[1],
      familyNameKana: nameKana.split(' ')[0],
      address,
    }
  }, [checkInData])

  useEffect(() => {
    suggestedAccountInfo && reset(suggestedAccountInfo)
  }, [suggestedAccountInfo])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormLayout>
        <RegisterAccountForm useFormReturn={useFormReturn} />
      </FormLayout>
      <Button buttonCss={[spacerTopStyle, formButtonStyle]} text={t('Next')} type="submit" />
    </form>
  )
}
