import { http } from '@/apis/aipass'
import { env } from '@/libs/env'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

interface checkoutInput {
  hotelId: string
  checkinId: string
  reservationId: string
  withReview: boolean
}
export const checkout = createAsyncThunk('checkout/checkout', async (data: checkoutInput, { rejectWithValue }) => {
  try {
    const response = await http({
      method: 'put',
      url: `${env('BASE_API_V3')}/checkout`,
      data,
    })

    return response.data
  } catch (error) {
    return rejectWithValue(error)
  }
})

const checkoutDataSlice = createSlice({
  name: 'checkout',
  reducers: {
    clearCheckoutResultHistories: state => {
      state.checkoutResultHistories = {}
      sessionStorage.setItem('checkoutResultHistories', '')
    },
  },
  initialState: {
    checkoutResultHistories: {},
    loading: false,
  },
  extraReducers(builder) {
    builder.addCase(checkout.fulfilled, (state, action) => {
      const payload = action.meta.arg

      Object.assign(state.checkoutResultHistories, { [payload.reservationId || payload.checkinId]: true })

      state.loading = false
      state.checkoutResultHistories = { ...state.checkoutResultHistories, [payload.reservationId || payload.checkinId]: true }
      sessionStorage.setItem('checkoutResultHistories', JSON.stringify(state.checkoutResultHistories))
    })
    builder.addCase(checkout.pending, (state) => {
      state.loading = true
    })
    builder.addCase(checkout.rejected, (state, action) => {
      const args = action.meta.arg

      state.loading = false
      state.checkoutResultHistories = { ...state.checkoutResultHistories, [args.reservationId || args.checkinId]: false }
      Object.assign(state.checkoutResultHistories, { [args.reservationId || args.checkinId]: false })

      sessionStorage.setItem('checkoutResultHistories', JSON.stringify(state.checkoutResultHistories))
    })
  },
})

export const { clearCheckoutResultHistories } = checkoutDataSlice.actions

export const checkoutDataReducer = checkoutDataSlice.reducer
