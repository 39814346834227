import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import { createRoot } from 'react-dom/client'
import 'antd/dist/reset.css'

// components
import { Router } from '@/components/apps/router'
import { AuthProvider } from './contexts/auth'
import { store } from './app/store'
import { Provider } from 'react-redux'
import { Global, css } from '@emotion/react'
import { AccountProvider } from './contexts/account'

export const App: React.FC = () => {
  return (
    <Provider store={store}>
      <AuthProvider>
        <AccountProvider>
          <Router />
        </AccountProvider>
      </AuthProvider>
    </Provider>
  )
}

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <React.StrictMode>
    <link rel="stylesheet" href={require('@/static/styles/reset.css')} />
    <link rel="stylesheet" href={require('@/static/styles/datepicker-custom.css')} />
    <Global
      styles={css`
        * {
          box-sizing: border-box;
          font-family: '游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic', 'Lato', sans-serif;
          letter-spacing: 0.3px;
          outline: 0;
          text-decoration: none;
        }
        @media only screen and (max-width: 599px) {
          * {
            font-family: 'Noto Sans JP', 'Lato', sans-serif;
          }
        }
        *::-webkit-scrollbar {
          height: 0;
          width: 0;
        }
        *::-webkit-scrollbar-track {
          background: transparent;
        }
        *::-webkit-scrollbar-thumb {
          background: #ccc;
          border-radius: 4px;
        }
        a {
          text-decoration: none;
        }
        li {
          list-style: none;
        }
      `}
    />
    <App />
  </React.StrictMode>,
)
