import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { NavigateHeader } from '@/components/atoms/navigate-header'
import { SelectBox } from '@/components/atoms/select-box'
import { RequestCartBlock } from '@/components/organisms/request/request-cart-block'
import { RequestPaymentForm } from '@/components/organisms/request/request-payment-form'
import { Layout } from '@/components/template/layout'
import { PaymentFormTemplate } from '@/components/template/payment-form-template'
import { RequestItem } from '@/features/request/request-cart-slice'
import { setSelectedRoom } from '@/features/request/request-order-slice'
import { css } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { CUSTOM_BLOCK } from '@/components/template/custom-design-style'
import { ContentWrapper } from '@/components/template/content-wrapper'

export const RequestOrderConfirmation: React.FC = () => {
  const { t, i18n } = useTranslation()

  const navigate = useNavigate()

  const { assetId } = useParams<{ assetId: string }>()

  const dispatch = useAppDispatch()

  const hasReservationPlugins = useAppSelector(state => state.hotelGuide.hasReservationPlugins)
  const { selectedRoom, ordersInfo } = useAppSelector(state => state.request.requestOrder)
  const cartRooms = useAppSelector(state => state.request.guestRoomByTelephone.cartRooms)
  const requestCart = useAppSelector(state => state.request.requestCart.cart)
  const requestCarts: RequestItem[] = assetId ? requestCart[assetId] : []
  const assets = useAppSelector(state => state.assets.assets)

  const sirName = (name: string | null) => {
    return i18n.language === 'ja' ? `${name ?? ''}様` : `Dear ${name ?? ''}`
  }

  const roomOptions = () => {
    const roomNumbers = cartRooms.map(cartRoom => cartRoom.roomNumber).flat()

    return roomNumbers.map(roomNumber => ({
      value: roomNumber,
      label: roomNumber,
    }))
  }

  const onSelectedRoom = (room: string) => {
    dispatch(setSelectedRoom({ room }))
  }

  useEffect(() => {
    if (isEmpty(requestCarts)) {
      return navigate(`/request/${assetId}`)
    }
  }, [requestCarts])

  useEffect(() => {
    if (!assets.find(asset => asset.id !== assetId)) {
      return navigate('/')
    }
  }, [assets])

  return (
    <Layout>
      <NavigateHeader title={t('Payment details')} style={{ marginBottom: '1rem' }} />
      <ContentWrapper>
        <div className={CUSTOM_BLOCK} style={{ padding: '2rem 1.5rem', marginBottom: '16px' }}>
          {hasReservationPlugins ? (
            <>
              {cartRooms.map(cartRoom => (
                <p key={cartRoom.checkinId} css={sirNameStyle}>
                  {sirName(cartRoom.name)}
                </p>
              ))}
              <span css={selectNameStyle}>{t('Select your room')}</span>

              <SelectBox
                name="roomNumber"
                onChange={e => onSelectedRoom(e.target.value)}
                defaultValue={selectedRoom}
                options={roomOptions()}
              />
            </>
          ) : (
            <>
              <p style={{ fontWeight: 'bold' }}>{`${ordersInfo.lastName}${ordersInfo.firstName}様`}</p>
              <p style={{ color: '##676767', fontSize: '14px', marginTop: '0.75rem' }}>
                {t('room name')}：{ordersInfo.roomNumber}
              </p>
            </>
          )}
        </div>

        {assetId && (
          <div css={{ marginBottom: '-0.5rem' }}>
            <RequestCartBlock requestCarts={requestCarts} assetId={assetId} />
          </div>
        )}

        <PaymentFormTemplate>
          <RequestPaymentForm selectedRoom={selectedRoom} />
        </PaymentFormTemplate>
      </ContentWrapper>
    </Layout>
  )
}

const sirNameStyle = css({
  fontWeight: 'bold',
  '&:not(first-of-type)': {
    marginTop: '0.25rem',
  },
})

const selectNameStyle = css({
  display: 'block',
  marginBlock: '1rem',
  fontSize: 12,
})
