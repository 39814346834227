import React, { useMemo } from 'react'
import { SerializedStyles, css } from '@emotion/react'
import useEmblaCarousel from 'embla-carousel-react'
import { EmblaOptionsType } from 'embla-carousel'
import { SelectedSnapDisplay, useSelectedSnapDisplay } from '@/components/atoms/carousel-selected-snap-display'

interface CarouselItemWrapperProps {
  children: React.ReactNode
  className?: string
  style: {
    width: string
    gap: string
  }
  options?: EmblaOptionsType
  customStyle?: SerializedStyles
  isShowSnapDisplay?: boolean
}

export const CarouselItemWrapper: React.FC<CarouselItemWrapperProps> = ({
  children,
  className,
  style,
  options,
  customStyle,
  isShowSnapDisplay,
}) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ align: 'center', containScroll: false, ...options })
  const { selectedSnap, snapCount } = useSelectedSnapDisplay(emblaApi)

  const emblaStyle = useMemo(() => {
    return css(
      {
        position: 'relative',
        '&.embla': {
          margin: 'auto',
          '--slide-spacing': style.gap,
          '--slide-size': style.width,
          overflow: 'hidden',
        },
        '.embla__viewport': {
          overflow: 'hidden',
        },
        '.embla__container': {
          backfaceVisibility: 'hidden',
          display: 'flex',
          touchAction: 'pan-y',
          marginLeft: 'calc(var(--slide-spacing) * -1)',
        },
        '.embla__slide': {
          flex: '0 0 calc(var(--slide-size) + var(--slide-spacing))',
          paddingLeft: 'var(--slide-spacing)',
        },
      },
      customStyle,
    )
  }, [style])

  return (
    <div css={emblaStyle} className={`embla ${className ?? ''}`} ref={emblaRef}>
      <div className="embla__container">{children}</div>
      {isShowSnapDisplay && <SelectedSnapDisplay selectedSnap={selectedSnap} snapCount={snapCount} />}
    </div>
  )
}
