import React, { useMemo, useRef, useState } from 'react'
import { css } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as PullDownIcon } from '@/static/images/icon/icon_pulldown.svg'
import dayjs from 'dayjs'
import { capitalize } from '@/libs/text'
import { groupBy, isEmpty } from 'lodash'
import { MapBusinessHourType } from '@/features/asset/assets-slice'

const NOW = dayjs()

interface BusinessTimeProps {
  mapBusinessHour: MapBusinessHourType[]
}

export const businessDay = (targetDay: string) => {
  const dayOfWeek = NOW.day()

  return targetDay === dayOfWeek.toString()
}

export const businessTime = (todayOpenBusinessTimes: MapBusinessHourType[]) =>
  todayOpenBusinessTimes.some(({ startTime, endTime }) => {
    const openDateTimeDayjs = dayjs(NOW.format(`YYYY/MM/DD ${startTime}`))
    let closeDateTimeDayjs = dayjs(NOW.format(`YYYY/MM/DD ${endTime}`))

    if (closeDateTimeDayjs.isSame(openDateTimeDayjs) || closeDateTimeDayjs.isBefore(openDateTimeDayjs)) {
      closeDateTimeDayjs = closeDateTimeDayjs.add(1, 'day')
    }

    return NOW.isAfter(openDateTimeDayjs) && NOW.isBefore(closeDateTimeDayjs)
  })

export const BusinessTime: React.FC<BusinessTimeProps> = ({ mapBusinessHour }) => {
  const [displayAllBusinessHours, setDisplayAllBusinessHours] = useState(false)

  const businessTextRef = useRef<HTMLSpanElement>(null)

  const {
    t,
    i18n: { language },
  } = useTranslation()

  const businessHoursList = useMemo(() => {
    const businessHoursItems = mapBusinessHour?.filter(item => !!item.weekday) || []

    if (
      language === 'ja' &&
      businessHoursItems.length &&
      businessHoursItems[0]?.weekday === '1' &&
      businessHoursItems[businessHoursItems.length - 1]?.weekday === '0'
    ) {
      const item = businessHoursItems.pop()
      if (item) {
        businessHoursItems.unshift(item)
      }
    }

    const businessHoursItemsGroupedDay = groupBy(businessHoursItems, 'weekday')
    const businessHoursOfDay = Object.keys(businessHoursItemsGroupedDay).map(key => {
      const aroundSpotBusinessHour = businessHoursItemsGroupedDay[key]
      const openHours = aroundSpotBusinessHour.map(({ startTime, endTime }) => `${startTime}~${endTime}`).join(', ')

      const dayName = dayjs().day(Number(key)).locale('en').format('dddd').toLocaleLowerCase()

      return {
        weekDay: key,
        day: dayName,
        openHours,
      }
    })

    return businessHoursOfDay
  }, [mapBusinessHour])

  const getTodayOpeningHours = () => {
    const todayOpenBusinessTimes = mapBusinessHour.filter(aroundSpotBusiness => businessDay(aroundSpotBusiness.weekday))

    if (isEmpty(todayOpenBusinessTimes)) {
      return <span>{t('regular holiday')}</span>
    }

    const isBusinessTime = businessTime(todayOpenBusinessTimes)

    return (
      <>
        <div css={todyBusinessTimeStyle}>
          <span ref={businessTextRef}>
            <span style={{ color: isBusinessTime ? '#399A39' : '#E36666' }}>
              {t(isBusinessTime ? 'open for business' : 'after business hours')}
            </span>
            &nbsp;:&nbsp;
          </span>
          <span className="time">{todayOpenBusinessTimes.map(({ startTime, endTime }) => `${startTime}~${endTime}`).join(', ')}</span>
        </div>
        <PullDownIcon
          css={[pullDownIconStyle, { transform: `rotate(${displayAllBusinessHours ? '180deg' : '0'})` }]}
          className="custom-design-icon"
          width={12}
        />
      </>
    )
  }

  if (isEmpty(businessHoursList)) {
    return '-'
  }

  return (
    <>
      <div css={timeTitleStyle} onClick={() => setDisplayAllBusinessHours(!displayAllBusinessHours)}>
        {getTodayOpeningHours()}
      </div>

      {displayAllBusinessHours && (
        <ul>
          {businessHoursList.map(businessHour => (
            <li key={businessHour.day} style={{ marginTop: '1rem', fontWeight: businessDay(businessHour.weekDay) ? 'bold' : 'normal' }}>
              <span style={{ width: `${businessTextRef.current?.getBoundingClientRect().width}px`, display: 'inline-block' }}>
                {capitalize(t(businessHour.day))}
              </span>
              {businessHour.openHours}
            </li>
          ))}
        </ul>
      )}
    </>
  )
}

const timeTitleStyle = css({
  display: 'flex',
  alignItems: 'center',
  '.time': {
    paddingRight: '0.5rem',
  },
})

const pullDownIconStyle = css({
  transitionProperty: 'all',
  transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
  transitionDuration: '150ms',
})

const todyBusinessTimeStyle = css({
  display: 'flex',
  alignItems: 'center',
  '.time': {
    flex: 1,
  },
})
