import { spacerTopStyle } from '@/styles/common'
import { css } from '@emotion/react'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { SelectBox } from '../atoms/select-box'
import { Input } from '../atoms/input'
import { explanatoryNoteStyle } from '@/styles/form'
import { Controller, UseFormReturn } from 'react-hook-form'
import { CardDetail } from '@/features/user/user-card-slice'
import { FormLayout } from '../template/form-layout'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { CardForm } from './card/card-form'
import { CardInfo } from './card/card-info'
import { isEmpty } from 'lodash'
import { LoginBlock } from './login-block'
import { useQuerySearchParams } from '@/hooks/use-query-search-params'
import { setPaymentType } from '@/features/user/payment/current-payment-slice'
import { Divider } from '../atoms/divider'
import { useLocation } from 'react-router-dom'

interface PaymentBlockProps {
  useFormReturn: UseFormReturn<CardDetail>
  paymentPlace: 'CHECKIN' | 'CHECKOUT' | 'MOBILE_REQUEST' | 'RESERVE_SPACE'
}

export const PaymentBlock: React.FC<PaymentBlockProps> = ({ useFormReturn, paymentPlace }) => {
  const { t } = useTranslation()

  const { hotelId, telephone } = useQuerySearchParams<{ hotelId: string; telephone: string }>()

  const dispatch = useAppDispatch()

  const hotelGuideData = useAppSelector(state => state.hotelGuide)
  const paymentSetting = useAppSelector(state => state.paymentSetting.settings)
  const userCreditCard = useAppSelector(state => state.creditCard.userCreditCardData)
  const userData = useAppSelector(state => state.accountInfo.user)

  const {
    control,
    register,
    formState: { errors },
    watch,
  } = useFormReturn

  const watchPaymentType = watch('paymentType')

  const { pathname } = useLocation()

  const createPaymentOptions = useMemo(() => {
    if (!hotelGuideData.hasPaymentPlugins) return []

    const paymentMethods =
      paymentSetting?.payment_time?.find(v => v.is_enabled && v.value === paymentPlace)?.payment_method?.filter(v => v.is_enabled) ?? []

    if (!paymentMethods.length) return []

    const paymentOptions: { label: string; value: string }[] = []

    for (const item of paymentMethods) {
      if (item.value === 'DIRECT') {
        paymentOptions.push({
          label: t('Payment at the front desk'),
          value: 'checkout',
        })
      } else if (item.value === 'CREDIT_CARD') {
        paymentOptions.push({
          label: t('Credit Card Payment'),
          value: 'creditCard',
        })
      }
    }

    return paymentOptions
  }, [hotelGuideData, paymentSetting])

  return (
    <section css={spacerTopStyle}>
      {!isEmpty(createPaymentOptions) && (
        <FormLayout>
          <Controller
            control={control}
            name="paymentType"
            render={({ field }) => (
              <SelectBox
                required
                {...register('paymentType')}
                label={t('Payment options')}
                options={createPaymentOptions}
                name="paymentType"
                error={errors.paymentType?.message}
                onChange={e => {
                  const value = e.target.value

                  field.onChange(value)
                  dispatch(setPaymentType({ paymentType: value }))
                }}
              />
            )}
          />
        </FormLayout>
      )}

      {watchPaymentType === 'creditCard' ? (
        isEmpty(userData) ? (
          <>
            <Divider />
            <LoginBlock redirectFrom={pathname} redirectParams={`hotelId=${hotelId}&telephone=${telephone}`}>
              <p css={loginTextStyle}>{t('Login is required for credit card payment')}</p>
            </LoginBlock>
          </>
        ) : (
          <>
            <Divider />
            {userCreditCard.cardBrand ? (
              <CardInfo />
            ) : (
              <>
                <CardForm useFormReturn={useFormReturn} />
              </>
            )}
            <FormLayout layoutCss={spacerTopStyle}>
              <div>
                <Input {...register('receiptName')} label={t('Receipt Name')} name="receiptName" />
                <span css={explanatoryNoteStyle}>{t('If you wish to split the receipt, please make the payment at the facility')}</span>
              </div>
            </FormLayout>
          </>
        )
      ) : (
        <></>
      )}
    </section>
  )
}

const loginTextStyle = css({
  paddingTop: '2rem',
  fontSize: '14px',
  textAlign: 'center',
})
