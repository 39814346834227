import React from 'react'
import { SerializedStyles, css } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import { CUSTOM_BLOCK } from '../template/custom-design-style'
import { displayYen } from '@/libs/text'

interface TotalAmountProps {
  totalAmount: string
  customCss?: SerializedStyles
}
export const TotalAmount: React.FC<TotalAmountProps> = ({ totalAmount, customCss }) => {
  const { t } = useTranslation()

  return (
    <div css={[totalAmountStyle, customCss]} className={CUSTOM_BLOCK}>
      <p>{t('Amount billed (tax included)')}</p>
      <p>{displayYen(totalAmount)}</p>
    </div>
  )
}

const totalAmountStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontWeight: 'bold',
  padding: '24px 32px',
  p: {
    '&:first-of-type': {
      fontSize: '12px',
    },
    '&:last-child': {
      fontSize: '20px',
      fontWeight: 'bold',
      textAlign: 'right',
    },
  },
})
