import { RequestItem, changeCart, removeCart } from '@/features/request/request-cart-slice'
import React, { useMemo } from 'react'
import { RequestInputItem } from './request-input-item'
import { useAppDispatch } from '@/app/hooks'
import { RequestAssetDetailType } from '@/features/asset/assets-slice'
import { sum } from 'lodash'
import { css } from '@emotion/react'
import { CUSTOM_BLOCK } from '@/components/template/custom-design-style'
import { useTranslation } from 'react-i18next'
import { displayYen } from '@/libs/text'

interface RequestCartBlockProps {
  requestCarts: RequestItem[]
  assetId: string
}

export const RequestCartBlock: React.FC<RequestCartBlockProps> = ({ requestCarts, assetId }) => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const onRemove = (detailId: string) => {
    dispatch(removeCart({ detailId, assetId }))
  }

  const onChange = (detail: RequestAssetDetailType, count: number) => {
    dispatch(changeCart({ count, detail, assetId }))
  }

  const totalAmount = useMemo(() => {
    const requestCartPrices = requestCarts.map(requestCart => requestCart.detail.price * requestCart.count)
    return sum(requestCartPrices)
  }, [requestCarts])

  return (
    <>
      <ul className="custom-design-block">
        {requestCarts?.map((requestCart, index, currentArray) => (
          <RequestInputItem
            key={requestCart.detail.id}
            requestCart={requestCart}
            onChange={e => onChange(requestCart.detail, Number(e.target.value))}
            displayDivider={index + 1 !== currentArray.length}
            onItemRemove={() => onRemove(requestCart.detail.id)}
          />
        ))}
      </ul>

      <div css={[totalAmountStyle, { marginBlock: '1rem', }]} className={CUSTOM_BLOCK}>
        <p>{t('Amount billed (tax included)')}</p>
        <p>{displayYen(totalAmount.toString())}</p>
      </div>
    </>
  )
}

const totalAmountStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontWeight: 'bold',
  padding: '26px 32px',
  p: {
    '&:first-of-type': {
      fontSize: '12px',
    },
    '&:last-child': {
      fontSize: '20px',
      fontWeight: 'bold',
      textAlign: 'right',
    },
  },
})
