import React, { useEffect } from 'react'
import { parseToken } from '@/apis/auth0'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '@/app/hooks'
import { signInBySns } from '@/features/auth/auth-slice'
import { useLoginRedirect } from '@/hooks/use-login-redirect'

export const AuthCallback: React.FC = () => {
  let isLoadedComponent = false
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { ifNeedLoginRedirect } = useLoginRedirect()

  const validSnsToken = async (accessToken: string) => {
    try {
      const { idToken } = await parseToken()
      await dispatch(signInBySns({ idToken })).unwrap()
      if (ifNeedLoginRedirect()) {
        return
      }
      navigate('/mypage')
    } catch (error) {
      console.error({ error })
      navigate('/login')
    }
  }

  useEffect(() => {
    if (isLoadedComponent) {
      return
    }
    isLoadedComponent = true

    const token = window.location.hash.replace(/#access_token=/g, '').split('&')[0]
    if (!token) {
      return
    }
    validSnsToken(token)
  }, [])

  return <></>
}
