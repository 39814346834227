import React from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { Link, useNavigate } from 'react-router-dom'
import { SubmitHandler, useForm } from 'react-hook-form'
import { z } from 'zod'

import { Input } from '@/components/atoms/input'
import { useTranslation } from 'react-i18next'
import { FormLayout } from '@/components/template/form-layout'
import { Button } from '@/components/atoms/button'
import { horizontalSpacing, spacerTopStyle } from '@/styles/common'
import { css } from '@emotion/react'
import { useAppDispatch } from '@/app/hooks'
import { checkEmail, forgotPassword, signIn, signUp } from '@/features/auth/auth-slice'
import { useQuerySearchParams } from '@/hooks/use-query-search-params'
import { passwordRefine } from '@/utils/zod-validate-map'
import { errorHandler } from '@/libs/errors'
import { MessageType, toastAction } from '@/features/notification/toast-slice'
import { useLoginRedirect } from '@/hooks/use-login-redirect'

export const EmailLoginBlock: React.FC = () => {
  const searchParams = useQuerySearchParams<{ hotelId: string; userStatus: string; email: string }>()
  const { ifNeedLoginRedirect } = useLoginRedirect()
  const navigate = useNavigate()

  const { t, i18n } = useTranslation()

  const dispatch = useAppDispatch()

  interface SubmitData {
    email: string
    password: string
  }

  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid, isDirty },
  } = useForm<SubmitData>({
    mode: 'onBlur',
    resolver: zodResolver(
      z.object({
        password: passwordRefine,
      }),
    ),
  })

  const watchEmail = watch('email')

  const onSubmit: SubmitHandler<SubmitData> = async submitData => {
    // check email
    try {
      let userStatus = searchParams.userStatus

      if (!userStatus) {
        userStatus = (await dispatch(checkEmail(submitData?.email)).unwrap()).status
      }

      const email = submitData.email || searchParams.email

      if (userStatus === 'reset') {
        await dispatch(
          forgotPassword({
            email: email,
            hotelId: searchParams.hotelId,
            language: i18n.language,
          }),
        ).unwrap()
        return
      }

      if (userStatus === 'exists') {
        await dispatch(signIn({ email: email, password: submitData.password })).unwrap()
      } else {
        await dispatch(
          signUp({
            email: email,
            password: submitData.password,
            language: i18n.language,
          }),
        ).unwrap()
      }

      if (ifNeedLoginRedirect()) {
        return
      }
      return navigate('/mypage')
    } catch (error) {
      if (typeof error === 'string') {
        return dispatch(toastAction.setMessage(MessageType.Error, t(error)))
      }

      errorHandler({ error })
    }
  }

  return (
    <>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormLayout>
            <Input required {...register('password')} name="password" type="password" label={t('Password')} placeholder="・・・・・・・" />
            <div css={passwordTextStyle}>
              <p>{t('Must include lowercase, uppercase letters and numbers')}</p>
              <Link to={`/forget-password?email=${searchParams.email || watchEmail}`}>{t('Did you forget your password?')}</Link>
            </div>
          </FormLayout>

          <Button disabled={!isDirty || !isValid} buttonCss={[spacerTopStyle, horizontalSpacing]} type="submit" text={t('Next')} />
        </form>
      </div>
    </>
  )
}

const passwordTextStyle = css({
  paddingTop: '1em',
  fontSize: 12,
  textAlign: 'center',
  lineHeight: 1.5,
  letterSpacing: 1.8,
  p: {
    textAlign: 'left',
    paddingBottom: '1rem',
  },
})
