import React from 'react'
import { ImageAssetDetailType } from '@/features/asset/assets-slice'
import { HtmlContent } from '@/components/atoms/html-content'
import { css } from '@emotion/react'
import { usePickByLanguage } from '@/hooks/use-pick-by-language'
import { CUSTOM_BLOCK } from '@/components/template/custom-design-style'
import { pcScreenStyle } from '@/styles/common'

type Props = {
  asset: ImageAssetDetailType
}

export const ImageAssetDetail: React.FC<Props> = ({ asset }) => {
  const pickByLanguage = usePickByLanguage()

  return (
    <div css={[pageStyle, pcScreenStyle]} className={CUSTOM_BLOCK}>
      <h2>{pickByLanguage(asset.title)}</h2>
      <HtmlContent text={pickByLanguage(asset.description)} />
    </div>
  )
}

const pageStyle = css({
  padding: '32px 16px',
  h2: {
    fontSize: 24,
    fontWeight: 'bold',
    lineHeight: 1.5,
    marginBottom: 24,
  },
})
