import { useAppSelector } from '@/app/hooks'
import { HtmlContent } from '@/components/atoms/html-content'
import { Layout } from '@/components/template/layout'
import { NewsAssetDetailType } from '@/features/asset/assets-slice'
import { usePickByLanguage } from '@/hooks/use-pick-by-language'
import { pcScreenStyle } from '@/styles/common'
import { css } from '@emotion/react'
import dayjs from 'dayjs'
import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'

export const NoticeDetail: React.FC = () => {
  const { assetId, noticeId } = useParams<{ noticeId: string; assetId: string }>()
  const { assets } = useAppSelector(state => state.assets)

  const pickByLanguage = usePickByLanguage()

  const content = useMemo(() => {
    const newsAsset = assets.find(asset => asset.id === assetId)
    return (newsAsset?.details as NewsAssetDetailType[])?.find(newsAsset => newsAsset.id === noticeId)
  }, [assets, assetId, noticeId])

  return (
    <Layout>
      {content && (
        <main className="custom-design-block" css={[mainLayoutStyle, pcScreenStyle]}>
          <h2 className="title">{pickByLanguage(content.title)}</h2>
          <time className="time">{dayjs(content.updatedAt).format('YYYY.MM.DD')}</time>
          <div className="content">
            <HtmlContent text={pickByLanguage(content.description)} />
          </div>
        </main>
      )}
    </Layout>
  )
}

const mainLayoutStyle = css({
  paddingBlock: '2rem',
  paddingInline: '1rem',
  '.title': {
    marginBottom: '0.25rem',
    fontSize: '20px',
    lineHeight: 1.45,
  },
  '.time': {
    fontSize: '12px',
  },
  '.content': {
    marginTop: '1.5rem',
  },
})
