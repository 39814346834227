import { css } from '@emotion/react'
import React from 'react'

export const ZhContent: React.FC = () => {
  return (
    <div css={layoutStyle}>
      <div css={administratorStyle}>
        <h2 css={pageTitleStyle}>aipass 服务条款｜设施用户</h2>

        <p css={updateStyle}>最后更新日期：2022 年 4 月 15 日。</p>
      </div>

      <h3 css={semiTitleStyle}>第 1 章 总则</h3>

      <h3 css={semiTitleStyle}>第 1 条（适用）。</h3>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          {`本 aipass 使用条款（以下简称 "条款"）适用于 aipass 有限公司提供的名为 aipass 的服务（以下简称 "服务"）。 本 aipass 使用条款（以下简称 "条款"）适用于 aipass 有限公司（以下简称 "公司"）提供的名为 aipass 的服务（以下简称 "服务"）。 (以下简称 "公司"）提供的名为 aipass 的服务（以下简称 "服务"）的使用。 (aipass 有限公司（以下简称 "本公司"）提供的 aipass 服务（以下简称 "本服务"）的使用，适用于本公司与用户之间。`}
        </li>
        <li css={listItemStyle}>
          {`除本条款外，公司还可能制定使用规则等与本服务相关的各种规定（以下简称 "个别规定"）。 这些个别条款无论名称如何，均构成本条款的一部分。 无论其名称如何，这些个别条款均构成本条款的一部分。`}
          如果本条款和条件的规定与前一条中的个别规定相冲突，则以个别规定为准，除非个别规定中另有规定。
        </li>
      </ol>

      <h3 css={semiTitleStyle}>第 2 条（定义）。</h3>
      <p css={leadTextStyle}>在本条款和条件中，以下术语具有相关章节中规定的含义。</p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          成员
          <br />
          {`同意本条款并注册信息后，与公司签订服务使用合同（以下简称 "使用合同"）的个人。 同意本条款并注册信息后，与公司签订服务使用合同（以下简称 "使用合同"）的个人。`}
        </li>
        <li css={listItemStyle}>
          用户
          <br />
          同意本条款和条件，与公司签订本用户协议，无需注册即可访问和使用服务的个人。
        </li>
        <li css={listItemStyle}>
          用户
          <br />
          成员和用户。
        </li>
        <li css={listItemStyle}>
          本网站
          <br />
          公司为提供服务而运营的网站。 服务通过网站或软件提供给用户，具体定义见下一条。
        </li>
        <li css={listItemStyle}>
          该软件
          <br />
          指公司为提供服务而创建和发布的应用软件，包括更新版本、修改版本、替换版本和副本。
        </li>
        <li css={listItemStyle}>
          注册信息
          <br />
          公司定义的会员相关信息，会员必须注册才能成为服务会员。
        </li>
        <li css={listItemStyle}>
          会员信息
          <br />
          本公司为本服务管理的服务器上保存的各种信息和通信记录，以及会员的所有其他信息，包括注册信息和本公司提供的信息或会员通过本服务获得的信息。
        </li>
      </ol>

      <h3 css={semiTitleStyle}>第 3 条 （对职权范围的修改）</h3>
      <p css={leadTextStyle}>公司可随时更改本条款和条件的内容，而无需事先征得用户的同意。</p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          公司根据aipass使用条款的规定变更条款内容并通知用户时，用户在通知中规定的日期（但通知中未规定日期的，以公司发出通知的日期为准）之后使用本服务的，视为同意变更后的条款内容。
          如果会员在通知中指定的日期或之后使用本服务（但如果通知中没有指定日期，则以发出通知的日期为准），则视为同意修改后的条款和条件，并适用修改后的条款和条件。
          如果会员未在通知中指定的日期内办理取消手续，则视为同意修订后的条款和条件。
        </li>
      </ol>

      <h3 css={semiTitleStyle}>第 4 条（通知）</h3>
      <p css={leadTextStyle}>
        公司向用户发出有关本服务的通知时，将以公司认为适当的方式进行，例如在网站上发布通知，或向注册信息中登记的电子邮件地址或地址发送电子邮件或文件。
      </p>
      <ol css={listStyle}>
        <li css={listItemStyle}>以前款规定的方式发出的通知，前者在网站上公布通知内容时生效，后者在公司发出电子邮件或文件时生效。</li>
        <li css={listItemStyle}>用户向公司提出的有关本服务的询问和其他通信或通知应按照公司规定的方式进行。</li>
      </ol>

      <h3 css={semiTitleStyle}>第 2 章 合同的订立</h3>
      <h3 css={semiTitleStyle}>第 5 条（本使用合同的订立（接纳）</h3>
      <p css={leadTextStyle}>
        {`希望利用本服务的人按照 "公司 "规定的方式提出申请，且 "公司 "接受该申请时，即签订利用本服务的合同。 希望使用本服务的人按照公司规定的方式提出申请时，即视为同意本条款和条件。`}
      </p>
      <ol css={listStyle}>
        <li css={listItemStyle}>会员自公司接受前款申请之日起即可使用本服务。</li>
        <li css={listItemStyle}>
          如果未成年人希望使用本服务，必须征得其法定代理人的同意。 如果未成年人成为会员，则视为其法定代理人已同意使用本服务及本条款和条件。
        </li>
      </ol>

      <h3 css={semiTitleStyle}>第 6 条（费用和付款方式）</h3>
      <p css={leadTextStyle}>
        使用服务所需的通信费用（包括下载和使用软件所产生的通信费用） 用户应承担并负责网站、软件和电信设备等的准备工作。
        公司不保证网站和软件在用户使用的通信设备等上正常运行。
      </p>

      <h3 css={semiTitleStyle}>第 3 章 服务使用注意事项</h3>
      <h3 css={semiTitleStyle}>第 7 条（会员标识等）</h3>
      <p css={leadTextStyle}>
        {`会员对申请本服务时指定的会员ID（电子邮件地址）和会员密码（以下简称 "会员ID等"）负有管理责任。 会员对申请本服务时指定的会员ID（电子邮件地址）和会员密码（以下简称 "会员ID等"）的管理负有严格的责任，并对使用会员ID等所进行的一切行为负责。`}
      </p>
      <ol css={listStyle}>
        <li css={listItemStyle}>会员不得允许自己以外的任何人使用本服务。</li>
        <li css={listItemStyle}>如果会员发现自己的会员密码或会员 ID 被盗或被第三方使用，应立即通知公司并遵守公司的指示。</li>
      </ol>

      <h3 css={semiTitleStyle}>第 8 条（注册信息的变更）</h3>
      <p css={leadTextStyle}>
        会员应按照公司规定的方式及时通知公司其注册信息的任何变更。 对于会员因未通知注册信息变更而造成的任何损失，公司不承担任何责任。
      </p>

      <h3 css={semiTitleStyle}>第 9 条（禁止行为）</h3>
      <p css={leadTextStyle}>用户不得从事与服务相关的以下行为</p>
      <ol css={listStyle}>
        <li css={listItemStyle}>向公司作虚假申报。</li>
        <li css={listItemStyle}>将公司根据本用户协议提供的信息和服务（包括网站和软件）用于服务使用之外的任何目的。</li>
        <li css={listItemStyle}>
          任何侵犯或可能侵犯公司或任何第三方财产（包括知识产权）的行为。
          侵犯或可能侵犯隐私权、名誉权、信用权、肖像权或公开权，或公司或第三方的其他权利或利益的行为。
        </li>
        <li css={listItemStyle}>侵犯或可能侵犯公司或第三方权利或利益的前项以外的行为。</li>
        <li css={listItemStyle}>违反法律法规或违背公共秩序和道德的行为，或可能导致此类行为的行为。</li>
        <li css={listItemStyle}>未经授权使用或获取第三方的会员 ID 等，或会员假借第三方名义使用本服务的任何其他行为。</li>
        <li css={listItemStyle}>使用或传播或可能使用或传播计算机病毒或其他有害程序的行为。</li>
        <li css={listItemStyle}>
          除前项规定外，公司认为干扰或可能干扰公司业务的执行、服务的实施或公司电信设备等的未经授权的访问行为或其他行为。
        </li>
        <li css={listItemStyle}>公司认为不适当的任何其他行为。</li>
      </ol>

      <h3 css={semiTitleStyle}>第 10 条（入住机构并接受住宿条款和条件）</h3>
      <p css={leadTextStyle}>如果用户通过本服务在住宿机构办理入住手续，则视为已申请该住宿机构并同意该住宿机构的条款和条件。</p>

      <h3 css={semiTitleStyle}>第 4 章 取消、终止和退出</h3>
      <h3 css={semiTitleStyle}>第 11 条（会员取消服务）</h3>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          {`会员希望解除本协议时，应按照 "公司 "规定的方式办理解除手续，解除手续办理完毕后，本服务利用协议即告解除。 此时，会员应自行确认公司的解除通知。 如果 "会员 "希望终止各服务的使用，则应按照各服务规定的终止手续办理。`}
        </li>
        <li css={listItemStyle}>如果会员根据第 1 条取消使用合同，公司可删除会员信息。</li>
      </ol>

      <h3 css={semiTitleStyle}>第 12 条（公司解除合同）</h3>
      <p css={leadTextStyle}>
        如果用户属于以下情况之一，公司可在不通知用户的情况下部分或全部取消用户协议、撤销用户会员资格或暂停提供服务。
      </p>
      <ol css={listStyle}>
        <li css={listItemStyle}>如果违反本条款和条件的任何规定。</li>
        <li css={listItemStyle}>如果向公司提供的全部或部分注册信息存在虚假、错误或遗漏</li>
        <li css={listItemStyle}>
          {`反社会势力等（指帮派、帮派成员、右翼组织、反社会势力、帮派准成员、大会成员、社会运动宣传团体、特别情报团体和其他类似人员）。
          (下同）。 (iii) 如果 "公司
          "合理地认定此人是反社会势力、右翼组织、反社会势力的准成员、总集会所、社会运动的倡导者、特殊情报暴力团伙或任何其他等同人员。`}
        </li>
        <li css={listItemStyle}>如果出现拖欠费用或其他付款义务的情况。</li>
        <li css={listItemStyle}>如果您超过 90 天未回复我们的通信</li>
        <li css={listItemStyle}>如果知道申请人以前曾被取消过服务会籍。</li>
        <li css={listItemStyle}>公司合理认定用户不适合作为用户的其他情况。</li>
      </ol>
      <p css={leadTextStyle}>
        {`根据前款规定退出会员资格或中止提供服务时，"公司 "不退还已收取的费用，也不对用户或第三方造成的任何损害或损失承担责任。`}
      </p>

      <h3 css={semiTitleStyle}>第 13 条（排除帮派等反社会势力）</h3>
      <p css={leadTextStyle}>
        {`用户在使用本服务时，应声明自己不属于或不属于黑帮、黑帮相关公司、总集会所、社会运动团体、政治运动团体、特殊情报暴力团体及其他反社会势力（以下简称 "黑帮等反社会势力"），并声明今后不与黑帮等反社会势力有任何瓜葛。 当事人应声明自己不属于或不属于以下任何一类：黑帮、黑帮附属公司、集会分子、社会活动家、政治活动家、特殊情报暴力团体和其他反社会势力（以下简称 "黑帮等反社会势力"），并声明今后不与黑帮等反社会势力有任何牵连。`}
      </p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          如本公司判定用户属于帮派等反社会势力或与之有牵连，本公司可在不事先通知的情况下，采取暂停或终止用户使用本服务等措施。
        </li>
        <li css={listItemStyle}>对于因用户违反本条规定而暂停或终止使用所造成的任何损失，公司不承担任何义务或责任。</li>
      </ol>

      <h3 css={semiTitleStyle}>第 5 章 暂停、修改和终止服务</h3>
      <h3 css={semiTitleStyle}>第 14 条（暂停服务）</h3>
      <p css={leadTextStyle}>如发生以下情况，公司可在不事先通知用户的情况下暂停部分或全部服务。</p>
      <ol css={listStyle}>
        <li css={listItemStyle}>如果提供该服务所需的系统、设备等出现故障，或需要进行维护、保养或施工。</li>
        <li css={listItemStyle}>因本公司以外的第三方行为（如电信运营商停止提供电信服务）而导致难以提供服务时。</li>
        <li css={listItemStyle}>
          发生紧急情况（自然灾害、战争、恐怖主义、暴乱、骚乱、官方处置、劳资纠纷等），导致或可能导致难以提供此项服务时。
        </li>
        <li css={listItemStyle}>如果由于可同步服务的情况导致可同步服务不再可用</li>
        <li css={listItemStyle}>如果由于法律规定、行政命令等原因难以提供此项服务。</li>
        <li css={listItemStyle}>
          其他公司认为必要或因无法控制的原因而不可避免的情况。 根据前款规定暂停服务时，对用户或第三方造成的损害或损失，公司不承担任何责任。
        </li>
      </ol>

      <h3 css={semiTitleStyle}>第 15 条（服务变更）</h3>
      <p css={leadTextStyle}>
        {`公司 "可自行决定添加或更改 "服务 "的任何部分。 公司 "不保证根据本条款对 "服务 "进行的追加或变更能够保持变更前 "服务 "的所有功能和性能。
      根据前款规定对 "服务 "进行追加或变更后，对用户造成的任何损害或不利，公司概不负责。`}
      </p>

      <h3 css={semiTitleStyle}>第 16 条（服务的中断和终止）</h3>
      <p css={leadTextStyle}>
        {`公司 "可在事先通知 "会员 "的情况下，自行决定中止或终止提供部分或全部 "服务"。 但是，如果中止或终止的性质并不严重，则无需通知即可执行。
      公司对用户根据前款规定暂停或终止服务所造成的任何损失不承担任何责任。`}
      </p>

      <h3 css={semiTitleStyle}>第17条 （契約上の地位の譲渡等）</h3>
      <p css={leadTextStyle}>
        {`未经 "公司 "事先同意，用户不得向第三方转让、转移、抵押或以其他方式处置其在使用合同中的地位或其在使用合同中的权利或义务。
      本公司通过业务转让、公司分割等方式将本服务相关业务转让给第三方时（以下简称 "业务转让等"），本公司可将本使用合同的地位、本使用合同的权利义务及用户相关信息转让给业务转让等的受让方，用户应事先同意该转让及转让。`}
      </p>

      <h3 css={semiTitleStyle}>第 6 章 一般规定</h3>
      <h3 css={semiTitleStyle}>第 18 条（保证）</h3>
      <p css={leadTextStyle}>
        公司将尽最大努力确保服务在推荐的环境中运行。但是，公司不保证用户信息的准确性、合法性、有用性或完整性。
        用户在使用用户信息时，应自行决定并承担必要的变更、修改等责任。
      </p>

      <h3 css={semiTitleStyle}>第19条 （知的財産権）</h3>
      <p css={leadTextStyle}>
        与服务相关的所有知识产权，包括版权、精神权利、专利权、实用新型权、设计权、商标权和宣传权，均属于本公司和合法权利人第三方。
      </p>

      <h3 css={semiTitleStyle}>第 20 条（赔偿和免责）</h3>
      <p css={leadTextStyle}>
        如果本公司被认定在本服务方面存在过失，本公司将对直接造成用户损失的本服务进行赔偿，最高赔偿额为 1,000 日元，对其他损失概不负责。
      </p>
      <ol css={listStyle}>
        <li css={listItemStyle}>如果用户违反这些条款和条件或在使用服务时给公司造成损失，用户必须赔偿给公司造成的损失。</li>
        <li css={listItemStyle}>
          如果用户与第三方之间发生与本服务有关的争议，用户应自行负责解决争议并承担费用，不得给公司造成任何不便，并应赔偿公司因此而造成的任何损失（包括律师费）。
          公司将赔偿用户因此造成的任何损失（包括律师费）。
        </li>
      </ol>

      <h3 css={semiTitleStyle}>第 21 条（委托）</h3>
      <p css={leadTextStyle}>公司可将与服务相关的部分或全部业务外包给第三方。</p>

      <h3 css={semiTitleStyle}>第 22 条（信息管理）</h3>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          未经会员事先同意，公司不会向第三方披露会员信息。 但是，这不适用于以下情况。
          <ol css={innerListStyle}>
            <li>当收到法律或公共机构的要求，并认为有必要满足该要求时。</li>
            <li>为保护某人的生命、身体或财产所必需，且难以征得其同意时</li>
            <li>向支付系统公司、信用卡公司、银行等存入本服务注册用户的信息，以便向会员收取使用本服务的费用时</li>
            <li>向受托人或代理人等披露信息，这是提供本服务或改进或加强其职能所必需的</li>
            <li>当公司与其子公司联合使用以提供协调服务时</li>
          </ol>
        </li>
        <li css={listItemStyle}>
          {`尽管有前款规定，但公司可能会汇总和分析用户信息的属性，并以无法识别或指定成员的方式进行处理（以下称为 "统计数据"）。`}
          统计资料可用于本服务和公司的其他服务。 统计资料也可能向第三方披露。
        </li>
        <li css={listItemStyle}>公司采取一切合理和最大限度的安全措施，防止会员信息丢失、破坏、篡改或泄漏。</li>
        <li css={listItemStyle}>本公司为了提高电话应答服务等的质量，可能会对与用户的通话进行录音，并将录音内容用于本公司的业务。</li>
      </ol>

      <h3 css={semiTitleStyle}>第 23 条（个人信息）</h3>
      <p css={leadTextStyle}>
        尽管有上一条的规定，我们对个人数据的处理仍应按照我们的《隐私政策》进行。
        即使在本用户协议终止后，公司仍可在隐私政策规定的使用目的范围内继续使用会员的个人数据。
      </p>

      <h3 css={semiTitleStyle}>第 24 条（禁止转让权利和义务）</h3>
      <p css={leadTextStyle}>
        {`未经本公司事先书面同意，"会员 "不得将本用户协议规定的权利义务转让给第三方（包括因合并、公司分立等导致的全面继承）。 未经本公司事先书面同意，"会员 "不得将本用户协议规定的权利和义务转让给任何第三方（包括因合并、分立等导致的全面继承），也不得以担保为目的提供这些权利和义务。`}
      </p>

      <h3 css={semiTitleStyle}>第 25 条（准据法、有管辖权的法院）</h3>
      <p css={leadTextStyle}>
        本条款和条件以及本用户协议应根据日本法律解释，并受日本法律管辖。
        用户与本公司就本用户协议发生任何争议时，东京地方法院为一审专属管辖法院。
      </p>

      <p css={[revisionStyle, { marginTop: '32px' }]}>2019 年 9 月 27 日 设立</p>
      <p css={revisionStyle}>2019年11月15日 修订</p>
      <p css={revisionStyle}>2020年01月08日 修订</p>
      <p css={revisionStyle}>2020年10月29日 修订</p>
      <p css={revisionStyle}>2021年07月28日 修订</p>
      <p css={revisionStyle}>2022年04月15日 修订</p>
    </div>
  )
}

const layoutStyle = css({
  marginTop: '5rem',
  paddingRight: '2.5rem',
  paddingLeft: '2.5rem',
  lineHeight: '1.625',
})

const pageTitleStyle = css({
  fontWeight: '600',
  textAlign: 'center',
  fontSize: '20px',
})

const administratorStyle = css({
  textAlign: 'center',
})

const updateStyle = css({
  marginTop: '24px',
  marginBottom: '24px',
  fontSize: '14px',
  color: '#475569',
})

const semiTitleStyle = css({
  fontWeight: '600',
  marginTop: '32px',
  marginBottom: '8px',
})

const leadTextStyle = css({
  fontSize: '12px',
  marginTop: '1.5rem',
  marginBottom: '1.5rem',
  lineHeight: '1.625',
})

const listStyle = css({
  paddingLeft: '1rem',
  paddingRight: '1rem',
  marginBottom: '1rem',
  listStyleType: 'decimal',
})

const listItemStyle = css({
  fontSize: '12px',
  lineHeight: '1.625',
  listStyleType: 'decimal',
})

const innerListStyle = css({
  marginRight: '32px',
  marginLeft: '32px',
  listStyleType: 'lower-latin',
})

const revisionStyle = css({
  fontWeight: '600',
  fontSize: '14px',
  marginBottom: '4px',
  '&:first-of-type': {
    marginTop: 0,
  },
})
