import React, { useMemo } from 'react'
import { useAppSelector } from '@/app/hooks'
import { Divider } from '@/components/atoms/divider'
import { NewsAssetType } from '@/features/asset/assets-slice'
import { usePickByLanguage } from '@/hooks/use-pick-by-language'
import { css } from '@emotion/react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { CUSTOM_TEXT_COLOR_LINK } from '@/components/template/custom-design-style'

const DISPLAY_LENGTH = 3

interface NoticeBlockProps {
  asset: NewsAssetType
}

export const NoticeBlock: React.FC<NoticeBlockProps> = ({ asset }) => {
  const { t } = useTranslation()

  const pickByLanguage = usePickByLanguage()

  const design = useAppSelector(state => state.design)

  const newsDetails = useMemo(() => {
    const details = asset?.details || []

    return details.slice(0, DISPLAY_LENGTH)
  }, [asset])

  return (
    <div className="custom-design-block" css={noticeBlockStyle}>
      <div className="title-block">
        <h3 className="title" style={{ color: design.theme.brandColor }}>
          {pickByLanguage(asset.title)}
        </h3>
        <Link style={{ color: design.theme.brandColor }} className="link" to={`/notice/${asset.id}`}>
          {t('View All')}
        </Link>
      </div>

      <ul className="details">
        {newsDetails.map((newsDetail, index) => (
          <React.Fragment key={newsDetail.id}>
            <li className={`list-item ${CUSTOM_TEXT_COLOR_LINK}`}>
              <Link className="list-item-link" to={`/notice/${asset.id}/${newsDetail.id}`}>
                <time>{dayjs(newsDetail.updatedAt).format('YYYY.MM.DD')}</time>
                <p className="list-item-title">{pickByLanguage(newsDetail.title)}</p>
              </Link>
            </li>
            {index + 1 !== newsDetails.length && <Divider />}
          </React.Fragment>
        ))}
      </ul>
    </div>
  )
}

const noticeBlockStyle = css({
  paddingBlock: '2rem',
  paddingInline: '1rem',
  marginBottom: '8px',
  '.title-block': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '4px',
  },
  '.title': {
    fontSize: '18px',
  },
  '.link': {
    fontSize: '12px',
  },
  '.list-item': {
    marginBottom: '1rem',
    ':last-of-type': {
      marginBottom: 0,
    },
  },
  '.list-item-link': {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '1rem',
    time: {
      fontSize: '10px',
    },
  },
  '.list-item-title': {
    paddingLeft: '1rem',
    fontSize: '14px',
    lineHeight: '1.5'
  },
})
