import { css } from '@emotion/react'
import React from 'react'

export const ZhTwContent: React.FC = () => {
  return (
    <div css={layoutStyle}>
      <div css={administratorStyle}>
        <h2 css={pageTitleStyle}>【第二版】資訊安全政策</h2>

        <p css={updateStyle}>最後更新時間：2024年 4月1日</p>
      </div>

      <p css={leadTextStyle}>
        ＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿ <br />
        ISMS-01_ <br />
        資訊安全政策 <br />
        修訂歷史第3版 <br />
        授權人：山田真由美 <br />
        內容：高層管理名稱的變更<br />
        批准日期： 2024年4月1日 <br />
        ＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿
      </p>

      <h3 css={semiTitleStyle}>1 目的</h3>
      <p css={leadTextStyle}>
        aipass株式會社（以下簡稱「本公司」）在進行網路應用程式的企劃、開發、營運、銷售業務以及員工管理（以下簡稱「本公司」）時收集了大量資訊。（以下簡稱「企業」）。由於資產是使用的，因此實現適當的資訊安全並努力保護資訊資產是促進基於社會信任的企業活動的必要要求，也是重要的。社會責任感。
        因此，考慮到資訊安全的重要性，我公司製定本資訊安全政策（以下簡稱「本政策」），並建立、實施和維護資訊安全管理系統來具體實施。繼續改進它。
      </p>

      <h3 css={semiTitleStyle}>2 資訊安全的定義</h3>
      <p css={leadTextStyle}>資訊安全被定義為維護機密性、完整性和可用性。</p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          機密性 <br />
          這意味著保護資訊資產免遭未經授權的訪問，並防止其洩露給未經授權查看的任何人。
          （不允許資訊被使用或揭露給未經授權的個人、實體或流程的特徵）
        </li>
        <li css={listItemStyle}>
          完整性 <br />
          這意味著資訊資產不受篡改和錯誤的影響，並且得到準確、完整的維護。 （準確性和完整性的特徵）
        </li>
        <li css={listItemStyle}>
          可用性 <br />
          這意味著可以保護資訊資產免於遺失、損壞或系統中斷，並且可以在需要時使用。 （經授權實體請求時可以存取和使用的特性）
        </li>
      </ol>

      <h3 css={semiTitleStyle}>3 適用範圍</h3>
      <p css={leadTextStyle}>本政策適用於本公司管理的所有資訊資產。 資訊資產的範圍不僅限於電子設備、電子數據，還包括紙本介質等一切形式。</p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          組織 <br />
          aipass株式会社（所有員工）
        </li>
        <li css={listItemStyle}>
          設施 <br />
          總辦事處（地址：Shinoda Building 3F, 23-3 Sakuragaokacho, Shibuya-ku, Tokyo）
        </li>
        <li css={listItemStyle}>
          商業 <br />
          Web應用程式的規劃、開發、營運、銷售
        </li>
        <li css={listItemStyle}>
          資產 <br />
          與上述業務及各類服務相關的文件、資料、資訊系統、網路等
        </li>
      </ol>

      <h3 css={semiTitleStyle}>4 實施事宜</h3>
      <p css={leadTextStyle}>根據本政策和我們的資訊安全管理體系，我們將實施以下事項。</p>
      <ol css={listStyle}>
        <li css={listItemStyle}>
          關於資訊安全目標 <br />
          我們將製定與本政策一致的資訊安全目標，考慮適用的資訊安全要求以及風險評估和風險應對的結果，向所有員工傳達，並應對公司環境的變化。即使沒有變化，也會不時地進行評論。
        </li>
        <li css={listItemStyle}>
          關於資訊資產的處理 <br />
          <ol css={listDiscStyle}>
            <li>存取權限將僅授予出於業務目的所需的人員。</li>
            <li>我們根據法律法規要求、合約要求以及我們的資訊安全管理系統的規定來管理這些資訊。</li>
            <li>我們從價值、機密性、完整性、可用性的角度，根據資訊資產的重要性進行適當分類和管理。</li>
            <li>我們進行持續監控，以確保資訊資產得到妥善管理。</li>
          </ol>
        </li>
        <li css={listItemStyle}>
          關於風險評估 <br />
          <ol css={listDiscStyle}>
            <li>我們進行風險評估，根據業務特徵對最重要的資訊資產實施適當的風險應對措施，並採取控制措施。</li>
            <li>我們將分析資訊安全相關事故的原因，並採取措施防止再次發生。</li>
          </ol>
        </li>
        <li css={listItemStyle}>
          關於業務連續性管理 <br />
          我們最大限度地減少因災害、故障等造成的業務中斷，確保業務連續性。
        </li>
        <li css={listItemStyle}>
          關於教育 <br />
          我們將為所有員工提供資訊安全教育和培訓。
        </li>
        <li css={listItemStyle}>
          遵守法規和程序 <br />
          我們將遵守資訊安全管理系統的規定和程序。
        </li>
        <li css={listItemStyle}>
          遵守法律法規和合約要求 <br />
          遵守有關資訊安全的法律法規要求以及合約要求。
        </li>
        <li css={listItemStyle}>
          連續的提高 <br />
          我們將持續完善資訊安全管理系統。
        </li>
        <li css={listItemStyle}>
          資訊安全政策組 <br />
          我們制定了以下作為與資訊安全政策相關的資訊安全政策小組。
        </li>
      </ol>
      <p css={leadTextStyle}>
        與行動裝置相關的政策 <br />
        存取控制 <br />
        使用加密控制的政策 <br />
        清除桌面/清除螢幕政策 <br />
        資訊傳輸政策 <br />
        發展政策要牢記安全 <br />
        供應商關係資訊安全政策 <br />
      </p>

      <h3 css={semiTitleStyle}>5 責任、義務和處罰</h3>
      <p css={leadTextStyle}>
        最高管理階層對包括本政策在內的資訊安全管理系統負責，本政策範圍內的員工有義務遵守既定的規則和程序。
        此外，對疏忽職守或違法行為的員工，將依勞動法規定給予紀律處分。 對於合作公司的員工，我們將按照各自簽訂的合約進行處理。
      </p>

      <h3 css={semiTitleStyle}>6 定期審查</h3>
      <p css={leadTextStyle}>應根據需要定期審查、維護和管理資訊安全管理系統。</p>

      <p css={[leadTextStyle, { marginTop: '2rem' }]}>
        頒布：2021年4月1日 <br />
        最後修改日期：2024年4月1日 <br />
        高階管理人員 山田 真由美
        <br />
      </p>
    </div>
  )
}

const layoutStyle = css({
  marginTop: '5rem',
  paddingRight: '2.5rem',
  paddingLeft: '2.5rem',
  lineHeight: '1.625',
})

const pageTitleStyle = css({
  fontWeight: '600',
  textAlign: 'center',
  fontSize: '20px',
})

const administratorStyle = css({
  textAlign: 'center',
})

const updateStyle = css({
  marginTop: '24px',
  marginBottom: '24px',
  fontSize: '14px',
  color: '#475569',
})

const semiTitleStyle = css({
  fontWeight: '600',
  marginTop: '32px',
  marginBottom: '8px',
})

const leadTextStyle = css({
  fontSize: '12px',
  marginTop: '1.5rem',
  marginBottom: '1.5rem',
  lineHeight: '1.625',
})

const listDiscStyle = css({
  listStyle: 'disc',
  marginRight: '1rem',
  marginLeft: '1rem',
  li: {
    listStyle: 'disc',
  },
})

const listStyle = css({
  paddingLeft: '1rem',
  paddingRight: '1rem',
  marginBottom: '1rem',
  listStyleType: 'decimal',
})

const listItemStyle = css({
  fontSize: '12px',
  lineHeight: '1.625',
  listStyleType: 'decimal',
})
