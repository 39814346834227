import { http } from '@/apis/aipass'
import { env } from '@/libs/env'
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

interface getInvoiceDetailsParams { cuicinUserId: string; orderId: string }
export const getInvoiceDetails = createAsyncThunk('user/usageDetail', async (params: getInvoiceDetailsParams, { rejectWithValue }) => {
  try {
    const response = await http({
      method: 'get',
      url: `${env('BASE_API_V3')}/payment/usage-detail`,
      params,
    })
  
    return response.data
  } catch (error) {
    return rejectWithValue(error)
  }
})

interface PdfInfo {
  date: string
  gender: string
  hotelAddress: string
  hotelName: string
  hotelTelephone: string
  hotelRegistrationNumber: string
  name: string
  receiptNumber: string
}

interface Sales {
  createdAt: string
  hotelId: string
  salesDate: string
  salesPrice: number
  salesQuantity: number
  salesSubjectName: string
  salesSubjectNameEn: string
  salesSubjectNameKo: string
  salesSubjectNameZh: string
  salesSubjectPrice: number
  salesSubjectTax: number
}

interface Total {
  totalReceipt8TaxPrice: number
  totalReceipt10TaxPrice: number
  totalSales8TaxPrice: number
  totalSales10TaxPrice: number
  totalSalesDiscount: number
  totalSalesDiscount8Price: number
  totalSalesDiscount10Price: number
  totalSalesPrice: number
}

interface UsageDetail {
  pdfInfo: PdfInfo,
  salesList: Sales[],
  total: Total
}

const getInvoiceDetailsSlice = createSlice({
  name: 'usageDetail',
  initialState: {
    loading: true,
    pdfInfo: {} as PdfInfo,
    salesList: [] as Sales[],
    total: {} as Total
  },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getInvoiceDetails.fulfilled, (state, action: PayloadAction<UsageDetail>) => {
      state.pdfInfo = action.payload.pdfInfo
      state.salesList = action.payload.salesList
      state.total = action.payload.total
      state.loading = false
    })
  },
})

export const getInvoiceDetailsReducer = getInvoiceDetailsSlice.reducer
