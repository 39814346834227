import React, { useMemo } from 'react'
import { SerializedStyles, css } from '@emotion/react'
import { useAppSelector } from '@/app/hooks'

interface CloseButtonProps {
  onClick?: () => void
  customStyle?: SerializedStyles
}

export const CloseButton: React.FC<CloseButtonProps> = ({ onClick, customStyle }) => {
  const design = useAppSelector(state => state.design)

  const closeButtonStyle = useMemo(
    () =>
      css({
        width: '22px',
        height: '22px',
        background: design?.background.backgroundColor,
        borderRadius: '50%',
        position: 'relative',
        border: 'none',
        '&:before, &:after': {
          content: '""',
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: '2px',
          height: '12px',
          background: design?.icon.color,
        },
        '&:before': {
          transform: 'translate(-50%,-50%) rotate(45deg)',
        },
        '&:after': {
          transform: 'translate(-50%,-50%) rotate(-45deg)',
        },
      }),
    [design],
  )

  return <button type="button" onClick={onClick} css={[closeButtonStyle, customStyle]}></button>
}
